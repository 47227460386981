import { type FunctionComponent, memo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// local imports
import { AnalyticsProvider } from './analytics';
import { GlobalProvider } from './global';
import { DataProvider } from './data';
import { SupervisorProvider } from './supervisor';
import { PersistentProvider } from './persistent';
import ApolloContext from './ApolloContext';

type ContextProvidersProps = {
  children?: ReactNode | ReactNode[];
};

const ContextProvidersPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired as Validator<ReactNode | ReactNode[]>
};

const ContextProviders: FunctionComponent<ContextProvidersProps> = ({
  children
}) => (
  <AnalyticsProvider>
    <GlobalProvider>
      <DataProvider>
        <SupervisorProvider>
          <PersistentProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <ApolloContext>
                {children}
              </ApolloContext>
            </LocalizationProvider>
          </PersistentProvider>
        </SupervisorProvider>
      </DataProvider>
    </GlobalProvider>
  </AnalyticsProvider>
);

ContextProviders.propTypes = ContextProvidersPropTypes;

export default memo(ContextProviders);
