import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// TM UI Components
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
// local imports
import { Skill } from '../models/skill';
// SCSS imports
import { root, icon, iconInactive } from './SelectableSkillCard.module.scss';

type SelectableSkillCardProps = {
  skill: Skill;
  onClick?: (skill: Skill) => void;
  disabled?: boolean | null;
}

const SelectableSkillCardPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

const SelectableSkillCard = forwardRef<HTMLDivElement, SelectableSkillCardProps>(({
  skill,
  disabled = false,
  onClick
}, ref) => {
  const { title, is_target } = skill || {};
  const isTarget = Boolean(is_target);

  const handleClick = useCallback(() => {
    if (onClick && skill && skill.id) onClick(skill);
  }, [onClick, skill]);

  return (
    <ItemCard
        ref={ref}
        small
        active={isTarget}
        onClick={disabled ? undefined : handleClick}
        className={root}
    >
      <TargetIcon
          small
          active={isTarget}
          disabled={disabled}
          className={isTarget ? icon : iconInactive}
      />
      {title}
    </ItemCard>
  );
});

SelectableSkillCard.displayName = 'SelectableSkillCard';

SelectableSkillCard.propTypes = SelectableSkillCardPropTypes;

export default memo(SelectableSkillCard);
