import { createContext, type FunctionComponent, type ReactNode, useContext, useEffect } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import mixpanel from 'mixpanel-browser';
// local imports
import { AdminJob } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import { Job } from '../models/job';
import { PATH_HOME } from '../config/paths';
import { history } from '../config/history';

export type AnalyticsStateType = {
  identify: Function,
  track: Function,
  time_event: Function,
  people: { set: Function },
  set_group: Function
}

const emptyMixpanel: AnalyticsStateType = {
  identify: (/* id */) => null,
  track: (/* name, props */) => null,
  time_event: (/* name */) => null,
  people: { set: (/* props */) => null },
  set_group: (/* id */) => null
};

export const AnalyticsContext = createContext<AnalyticsStateType>(emptyMixpanel);

export const useMixpanel = () => useContext(AnalyticsContext);

export const currentEmployeeDetails: {
  code?: string;
  distinct_id?: string;
} = {};

export const getJobAnalyticsData = (role: Job | JobLookupItem | AdminJob) => role ? {
  ...currentEmployeeDetails,
  jobId: role.id,
  jobCode: role.code as string,
  jobTitle: role.title
} : currentEmployeeDetails;

type AnalyticsProviderProps = {
  children: ReactNode | ReactNode[];
};

const AnalyticsProviderPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired as Validator<ReactNode | ReactNode[]>
};

export const AnalyticsProvider: FunctionComponent<AnalyticsProviderProps> = ({
  children
}) => {
  useEffect(() => {
    const token = window.MIXPANEL_TOKEN;
    if (token) {
      mixpanel.init(token);
      history.listen(({ location: { pathname } }) => {
        if (pathname && pathname !== PATH_HOME) {
          const { code } = currentEmployeeDetails;
          if (code) {
            mixpanel.identify(code);
            mixpanel.track('Navigation', { ...currentEmployeeDetails, pathname });
            mixpanel.people.increment('page_views');
          }
        }
      });
    }
  }, []);

  return (
    <AnalyticsContext.Provider value={window.MIXPANEL_TOKEN ? mixpanel : emptyMixpanel}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = AnalyticsProviderPropTypes;
