import { useMemo, useState } from 'react';
import {
  type OperationVariables, type LazyQueryHookOptions, type QueryResult, type LazyQueryResultTuple
} from '@apollo/client';

type ICountedResponse<T, K extends string = 'results'> = {
  count?: number;
} & Record<K, T[] | undefined>;

function useQueryCounted<T, D extends object, V extends OperationVariables>({
  key,
  resultsKey = 'results',
  query: queryResult,
  lazyQuery: queryTuple
}: {
  key: keyof D;
  resultsKey?: string;
  query?: QueryResult<D, V>;
  lazyQuery?: LazyQueryResultTuple<D, V>;
  data: T;
}) {
  const [queryAsync, result] = queryTuple || [undefined, queryResult];
  if (!result) throw new Error('useQueryCounted: either `query` or `lazyQuery` is required');
  const { loading, error, data, previousData, variables } = result;
  const cachedData = (data as D)?.[key] || (previousData as D)?.[key];
  const { count, [resultsKey]: results } = (cachedData || {}) as ICountedResponse<T, typeof resultsKey>;
  const [called, setCalled] = useState(!queryAsync);

  // catch any exceptions thrown by the query
  const query = useMemo(() => queryAsync
    ? (options?: Partial<LazyQueryHookOptions<D, V>>) => {
        setCalled(true);
        (async () => {
          try {
            await queryAsync(options);
          } catch (err) {
            // do nothing
          }
        })();
      }
    : queryAsync,
    [queryAsync]
  );

  const failed = Boolean(error) || (called && !loading && !cachedData);
  const pending = loading || (!error && !cachedData);

  return useMemo(() => ({
    // fetch function for lazy queries
    query,
    // standard Apollo Client result
    loading,
    error,
    data,
    previousData,
    variables,
    // our custom result
    failed,
    pending,
    count: failed ? null : count,
    results
  }), [count, data, error, failed, loading, pending, previousData, query, results, variables]);
}

export default useQueryCounted;
