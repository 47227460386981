import { forwardRef, memo, useReducer, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toLower from 'lodash/toLower';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
// TM UI Components
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import SimpleTooltip from '@empathco/ui-components/src/elements/SimpleTooltip';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
// local imports
import { SkillActivity, SkillActivityType } from '../graphql/types';
import { getActivityIcon } from '../helpers/icons';
import { toggleReducer } from '../helpers/reducers';
import SkillActivityDialog from '../v3/SkillActivityDialog';
// Media and SCSS imports
import SkillAdvisors from '../images/skill_advisors.svg?react';
import { icon, activityIcon, smallIcon, disabledIcon, txt, subtitle } from './SkillDevelopmentResource.module.scss';

type SkillDevelopmentResourceProps = {
  skillActivity?: SkillActivity | null;
  small?: boolean;
  src?: string | null;
  text?: ReactNode | ReactNode[] | null;
  href?: string | null;
  hover?: string;
  onClick?: () => void;
  disabled?: boolean | null;
  // for Storybook only
  testOpen?: boolean;
}

const SkillDevelopmentResourcePropTypes = {
  skillActivity: PropTypes.object as Validator<SkillActivity>,
  small: PropTypes.bool,
  src: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  href: PropTypes.string,
  hover: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  testOpen: PropTypes.bool
};

// eslint-disable-next-line complexity
const SkillDevelopmentResource = forwardRef<HTMLDivElement, SkillDevelopmentResourceProps>(({
  skillActivity,
  small = false,
  src = '',
  text = '',
  href = '',
  hover = '',
  onClick,
  disabled = false,
  testOpen = false
}, ref) => {
  const [open, toggleOpen] = useReducer(toggleReducer, testOpen);

  const icn = skillActivity ? (small && smallIcon) || activityIcon : icon;
  const iconClassName = disabled ? `${icn} ${disabledIcon}` : icn;
  const activityType = !src && skillActivity?.activity_type
    ? toLower(skillActivity.activity_type) as SkillActivityType : undefined;
  const ActivityIcon = getActivityIcon(activityType, Boolean(skillActivity));

  const img = src ? <img src={src} className={iconClassName} alt="" loading="eager"/> : (ActivityIcon && (
    <ActivityIcon color={disabled ? 'inherit' : 'secondary'} className={iconClassName}/>
  )) || <SkillAdvisors className={iconClassName}/>;

  const button = (href && (
    <Button
        variant="text"
        color="primary"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        disabled={disabled ? true : undefined}
    >
      {img}
    </Button>
  )) || (!small && (
    <Button
        variant="text"
        color="primary"
        onClick={skillActivity ? toggleOpen : onClick}
        disabled={disabled ? true : undefined}
    >
      {img}
    </Button>
  )) || undefined;

  const content = (
    <>
      {disabled || !hover || !button ? button : (
        <SimpleTooltip
            open={open}
            onOpen={toggleOpen}
            onClose={toggleOpen}
            disableInteractive
            disableFocusListener
            placement="top"
            title={hover}
        >
          {button}
        </SimpleTooltip>
      )}
      {skillActivity ? (small && (
        <Chip
            variant="outlined"
            color="primary"
            clickable
            onClick={toggleOpen}
            label={(
              <Box display="flex" alignItems="center">
                {img}
                <TruncatedTextLink
                    plain
                    text={skillActivity.name}
                    variant="inherit"
                    maxLines={1}
                    disabled={disabled ? true : undefined}
                />
              </Box>
            )}
            disabled={disabled ? true : undefined}
        />
      )) || (
        <>
          <FormattedMessage id="skill.development.activity" values={{ type: activityType || null }}/>
          <br/>
          <TruncatedTextLink
              onClick={disabled ? undefined : toggleOpen}
              maxLines={1.75}
              variant="h4"
              className={subtitle}
              text={skillActivity.name}
          />
        </>
      ) : text}
    </>
  );

  return (
    <>
      {small || skillActivity ? (
        <Box
            ref={ref}
            display="flex"
            flexDirection={small ? undefined : 'column'}
            alignItems="center"
            px={2}
            pb={2}
            color={disabled ? 'action.disabled' : undefined}
            className={txt}
        >
          {content}
        </Box>
      ) : (
        <GridBox
            ref={ref}
            item
            container
            xs={12}
            md={6}
            lg={4}
            xl={3}
            flexDirection="column"
            alignItems="center"
            px={4}
            pb={2}
            color={disabled ? 'action.disabled' : undefined}
            className={txt}
        >
          {content}
        </GridBox>
      )}
      {skillActivity ? <SkillActivityDialog skillActivity={skillActivity} open={open} onClose={toggleOpen}/> : undefined}
    </>
  );
});

SkillDevelopmentResource.displayName = 'SkillDevelopmentResource';

SkillDevelopmentResource.propTypes = SkillDevelopmentResourcePropTypes;

export default memo(SkillDevelopmentResource);
