import { memo, useEffect, useMemo, type FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// TM UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
// local imports
import { getDelegator } from '../models/user';
import { DEV_PLAN_QUERY } from '../graphql/DevPlan';
import { COHORT_QUERY } from '../graphql/Cohort';
import { EMPLOYEE_DETAILS_QUERY } from '../graphql/EmployeeDetails';
import { DevPlanDocument, CohortDocument, EmployeeDetailsDocument } from '../graphql/types';
import { CohortDetails, DevPlanDetails, TalentEmployeeDetails } from '../graphql/customTypes';
import {
  PATH_HR_DEV_PLANS, PATH_HR_DEV_PLAN_COHORT_NEW, PATH_HR_DEV_PLAN_EMPLOYEE_NEW, PATH_HR_DEV_PLAN_EDITOR
} from '../config/paths';
import { getPlanId, getCohortId, getEmployeeId, toSafeIntId } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import LeaderHeader from '../elements/LeaderHeader';
import Screen from '../v3/Screen';
import DevPlansPanel from '../panels/DevPlansPanel';
import DevPlanReview from '../panels/DevPlanReview';
import DevPlanEditor from '../panels/DevPlanEditor';

// eslint-disable-next-line complexity
const HRDevPlanScreen: FunctionComponent = () => {
  const params = useParams();
  const plan_id = getPlanId(params);
  const cohort_id = getCohortId(params);
  const employee_id = toSafeIntId(getEmployeeId(params));

  const { pathname } = useLocation();
  const { content, user } = useVerifyUser({ hr: true });
  const leader = getDelegator(user);

  // lazy load dev plan
  const { query: getDevPlan, pending, failed, results: devplan } = useQueryObject({
    data: undefined as unknown as DevPlanDetails,
    key: 'devplan',
    flatResults: true,
    lazyQuery: useLazyQuery(DEV_PLAN_QUERY as typeof DevPlanDocument)
  });

  // lazy load cohort
  const { query: getCohort, pending: pendingCohort, failed: failedCohort, results: cohort } = useQueryObject({
    data: undefined as unknown as CohortDetails,
    key: 'cohort',
    flatResults: true,
    lazyQuery: useLazyQuery(COHORT_QUERY as typeof CohortDocument)
  });

  // lazy load employee
  const { query: getEmployee, pending: pendingEmployee, failed: failedEmployee, results: employee } = useQueryObject({
    data: undefined as unknown as TalentEmployeeDetails,
    key: 'employeeDetails',
    flatResults: true,
    lazyQuery: useLazyQuery(EMPLOYEE_DETAILS_QUERY as typeof EmployeeDetailsDocument)
  });

  useEffect(() => {
    if (!content && plan_id) getDevPlan?.({ variables: { devplan_id: plan_id } });
  }, [content, plan_id, getDevPlan]);

  useEffect(() => {
    if (!content && cohort_id) getCohort?.({ variables: { cohort_id } });
  }, [content, cohort_id, getCohort]);

  useEffect(() => {
    if (!content && employee_id) getEmployee?.({ variables: { employee_id } });
  }, [content, employee_id, getEmployee]);

  const loadedPlan = Boolean(plan_id) && plan_id === devplan?.id;
  const loadedCohort = Boolean(cohort_id) && cohort_id === cohort?.id;
  const loadedEmployee = Boolean(employee_id) && employee_id === employee?.id;

  const link = useMemo(() =>
    (plan_id && injectParams(PATH_HR_DEV_PLAN_EDITOR, { plan_id })) ||
    (cohort_id && injectParams(PATH_HR_DEV_PLAN_COHORT_NEW, { cohort_id })) ||
    (employee_id && injectParams(PATH_HR_DEV_PLAN_EMPLOYEE_NEW, { employee_id })) || undefined,
    [plan_id, cohort_id, employee_id]
  );

  const breadcrumbs = useMemo(() => loadedPlan ? [{ title: devplan.title }] : undefined, [loadedPlan, devplan]);

  const items = useMemo(() => [
    {
      title: 'hr.dev_plans',
      link
    },
    {
      title: 'hr.dev_plans.saved',
      active: Boolean(plan_id) && pathname !== link,
      link: PATH_HR_DEV_PLANS
    }
  ], [plan_id, pathname, link]);

  return content || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      {leader ? <LeaderHeader leader={leader}/> : undefined}
      <ContentCard withoutTopMargin={Boolean(leader)}>
        <CardTabbar withoutScroll items={items}>
          <DevPlanEditor
              devplan={loadedPlan ? devplan : null}
              cohort={loadedCohort ? cohort : null}
              employee={loadedEmployee ? employee : null}
              pending={(plan_id && (pending || plan_id !== devplan?.id)) ||
                (cohort_id && (pendingCohort || cohort_id !== cohort?.id)) ||
                (employee_id && (pendingEmployee || employee_id !== employee?.id))
                ? true : undefined}
              failed={(plan_id && failed) || (cohort_id && failedCohort) || (employee_id && failedEmployee)
                ? true : undefined}
          />
          {plan_id ? (
            <DevPlanReview
                devplan={loadedPlan ? devplan : null}
                pending={pending || plan_id !== devplan?.id}
                failed={failed}
            />
          ) : <DevPlansPanel/>}
        </CardTabbar>
      </ContentCard>
    </Screen>
  );
};

export default memo(HRDevPlanScreen);
