import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import round from 'lodash/round';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type GridSize } from '@mui/material/Grid';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { OtherResource } from '../config/customer';

const values = { br: <br/> };

type ExploreOtherResourcesSectionProps = {
  title: string;
  isInternational?: boolean;
  vertical?: boolean;
  resources?: OtherResource[] | null;
};

const ExploreOtherResourcesSectionPropTypes = {
  title: PropTypes.string.isRequired,
  isInternational: PropTypes.bool,
  vertical: PropTypes.bool,
  resources: PropTypes.array
};

const ExploreOtherResourcesSection: FunctionComponent<ExploreOtherResourcesSectionProps> = ({
  title,
  isInternational = false,
  vertical = false,
  resources
}) => (
  <>
    <BoxTypography
        px={vertical ? 7 : 6}
        variant="h3"
        textAlign={vertical ? undefined : 'center'}
    >
      <FormattedMessage id={title}/>
    </BoxTypography>
    <GridBox
        container
        py={3.5}
        px={4}
    >
      {map(resources, ({ text, link, intl }, index) => (
        <GridBox
            key={index}
            item
            xs={12}
            sm={vertical ? undefined : 6}
            md={vertical ? undefined : round(12 / size(resources)) as GridSize}
            container
            justifyContent={vertical ? undefined : 'center'}
            px={3}
            py={1.5}
        >
          <StandardLink
              active
              variant="h4"
              text={isInternational && intl ? intl.text : text}
              link={isInternational && intl ? intl.link : link}
              values={values}
          />
        </GridBox>
      ))}
    </GridBox>
  </>
);
ExploreOtherResourcesSection.propTypes = ExploreOtherResourcesSectionPropTypes;

export default memo(ExploreOtherResourcesSection);
