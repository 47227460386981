import {
  memo, useState, useCallback, useEffect, useLayoutEffect, useMemo,
  type FunctionComponent, type MouseEventHandler
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import endsWith from 'lodash/endsWith';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
// TM UI Components
import { getName } from '@empathco/ui-components/src/helpers/strings';
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { REDEPLOYMENT_EMPLOYEE_JOBS_QUERY } from '../graphql/RedeploymentEmployeeJobs';
import { UPDATE_REDEPLOYMENT_EMPLOYEE_JOB } from '../graphql/UpdateRedeploymentEmployeeJob';
import {
  JobScope, EmployeeJobsSort, SortDirection, RedeploymentEmployeeJobsQuery, RedeploymentEmployeeJobsInput,
  RedeploymentEmployeeJobsDocument, UpdateRedeploymentEmployeeJobDocument
} from '../graphql/types';
import { RedeploymentEmployee, RedeploymentDetails, RedeploymentEmployeeJob } from '../graphql/customTypes';
import {
  JobSort, JOB_SORT_LEVEL, JOB_SORT_LOCATION, JOB_SORT_MATCH_RATE, JOB_SORT_OPEN_REQS, JOB_SORT_TITLE, JobSortExt
} from '../constants/jobSort';
import { Job } from '../models/job';
import { locationParams } from '../helpers/context';
import useModels from '../helpers/models';
import { updateCachedResults } from '../helpers/graphql';
import { redeploymentJobsSortVar, redeploymentJobsVar } from '../context/variables';
import useCustomerSettings from '../config/customer';
import EmployeeName from '../elements/EmployeeName';
import JobsIndexFilters from '../v3/JobsIndexFilters';
import PaginationControls from '../v3/PaginationControls';
import RoleName from '../v3/RoleName';
import JobsTable from '../v3/JobsTable';
// SCSS imports
import { header } from './EmployeeRedeploymentPopup.module.scss';

type EmployeeRedeploymentPopupProps = {
  employeePath?: string | null;
  leaderUid?: string,
  employee: RedeploymentEmployee;
  plan: RedeploymentDetails;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onJobChange?: (employeeId: number, jobCode: string) => void;
  // for Storybook only
  testAction?: boolean | null;
  testPending?: boolean;
}

const EmployeeRedeploymentPopupPropTypes = {
  // attributes
  employeePath: PropTypes.string,
  leaderUid: PropTypes.string,
  employee: PropTypes.object.isRequired as Validator<RedeploymentEmployee>,
  plan: PropTypes.object.isRequired as Validator<RedeploymentDetails>,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onJobChange: PropTypes.func,
  // for Storybook only
  testAction: PropTypes.bool,
  testPending: PropTypes.bool
};

const EmployeeRedeploymentPopup: FunctionComponent<EmployeeRedeploymentPopupProps> = ({
  employeePath,
  leaderUid,
  employee,
  plan,
  isOpen = false,
  onClose,
  onJobChange,
  testAction,
  testPending
}) => {
  const { DEFAULT_MATCH_RATE, MANAGEMENT_LEVEL_FIRST } = useCustomerSettings();
  const { getLocationStr } = useModels();
  const { id: plan_id, job: planJob } = plan || {};
  const { title: planJobTitle } = planJob || {};
  const { id: employee_id, first_name, manager, location, job } = employee || {};
  const locationStr = getLocationStr(location);
  const { code, title, location: redeploymentLocation } = job || {};
  const redeploymentLocationStr = getLocationStr(redeploymentLocation);

  const titleValues = useMemo(() => {
    const employeeName = getName(first_name);
    return {
      name: employeeName,
      endsWithS: endsWith(employeeName, 's')
    };
  }, [first_name]);

  const filters = useReactiveVar(redeploymentJobsVar);
  const { sortOrder, direction } = useReactiveVar(redeploymentJobsSortVar);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  // lazy load employee jobs
  const {
    query: getJobs, pending: pendingEmployeeJobs, failed: failedJobs, count, results: jobs, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as RedeploymentEmployeeJob,
    key: 'redeploymentEmployeeJobs',
    lazyQuery: useLazyQuery(REDEPLOYMENT_EMPLOYEE_JOBS_QUERY as typeof RedeploymentEmployeeJobsDocument)
  });
  const pendingJobs = pendingEmployeeJobs || testPending;

  const { mutate: updateJob, loading: updatePending, failed: updateFailed } = useMutationMethod({
    key: 'updateRedeploymentEmployeeJob',
    mutation: useMutation(UPDATE_REDEPLOYMENT_EMPLOYEE_JOB as typeof UpdateRedeploymentEmployeeJobDocument)
  });

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);
  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    if (plan_id && employee_id && filters && pageSize && getJobs) {
      const input: RedeploymentEmployeeJobsInput = {
        ...locationParams(filters.country_id, filters.state_id),
        ...filters.scope ? { scope: filters.scope as JobScope } : {},
        ...isNil(filters.management_level) || filters.management_level < MANAGEMENT_LEVEL_FIRST
          ? {} : { management_level: filters.management_level },
        ...isNil(filters.management_level_min) || filters.management_level_min < MANAGEMENT_LEVEL_FIRST
          ? {} : { management_level_min: filters.management_level_min },
        ...isNil(filters.management_level_max) || filters.management_level_max < MANAGEMENT_LEVEL_FIRST
          ? {} : { management_level_max: filters.management_level_max },
        ...filters.open_reqs_only ? { open_reqs_only: filters.open_reqs_only } : {},
        ...filters.supervisory_jobs_only ? { supervisory_jobs_only: filters.supervisory_jobs_only } : {},
        ...leaderUid ? { selected_leader_id: leaderUid } : {},
        min_match_rate: DEFAULT_MATCH_RATE,
        sort_by: (sortOrder === JOB_SORT_TITLE && EmployeeJobsSort.job) ||
          (sortOrder === JOB_SORT_LOCATION && EmployeeJobsSort.location) ||
          (sortOrder === JOB_SORT_MATCH_RATE && EmployeeJobsSort.match_rate) ||
          (sortOrder === JOB_SORT_OPEN_REQS && EmployeeJobsSort.open_reqs_count) ||
          (sortOrder === JOB_SORT_LEVEL && EmployeeJobsSort.management_level) ||
          EmployeeJobsSort.match_rate,
        direction: direction ? SortDirection.ascending : SortDirection.descending,
        limit: pageSize
      };
      let curPage = currentPage;
      if (!prevVars?.plan_id || prevVars.plan_id !== plan_id ||
        !prevVars?.employee_id || prevVars.employee_id !== employee_id ||
        paramsDiffer(prevVars?.input, input)
      ) {
        curPage = 1;
        setCurrentPage(1);
      }
      input.offset = pageSize * (curPage - 1);
      getJobs({
        variables: {
          plan_id,
          employee_id,
          input,
          pathBuilder: pathBuilder as unknown as string
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `prevVars` changes
    plan_id, filters, employee_id, direction, sortOrder, currentPage, pageSize, leaderUid, getJobs,
    DEFAULT_MATCH_RATE, MANAGEMENT_LEVEL_FIRST
  ]);

  const handleSort = useCallback((sort: JobSortExt, dir: SortDirection) =>
    redeploymentJobsSortVar({ sortOrder: sort as JobSort, direction: dir === SortDirection.ascending }), []);

  const handleJobChange = useCallback((jobCode: string) => {
    if (plan_id && employee_id) {
      const job_id = find(jobs, ['code', jobCode])?.id;
      if (job_id) updateJob?.({
        variables: { plan_id, input: { employee_id, job_id } },
        update: (cache, result) => {
          if (result?.data?.updateRedeploymentEmployeeJob?.optimistic) {
            cache.updateQuery({
              query: REDEPLOYMENT_EMPLOYEE_JOBS_QUERY, variables: prevVars
            }, (data: RedeploymentEmployeeJobsQuery | null) => updateCachedResults(
              data, 'redeploymentEmployeeJobs',
              (jb: RedeploymentEmployeeJob) => ({ ...jb, is_selected: jb.id === job_id })
            ));
          } else {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentEmployeeJobs' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentEmployees' });
            onJobChange?.(employee_id, jobCode);
          }
        },
        optimisticResponse: { updateRedeploymentEmployeeJob: {
          success: true, optimistic: true, __typename: 'MutationResponse'
        } }
      });
    }
  }, [plan_id, employee_id, prevVars, jobs, updateJob, onJobChange]);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testAction) updateJob?.({ variables: { plan_id: 1, input: { employee_id: 2, job_id: 3 } } });
  }, [testAction, updateJob]);

  const disabled = pendingJobs || updatePending ? true : undefined;

  return mounted ? (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={updatePending ? undefined : onClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={onClose} disabled={updatePending}/>
        <CardSection className={header}>
          <Box color="misc.selectedBorder">
            <AccountCircleAlt fontSize="large" color="inherit"/>
          </Box>
          <Box flexGrow={1} py={0.625} pl={0.75} pr={3}>
            <EmployeeName
                variant="h4"
                employee={employee}
                route={employeePath}
                manager
                disabled={disabled}
            />
            <BoxTypography pt={0.75} variant="body2">
              {planJobTitle}
            </BoxTypography>
            {locationStr ? (
              <Typography variant="body2">
                {locationStr}
              </Typography>
            ) : undefined}
          </Box>
          {code && title ? (
            <Box flexGrow={1} py={0.625} pr={3}>
              <BoxTypography pb={0.75} variant="h4">
                <FormattedMessage id="hr.redeployment.recommended"/>
              </BoxTypography>
              <RoleName title={title} code={disabled ? undefined : code}/>
              {redeploymentLocationStr ? (
                <Typography variant="body2">
                  {redeploymentLocationStr}
                </Typography>
              ) : undefined}
            </Box>
          ) : undefined}
          {manager ? (
            <Box flexGrow={1} py={0.625} pr={3} justifySelf="flex-end">
              <BoxTypography pb={0.75} variant="h4">
                <FormattedMessage id="hr.redeployment.column.manager"/>
              </BoxTypography>
              <EmployeeName
                  variant="h4"
                  employee={manager}
                  manager
                  disabled={disabled}
              />
            </Box>
          ) : undefined}
        </CardSection>
        <CardTitle
            title="hr.redeployment.employee.title"
            values={titleValues}
            withDivider
        />
        <CardSection>
          <JobsIndexFilters
              visible
              disabled={disabled}
              onChange={redeploymentJobsVar}
              dropdownsFirst
              isInternational
              withScope
              withCountry
              withState
              withLevel
              withOpenReqs
              withSupervisory
          />
        </CardSection>
        <JobsTable
            redeployment
            withReloading
            onClick={handleJobChange}
            data={jobs as Job[]}
            pending={pendingJobs}
            failed={failedJobs}
            sortBy={sortOrder}
            direction={direction}
            changeSort={handleSort}
            disabled={disabled}
        />
        <CardSection flex compact={failedJobs || (pendingJobs && !jobs)}>
          <PaginationControls
              settingsId="redeployment_jobs"
              loaded={Boolean(jobs)}
              pending={pendingJobs}
              loading={pendingJobs}
              total={count}
              currentPage={currentPage}
              onPageSelected={setCurrentPage}
              onPageSize={setPageSize}
              disabled={disabled || failedJobs}
          />
        </CardSection>
      </Dialog>
      <ActionFailedAlert
          message="hr.redeployment.employee_job_error"
          open={updateFailed}
      />
    </>
  ) : null;
};

EmployeeRedeploymentPopup.propTypes = EmployeeRedeploymentPopupPropTypes;

export default memo(EmployeeRedeploymentPopup);
