import { memo, useCallback, useEffect, useState, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import split from 'lodash/split';
import toLower from 'lodash/toLower';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { SkillActivity } from '../graphql/types';
import { getActivityIcon } from '../helpers/icons';
// SCSS imports
import { icon, title } from './SkillActivityDialog.module.scss';

const selectButtonSx = { ml: 5 };

type SkillActivityDialogProps = {
  skillActivity?: SkillActivity | null;
  selected?: boolean;
  open?: boolean;
  onClose: () => void;
  onSelect?: (activity: SkillActivity) => void;
  onComplete?: (activity: SkillActivity) => void;
  onSelectPending?: boolean;
  onCompletePending?: boolean;
}

const SkillActivityDialogPropTypes = {
  skillActivity: PropTypes.object as Validator<SkillActivity>,
  selected: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onSelectPending: PropTypes.bool,
  onCompletePending: PropTypes.bool
};

const SkillActivityDialog: FunctionComponent<SkillActivityDialogProps> = ({
  skillActivity,
  selected = false,
  open = false,
  onClose,
  onSelect,
  onComplete,
  onSelectPending = false,
  onCompletePending = false
}) => {
  const [mounted, setMounted] = useState(open);
  useEffect(() => {
    if (open) setMounted(true);
  }, [open]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const handleSelect = useCallback(() => {
    if (skillActivity) {
      onSelect?.(skillActivity);
      onClose();
    }
  }, [skillActivity, onSelect, onClose]);

  const handleComplete = useCallback(() => {
    if (skillActivity) {
      onComplete?.(skillActivity);
      onClose();
    }
  }, [skillActivity, onComplete, onClose]);

  if (!skillActivity || !mounted) return null;

  const { name, activity_type, activity_link, description } = skillActivity;

  const lines = split(description, '\n');
  const nLines = size(lines);
  const ActivityIcon = getActivityIcon(activity_type);

  const disabled = onSelectPending || onCompletePending ? true : undefined;

  return (
    <Dialog
        disableEnforceFocus
        disablePortal
        maxWidth="md"
        fullWidth
        scroll="body"
        open={open}
        onClose={onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      <DialogTitle component="div" className={title}>
        <BoxTypography variant="subtitle2" color="greys.popupBorder" display="flex" alignItems="center">
          {ActivityIcon ? <ActivityIcon fontSize="medium" color="inherit" className={icon}/> : undefined}
          <FormattedMessage id="skill.development.activity" values={{ type: toLower(activity_type || '') || null }}/>
        </BoxTypography>
        <Typography variant="h3">
          {name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {map(lines, (line, index) => line ? (
          <Typography key={index} variant="body1">
            {line}
            {activity_link && nLines === 1 ? (
              <>
                {' '}
                <StandardLink text="skill.development.activities.link_text" href={activity_link}/>
              </>
            ) : undefined}
          </Typography>
        ) : <br key={index}/>)}
        {activity_link && nLines !== 1 ? (
          <Typography variant="body1">
            <StandardLink text="skill.development.activities.link_text" href={activity_link}/>
          </Typography>
        ) : undefined}

        {onSelect || onComplete ? (
          <Box display="flex" justifyContent="flex-end" pt={1.5}>
            {onComplete ? (
              <Button
                  color="secondary"
                  variant="text"
                  onClick={handleComplete}
                  disabled={disabled}
                  startIcon={onCompletePending ? <CircularProgress size={16} color="inherit"/> : undefined}
              >
                <FormattedMessage id="hr.dev_plan.button.mark_as_completed"/>
              </Button>
            ) : undefined}
            {onSelect ? (
              <Button
                  color="primary"
                  variant={selected ? 'outlined' : 'contained'}
                  onClick={handleSelect}
                  disabled={disabled}
                  startIcon={onSelectPending ? <CircularProgress size={16} color="inherit"/> : undefined}
                  sx={selectButtonSx}
              >
                <FormattedMessage id={selected ? 'hr.dev_plan.button.unselect' : 'hr.dev_plan.button.select'}/>
              </Button>
            ) : undefined}
          </Box>
        ) : undefined}

      </DialogContent>
    </Dialog>
  );
};

SkillActivityDialog.propTypes = SkillActivityDialogPropTypes;

export default memo(SkillActivityDialog);
