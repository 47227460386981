/* eslint-disable max-lines */
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import sum from 'lodash/sum';
import size from 'lodash/size';
import head from 'lodash/head';
import join from 'lodash/join';
import take from 'lodash/take';
import times from 'lodash/times';
import every from 'lodash/every';
import range from 'lodash/range';
import valuesFunc from 'lodash/values';
import constant from 'lodash/constant';
import ceil from 'lodash/ceil';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import transform from 'lodash/transform';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import toSafeInteger from 'lodash/toSafeInteger';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { type CallbackDataParams } from 'echarts/types/src/util/types';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { alpha } from '@mui/system/colorManipulator';
import { useTheme } from '@mui/material/styles';
// TM UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import {
  EmployeeCountByDate, DAAverageSkills, DACloseMatchJobs, DACurrentRoleMatch, DAEmployeeVelocity, DASkillCourseMapping,
  DAUpskillingVelocity
} from '../graphql/types';
import { JOB_CATEGORIES, EMPLOYEE_VELOCITIES, DACourseSkill } from '../graphql/customTypes';
import { MATCH_RATE_BUCKET } from '../constants/matchRateBuckets';
import { SKILL_COURSE_MAPPING_SERIES } from '../constants/skillCourseMapping';
import { GlobalEChartsStyles, MAX_MANAGER_DASHBOARD_ITEMS } from '../config/params';
import {
  NormalizedTimelineData, NormalizedTimelineExtra, NormalizedTimelineValue,
  addPointsFactory, generateTimelineChartData, getPreparedTimelineData, pushTimelineData
} from '../helpers/charts';
import Chart from '../elements/Chart';
// SCSS imports
import {
  primary, series0, series1, series2, series3, series4, series5, series6, s1L3, s2L3, s3L4, s4L3, s5L3, s6L3
} from '../styles/modules/Chart.module.scss';
import {
  chart, chartPreview, tooltip, tooltipHeader, tooltipHeaderText, tooltipHeaderValue, tooltipHeaderPadding, tooltipSubheader,
  tooltipLine, tooltipPercent, tooltipTitle, tooltipValue, tooltipNumber, tooltipBar, tooltipInfo, marker
} from './TopChartTimeline.module.scss';

const EMPTY_ARRAY: string[] = [];

const tooltipColors = [series1, series2, series3, series4, series5, series6];

const TIMELINE_VARIANT =
  ['employees', 'jobs', 'skills', 'velocity', 'match', 'skill_course', 'course_skill', 'upskilling'] as const;
type TimelineVariant = typeof TIMELINE_VARIANT[number];

type TopChartTimelineProps = {
  preview?: boolean;
  variant: TimelineVariant;
  wide?: boolean;
  data?: (EmployeeCountByDate | DACloseMatchJobs | DAAverageSkills |
    DAEmployeeVelocity | DACurrentRoleMatch | DASkillCourseMapping | DAUpskillingVelocity
  )[];
  years?: number | null;
  path?: string;
  pending?: boolean | null;
}

const TopChartTimelinePropTypes = {
  // attributes
  preview: PropTypes.bool,
  variant: PropTypes.oneOf(TIMELINE_VARIANT).isRequired as Validator<TimelineVariant>,
  wide: PropTypes.bool,
  data: PropTypes.array,
  years: PropTypes.number,
  path: PropTypes.string,
  pending: PropTypes.bool
};

type UpskillingRecord = {
  date: string;
  skill_totals: (number | null | undefined)[];
};

// eslint-disable-next-line max-lines-per-function, max-statements
const TopChartTimeline = forwardRef<EChartsReactCore, TopChartTimelineProps>(({
  preview = false,
  variant,
  wide = false,
  data: rawData,
  years,
  path,
  pending = false
}, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isEmployees = variant === 'employees';
  const isJobs = variant === 'jobs';
  const isSkills = variant === 'skills';
  const isVelocity = variant === 'velocity';
  const isMatch = variant === 'match';
  const isCourseSkill = variant === 'course_skill';
  const isSkillCourse = variant === 'skill_course' || isCourseSkill;
  const isUpskilling = variant === 'upskilling';
  const triggerEvents = !preview && Boolean(path && isUpskilling);

  const data = useMemo(() => {
    if (!isUpskilling || !rawData || size(rawData) < 1) return rawData;
    const truncedData = take(rawData as DAUpskillingVelocity[], MAX_MANAGER_DASHBOARD_ITEMS);
    return valuesFunc(transform(truncedData, (result, { data: historyData }, idx) => {
      forEach(historyData, ({ date, total }) => {
        if (!result[date]) result[date] = { date, skill_totals: times(size(truncedData), constant(null)) };
        result[date].skill_totals[idx] = total;
      });
    }, {} as Record<string, UpskillingRecord>));
  }, [rawData, isUpskilling]);

  const skillTitles = useMemo(() => isUpskilling && rawData
    ? map(rawData as DAUpskillingVelocity[], ({ abbr, title }) => JSON.stringify({ abbr, title })) : EMPTY_ARRAY,
    [rawData, isUpskilling]);

  const seriesCount = (isSkills && JOB_CATEGORIES.length) ||
    (isVelocity && EMPLOYEE_VELOCITIES.length) ||
    (isMatch && MATCH_RATE_BUCKET.length) ||
    (isSkillCourse && SKILL_COURSE_MAPPING_SERIES.length) ||
    (isUpskilling && (preview ? MAX_MANAGER_DASHBOARD_ITEMS : size((head(data) as UpskillingRecord)?.skill_totals))) ||
    1;

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [colors, altColors, tooltipMarkerColors] = useMemo(() =>
    (isEmployees && [
      [theme.palette.primary.main],
      [alpha(theme.palette.primary.main, theme.palette.action.disabledOpacity / 2)],
      [primary]
    ]) ||
    (isJobs && [
      [theme.palette.chart.series0],
      [alpha(theme.palette.chart.series0, theme.palette.action.disabledOpacity / 2)],
      [series0]
    ]) ||
    (isSkills && [
      [theme.palette.chart.series0, theme.palette.primary.main, theme.palette.chart.series5],
      [
        alpha(theme.palette.chart.series0, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.primary.main, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series5, theme.palette.action.disabledOpacity / 2)
      ],
      [series0, primary, series5]
    ]) ||
    (isVelocity && [
      [theme.palette.chart.series2level3, theme.palette.chart.series3level4, theme.palette.chart.series4level3],
      [
        alpha(theme.palette.chart.series2level3, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series3level4, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series4level3, theme.palette.action.disabledOpacity / 2)
      ],
      [s2L3, s3L4, s4L3]
    ]) ||
    (isMatch && [
      [
        theme.palette.chart.series6level3,
        theme.palette.chart.series5level3,
        theme.palette.chart.series4level3,
        theme.palette.chart.series3level4,
        theme.palette.chart.series2level3,
        theme.palette.chart.series1level3
      ],
      [],
      [s6L3, s5L3, s4L3, s3L4, s2L3, s1L3]
    ]) ||
    (isSkillCourse && [
      [
        theme.palette.chart.series2level3,
        theme.palette.chart.series4level3
      ], [
        alpha(theme.palette.chart.series2level3, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series4level3, theme.palette.action.disabledOpacity / 2)
      ],
      [s2L3, s4L3]
    ]) ||
    (isUpskilling && [
      [
        theme.palette.chart.series1level3,
        theme.palette.chart.series2level3,
        theme.palette.chart.series3level4,
        theme.palette.chart.series4level3,
        theme.palette.chart.series5level3,
        theme.palette.chart.series6level3
      ],
      [
        alpha(theme.palette.chart.series1level3, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series2level3, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series3level4, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series4level3, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series5level3, theme.palette.action.disabledOpacity / 2),
        alpha(theme.palette.chart.series6level3, theme.palette.action.disabledOpacity / 2)
      ],
      [s1L3, s2L3, s3L4, s4L3, s5L3, s6L3]
    ]) ||
    [[], [], []], [isEmployees, isJobs, isSkills, isVelocity, isMatch, isSkillCourse, isUpskilling, theme]);

  const legendFormatter = useMemo(() => skillTitles === EMPTY_ARRAY ? undefined : (name: string) => {
    try {
      return JSON.parse(name).title;
    } catch (_err) {
      return name;
    }
  }, [skillTitles]);

  const handleLegendSelectChanged = useMemo(() => triggerEvents ? (event: unknown) => {
    const chartInstance = innerRef.current?.getEchartsInstance();
    const { name } = (event as { name: string }) || {};
    if (!chartInstance || !name) return;
    // reverse legend unselection:
    chartInstance.setOption({ animation: false });
    chartInstance.dispatchAction({ type: 'legendSelect', name });
    chartInstance.setOption({ animation: true });
    // navigate to the Skill Details screen:
    const { abbr } = JSON.parse(name);
    if (path && abbr) navigate(injectParams(path, { skill_id: abbr }));
  } : undefined, [triggerEvents, path, navigate]);

  // Sorting by months and removing duplicates:
  const normalized = useMemo(() => preview || size(data) < 1 ? []
    : transform(sortBy(data, isEmployees || isVelocity || isSkillCourse || isUpskilling ? 'date' : 'month'),
      // eslint-disable-next-line complexity
      (result, record) => {
        const date = isEmployees || isVelocity || isSkillCourse || isUpskilling
          ? (record as EmployeeCountByDate | DAEmployeeVelocity | DASkillCourseMapping | UpskillingRecord).date
          : (record as DACloseMatchJobs | DAAverageSkills | DACurrentRoleMatch).month;
        if (isSkillCourse) {
          const { total: skills_total, overall_total, all } = record as DASkillCourseMapping;
          const unmatched = (overall_total || 0) - (skills_total || 0);
          const values = isNil(skills_total) || isNil(overall_total) ? [null, null]
            : [skills_total, unmatched >= 1 ? unmatched : 0];
          pushTimelineData(result, date, values, [all]);
        } else if (isVelocity) {
          const { high, medium, low } = record as DAEmployeeVelocity;
          pushTimelineData(result, date, [high, medium, low]);
        } else if (isUpskilling) {
          pushTimelineData(result, date, (record as UpskillingRecord).skill_totals);
        } else {
          /* eslint-disable no-nested-ternary */
          const count = isVelocity || isMatch
            ? (record as DACurrentRoleMatch).total || 0
            : toSafeInteger(isEmployees
              ? (record as EmployeeCountByDate).employee_count
              : isSkills
                ? (record as DAAverageSkills).average_skills
                : (record as DACloseMatchJobs).job_count
          );
          /* eslint-enable no-nested-ternary */
          const idx = (isSkills && JOB_CATEGORIES.indexOf((record as DAAverageSkills)?.job_category || JOB_CATEGORIES[0])) ||
            (isMatch && MATCH_RATE_BUCKET.indexOf((record as DACurrentRoleMatch)?.rate_bucket || MATCH_RATE_BUCKET[0])) ||
            0;
          pushTimelineData(result, date, [count], undefined, idx, seriesCount);
        }
      }, [] as NormalizedTimelineData<DACourseSkill>[]
    ),
    [data, isEmployees, isSkills, isVelocity, isMatch, isSkillCourse, isUpskilling, seriesCount, preview]
  );

  const { categories, seriesData, totals, maxIndex } = useMemo(() => {

    const addPoints = addPointsFactory({
      preview,
      colors,
      altColors,
      dots: isVelocity || isSkillCourse || (isMatch ? null : false),
      highlighted: isEmployees || isSkills || isVelocity || isUpskilling,
      paperColor: theme.palette.background.paper,
      pointSize: ((isSkills || isUpskilling) && (preview ? 8 : 10)) || (isVelocity && 12) || (isSkillCourse && 11) || 9
    });

    if (preview) return {
      categories: ['0', '1', '2', '3', '4', '5'],
      seriesData: isMatch ? [
        { counts: [2 / 21, 5 / 21, 4 / 21, 6 / 21, 1 / 21, 3 / 21], points: [], employeeCounts: [], extraData: [] },
        { counts: [3 / 21, 2 / 21, 5 / 21, 4 / 21, 6 / 21, 1 / 21], points: [], employeeCounts: [], extraData: [] },
        { counts: [5 / 21, 4 / 21, 6 / 21, 1 / 21, 3 / 21, 2 / 21], points: [], employeeCounts: [], extraData: [] },
        { counts: [4 / 21, 6 / 21, 1 / 21, 3 / 21, 2 / 21, 5 / 21], points: [], employeeCounts: [], extraData: [] },
        { counts: [6 / 21, 1 / 21, 3 / 21, 2 / 21, 5 / 21, 4 / 21], points: [], employeeCounts: [], extraData: [] },
        { counts: [1 / 21, 3 / 21, 2 / 21, 5 / 21, 4 / 21, 6 / 21], points: [], employeeCounts: [], extraData: [] }
      ] : map(range(seriesCount), (seriesIndex) => {
        const counts = (seriesIndex === 0 && !isJobs && [0, 2, 1, 5, 3, 4]) ||
          ((seriesIndex === 1 || isJobs) && [3, 0, 2, 3, 1, 5]) ||
          (seriesIndex === 2 && (isVelocity || isUpskilling) && [5, 3, 3, 1, 2, 2]) ||
          (seriesIndex === 3 && isUpskilling && [1, 1, 0, 2, 0, 0]) ||
          (seriesIndex === 4 && isUpskilling && [4, 3, 4, 4, 4, 3]) ||
          [2, 4, 3, 0, 5, 1];
        const points: object[] = [];
        forEach(counts, (count, idx) => addPoints(points, idx, seriesIndex, count, 5));
        return { counts: map(counts, (count, idx) => [idx, count]), points, employeeCounts: [], extraData: [] };
      }),
      totals: [],
      maxIndex: 5
    };

    const { takeMonths, stepMonths, lastDate, maxIdx, prepared } = getPreparedTimelineData({ normalized, wide, years });

    const cats: string[] = [];
    const ttls: number[] = [];
    const serData: {
      counts: (NormalizedTimelineValue[] | NormalizedTimelineValue)[];
      points: object[],
      employeeCounts: NormalizedTimelineValue[];
      extraData?: NormalizedTimelineExtra<DACourseSkill>;
    }[] = times(seriesCount, () => ({
      counts: [],
      points: [],
      employeeCounts: [],
      ...isSkillCourse ? { extraData: [] } : {}
    }));

    // Generating chart data:
    generateTimelineChartData({
      seriesCount, takeMonths, stepMonths, lastDate, prepared,
      callback: (index, category, values, extraData) => {
        cats.push(category);

        // sum values to get total employees count (for Employee Velocity and Current Role Match charts only):
        const totalEmployees = isVelocity || isMatch ? sum(values) || 1 : 1;

        forEach(values, (value, idx) => {
          const curData = serData[idx];
          curData.employeeCounts[index] = value;
          ttls[index] = totalEmployees;
          if (extraData) curData.extraData?.push(extraData[idx]);
          // eslint-disable-next-line no-nested-ternary
          const val = isNull(value) ? null : isVelocity || isMatch ? (value || 0) / totalEmployees : value || 0;
          curData.counts.push(isMatch ? val : [index, val]);
          addPoints(curData.points, index, idx, val, maxIdx);
        });
      }
    });

    return {
      categories: cats,
      seriesData: serData,
      totals: ttls,
      maxIndex: maxIdx
    };

  }, [
    normalized, years, isEmployees, isSkills, isJobs, isVelocity, isMatch, isSkillCourse, isUpskilling, seriesCount,
    wide, colors, altColors, preview, theme
  ]);

  const [nameText, timelineText, seriesNames, seriesInfos] = useMemo(() => preview ? ['', '', [], []] : [
    isVelocity || isMatch ? '' : formatMessage({ id: `hr.dashboard.label.${variant}` }),
    formatMessage({ id: 'hr.dashboard.label.time' }),
    (isMatch && MATCH_RATE_BUCKET) ||
    (isUpskilling && skillTitles) ||
    (!isSkills && !isVelocity && !isSkillCourse && [formatMessage({ id: `hr.dashboard.tooltip.header.${variant}` })]) ||
    ((isSkills && JOB_CATEGORIES) ||
     (isVelocity && EMPLOYEE_VELOCITIES) ||
     (isSkillCourse && SKILL_COURSE_MAPPING_SERIES) || []
    ).map((seriesId) => formatMessage({ id: `hr.dashboard.series.${isCourseSkill ? 'courses.' : ''}${seriesId}` })),
    isVelocity ? EMPLOYEE_VELOCITIES.map((seriesId) =>
      formatMessage({ id: `hr.dashboard.series.tooltip.${seriesId}` })) : undefined
  ], [variant, isSkills, isVelocity, isMatch, isSkillCourse, isCourseSkill, isUpskilling, skillTitles, preview, formatMessage]);

  const tooltipFormatter = useCallback((params: CallbackDataParams[]) => {
    if (every(params, ({ value }) => isNil(value) || (isArray(value) && isNil(value[1])))) return null;
    const { name, dataIndex: headerDataIndex } = params[0] || {};
    const singleLine = isEmployees || isJobs || isSkillCourse;
    return `<div class="${tooltip}"><div class="${tooltipHeader}"><span class="${tooltipHeaderText}">${
      singleLine ? '' : formatMessage({ id: `hr.dashboard.tooltip.header.${variant}` })
    }</span><span class="${tooltipHeaderValue}">${
      name || categories[headerDataIndex]
    }</span></div>${
    // eslint-disable-next-line complexity
    join(map(params, (param, index) => {
      const { seriesIndex, seriesName, dataIndex, data: chartData } = isMatch ? params[params.length - index - 1] : param;
      const { employeeCounts, extraData } = seriesData[seriesIndex || 0];
      const employeesTotal = totals[dataIndex || 0];
      const extra = extraData?.[dataIndex];
      const hasExtraData = extra && size(extra) >= 1;
      const totalCount = hasExtraData
        ? (extra && maxBy(extra, 'total')?.total) || 1
        : employeeCounts?.[dataIndex] || 0;
      const tooltipMarkerColor = tooltipMarkerColors[seriesIndex || 0];
      return isSkillCourse || hasExtraData

        // Skill Course Mapping tooltip with a list of courses:
        ? `<div class="${tooltipHeader}${seriesIndex ? ` ${tooltipHeaderPadding}` : ''}">${
          tooltipMarkerColor ? `<span class="${marker} ${tooltipMarkerColor}">\u00A0</span>` : ''}${
          formatMessage(
            { id: `hr.dashboard.tooltip.${variant}.header${seriesIndex || 0}` },
            { total: (chartData as number[])?.[1] || 0 }
          )
        }</div>${hasExtraData ? `<div class="${tooltipSubheader}">${
          formatMessage({ id: `hr.dashboard.tooltip.${variant}.subheader` })
        }</div>` : ''}<div class="${tooltipLine}">${
            join(
              map(take(extra, MAX_MANAGER_DASHBOARD_ITEMS), ({ title, total }, idx) =>
                `<span class="${tooltipTitle}">${title}</span><span class="${tooltipValue}"><span class="${tooltipBar
                } ${tooltipColors[idx % tooltipColors.length]} bar-width-${ceil(100 * (total || 0) / totalCount)
                }"></span><span class="${tooltipNumber}">${toSafeInteger(total)}</span></span>`),
              `</div><div class="${tooltipLine}">`
            )
          }</div>`

        // All other tooltips:
        : `<div class="${tooltipLine}"><span class="${tooltipTitle}">${
          tooltipMarkerColor ? `<span class="${marker} ${tooltipMarkerColor}">\u00A0</span>` : ''}${
            legendFormatter ? legendFormatter(seriesName as string) : seriesName
          }</span><span>${
            formatNumber(totalCount)
          }</span>${isMatch || isVelocity ? `<span class="${tooltipPercent}">(${
            formatNumber(ceil(totalCount / employeesTotal, 2), percentageOptions)
          })</span>` : ''}</div>${seriesInfos ? `<div class="${tooltipInfo}">${seriesInfos[seriesIndex || 0]}</div>` : ''}`;
    }), '')}</div>`;
  }, [
    seriesData, seriesInfos, totals, categories, isEmployees, isVelocity, isJobs, isSkillCourse, isMatch, variant,
    tooltipMarkerColors, legendFormatter, formatMessage, formatNumber
  ]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();
    const yLabelWidth = wide && !isSkillCourse ? 140 as const : 70 as const;

    const series = map(seriesData, ({ counts, points }, idx) => ({
      ...isMatch ? {
        type: 'bar',
        stack: 'main',
        barWidth: '20%',
        itemStyle: {
          borderRadius: 0
        }
      } : {
        type: 'line',
        markPoint: { data: points }
      },
      name: seriesNames[idx],
      data: counts,
      color: colors[idx]
    })) as object[];

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      silent: Boolean(pending),
      grid: preview ? {
        left: spacing(1), top: '25%', right: spacing(1), bottom: '25%'
      } : {
        left: yLabelWidth + spacing(wide ? 10 : 5),
        top: spacing(isVelocity || isMatch ? 5 : 10),
        right: spacing((wide && 16) || (isEmployees ? 10 : 13)),
        bottom: spacing(isEmployees || isVelocity || isMatch || isSkillCourse || isUpskilling ? 8.5 : 7),
        containLabel: true
      },
      ...preview || pending ? {} : {
        tooltip: {
          show: true,
          confine: true,
          trigger: 'axis',
          axisPointer: { type: 'none' },
          formatter: tooltipFormatter,
          color: theme.palette.text.label,
          borderColor: theme.palette.greys.border,
          borderWidth: theme.shape.borderWidth,
          backgroundColor: theme.palette.background.tooltipMedium,
          extraCssText: `box-shadow: ${theme.shadows[2]}; text-align: center;`,
          lineHeight: 20,
          textStyle: {
            fontSize: 15,
            fontWeight: theme.typography.fontWeightRegular
          }
        }
      },
      ...!preview && (isSkills || isVelocity || isMatch || isSkillCourse || isUpskilling) ? {
        legend: {
          bottom: isVelocity || isMatch || isSkillCourse ? spacing(1) : 0,
          padding: isUpskilling ? spacing(2.5) : [0, 0, spacing(1.5), 0],
          icon: isSkillCourse ? 'roundRect' : 'circle',
          itemWidth: spacing(1.75),
          itemHeight: spacing(1.75),
          itemGap: spacing((isMatch && 3) || (isUpskilling && 4.5) || 6),
          itemStyle: {
            borderWidth: theme.shape.thinBorderWidth
          },
          textStyle: {
            ...isUpskilling ? {
              fontSize: 15,
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.secondary.text
            } : {
              fontSize: 14,
              fontStyle: 'italic',
              color: theme.palette.info.caption
            },
            padding: spacing(0.125)
          },
          ...isUpskilling ? {
            type: 'scroll',
            formatter: legendFormatter,
            pageButtonItemGap: spacing(1.25),
            pageFormatter: ' ',
            pageIconSize: 18,
            pageIconColor: theme.palette.action.active,
            pageIconInactiveColor: theme.palette.action.disabled,
            pageTextStyle: {
              color: 'transparent',
              fontSize: 1,
              width: 0,
              height: 0
            }
          } : {}
        }
      } : {},
      xAxis: {
        ...isMatch ? {
          type: 'category',
          data: categories
        } : {
          type: 'value',
          min: 0,
          max: maxIndex,
          minInterval: 1,
          maxInterval: 1,
          interval: 1
        },
        axisLine: isVelocity || isMatch ? {
          show: true,
          lineStyle: {
            color: theme.palette.chart.grid
          }
        } : { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: {
            color: theme.palette.chart.grid,
            width: theme.shape.mediumBorderWidth
          }
        },
        name: timelineText,
        nameTextStyle: !preview && timelineText ? {
          fontSize: 13,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.info.caption,
          align: isEmployees ? 'right' : 'left',
          verticalAlign: 'top',
          padding: [spacing(isEmployees ? 6 : 2), 0, 0, isEmployees ? 0 : spacing(4.5)]
        } : null,
        axisLabel: preview ? null : {
          ...isMatch ? {} : { formatter: (value: number) => categories[value] },
          fontSize: 14,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.label,
          padding: [spacing(1), 0, 0, 0]
        }
      },
      yAxis: {
        type: 'value',
        minInterval: isVelocity || isMatch ? 0.1 : 1,
        ...isMatch ? {
          min: 0,
          max: 1
        } : {},
        position: 'left',
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        name: nameText,
        nameTextStyle: !preview && nameText ? {
          fontSize: 13,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.info.caption,
          align: isSkillCourse ? 'left' : 'right',
          verticalAlign: 'bottom',
          padding: [
            0, spacing(isSkillCourse ? 0
              : (isEmployees && ((wide && 6) || -3)) ||
                ((isSkills || isUpskilling) && ((wide && 6.5) || -2.5)) ||
                (wide && 11) || 2.5
            ), spacing(2), isSkillCourse ? spacing(-10.5) : 0
          ]
        } : null,
        axisLabel: preview ? null : {
          ...isVelocity || isMatch ? {
            formatter: (value: number | null) => formatNumber(value || 0, percentageOptions)
          } : {},
          inside: true,
          width: yLabelWidth,
          margin: -(yLabelWidth + spacing(2.25)),
          overflow: 'truncate',
          fontSize: 14,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.label,
          padding: [spacing(0.5), 0, 0, spacing(0.75)]
        }
      },
      series
    }, true);
    echartInstance.resize();
    if (handleLegendSelectChanged) echartInstance.on('legendselectchanged', handleLegendSelectChanged);
    else echartInstance.off('legendselectchanged');
  }, [
    categories, seriesData, maxIndex, pending, nameText, timelineText, seriesNames,
    variant, isEmployees, isSkills, isVelocity, isMatch, isSkillCourse, isUpskilling, wide,
    tooltipFormatter, legendFormatter, handleLegendSelectChanged, colors, preview, theme, formatNumber, formatMessage
  ]);

  return <Chart ref={chartRef} option={GlobalEChartsStyles} className={preview ? chartPreview : chart}/>;
});

TopChartTimeline.displayName = 'TopChartTimeline';

TopChartTimeline.propTypes = TopChartTimelinePropTypes;

export default memo(TopChartTimeline);
