import { memo, type FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// TM UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import InDemandSkillsEditor from '../panels/InDemandSkillsEditor';

const InDemandSkillsScreen: FunctionComponent = () => {
  const { content } = useVerifyUser({ admin: true });
  return content || (
    <Screen admin>
      <ContentCard>
        <CardTitle title="hr.in_demand_skills.title" withDivider/>
        <DndProvider backend={HTML5Backend} context={window}>
          <InDemandSkillsEditor/>
        </DndProvider>
      </ContentCard>
    </Screen>
  );
};

export default memo(InDemandSkillsScreen);
