import { forwardRef, memo, type Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import split from 'lodash/split';
// Material UI imports
import { type BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// TM UI Components
import Scrollable from '@empathco/ui-components/src/elements/Scrollable';

type RoleDescriptionProps = {
  autofit?: boolean;
  description?: string | null;
}

const RoleDescriptionPropTypes = {
  // attributes
  autofit: PropTypes.bool,
  description: PropTypes.string
};

const RoleDescription = forwardRef<Component<BoxProps>, RoleDescriptionProps>(({
  autofit,
  description = ''
}, ref) => (
  <Scrollable ref={ref} autofit={autofit}>
    {map(split(description, '\n'), (line, index) => (
      <Typography key={index} paragraph>
        {line}
      </Typography>
    ))}
  </Scrollable>
));

RoleDescription.displayName = 'RoleDescription';

RoleDescription.propTypes = RoleDescriptionPropTypes;

export default memo(RoleDescription);
