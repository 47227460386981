// TM UI Components
import { Range } from '@empathco/ui-components/src/helpers/types';

export const TOUR_TAB_DASHBOARD = 'dashboard' as const;
export const TOUR_TAB_SKILLS = 'skills' as const;
export const TOUR_TAB_JOBS = 'jobs' as const;

export const TOUR_TABS = [
  TOUR_TAB_DASHBOARD,
  TOUR_TAB_SKILLS,
  TOUR_TAB_JOBS
] as const;

export type TourTab = typeof TOUR_TABS[number];

export type TourTooltipPlacement = 'left' | 'right' | 'bottom' | 'top';

export type TourScreenshot = Range<1, 18>;

export interface TourScreen {
  tab?: TourTab; // which tab this screen belongs to
  screenshot: TourScreenshot; // which screenshot it is based on
  placement: TourTooltipPlacement; // display tooltip to the left or to the right of the target point
  x0: number; // X coordinate of the target point, counting from left to right (`0`..`theme.shape.tourScreenshotWidth`)
  y0: number; // Y coordinate of the target point, counting from top to bottom (`0`..`theme.shape.tourScreenshotHeight`)
  width: number; // width of the tooltip window in pixels (height is adjusted automatically based on the tooltip text)
}
