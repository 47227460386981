import { Fragment, forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { CategoriesGroup } from '../models/categoriesGroup';
import CategoryMenuItem from './CategoryMenuItem';
// SCSS imports
import { panel, staticPanel, menu } from './CategoriesMenu.module.scss';

type CategoriesMenuProps = {
  groups?: CategoriesGroup[];
  value?: number;
  onSelect: (value: number) => void;
  required?: boolean;
  withoutScroll?: boolean;
};

const CategoriesMenuPropTypes = {
  groups: PropTypes.array as Validator<CategoriesGroup[]>,
  value: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  required: PropTypes.bool,
  withoutScroll: PropTypes.bool
};

const CategoriesMenu = forwardRef<HTMLDivElement, CategoriesMenuProps>(({
  groups,
  value,
  onSelect,
  required = false,
  withoutScroll = false
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  return (
    <Box ref={ref} className={withoutScroll ? staticPanel : panel}>
      {map(groups, ({ header, categories, icon }) => {
        const headerStr = formatMessage({ id: header });
        const content = (
          <>
            <BoxTypography px={2} pt={2} pb={0.5} variant="body2" fontStyle="italic" color="info.caption">
              {headerStr}
            </BoxTypography>
            <MenuList
                tabIndex={0}
                aria-label={headerStr}
                className={menu}
            >
              {required ? undefined : (
                <CategoryMenuItem
                    id={0}
                    selected={value === 0}
                    onClick={onSelect}
                />
              )}
              {map(categories, ({ id, title, name, values }) => (
                <CategoryMenuItem
                    key={id}
                    id={id}
                    title={name || title}
                    values={values}
                    icon={icon}
                    selected={id === value}
                    onClick={onSelect}
                />
              ))}
            </MenuList>
          </>
        );
        return withoutScroll ? (
          <Box key={header}>
            {content}
          </Box>
        ) : (
          <Fragment key={header}>
            {content}
          </Fragment>
        );
      })}
    </Box>
  );
});

CategoriesMenu.displayName = 'CategoriesMenu';

CategoriesMenu.propTypes = CategoriesMenuPropTypes;

export default memo(CategoriesMenu);
