import { memo, useCallback, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import endsWith from 'lodash/endsWith';
import { useParams, Navigate } from 'react-router-dom';
// Material UI imports
import LinearProgress from '@mui/material/LinearProgress';
// TM UI Components
import { getFullName, getName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { hasHRBP } from '../models/user';
import useNonReducedUI from '../constants/managementLevel';
import useCustomerSettings from '../config/customer';
import { PATH_HR_TEAMS, PATH_SUPERVISOR_TEAM } from '../config/paths';
import { getEmployeeId } from '../helpers/routerParams';
import { SupervisorContext } from '../context/supervisor';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
// import DataStatusPoller from '../v3/DataStatusPoller';
import EmployeeDetailsBand from '../components/EmployeeDetailsBand';
import MySkills from '../components/MySkills';
import EmployeeSkillsRecommended from '../components/EmployeeSkillsRecommended';
import SkillsDevelopment from '../components/SkillsDevelopment';
import EmployeeDevPlansPanel from '../components/EmployeeDevPlansPanel';
import JobsIndex from '../components/JobsIndex';
import SkillsGapForRole from '../components/SkillsGapForRole';

const SupervisorEmployeeScreen: FunctionComponent = () => {
  const { HAS_DEV_PLAN, HAS_TEAM_BUILDER } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();

  const employeeId = getEmployeeId(useParams());
  const { content, user } = useVerifyUser({ manager: true });
  const { mgrEmployee: { data: employee, pending, failed }, requireEmployee } = useContext(SupervisorContext);
  const isHR = hasHRBP(user);

  const [roleId, setRoleId] = useState<string | null>(null);

  const showFullProfile = showNonReducedUI(employee);
  const { code, first_name, last_name, org, current_job } = employee || {};
  const uid = code || null;
  const employeeName = useMemo(() => getName(first_name), [first_name]);
  const fullName = useMemo(() => getFullName(first_name, last_name, code), [first_name, last_name, code]);

  useEffect(() => {
    if (!content && employeeId) requireEmployee?.();
  }, [content, employeeId, requireEmployee]);

  const handleJobClick = useCallback((id: string) => setRoleId(id), []);
  const handleJobClose = useCallback(() => setRoleId(null), []);

  const breadcrumbs = useMemo(() => [
    HAS_TEAM_BUILDER && isHR ? {
      title: 'breadcrumbs.hr.teams',
      link: PATH_HR_TEAMS
    } : {
      title: 'breadcrumbs.my_team',
      link: PATH_SUPERVISOR_TEAM
    },
    {
      title: 'breadcrumbs.employee_record',
      values: {
        fullName,
        endsWithS: endsWith(fullName, 's')
      }
    }
  ], [fullName, isHR, HAS_TEAM_BUILDER]);

  return content || (
    failed && <Navigate replace to={HAS_TEAM_BUILDER && isHR ? PATH_HR_TEAMS : PATH_SUPERVISOR_TEAM}/>
  ) || (
    (pending || !uid) && <LinearProgress/>
  ) || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      <EmployeeDetailsBand
          fullName={fullName}
          employee={employee}
          onJobClick={showFullProfile ? handleJobClick : undefined}
      />
      <EmployeeSkillsRecommended
          supervisor
          reducedUI={!showFullProfile}
          uid={uid as string}
          employeeName={employeeName}
          employeeJobCode={current_job?.code || undefined}
          userOrgId={org?.id || undefined}
      />
      <MySkills
          supervisor
          uid={uid}
          reducedUI={!showFullProfile}
          employeeName={employeeName}
          employee={employee}
      />
      {showFullProfile && HAS_DEV_PLAN ? <EmployeeDevPlansPanel supervisor uid={uid} employeeName={employeeName}/> : undefined}
      {showFullProfile ? <SkillsDevelopment supervisor uid={uid} employeeName={employeeName}/> : undefined}
      <JobsIndex supervisor uid={uid} employeeName={employeeName} onJobClick={handleJobClick}/>
      {/* <DataStatusPoller/> – we may need this if Skill Level Approvals are added */}
      {code ? (
        <SkillsGapForRole
            code={code}
            fullName={fullName}
            roleId={roleId || undefined}
            isOpen={Boolean(roleId)}
            onClose={handleJobClose}
        />
      ) : undefined}
    </Screen>
  );
};

export default memo(SupervisorEmployeeScreen);
