import { forwardRef, memo, useMemo, type Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type BoxProps } from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// Material Icon imports
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// TM UI Components
import { bold } from '@empathco/ui-components/src/helpers/intl';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import LevelDescription from '@empathco/ui-components/src/elements/LevelDescription';
// local imports
import { SkillActivity } from '../graphql/types';
import { Skill, SkillLevel } from '../models/skill';
import SkillActivities from '../components/SkillActivities';
// SCSS imports
import { first, last, details } from './LevelDescriptionsAccordion.module.scss';

type LevelDescriptionsAccordionProps = {
  defaultExpandedLevel?: number;
  levelDescriptions?: string[] | null;
  activities?: SkillActivity[] | null;
  disabled?: boolean | null;
}

const LevelDescriptionsAccordionPropTypes = {
  defaultExpandedLevel: PropTypes.number,
  levelDescriptions: PropTypes.array,
  activities: PropTypes.array,
  disabled: PropTypes.bool
};

const LevelDescriptionsAccordion = forwardRef<Component<BoxProps>, LevelDescriptionsAccordionProps>(({
  defaultExpandedLevel,
  levelDescriptions,
  activities,
  disabled = false
}, ref) => {
  const skills = useMemo(() => [{ activities } as Skill], [activities]);
  const lastLevel = size(levelDescriptions) - 1;
  return lastLevel > 0 ? (
    <CardSection ref={ref}>
      <Typography variant="h5" paragraph>
        <FormattedMessage id="skill.levels_title"/>
      </Typography>
      {map(levelDescriptions, (description, level) => (
        <Accordion
            key={level}
            defaultExpanded={level === defaultExpandedLevel}
            disabled={disabled ? true : undefined}
            elevation={0}
            square
            className={(level === 0 && first) || (level === lastLevel && last) || undefined}
        >
          <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls={`panel${level}-content`}
              id={`panel${level}-header`}
          >
            <FormattedMessage id="common.skill_level.item" values={{ level, bold }}/>
          </AccordionSummary>
          <AccordionDetails id={`panel${level}-content`} className={details}>
            <LevelDescription description={description}/>
            <SkillActivities accordion skills={skills} selectedLevel={level as SkillLevel} disabled={disabled}/>
          </AccordionDetails>
        </Accordion>
      ))}
    </CardSection>
  ) : null;
});

LevelDescriptionsAccordion.displayName = 'LevelDescriptionsAccordion';

LevelDescriptionsAccordion.propTypes = LevelDescriptionsAccordionPropTypes;

export default memo(LevelDescriptionsAccordion);
