import { useCallback, useMemo } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// TM UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { DevPlanTargetSkill, SkillActivity } from '../graphql/types';
import { DevPlanAdvisor, DevPlanCourse, DevPlanOpportunity, DevPlanProgress } from '../graphql/customTypes';
import { Skill } from '../models/skill';
import { APIResponse } from '../models/apiResponse';
import useCustomerSettings from '../config/customer';
import { getSkillCurrentLevel } from '../helpers/models';
import {
  IAutoTable, IJsPDF, Rows, Headers,
  getTitleFilename, getBooleanString,
  downloadAndSave, saveToCSV, saveToPDF
} from '../helpers/export';

// DevPlan Employees Progress export

const DEV_PLAN_PROGRESS_CSV_COLUMNS = [
  'supervisor.export.code',
  'supervisor.export.first_name',
  'supervisor.export.last_name',
  'supervisor.export.role_code',
  'supervisor.export.role_title',
  'supervisor.export.city',
  'supervisor.export.state',
  'supervisor.export.country',
  'supervisor.export.manager.uid',
  'supervisor.export.manager.name',
  'supervisor.export.performance_rating',
  'supervisor.export.years_on_job',
  'supervisor.export.devplan_status',
  'supervisor.export.initial_match_rate',
  'supervisor.export.current_match_rate'
];

function useExportDevPlanProgress() {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  const saveDevPlanProgress = useCallback((
    devplanTitle: string,
    headers: Headers,
    targetSkills: DevPlanTargetSkill[],
    courses: DevPlanCourse[],
    advisors: DevPlanAdvisor[],
    opportunities: DevPlanOpportunity[],
    activities: SkillActivity[] | null | undefined,
    employees: DevPlanProgress[],
    jsPDF?: IJsPDF,
    autoTable?: IAutoTable
    // eslint-disable-next-line max-params
  ) => {
    const titles = [
      ...headers,
      ...HAS_COURSES ? map(courses, ({ code, title }) => `${title} - ${code}`) : [],
      ...HAS_MENTORING ? map(advisors, ({ code, first_name, last_name }) =>
        first_name || last_name ? `${getFullName(first_name, last_name)} - ${code}` : code) : [],
      ...HAS_DEV_PLAN_OPPORTUNITIES ? map(opportunities, ({ id, title }) => `${title} - ${id}`) : [],
      ...activities ? map(activities, ({ code, name }) => `${name} - ${code}`) : [],
      ...map(targetSkills, 'title')
    ];
    const rows = map(employees, ({
      code, first_name, last_name, performance_rating, years_on_job, current_job, location, manager, skills,
      is_devplan_active, initial_match_rate, current_match_rate,
      completed_courses, completed_advisors, completed_opportunities, activities: completed_activities
    }) => [
      code,
      first_name,
      last_name,
      current_job?.code,
      current_job?.title,
      location?.city,
      location?.state,
      location?.country,
      manager?.code,
      getFullName(manager?.first_name, manager?.last_name),
      performance_rating,
      years_on_job,
      getBooleanString(is_devplan_active),
      initial_match_rate,
      current_match_rate,
      ...HAS_COURSES ? map(courses, ({ id }) => getBooleanString(Boolean(find(completed_courses, ['id', id])))) : [],
      ...HAS_MENTORING ? map(advisors, ({ id }) => getBooleanString(Boolean(find(completed_advisors, ['id', id])))) : [],
      ...HAS_DEV_PLAN_OPPORTUNITIES ? map(opportunities, ({ id }) =>
        getBooleanString(Boolean(find(completed_opportunities, ['id', id])))) : [],
      ...activities ? map(activities, ({ id }) =>
        getBooleanString(Boolean(find(completed_activities, ({ activity }) => activity.id === id)))) : [],
      ...map(targetSkills, ({ id }) => getSkillCurrentLevel(find(skills, ['id', id]) as Skill))
    ] as RowInput) as Rows;
    const filename = `devplan_progress_${getTitleFilename(devplanTitle)}`;
    if (jsPDF && autoTable) {
      saveToPDF(jsPDF, autoTable, filename, titles, rows, devplanTitle, '' /* , {} */);
    } else {
      saveToCSV(filename, titles, rows);
    }
  }, [HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING]);

  const exportDevPlanProgress = useCallback((
    format: string,
    title: string,
    headers: Headers,
    skills: DevPlanTargetSkill[],
    courses: DevPlanCourse[],
    advisors: DevPlanAdvisor[],
    opportunities: DevPlanOpportunity[],
    activities: SkillActivity[] | null | undefined,
    employees: DevPlanProgress[]
    // eslint-disable-next-line max-params
  ): Promise<boolean | number> => downloadAndSave<DevPlanProgress>(
    format,
    {
      status: 200, data: { results: employees } as APIResponse<DevPlanProgress>
    } as AxiosResponse<APIResponse<DevPlanProgress>>,
    (data, jsPDF, autoTable) =>
      saveDevPlanProgress(title, headers, skills, courses, advisors, opportunities, activities, data, jsPDF, autoTable)
  ), [saveDevPlanProgress]);

  return useMemo(() => ({
    DEV_PLAN_PROGRESS_CSV_COLUMNS,
    exportDevPlanProgress
  }), [exportDevPlanProgress]);
}

export default useExportDevPlanProgress;
