import { memo, useEffect, type FunctionComponent } from 'react';
import map from 'lodash/map';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import {
  unstable_HistoryRouter as HistoryRouter, Routes, Route,
  useLocation, useNavigationType, createRoutesFromChildren, matchRoutes
} from 'react-router-dom';
import { init, reactRouterV6BrowserTracingIntegration, withSentryReactRouterV6Routing } from '@sentry/react';
// TM UI Components
import ErrorBoundary from '@empathco/ui-components/src/common/ErrorBoundary';
import ErrorBoundaryFallback from '@empathco/ui-components/src/common/ErrorBoundaryFallback';
// TM UI Components - SCSS imports
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { root } from '@empathco/ui-components/src/styles/modules/App.module.scss';
// local imports
import { API_MY_NOTIFICATION_COUNTS } from './config/api';
import { history } from './config/history';
import useCustomerSettings from './config/customer';
import usePathConfig, {
  PATH_HOME, PATH_SSO_SERVICE, PATH_LOGIN_LINK_EMAIL, PATH_LOGIN_LINK_SSO,
  PATH_SKILL, PATH_JOB, PATH_JOB_TCP,
  PATH_HR, PATH_HR_TEAMS, PATH_HR_JOB, PATH_HR_SKILL, PATH_HR_JOB_TCP, PATH_HR_DELEGATION,
  PATH_SUPERVISOR, PATH_SUPERVISOR_TEAM,
  PATH_SUPERVISOR_EMPLOYEE, PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_ACTIVITIES,
  PATH_SUPERVISOR_IN_DEMAND, PATH_SUPERVISOR_IN_DEMAND_LEARNING,
  PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES,
  PATH_SUPERVISOR_CURRENT_ROLE, PATH_SUPERVISOR_CURRENT_ROLE_LEARNING,
  PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP, PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES,
  PATH_SUPERVISOR_TALENT_FINDER, PATH_SUPERVISOR_SKILL,
  PATH_SUPERVISOR_OPPORTUNITIES, PATH_SUPERVISOR_OPPORTUNITY_NEW, PATH_SUPERVISOR_OPPORTUNITY_EDIT,
  PATH_SUPERVISOR_OPPORTUNITY, PATH_SUPERVISOR_OPPORTUNITY_MATCHES, PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS,
  PATH_MY, PATH_MY_SKILL, PATH_MY_SKILLS, PATH_MY_SKILLS_BROWSER,
  PATH_MY_SKILLS_LEARNING, PATH_MY_SKILLS_MENTORSHIP, PATH_MY_SKILLS_ACTIVITIES,
  PATH_MY_SKILLS_IN_DEMAND, PATH_MY_SKILLS_IN_DEMAND_LEARNING,
  PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP, PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES,
  PATH_MY_SKILLS_CURRENT_ROLE, PATH_MY_SKILLS_CURRENT_ROLE_LEARNING,
  PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP, PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES,
  PATH_MY_DEV_PLAN_NEW, PATH_MY_DEV_PLAN, PATH_MY_DEV_PLAN_EDITOR,
  PATH_MY_JOBS, PATH_MY_JOB, PATH_MY_JOB_TCP, PATHS_MY_PROFILE_BUILDER,
  PATH_MY_OPPORTUNITIES, PATH_MY_OPPORTUNITIES_BROWSE, PATH_MY_OPPORTUNITY,
  PATH_MY_PREFERENCES, PATH_MY_NOTIFICATIONS, PATH_MY_WELCOME, PATH_MY_SPLASH,
  PATH_HR_TALENT_FINDER, PATH_HR_COHORTS, PATH_HR_COHORT, PATH_HR_COHORT_EDIT,
  PATH_HR_DEV_PLANS, PATH_HR_DEV_PLAN_COHORT_NEW, PATH_HR_DEV_PLAN_EMPLOYEE_NEW, PATH_HR_DEV_PLAN, PATH_HR_DEV_PLAN_EDITOR,
  PATH_HR_TEAM_BUILDER, PATH_HR_TEAM_BUILDER_TEAM, PATH_HR_TEAM_BUILDER_REVIEW,
  PATH_ADMIN_EMPLOYEES, PATH_ADMIN_JOBS, PATH_ADMIN_REPORTS, PATH_ADMIN_USERS, PATH_ADMIN_REPORT, PATH_ADMIN_SETTINGS,
  PATH_ADMIN_INDEMAND_SKILLS, PATH_HR_REDEPLOYMENTS, PATH_HR_REDEPLOYMENT,
  PATH_ADMIN_SET_PASSWORD
} from './config/paths';
import ContextProviders from './context/ContextProviders';
// Redirectoin helper
import RedirectToNewPath from './elements/RedirectToNewPath';
// Home Screens
import EmptyScreen from './screens/EmptyScreen';
import HomeScreen from './screens/HomeScreen';
import SingleSignOnScreen from './screens/SingleSignOnScreen';
import SplashScreen from './screens/SplashScreen';
import LoginScreen from './screens/LoginScreen';
// HRBP Screens
import HRDashboardScreen from './screens/HRDashboardScreen';
import HRTeamsScreen from './screens/HRTeamsScreen';
import HRTalentFinderScreen from './screens/HRTalentFinderScreen';
import HRDevPlanScreen from './screens/HRDevPlanScreen';
import HRRedeploymentScreen from './screens/HRRedeploymentScreen';
import DelegationScreen from './screens/DelegationScreen';
import InDemandSkillsScreen from './screens/InDemandSkillsScreen';
// Admin Screens
import AdminScreen from './screens/AdminScreen';
import AdminSetPasswordScreen from './screens/AdminSetPasswordScreen';
// Supervisor Screens
import SupervisorScreen from './screens/SupervisorScreen';
import SupervisorTeamScreen from './screens/SupervisorTeamScreen';
import SupervisorTalentFinderScreen from './screens/SupervisorTalentFinderScreen';
import SupervisorEmployeeScreen from './screens/SupervisorEmployeeScreen';
// Opportuinity Marketplace
import OpportunitiesScreen from './screens/OpportunitiesScreen';
// Employee Screens
import EmployeeDashboardScreen from './screens/EmployeeDashboardScreen';
import EmployeeRolesScreen from './screens/EmployeeRolesScreen';
import EmployeeSkillsScreen from './screens/EmployeeSkillsScreen';
import EmployeeDevPlanEditorScreen from './screens/EmployeeDevPlanEditorScreen';
import EmployeeDevPlanReviewScreen from './screens/EmployeeDevPlanReviewScreen';
import PreferencesScreen from './screens/PreferencesScreen';
import NotificationsScreen from './screens/NotificationsScreen';
// Employee Onboarding
import ProfileBuilderScreen from './screens/ProfileBuilderScreen';
// Universal Screens
import RoleScreen from './screens/RoleScreen';
import SkillScreen from './screens/SkillScreen';

const dsn = window.SENTRY_DSN;
if (dsn) init({
  dsn,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes
    })
  ],
  ignoreErrors: ['Non-Error exception captured'],
  beforeSendTransaction(event) {
    // Don't send the notifications count polling requests to Sentry
    const breadcrumbs = reject(event.breadcrumbs, ({ data }) => data && includes(data.url, API_MY_NOTIFICATION_COUNTS));
    return !event.breadcrumbs || event.breadcrumbs.length === breadcrumbs.length ? event
      : { ...event, breadcrumbs } as typeof event;
  },
  tracesSampleRate: 1.0
});

const SentryRoutes = dsn ? withSentryReactRouterV6Routing(Routes) : Routes;

// eslint-disable-next-line max-statements, complexity
const App: FunctionComponent = () => {
  const {
    HAS_INDEMAND_SKILLS, HAS_MENTORING, HAS_COURSES_READONLY, HAS_PROFILE_BUILDER, HAS_TALENT_FINDER, HAS_DEV_PLAN,
    HAS_OPPORTUNITIES, HAS_REDEPLOYMENT, HAS_TEAM_BUILDER, HAS_INDEMAND_SKILLS_EDITOR, HAS_ADMIN_UI
  } = useCustomerSettings();
  const { PATH_LOGIN_SSO, PATH_LOGOUT_LINK } = usePathConfig();

  const HAS_HR_DEV_PLANS = HAS_TALENT_FINDER && HAS_DEV_PLAN;

  const emptyScreen = <EmptyScreen/>;
  const splashScreen = <SplashScreen/>;
  const homeScreen = <HomeScreen/>;
  const loginScreen = <LoginScreen/>;
  const singleSignOnScreen = <SingleSignOnScreen/>;
  const hrDashboardScreen = <HRDashboardScreen/>;
  const roleScreen = <RoleScreen/>;
  const skillScreen = <SkillScreen/>;
  const hrTeamsScreen = HAS_TEAM_BUILDER ? <HRTeamsScreen/> : undefined;
  const hrTalentFinderScreen = HAS_TALENT_FINDER ? <HRTalentFinderScreen/> : undefined;
  const supervisorTalentFinderScreen = HAS_TALENT_FINDER ? <SupervisorTalentFinderScreen/> : undefined;
  const hrDevPlanScreen = HAS_HR_DEV_PLANS ? <HRDevPlanScreen/> : undefined;
  const hrRedeploymentScreen = HAS_REDEPLOYMENT ? <HRRedeploymentScreen/> : undefined;
  const delegationScreen = <DelegationScreen/>;
  const inDemandSkillsScreen = HAS_INDEMAND_SKILLS_EDITOR ? <InDemandSkillsScreen/> : undefined;
  const adminScreen = HAS_ADMIN_UI ? <AdminScreen/> : undefined;
  const adminSetPasswordScreen = HAS_ADMIN_UI ? <AdminSetPasswordScreen/> : undefined;
  const supervisorScreen = <SupervisorScreen/>;
  const supervisorTeamScreen = <SupervisorTeamScreen/>;
  const supervisorEmployeeScreen = <SupervisorEmployeeScreen/>;
  const employeeDashboardScreen = <EmployeeDashboardScreen/>;
  const employeeSkillsIndexScreen = <EmployeeSkillsScreen/>;
  const employeeRolesScreen = <EmployeeRolesScreen/>;
  const preferencesScreen = <PreferencesScreen/>;
  const notificationsScreen = <NotificationsScreen/>;
  const employeeDevPlanReviewScreen = HAS_DEV_PLAN ? <EmployeeDevPlanReviewScreen/> : undefined;
  const employeeDevPlanEditorScreen = HAS_DEV_PLAN ? <EmployeeDevPlanEditorScreen/> : undefined;
  const profileBuilderScreen = HAS_PROFILE_BUILDER ? <ProfileBuilderScreen/> : undefined;
  const opportunitiesScreen = HAS_OPPORTUNITIES ? <OpportunitiesScreen/> : undefined;

  const skillRedirect = <RedirectToNewPath path={PATH_SKILL}/>;
  const roleRedirect = <RedirectToNewPath path={PATH_JOB}/>;
  const roleTcpRedirect = <RedirectToNewPath path={PATH_JOB_TCP}/>;

  const routes = [
    // Login Screens
    ...map([PATH_LOGOUT_LINK, PATH_SSO_SERVICE, PATH_LOGIN_LINK_SSO], (path) => ({ path, element: emptyScreen })),
    { path: PATH_LOGIN_LINK_EMAIL, element: loginScreen },
    { path: PATH_LOGIN_SSO, element: singleSignOnScreen },

    // HR Screens
    { path: PATH_HR, element: hrDashboardScreen },

    ...HAS_TEAM_BUILDER ? map(
      [PATH_HR_TEAMS, PATH_HR_TEAM_BUILDER, PATH_HR_TEAM_BUILDER_TEAM, PATH_HR_TEAM_BUILDER_REVIEW],
      (path) => ({ path, element: hrTeamsScreen })
    ) : [],

    ...HAS_TALENT_FINDER ? map(HAS_DEV_PLAN
      ? [PATH_HR_TALENT_FINDER, PATH_HR_COHORTS, PATH_HR_COHORT, PATH_HR_COHORT_EDIT]
      : [PATH_HR_TALENT_FINDER],
      (path) => ({ path, element: hrTalentFinderScreen })
    ) : [],

    ...HAS_TALENT_FINDER ? [{ path: PATH_SUPERVISOR_TALENT_FINDER, element: supervisorTalentFinderScreen }] : [],

    ...HAS_HR_DEV_PLANS ? map([
      PATH_HR_DEV_PLANS, PATH_HR_DEV_PLAN_COHORT_NEW, PATH_HR_DEV_PLAN_EMPLOYEE_NEW,
      PATH_HR_DEV_PLAN, PATH_HR_DEV_PLAN_EDITOR
    ], (path) => ({ path, element: hrDevPlanScreen })
    ) : [],

    ...HAS_REDEPLOYMENT ? map([PATH_HR_REDEPLOYMENTS, PATH_HR_REDEPLOYMENT],
      (path) => ({ path, element: hrRedeploymentScreen })) : [],

    { path: PATH_HR_DELEGATION, element: delegationScreen },
    ...HAS_INDEMAND_SKILLS_EDITOR ? [{ path: PATH_ADMIN_INDEMAND_SKILLS, element: inDemandSkillsScreen }] : [],

    // Admin Screens
    ...HAS_ADMIN_UI ? map([
      PATH_ADMIN_EMPLOYEES, PATH_ADMIN_JOBS, PATH_ADMIN_REPORTS,
      PATH_ADMIN_REPORT, PATH_ADMIN_USERS, PATH_ADMIN_SETTINGS],
      (path) => ({ path, element: adminScreen })) : [],
    ...HAS_ADMIN_UI ? [{ path: PATH_ADMIN_SET_PASSWORD, element: adminSetPasswordScreen }] : [],

    // Supervisor Screens
    { path: PATH_SUPERVISOR, element: supervisorScreen },
    { path: PATH_SUPERVISOR_TEAM, element: supervisorTeamScreen },
    ...map([
      PATH_SUPERVISOR_EMPLOYEE,
      PATH_SUPERVISOR_ACTIVITIES,
      PATH_SUPERVISOR_CURRENT_ROLE,
      PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES,
      ...HAS_COURSES_READONLY ? [PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_CURRENT_ROLE_LEARNING] : [],
      ...HAS_MENTORING ? [PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP] : [],
      ...HAS_INDEMAND_SKILLS ? [
        PATH_SUPERVISOR_IN_DEMAND,
        ...HAS_COURSES_READONLY ? [PATH_SUPERVISOR_IN_DEMAND_LEARNING] : [],
        ...HAS_MENTORING ? [PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP] : [],
        PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES
      ] : []
    ], (path) => ({ path, element: supervisorEmployeeScreen })),

    // Employee Screens
    { path: PATH_MY, element: employeeDashboardScreen },
    ...map([
      PATH_MY_SKILLS,
      PATH_MY_SKILLS_BROWSER,
      ...HAS_COURSES_READONLY ? [PATH_MY_SKILLS_LEARNING, PATH_MY_SKILLS_CURRENT_ROLE_LEARNING] : [],
      ...HAS_MENTORING ? [PATH_MY_SKILLS_MENTORSHIP, PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP] : [],
      PATH_MY_SKILLS_ACTIVITIES,
      PATH_MY_SKILLS_CURRENT_ROLE,
      PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES,
      ...HAS_INDEMAND_SKILLS ? [
        PATH_MY_SKILLS_IN_DEMAND,
        ...HAS_COURSES_READONLY ? [PATH_MY_SKILLS_IN_DEMAND_LEARNING] : [],
        ...HAS_MENTORING ? [PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP] : [],
        PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES
      ] : []
    ], (path) => ({ path, element: employeeSkillsIndexScreen })),

    ...HAS_DEV_PLAN ? map([PATH_MY_DEV_PLAN_NEW, PATH_MY_DEV_PLAN_EDITOR],
      (path) => ({ path, element: employeeDevPlanEditorScreen })) : [],
    ...HAS_DEV_PLAN ? [{ path: PATH_MY_DEV_PLAN, element: employeeDevPlanReviewScreen }] : [],

    ...HAS_PROFILE_BUILDER ? map([PATH_MY_WELCOME, ...PATHS_MY_PROFILE_BUILDER],
      (path) => ({ path, element: profileBuilderScreen })) : [],

    ...HAS_OPPORTUNITIES ? map([
      PATH_MY_OPPORTUNITIES, PATH_MY_OPPORTUNITIES_BROWSE, PATH_MY_OPPORTUNITY,
      PATH_SUPERVISOR_OPPORTUNITIES, PATH_SUPERVISOR_OPPORTUNITY_NEW, PATH_SUPERVISOR_OPPORTUNITY_EDIT,
      PATH_SUPERVISOR_OPPORTUNITY, PATH_SUPERVISOR_OPPORTUNITY_MATCHES, PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS
    ], (path) => ({ path, element: opportunitiesScreen })) : [],

    { path: PATH_MY_SPLASH, element: splashScreen },
    { path: PATH_MY_JOBS, element: employeeRolesScreen },
    { path: PATH_MY_PREFERENCES, element: preferencesScreen },
    { path: PATH_MY_NOTIFICATIONS, element: notificationsScreen },

    // Universal paths
    { path: PATH_SKILL, element: skillScreen },
    { path: PATH_JOB, element: roleScreen },
    { path: PATH_JOB_TCP, element: roleScreen },

    // Deprecated paths
    ...map([PATH_MY_SKILL, PATH_SUPERVISOR_SKILL, PATH_HR_SKILL], (path) => ({ path, element: skillRedirect })),
    ...map([PATH_MY_JOB, PATH_HR_JOB], (path) => ({ path, element: roleRedirect })),
    ...map([PATH_MY_JOB_TCP, PATH_HR_JOB_TCP], (path) => ({ path, element: roleTcpRedirect })),

    // Home Screen
    { path: PATH_HOME, element: homeScreen },
    { path: '*', element: homeScreen }
  ];

  return (
    <div className={root}>
      <HistoryRouter history={history as unknown as GetComponentProps<typeof HistoryRouter>['history']}>
        <SentryRoutes>
          {routes.map(({ path, element }) => (
            <Route
                key={path}
                path={path}
                element={(
                  <ErrorBoundary fallback={<ErrorBoundaryFallback/>}>
                    <ContextProviders>
                      {element}
                    </ContextProviders>
                  </ErrorBoundary>
                )}
            />
          ))}
        </SentryRoutes>
      </HistoryRouter>
    </div>
  );
};

export default memo(App);
