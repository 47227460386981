import { memo, useMemo, useContext, useEffect, type ReactNode, type FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
// TM UI Components
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import { SNACKBAR_ELEVATION } from '@empathco/ui-components/src/config/params';
import AlertTitleTypography from '@empathco/ui-components/src/mixins/AlertTitleTypography';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { isValidAuthErrorCode } from '../constants/authErrorCodes';
import useCustomerSettings from '../config/customer';
import usePathConfig from '../config/paths';
import { GlobalContext } from '../context/global';
import SplashText from '../elements/SplashText';
import Screen from '../v3/Screen';
// SCSS imports
import { alert } from './SingleSignOnScreen.module.scss';

const SingleSignOnScreen: FunctionComponent = () => {
  const { HAS_LOGIN, HAS_MULTIPLE_SSO } = useCustomerSettings();
  const { PATH_LOGIN_LINK } = usePathConfig();

  const { search } = useLocation();
  const { token, authErrorCode, user: { data, pending, failed }, authenticate } = useContext(GlobalContext);
  const noAccess = isValidAuthErrorCode(authErrorCode);
  const mayLogin = !pending && !failed && !token && !data && !noAccess;

  useEffect(() => {
    if (authenticate && mayLogin) {
      let tkn: string | null | undefined = null;
      if (search) try {
        tkn = new URLSearchParams(search)?.get?.('token');
      } catch (_error) {
        // nothing to do
      }
      authenticate({ token: tkn });
    }
  }, [authenticate, mayLogin, search]);

  const failedValues = useMemo(() => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink
          inherit
          withoutTarget
          href={HAS_LOGIN || HAS_MULTIPLE_SSO ? undefined : PATH_LOGIN_LINK}
          to={HAS_LOGIN || HAS_MULTIPLE_SSO ? PATH_LOGIN_LINK : undefined}
      >
        {mapChunks(chunks)}
      </StandardLink>
    )
  }), [HAS_MULTIPLE_SSO, HAS_LOGIN, PATH_LOGIN_LINK]);

  return noAccess ? (
    <Screen appbar="empty" splash>
      <SplashText variant="access_denied"/>
    </Screen>
  ) : (
    <>
      <LinearProgress
          color={failed ? 'secondary' : undefined}
          variant={failed ? 'determinate' : undefined}
          value={failed ? 0 : undefined}
      />
      {failed ? (
        <Box
            py={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <Alert
              severity="error"
              variant="standard"
              elevation={SNACKBAR_ELEVATION}
              className={alert}
          >
            <AlertTitleTypography variant="subtitle1">
              <FormattedMessage id="login.error.failed.title"/>
            </AlertTitleTypography>
            <Typography variant="subtitle2">
              <FormattedMessage
                  id="login.error.failed.text"
                  values={failedValues}
              />
            </Typography>
          </Alert>
        </Box>
      ) : undefined}
    </>
  );
};

export default memo(SingleSignOnScreen);
