import { memo, useContext, type FunctionComponent } from 'react';
// TM UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { Skill } from '../models/skill';
import { getDataStatus, isDirtyData } from '../constants/dataStatuses';
import { DataContext } from '../context';
import RolesGrid from '../v3/RolesGrid';

const EmployeeSkillRelatedJobs: FunctionComponent = () => {
  const {
    dataStatus: { data: dataStatus, pending: pendingStatus, failed: failedStatus },
    skill: { data: skill, pending, failed }
  } = useContext(DataContext);
  const dirty = isDirtyData(getDataStatus(dataStatus));
  const { jobs } = pending || failed || !skill ? {} as Skill : skill;
  return failed ? null : (
    <ContentCard>
      <CardTitle
          title="skill.related_jobs"
          withDivider
      />
      <RolesGrid
          dirty={dirty}
          roles={jobs}
          pending={pending || pendingStatus}
          failed={failed || failedStatus || (!pending && skill && !jobs)}
      />
    </ContentCard>
  );
};

export default memo(EmployeeSkillRelatedJobs);
