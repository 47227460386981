import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import clsx from 'clsx';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// TM UI Components
import { dateOnlyOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { TbTeam } from '../models/tbTeam';
import { Cohort, DevPlan } from '../graphql/types';
import { EmployeeDevPlan } from '../graphql/customTypes';
import { PATH_HR_COHORT, PATH_HR_DEV_PLAN, PATH_HR_REDEPLOYMENT, PATH_HR_TEAM_BUILDER_REVIEW } from '../config/paths';
// SCSS imports
import { card, ownCard, titleRow, titleDevPlan, disabledText } from './TbTeamCard.module.scss';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';

export type TeamCardVariant = 'cohort' | 'devplan' | 'redeployment';

type TbTeamCardProps = {
  item: TbTeam | Cohort | DevPlan | EmployeeDevPlan;
  variant?: TeamCardVariant;
  hrbp?: boolean;
  userId?: number;
  disabled?: boolean | null;
  onClick?: (devplan: EmployeeDevPlan) => void;
}

const TbTeamCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<TbTeam>,
  variant: PropTypes.string as Validator<TeamCardVariant>,
  hrbp: PropTypes.bool,
  userId: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

// eslint-disable-next-line complexity
const TbTeamCard = forwardRef<HTMLDivElement, TbTeamCardProps>(({
  item,
  variant,
  hrbp = false,
  userId,
  disabled = false,
  onClick
}, ref) => {
  const { id, title, employees_count, updated_at } = (item || {}) as TbTeam & Cohort;
  const { employee, cohort: savedCohort } = ((variant === 'devplan' && item) || {}) as DevPlan;
  const { is_new, is_active, owner } = ((variant === 'devplan' && item) || {}) as EmployeeDevPlan;
  const { employees_count: cohort_count } = savedCohort || {};

  const handleClick = useCallback(() => onClick?.(item as EmployeeDevPlan), [item, onClick]);

  const values = useMemo(() => ({
    date: (
      <FormattedDate
          value={getJsDateFromISO(updated_at)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...{ ...dateOnlyOptions, month: 'numeric', year: '2-digit', day: 'numeric' }}
      />
    ),
    ...!hrbp && owner ? {
      br: <br/>,
      updater: userId && userId === owner.id ? null : getFullName(owner.first_name, owner.last_name)
    } : {}
  }), [updated_at, owner, userId, hrbp]);

  return (
    <ItemCard
        ref={ref}
        light
        link={hrbp && !disabled ? injectParams(
          (variant === 'cohort' && PATH_HR_COHORT) ||
          (variant === 'devplan' && PATH_HR_DEV_PLAN) ||
          (variant === 'redeployment' && PATH_HR_REDEPLOYMENT) ||
          PATH_HR_TEAM_BUILDER_REVIEW,
          (variant === 'cohort' && { cohort_id: id }) ||
          ((variant === 'devplan' || variant === 'redeployment') && { plan_id: id }) ||
          { team_id: id }
        ) : undefined}
        onClick={onClick && !disabled ? handleClick : undefined}
        className={hrbp ? card : ownCard}
    >
      {(!hrbp && (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            pr={is_active ? undefined : 0.75}
            pb={2}
        >
          {is_active ? <TagLabel variant="active" small/> : <SkillNewTag active={is_new}/>}
        </Box>
      ))}
      <BoxTypography
          variant={variant === 'devplan' ? 'subtitle1' : 'subtitle2'}
          color={disabled ? undefined : 'secondary.main'}
          className={clsx(variant === 'devplan' ? titleDevPlan : titleRow, { [disabledText]: disabled })}
      >
        {title}
      </BoxTypography>
      {hrbp ? (
        <BoxTypography pt={1} variant="body2">
          {variant === 'devplan' && employee ? getFullName(employee.first_name, employee.last_name) : (
            <FormattedMessage
                id={
                  (variant === 'cohort' && 'hr.cohorts.employees_count') ||
                  (variant === 'devplan' && 'hr.dev_plans.cohort_employees_count') ||
                  (variant === 'redeployment' && 'hr.redeployment.employees_count') ||
                  'hr.teambuilder.team_members'
                }
                values={{ count: toSafeInteger(variant === 'devplan' ? cohort_count : employees_count) }}
            />
          )}
        </BoxTypography>
      ) : undefined}
      <BoxTypography
          pt={1.5}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          variant="body2"
          fontStyle="italic"
      >
        {updated_at ? (
          <FormattedMessage
              id={hrbp || !owner ? 'hr.teambuilder.last_saved' : 'hr.dev_plan.last_saved_by'}
              values={values}
          />
        ) : '—'}
      </BoxTypography>
    </ItemCard>
  );
});

TbTeamCard.displayName = 'TbTeamCard';

TbTeamCard.propTypes = TbTeamCardPropTypes;

export default memo(TbTeamCard);
