// TM UI Components
import { type TypeDefinitions } from '@empathco/ui-components/src/helpers/typePatcherFactory';

export const typeDefinitions: TypeDefinitions = {

  // IMPORTANT: Only include objects that DO NOT change depending on query parameters!

  // My Search
  MySearchPayload: {
    results: 'MySearchItem'
  },

  // Orgs
  OrgsPayload: {
    results: 'Org'
  },

  // Lookup Items
  LookupPayload: {
    results: 'LookupItem'
  },

  // Course Providers
  CourseProvidersPayload: {
    results: 'CourseProvider'
  },

  // Skills Search
  SimpleSkillsPayload: {
    results: 'SimpleSkill'
  },

  // Leaders
  LeadersPayload: {
    results: 'Leader'
  },

  // Delegates
  DelegatesPayload: {
    results: 'Delegate'
  },

  // States
  StatesPayload: {
    results: 'State'
  },

  // Locations
  LocationsPayload: {
    results: 'Location'
  },

  // Success Stories
  SuccessStoriesPayload: {
    results: 'SuccessStory'
  },

  // Course Advisor Search
  CourseAdvisorSearchPayload: {
    results: 'CourseAdvisorSearchItem'
  },

  // Activities
  EmployeeActivitiesPayload: {
    results: 'EmployeeActivity'
  },

  // In-Demand Skills
  InDemandSkillsPayload: {
    results: 'SimpleSkill'
  },

  // Suggested In-Demand Skills
  SuggestedInDemandSkillsPayload: {
    results: 'SimpleSkill'
  },

  // Manager Dashboard In-Demand Skills
  MDInDemandSkillsPayload: {
    results: 'MDInDemandSkill'
  },

  // Cohorts
  CohortsPayload: {
    results: 'Cohort'
  },

  // Development Plan
  EmployeesSearchPayload: {
    results: 'EmployeeSearchItem'
  },
  DevPlansPayload: {
    results: 'DevPlan'
  },

  // Redeployment Plans
  RedeploymentPlansPayload: {
    results: 'RedeploymentPlan'
  },

  // Opportunity Marketplace
  OpportunitiesPayload: {
    results: 'Opportunity'
  },
  Opportunity: {
    location: 'Location'
  },
  OpportunityOwnersPayload: {
    results: 'OpportunityOwner'
  },
  MyOpportunitiesPayload: {
    results: 'MyOpportunity'
  },
  MyOpportunity: {
    opportunity: 'Opportunity'
  },

  // Admin - Jobs
  AdminJobsPayload: {
    results: 'AdminJob'
  },
  AdminJob: {
    location: 'Location',
    career_ladder: 'CareerLadder',
    category: 'AdminJobCategory',
    discipline: 'JobDiscipline',
    unique_job: 'UniqueJob',
    sub_family: 'JobSubFamily'
  },
  JobDiscipline: {
    sub_family: 'JobDisciplineSubFamily'
  }

};
