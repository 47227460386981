import { memo, useCallback, useContext, useEffect, useState, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
// TM UI Components
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import SkillsGapLegend from '@empathco/ui-components/src/elements/SkillsGapLegend';
// local imports
import { NEW_OPPORTUNITY_BOOKING } from '../graphql/NewOpportunityBooking';
import { UPDATE_OPPORTUNITY_BOOKING } from '../graphql/UpdateOpportunityBooking';
import { UPDATE_OPPORTUNITY_MATCH } from '../graphql/UpdateOpportunityMatch';
import { UPDATE_OPPORTUNITY } from '../graphql/UpdateOpportunity';
import {
  BookingStatus, MyOpportunityStatus, OpportunityStatus, Opportunity, OpportunityMatch, MyOpportunity,
  NewOpportunityBookingDocument, UpdateOpportunityBookingDocument, UpdateOpportunityMatchDocument, UpdateOpportunityDocument
} from '../graphql/types';
import { GlobalContext } from '../context/global';
import PaginationControls, { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';
import OpportunityBookingCard from '../v3/OpportunityBookingCard';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';

const legendSx = { pb: 2.5 };

type OpportunityBookingPanelProps = {
  supervisor?: boolean;
  readOnly?: boolean;
  opportunity?: Opportunity | null;
  disabled?: boolean | null;
  bookings?: (OpportunityMatch | MyOpportunity)[] | null;
  count?: number | null;
  pending?: boolean | null;
  failed?: boolean | null;
  onBookingRequest?: (opp: MyOpportunity, reject?: boolean) => void;
  onRemoveApplication?: (opp: MyOpportunity) => void;
  onEndOpportunity?: (opp: MyOpportunity) => void;
  confirmingId?: number | null;
  rejectingId?: number | null;
  removingId?: number | null;
  filters?: ReactNode | ReactNode[];
  currentPage: number;
  onPageSelected: (page: number) => void;
  onPageSize: (pageSize: number) => void;
  // for Strorybook only
  testAction?: number;
}

const OpportunityBookingPanelPropTypes = {
  supervisor: PropTypes.bool,
  readOnly: PropTypes.bool,
  opportunity: PropTypes.object as Validator<Opportunity>,
  disabled: PropTypes.bool,
  bookings: PropTypes.array as Validator<(OpportunityMatch | MyOpportunity)[]>,
  count: PropTypes.number,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  onBookingRequest: PropTypes.func,
  onRemoveApplication: PropTypes.func,
  onEndOpportunity: PropTypes.func,
  confirmingId: PropTypes.number,
  rejectingId: PropTypes.number,
  removingId: PropTypes.number,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  currentPage: PropTypes.number.isRequired,
  onPageSelected: PropTypes.func.isRequired,
  onPageSize: PropTypes.func.isRequired,
  testAction: PropTypes.number
};

// eslint-disable-next-line complexity, max-lines-per-function
const OpportunityBookingPanel: FunctionComponent<OpportunityBookingPanelProps> = ({
  supervisor = false,
  readOnly = false,
  opportunity,
  disabled: parentDisabled = false,
  bookings,
  count,
  pending = false,
  failed = false,
  onBookingRequest,
  onRemoveApplication,
  onEndOpportunity,
  confirmingId,
  rejectingId,
  removingId,
  filters,
  currentPage,
  onPageSelected,
  onPageSize,
  testAction
}) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);

  const { id: opportunity_id } = opportunity || {};

  const { mutate: newRequest, loading: requestPending, failed: requestFailed } = useMutationMethod({
    // TODO: key: 'newOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(NEW_OPPORTUNITY_BOOKING as typeof NewOpportunityBookingDocument)
  });

  const { mutate: updateBooking, loading: bookingPending, failed: bookingFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_BOOKING as typeof UpdateOpportunityBookingDocument)
  });

  const { mutate: rejectBooking, loading: rejectPending, failed: rejectFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_BOOKING as typeof UpdateOpportunityBookingDocument)
  });

  const { mutate: deleteBooking, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_BOOKING as typeof UpdateOpportunityBookingDocument)
  });

  const { mutate: updateMatch, loading: matchPending, failed: matchFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityMatch', // -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_MATCH as typeof UpdateOpportunityMatchDocument)
  });

  const { mutate: updateOpportunity, loading: updatePending, failed: updateFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunity', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY as typeof UpdateOpportunityDocument)
  });

  const [pendingMatchId, setPendingMatchId] = useState<number | null>(null);

  const handleRemove = useCallback((match_id: number) => {
    if (opportunity_id && match_id) {
      setPendingMatchId(match_id);
      updateMatch?.({
        variables: { opportunity_id, match_id, input: { owner_status: MyOpportunityStatus.active } },
        // TODO: optimistic response
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' }); // need to update counts
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' }); // need to update counts
        },
        onCompleted: () => setPendingMatchId(null),
        onError: () => setPendingMatchId(null)
      });
    }
  }, [opportunity_id, updateMatch]);

  const handleDelete = useCallback((match_id: number, booking_id?: number, shortlisted?: boolean) => {
    if (opportunity_id && match_id) {
      setPendingMatchId(match_id);
      if (booking_id) {
        deleteBooking?.({
          variables: { opportunity_id, booking_id, input: { status: BookingStatus.deleted } },
          // TODO: optimistic response
          update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' }); // need to update counts
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' }); // need to update counts
          },
          onCompleted: () => {
            if (shortlisted) handleRemove(match_id); // remove from shortlist
            else setPendingMatchId(null);
          },
          onError: () => setPendingMatchId(null)
        });
      } else /* if (shortlisted) -- must be `true` anyway */ handleRemove(match_id);
    }
  }, [opportunity_id, handleRemove, deleteBooking]);

  const handleRequest = useCallback((oppMatch: OpportunityMatch, reject = false) => {
    if (!opportunity_id || !oppMatch) return;
    const { id: booking_id } = oppMatch.booking || {};
    const status = reject ? BookingStatus.manager_rejected : BookingStatus.manager_requested;
    if (booking_id) {
      setPendingMatchId(oppMatch.id);
      (reject ? rejectBooking : updateBooking)?.({
        variables: { opportunity_id, booking_id, input: { status } },
        // TODO: optimistic response
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' }); // need to update counts
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' }); // need to update counts
        },
        onCompleted: () => setPendingMatchId(null),
        onError: () => setPendingMatchId(null)
      });
    } else {
      const { id: employee_id } = oppMatch.employee || {};
      if (employee_id) {
        setPendingMatchId(oppMatch.id);
        newRequest?.({
          variables: { opportunity_id, input: { employee_id, status } },
          // TODO: optimistic response
          update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' }); // need to update counts
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' }); // need to update counts
          },
          onCompleted: () => setPendingMatchId(null),
          onError: () => setPendingMatchId(null)
        });
      }
    }
  }, [opportunity_id, newRequest, updateBooking, rejectBooking]);

  const handleOpportunityStatus = useCallback((matchId: number, status: OpportunityStatus) => {
    // Manager can only Start or Archive an opportunity from Booking tab
    if (opportunity_id && (status === OpportunityStatus.started || status === OpportunityStatus.archived)) {
      setPendingMatchId(matchId);
      updateOpportunity?.({
        variables: { opportunity_id, input: { status } },
        // TODO: optimistic response
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
        },
        onCompleted: () => setPendingMatchId(null),
        onError: () => setPendingMatchId(null)
      });
    }
  }, [opportunity_id, updateOpportunity]);

  useEffect(() => {
    if (testAction) {
      if (testAction === 1) {
        setPendingMatchId(15);
        newRequest?.({
          variables: { opportunity_id: 444, input: { employee_id: 304, status: BookingStatus.manager_requested } },
          onError: () => setPendingMatchId(null)
        });
      } else if (testAction === 2 || testAction === 3) {
        setPendingMatchId(14);
        (testAction === 3 ? rejectBooking : updateBooking)?.({
          variables: { opportunity_id: 444, booking_id: 123779, input: { status: BookingStatus.manager_requested } },
          onError: () => setPendingMatchId(null)
        });
      } else if (testAction === 4) {
        setPendingMatchId(14);
        updateMatch?.({
          variables: { opportunity_id: 444, match_id: 14, input: { owner_status: MyOpportunityStatus.active } },
          onError: () => setPendingMatchId(null)
        });
      } else if (testAction === 5 || testAction === 6) {
        setPendingMatchId(12);
        updateOpportunity?.({
          variables: { opportunity_id: 444, input: {
            status: testAction === 5 ? OpportunityStatus.started : OpportunityStatus.archived
          } },
          onError: () => setPendingMatchId(null)
        });
      }
    }
  }, [testAction, newRequest, updateBooking, rejectBooking, updateMatch, updateOpportunity]);

  const disabled = parentDisabled || requestPending || bookingPending || rejectPending || deletePending ||
    matchPending || updatePending;

  const pagination = (
    <PaginationControls
        settingsId={supervisor ? 'opp_booking' : 'my_opportunities'}
        loaded={Boolean(bookings)}
        pending={pending}
        loading={pending}
        total={count}
        currentPage={currentPage}
        onPageSelected={onPageSelected}
        onPageSize={onPageSize}
        disabled={disabled || failed}
    />
  );

  const withPagination = hasPagination(pagination as PaginationControlsComponent);
  const loading = pending && !bookings;
  const reloading = Boolean(pending && bookings);

  const content = failed || loading ? undefined
    : (size(bookings) < 1 && (
      <Alert severity="info" variant="standard">
        <FormattedMessage id={supervisor ? 'opportunities.booking.empty' : 'opportunities.my.empty'}/>
      </Alert>
    )) || (
      <CardSection>
        {supervisor ? <SkillsGapLegend withGrowth sx={legendSx}/> : undefined}
        <Box display="flex" flexDirection="column">
          {/* eslint-disable-next-line complexity */}
          {map(bookings, (booking) => (
            <OpportunityBookingCard
                key={booking.id}
                supervisor={supervisor}
                item={booking}
                opportunity={opportunity}
                disabled={disabled}
                // manager
                route={supervisor ? supvEmplPath : undefined}
                readOnly={readOnly}
                onRequest={supervisor ? handleRequest : undefined}
                onRemove={supervisor ? handleRemove : undefined}
                onDelete={supervisor ? handleDelete : undefined}
                onOpportunityStatus={supervisor ? handleOpportunityStatus : undefined}
                statusPending={updatePending && pendingMatchId === booking.id ? true : undefined}
                deletePending={deletePending && pendingMatchId === booking.id ? true : undefined}
                // employee
                onBookingRequest={supervisor ? undefined : onBookingRequest}
                onRemoveApplication={supervisor ? undefined : onRemoveApplication}
                onEndOpportunity={supervisor ? undefined : onEndOpportunity}
                // manager & employee
                requestPending={((requestPending || bookingPending) && pendingMatchId === booking.id) ||
                  (confirmingId && confirmingId === booking.id)
                  ? true : undefined}
                rejectPending={(rejectPending && pendingMatchId === booking.id) ||
                  (rejectingId && rejectingId === booking.id)
                  ? true : undefined}
                removePending={(matchPending && pendingMatchId === booking.id) ||
                  (removingId && removingId === booking.id)
                  ? true : undefined}
            />
          ))}
        </Box>
      </CardSection>
    );

  return (
    <>
      {filters ? (
        <CardSection top={!failed && !loading} flex>
          {filters}
        </CardSection>
      ) : undefined}
      {(failed && <FetchFailedAlert flat/>) ||
      (loading && <LoadingPlaceholder flat/>) || (reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content)}
      {withPagination ? (
        <CardSection flex bottom={!loading}>
          {pagination}
        </CardSection>
      ) : pagination}
      <ActionFailedAlert
          message="opportunities.booking.new.error"
          open={requestFailed}
      />
      <ActionFailedAlert
          message="opportunities.booking.update.error"
          open={bookingFailed || rejectFailed || deleteFailed}
      />
      <ActionFailedAlert
          message="opportunities.matches.update.error"
          open={matchFailed}
      />
      <ActionFailedAlert
          message="opportunities.update.error"
          open={updateFailed}
      />
    </>
  );
};

OpportunityBookingPanel.propTypes = OpportunityBookingPanelPropTypes;

export default memo(OpportunityBookingPanel);
