import { forwardRef, memo, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { OpenReq } from '../models/openReq';
import useCustomerSettings from '../config/customer';
import useModels from '../helpers/models';
// SCSS imports
import { titleContainer, titleRow, infoContainer, jobRow } from './OpenReqCard.module.scss';

type OpenReqCardProps = {
  item: OpenReq;
}

const OpenReqCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<OpenReq>
};

const OpenReqCard = forwardRef<HTMLDivElement, OpenReqCardProps>(({
  item
}, ref) => {
  const { getOpenReqUrl } = useCustomerSettings();
  const { getLocationStr } = useModels();
  const { code, title, is_new, dept, location } = item || {};
  const locationStr = useMemo(() => getLocationStr(location), [location, getLocationStr]);
  return (
    <ItemCard
        ref={ref}
        bottomPadding
        light
        href={getOpenReqUrl(code)}
    >
      <SkillNewTag active={is_new}/>
      <Box mt={0.5} display="flex" className={titleContainer}>
        <Box color="secondary.main" className={titleRow}>
          {title}
        </Box>
      </Box>
      <Box
          color="text.label"
          display="flex"
          flexDirection="column"
          className={infoContainer}
      >
        {dept ? (
          <Typography variant="body1" className={jobRow}>
            {dept}
          </Typography>
        ) : undefined}
        {locationStr ? (
          <BoxTypography pt={1} variant="body1">
            {locationStr}
          </BoxTypography>
        ) : undefined}
      </Box>
    </ItemCard>
  );
});

OpenReqCard.displayName = 'OpenReqCard';

OpenReqCard.propTypes = OpenReqCardPropTypes;

export default memo(OpenReqCard);
