import { memo, type FunctionComponent } from 'react';
// TM UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import TeamView from '../components/TeamView';

const SupervisorTeamScreen: FunctionComponent = () => {
  const { content } = useVerifyUser({ manager: true });

  return content || (
    <Screen withSettings>
      <ContentCard>
        <CardTitle title="supervisor.my_team.title"/>
        <TeamView/>
      </ContentCard>
    </Screen>
  );
};

export default memo(SupervisorTeamScreen);
