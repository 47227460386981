import { forwardRef, memo, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { type ConnectableElement } from 'react-dnd';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
// Material Icon imports
import PanToolAltOutlined from '@mui/icons-material/PanToolAltOutlined';
// TM UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import { bold, type Values } from '@empathco/ui-components/src/helpers/intl';
import PlusChip from '@empathco/ui-components/src/elements/PlusChip';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
// local imports
import { Skill, SKILL_LEVEL_REGULAR } from '../models/skill';
import SkillChip from '../elements/SkillChip';
import SkillDraggableChip from '../elements/SkillDraggableChip';
import JobSkillsResetButton from '../elements/JobSkillsResetButton';
// SCSS imports
import { dropArea, dropUniform, placeholder, reset } from './TeamSelectedSkills.module.scss';

export type TeamSelectedSkillsVariant = 'normal' | 'simple' | 'uniform' | 'master' | 'extra';

const emptyMessageValues = { bold };

type TeamSelectedSkillsProps = {
  variant: TeamSelectedSkillsVariant;
  skillIds: number[];
  skills: Record<number, Skill>;
  moveSkill?: (id: number, index: number | null) => number[];
  onAddSkill?: MouseEventHandler<HTMLButtonElement>;
  onClick?: (id: number, abbr: string) => void;
  onDelete: (id: number) => void;
  onReset?: () => void;
  withLevels?: boolean;
  disabled?: boolean | null;
  pending?: boolean | null;
  isActive?: boolean | null;
  emptyMessage?: string;
  warning?: string;
  values?: Values;
  className?: string;
}

const TeamSelectedSkillsPropTypes = {
  variant: PropTypes.string.isRequired as Validator<TeamSelectedSkillsVariant>,
  skillIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  skills: PropTypes.object.isRequired as Validator<Record<number, Skill>>,
  moveSkill: PropTypes.func,
  onAddSkill: PropTypes.func,
  onClick: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  withLevels: PropTypes.bool,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  isActive: PropTypes.bool,
  emptyMessage: PropTypes.string,
  warning: PropTypes.string,
  values: PropTypes.object as Validator<Values>,
  className: PropTypes.string
};

// eslint-disable-next-line complexity
const TeamSelectedSkills = forwardRef<ConnectableElement, TeamSelectedSkillsProps>(({
  variant,
  skillIds,
  skills,
  moveSkill,
  onAddSkill,
  onClick,
  onDelete,
  onReset,
  withLevels = false,
  disabled = false,
  pending = false,
  isActive = false,
  emptyMessage,
  warning,
  values,
  className
}, ref) => {
  const uniform = variant === 'uniform';
  const simple = variant === 'simple';
  const master = variant === 'master';
  const extra = variant === 'extra';
  const hasSkills = size(skillIds) >= 1;
  return (
    <Box
        ref={ref}
        bgcolor={(master && 'background.switch') || (!uniform && !extra && 'background.darker') || undefined}
        display="flex"
        flexWrap="wrap"
        justifyContent={simple || master ? 'center' : undefined}
        mx={uniform || extra ? -1 : undefined}
        my={simple || uniform || extra || master ? undefined : 3}
        p={uniform || extra ? undefined : 1}
        borderRadius={uniform || extra ? undefined : shape.tinyBorderRadius}
        className={clsx({
          [dropUniform]: uniform || extra,
          [dropArea]: !uniform && !extra
        }, className)}
    >
      {hasSkills ? map(skillIds, (skillId) => {
        const skill = skills[skillId];
        return skill ? ((uniform || extra) && (
          <SkillDraggableChip
              key={skillId}
              skill={skill}
              level={withLevels ? skill.skill_proficiency_level || SKILL_LEVEL_REGULAR : undefined}
              onActivate={onClick}
              onDelete={onDelete}
              moveSkill={moveSkill}
              disabled={disabled ? true : undefined}
          />
        )) || (
          <SkillChip
              key={skillId}
              skill={skills[skillId]}
              level={withLevels ? skill.skill_proficiency_level || SKILL_LEVEL_REGULAR : undefined}
              onActivate={onClick}
              onDelete={onDelete}
              disabled={disabled ? true : undefined}
          />
        ) : null;
      }) : undefined}
      {isActive ? <PlusChip/> : undefined}
      {!simple && !uniform && (
        !extra || (!isActive && !hasSkills && size(skills) >= 1)
      ) && (
        !master || (emptyMessage && !hasSkills)
      ) ? (
        <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={2}
            py={1}
            color="text.secondary"
        >
          <Typography
              variant={extra ? 'subtitle2' : 'h4'}
              className={placeholder}
          >
            <FormattedMessage
                id={emptyMessage && !hasSkills ? emptyMessage : 'supervisor.drag_area_placeholder'}
                values={emptyMessageValues}
            />
          </Typography>
        </Box>
      ) : undefined}
      {onAddSkill ? (
        <Box mx={1.5} my={1}>
          <PlusButton
              label="supervisor.add_skill"
              disabled={disabled ? true : undefined}
              pending={pending ? true : undefined}
              onClick={onAddSkill}
          />
        </Box>
      ) : undefined}
      {simple ? (
        <JobSkillsResetButton
            onReset={onReset}
            disabled={disabled}
        />
      ) : onReset && (hasSkills || master || uniform) && (
        <Button
            color="primary"
            variant="text"
            disabled={disabled ? true : undefined}
            onClick={onReset}
            className={reset}
        >
          <FormattedMessage id="supervisor.reset"/>
        </Button>
      )}
      {warning ? (
        <>
          <Box width="100%"/>
          <Box pt={1.5} flex="1 1 0" display="flex" justifyContent="center">
            <Alert severity="error" variant="standard" elevation={9} icon={<PanToolAltOutlined/>}>
              <Typography variant="subtitle2">
                <FormattedMessage id={warning} defaultMessage={warning} values={values}/>
              </Typography>
            </Alert>
          </Box>
        </>
      ) : undefined}
    </Box>
  );
});

TeamSelectedSkills.displayName = 'TeamSelectedSkills';

TeamSelectedSkills.propTypes = TeamSelectedSkillsPropTypes;

export default memo(TeamSelectedSkills);
