import { memo, useCallback, useContext, useEffect, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// TM UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
// local imports
import { ADMIN_USERS_QUERY } from '../graphql/AdminUsers';
import {
  AdminUsersSort,
  AdminUser, AdminUsersDocument, AdminUsersQueryVariables
} from '../graphql/types';
import { ADMIN_USERS_SORT, DEFAULT_ADMIN_USERS_DIRECTION } from '../graphql/customTypes';
import { ADMIN_TABLE_PAGE_SIZES } from '../config/params';
import useFilters, { FilterValues } from '../hooks/useFilters';
import useTableSort from '../hooks/useTableSort';
import { DataContext } from '../context';
import PaginationControls, { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';
import UsersTable from '../v3/UsersTable';
import AdminUserDialog from '../widgets/AdminUserDialog';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { filters } from './AdminUsers.module.scss';

type AdminUsersProps = {
  settingsId?: string;
  // for Storybook only
  testPending?: boolean;
}

const AdminUsersPropTypes = {
  settingsId: PropTypes.string,
  testPending: PropTypes.bool
};

// eslint-disable-next-line max-statements
const AdminUsers: FunctionComponent<AdminUsersProps> = ({
  settingsId,
  testPending
}) => {
  const { defaultFilters, updateSearchParams } = useFilters(ADMIN_USERS_SORT);
  const defaultPage = defaultFilters?.current_page;

  const { sort, dir, handleSort } = useTableSort({
    settingsId,
    sortValues: ADMIN_USERS_SORT,
    defaultDirections: DEFAULT_ADMIN_USERS_DIRECTION,
    defaultSortBy: defaultFilters?.sort_by as AdminUsersSort,
    defaultDirection: defaultFilters?.direction
  });

  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);
  const settingsLoaded = !settingsId || (pendingSettings === false && failedSettings === false && Boolean(settingsData));

  // lazy load reports
  const { query: getUsers, pending: pendingUsers, failed, results: users, count, variables: prevVars } = useQueryCounted({
    data: undefined as unknown as AdminUser,
    key: 'adminUsers',
    lazyQuery: useLazyQuery(ADMIN_USERS_QUERY as typeof AdminUsersDocument)
  });
  const pending = pendingUsers || testPending;

  const search = defaultFilters?.search || '';
  // const [search, setSearch] = useState(defaultFilters?.search || '');
  const [currentPage, setCurrentPage] = useState(defaultPage || 1);
  const [pageSize, setPageSize] = useState(defaultFilters?.page_size || undefined);

  const [user, setUser] = useState<AdminUser>();
  const [dialogKey, setDialogKey] = useState(0);

  useEffect(() => {
    if (settingsLoaded && getUsers && !isNil(pageSize)) {
      const filterValues: FilterValues = {
        search: trim(search) || null,
        direction: dir
      };
      const variables: AdminUsersQueryVariables = {
        ...omitBy(filterValues, isNull),
        sort_by: sort, // typed as AdminUsersSort
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables) && (size(prevVars) >= 1 || !defaultPage)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getUsers({ variables });
      updateSearchParams({
        ...filterValues,
        sort_by: sort, // typed as string
        current_page: curPage,
        page_size: pageSize
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only filter values are monitored here (i.e. ignoring `prevVars` changes):
    search, sort, dir, pageSize, currentPage, getUsers, settingsLoaded, defaultPage, updateSearchParams
  ]);

  const hadleAddUser = useCallback(() => {
    setUser({ is_active: true } as AdminUser);
  }, []);

  const hadleUserClick = useCallback((usr: AdminUser) => {
    setUser(usr);
  }, []);

  const handleClose = useCallback(() => {
    setDialogKey((prev) => prev + 1);
    setUser(undefined);
  }, []);

  const pendingAll = pending;
  const loading = pendingAll || !users;
  const failedAny = failed || (settingsId ? failedSettings : false);
  const disabled = loading || failedAny || (settingsId && pendingSettingsUpdate) ? true : undefined;

  const reloading = Boolean(pending && users);

  const pagination = (
    <PaginationControls
        init={defaultFilters}
        settingsId={settingsId}
        loaded={Boolean(users)}
        pending={pending}
        loading={pendingAll}
        total={count}
        currentPage={currentPage}
        pageSizes={ADMIN_TABLE_PAGE_SIZES}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={disabled}
    />
  );
  const withPagination = hasPagination(pagination as PaginationControlsComponent);

  const content = (
    <CardSection top={withPagination}>
      <UsersTable
          data={users}
          pending={pending}
          failed={failedAny}
          sortBy={sort}
          direction={dir}
          changeSort={handleSort}
          onClick={hadleUserClick}
          disabled={disabled}
          withReloading
      />
    </CardSection>
  );
  
  return (
    <>
      <CardSection top className={filters}>
        <PlusButton
            label="admin.users.button.create"
            disabled={disabled}
            onClick={hadleAddUser}
        />
      </CardSection>
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      {withPagination ? (
        <CardSection flex>
          {pagination}
        </CardSection>
      ) : pagination}
      <AdminUserDialog
          key={dialogKey}
          user={user}
          onClose={handleClose}
      />
    </>
  );
};

AdminUsers.propTypes = AdminUsersPropTypes;

export default memo(AdminUsers);
