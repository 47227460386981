import { memo, useContext, useEffect, useCallback, useState, useLayoutEffect, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
// Material Icon imports
import Bullseye from 'mdi-material-ui/Bullseye';
// TM UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getName } from '@empathco/ui-components/src/helpers/strings';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
import SkillTrendIcon from '@empathco/ui-components/src/elements/SkillTrendIcon';
// local imports
import { isNotContractor } from '../models/user';
import useNonReducedUI from '../constants/managementLevel';
import useCustomerSettings from '../config/customer';
import { PATH_MY_SKILLS, PATH_MY_JOBS, PATH_SKILL } from '../config/paths';
import { RouterLinkState } from '../helpers/routerParams';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import RoleName from '../v3/RoleName';
import RoleSkills from './RoleSkills';
import DashboardCard from '../v3/DashboardCard';
import DashboardChart from '../v3/DashboardChart';
// SCSS imports
import { list, listItem, skillItem, icon, alert } from './EmployeeDashboard.module.scss';

const linkState: RouterLinkState = { fromDashboard: true };

type EmployeeDashboardProps = {
  exportSummary?: boolean;
}

const EmployeeDashboardPropTypes = {
  exportSummary: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements
const EmployeeDashboard: FunctionComponent<EmployeeDashboardProps> = ({
  exportSummary = false
}) => {
  const { HAS_EXPORT_MY_PLAN, HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { showNonReducedUI } = useNonReducedUI();
  const { fontsLoaded, user: { data: user } } = useContext(GlobalContext);
  const {
    boardSkills: { data: boardSkills, pending: pendingSkills, failed: failedSkills }, requireBoardSkills,
    boardSuggested: { data: boardSuggested, pending: pendingSuggested, failed: failedSuggested }, requireBoardSuggested,
    boardJobs: { data: boardJobs, pending: pendingJobs, failed: failedJobs }, requireBoardJobs,
    role: { data: role, pending: rolePending, failed: roleFailed }, requireEmployeeRole
  } = useContext(DataContext);
  const { first_name } = user || {};
  const userOrgId = HAS_INDEMAND_SKILLS ? user?.org?.id : undefined;
  const nonReducedUI = isNotContractor(user);

  useEffect(() => {
    requireBoardSkills?.();
  }, [requireBoardSkills]);
  useEffect(() => {
    requireBoardSuggested?.();
  }, [requireBoardSuggested]);
  useEffect(() => {
    requireBoardJobs?.();
  }, [requireBoardJobs]);

  const roleToExportSkillsGap = (HAS_EXPORT_MY_PLAN && nonReducedUI && user?.current_job?.code) || undefined;
  useEffect(() => {
    if (roleToExportSkillsGap) requireEmployeeRole?.({ role_id: roleToExportSkillsGap });
  }, [roleToExportSkillsGap, requireEmployeeRole]);

  const canExportMyPlan = Boolean(roleToExportSkillsGap) && nonReducedUI &&
    !rolePending && !roleFailed && Boolean(role) && showNonReducedUI(role) &&
    ((size(role?.skills) + size(role?.skills_with_gap)) >= 1 || Boolean(userOrgId));

  const [exporting, setExporting] = useState<boolean | null>(canExportMyPlan && exportSummary ? true : null);
  useLayoutEffect(() => {
    if (canExportMyPlan && exportSummary) setExporting(true);
  }, [exportSummary, canExportMyPlan]);

  const handleExport = useCallback(() => {
    setExporting(true);
  }, []);
  const handleExportFinished = useCallback((success: boolean) => {
    setExporting(success ? null : false);
  }, []);
  const handleExportDismiss = useCallback(() => {
    setExporting(null);
  }, []);

  const mySkillsCounts = { ...boardSkills || {} };
  if (!nonReducedUI) {
    mySkillsCounts.total = toSafeInteger(mySkillsCounts.total) - toSafeInteger(mySkillsCounts.new);
    if (mySkillsCounts.total < 0) mySkillsCounts.total = 0;
    mySkillsCounts.new = 0;
  }
  const { total: my_skills_total, new: my_skills_new } = mySkillsCounts;
  const { total: recommended_total, new: recommended_new, top: recommended_skills } = boardSuggested || {};
  const { total: jobs_total, new_close_match, new_open_reqs, top: jobs } = boardJobs || {};

  return (
    <>
      {canExportMyPlan ? (
        <>
          <Box pt={1.5} pb={1} px={3.25} display="flex" alignItems="center" justifyContent="flex-end">
            <Button
                color="primary"
                variant="contained"
                disabled={Boolean(exporting)}
                startIcon={exporting ? <CircularProgress size={18} color="inherit"/> : undefined}
                onClick={handleExport}
            >
              <FormattedMessage id="board.button.export"/>
            </Button>
          </Box>
          {exporting ? <RoleSkills isEmployee onExportFinished={handleExportFinished}/> : undefined}
        </>
      ) : undefined}
      <BoxTypography pt={canExportMyPlan ? undefined : 7} pb={5} px={6} variant="h2" align="center">
        <FormattedMessage
            id="board.hello"
            values={{
              name: getName(first_name) || '…'
            }}
        />
      </BoxTypography>
      <CardDeck centered>
        <DashboardCard
            wide={!nonReducedUI}
            title="board.my_skills"
            newMessage="board.my_skills_new"
            link={PATH_MY_SKILLS}
            linkState={linkState}
            count={my_skills_total}
            newCount={my_skills_new}
            hasChart
            pending={pendingSkills || !fontsLoaded}
            failed={failedSkills}
        >
          <DashboardChart counts={mySkillsCounts}/>
        </DashboardCard>
        {nonReducedUI ? (
          <DashboardCard
              title="board.recommendations"
              newMessage="board.recommendations_new"
              link={PATH_MY_SKILLS}
              count={recommended_total}
              newCount={recommended_new}
              pending={pendingSuggested}
              failed={failedSuggested}
          >
            {size(recommended_skills) > 0 ? (
              <List className={list}>
                {map(recommended_skills, ({ id, abbr, /* TODO: is_new, */ is_in_demand, title }) => (
                  <ListItem key={id} className={listItem}>
                    <ListItemText
                        disableTypography
                        primary={
                          <TruncatedTextLink
                              variant="h4"
                              maxLines={1}
                              text={title}
                              to={injectParams(PATH_SKILL, { skill_id: abbr })}
                              suffix={is_in_demand
                                ? <SkillTrendIcon active small placement="top" className={icon}/> : undefined}
                              className={is_in_demand ? skillItem : undefined}
                          />
                        }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Alert severity="info" variant="standard" className={alert}>
                <FormattedMessage id="board.skills.empty"/>
              </Alert>
            )}
          </DashboardCard>
        ) : undefined}
        <DashboardCard
            wide={!nonReducedUI}
            title="board.close_match_jobs"
            newMessage="board.open_reqs"
            link={PATH_MY_JOBS}
            linkState={linkState}
            count={jobs_total}
            newCount={new_open_reqs}
            altCount={new_close_match}
            pending={pendingJobs}
            failed={failedJobs}
        >
          {size(jobs) > 0 ? (
            <List className={list}>
              {map(jobs, (job) => {
                const { id, code, is_index_display, title, is_target, is_current, match_rate } = job;
                return (
                  <ListItem key={id} className={listItem}>
                    <ListItemText
                        primary={(
                          <Box display="flex" alignItems="center">
                            <Box flex="1 1 0" display="flex" alignItems="center">
                              <RoleName
                                  isEmployee
                                  variant="h4"
                                  code={is_index_display ? code : undefined}
                                  title={title}
                                  isCurrent={is_current}
                                  // isTarget={is_target}
                              />
                              {is_target ? <Bullseye color="primary" className={icon}/> : undefined}
                            </Box>
                            {showNonReducedUI(job) && (
                              <Box flex="0 1 0" pl={1}>
                                <FormattedMessage id="board.match_rate" values={{ value: toSafeInteger(match_rate) / 100 }}/>
                              </Box>
                            )}
                          </Box>
                        )}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Alert severity="info" variant="standard" className={alert}>
              <FormattedMessage id="board.jobs.empty"/>
            </Alert>
          )}
        </DashboardCard>
      </CardDeck>
      <ActionFailedAlert
          message="supervisor.export.failed"
          open={exporting === false}
          onDismiss={handleExportDismiss}
      />
    </>
  );
};

EmployeeDashboard.propTypes = EmployeeDashboardPropTypes;

export default memo(EmployeeDashboard);
