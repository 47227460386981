import { forwardRef, memo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import TableContainer from '@mui/material/TableContainer';
import Table, { type TableProps } from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Alert from '@mui/material/Alert';
// local imports
import { type Values, ValuesPropType } from '../helpers/intl';
import FetchFailedAlert from './FetchFailedAlert';
import LoadingPlaceholder from './LoadingPlaceholder';

export interface DataTableRow {
  values: ReactNode[];
  selected: boolean;
}

type DataTableProps = {
  data?: DataTableRow[] | null;
  titles: ReactNode[];
  titleValues?: Values;
  empty: string;
  pending?: boolean | null;
  failed?: boolean | null;
  lastLeftAlignedTitle?: number;
  tableSize?: TableProps['size'];
  className?: string;
}

const DataTablePropTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<DataTableRow>),
  titles: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired as Validator<ReactNode[]>,
  titleValues: ValuesPropType,
  empty: PropTypes.string.isRequired,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  lastLeftAlignedTitle: PropTypes.number,
  tableSize: PropTypes.string as Validator<TableProps['size']>,
  className: PropTypes.string
};

const DataTable = forwardRef<HTMLDivElement, DataTableProps>(({
  data,
  titles,
  titleValues,
  empty,
  pending = false,
  failed = false,
  lastLeftAlignedTitle = 0,
  tableSize = 'small',
  className
}, ref) => (
  <TableContainer ref={ref}>
    <Table size={tableSize} className={className}>
      <TableHead>
        <TableRow>
          {map(titles, (title, index) => (
            <TableCell
                key={index}
                align={index <= lastLeftAlignedTitle ? 'left' : 'center'}
            >
              {isString(title) && size(title) >= 1 ? (
                <FormattedMessage id={title} defaultMessage={title} values={titleValues}/>
              ) : title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {pending || failed || !data || size(data) < 1
        ? (
          <TableRow>
            <TableCell padding="none" colSpan={size(titles)}>
              {(failed && <FetchFailedAlert flat/>) ||
              ((pending || !data) && <LoadingPlaceholder flat/>) || (
                <Alert severity="info" variant="standard">
                  <FormattedMessage id={empty} defaultMessage={empty}/>
                </Alert>
            )}
            </TableCell>
          </TableRow>
          )
        : map(data, ({ values, selected }, rowIndex) => (
          <TableRow
              key={rowIndex}
              selected={selected}
          >
            {map(titles, (_title, index) => (
              <TableCell
                  key={index}
                  align={index <= lastLeftAlignedTitle ? 'left' : 'center'}
              >
                {values[index]}
              </TableCell>
            ))}
          </TableRow>
          )
        )}
      </TableBody>
    </Table>
  </TableContainer>
));

DataTable.displayName = 'DataTable';

DataTable.propTypes = DataTablePropTypes;

export default memo(DataTable);
