import { memo, useCallback, useContext, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
// Material Icon imports
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { isNotContractor } from '../models/user';
import useBuilderSteps, { BUILDER_STEPS } from '../constants/builder';
import useCustomerSettings, { OtherResources } from '../config/customer';
import { GlobalContext } from '../context/global';
import QuickTourDialog from '../widgets/QuickTourDialog';
import SuccessStoriesPanel from '../panels/SuccessStoriesPanel';
import GettingStartedItem from './GettingStartedItem';
import ExploreOtherResources from './ExploreOtherResources';
// SCSS imports
import { qtBtn, qtIcon } from './DashboardFooter.module.scss';

type DashboardFooterProps = {
  otherResources?: OtherResources;
};

const DashboardFooterPropTypes = {
  otherResources: PropTypes.object as Validator<OtherResources>
};

const DashboardFooter: FunctionComponent<DashboardFooterProps> = ({
  otherResources
}) => {
  const { HAS_PROFILE_BUILDER, HAS_QUICK_TOUR, CUSTOMER_OTHER_RESOURCES } = useCustomerSettings();
  const { allStepsCompleted, isValidStep } = useBuilderSteps();

  const { user: { data: user } } = useContext(GlobalContext);
  const { onboarding_steps, is_international } = user || {};

  const [tour, setTour] = useState(false);
  const handleTourOpen = useCallback(() => setTour(true), []);
  const handleTourClose = useCallback(() => setTour(false), []);

  const nonReducedUI = isNotContractor(user);
  const incomplete = nonReducedUI && HAS_PROFILE_BUILDER && !allStepsCompleted(user);

  return (
    <>
      <SuccessStoriesPanel reversePadding={!nonReducedUI}/>
      {incomplete ? (
        <Box
            mt={6}
            display="flex"
            alignItems="stretch"
            justifyContent="center"
        >
          <Box
              display="flex"
              alignItems="stretch"
              flexDirection="column"
          >
            <BoxTypography p={2} variant="h2">
              <FormattedMessage id="board.getting_started"/>
            </BoxTypography>
            <List>
              {map(BUILDER_STEPS, (step, index) =>
                isValidStep(user?.has_current_job_skills, user?.has_in_demand_skills, index + 1) ? (
                  <GettingStartedItem
                      key={step}
                      step={index + 1}
                      completed={Boolean(onboarding_steps && onboarding_steps[step])}
                      hasSkills={Boolean(user?.has_current_job_skills || user?.has_in_demand_skills)}
                  />
                ) : null)}
            </List>
          </Box>
        </Box>
      ) : undefined}
      {nonReducedUI ? (
        <>
          <ExploreOtherResources
              isInternational={Boolean(is_international)}
              noTopMargin={incomplete}
              otherResources={otherResources || CUSTOMER_OTHER_RESOURCES}
          />
          {HAS_QUICK_TOUR ? (
            <QuickTourDialog
                isOpen={tour}
                onClose={handleTourClose}
            />
          ) : undefined}
        </>
      ) : undefined}
      {nonReducedUI && HAS_QUICK_TOUR ? (
        <Fab
            color="primary"
            variant="extended"
            aria-label="quick tour"
            disabled={tour}
            onClick={/* nonReducedUI ? ... : undefined */ handleTourOpen}
            // TODO: link to QuickTour PDF for contractors
            // href={nonReducedUI ? undefined : ...}
            // target={nonReducedUI ? undefined : '_blank'}
            // rel={nonReducedUI ? undefined : 'noopener noreferrer'}
            className={qtBtn}
        >
          <Box pr={2}>
            <FormattedMessage id="board.quick_tour"/>
          </Box>
          <Avatar variant="circular" className={qtIcon}>
            <ChevronRightRounded color="inherit" fontSize="large"/>
          </Avatar>
        </Fab>
      ) : undefined}
    </>
  );
};

DashboardFooter.propTypes = DashboardFooterPropTypes;

export default memo(DashboardFooter);
