import { useMemo, useState } from 'react';
import {
  type OperationVariables, type LazyQueryHookOptions, type QueryResult, type LazyQueryResultTuple
} from '@apollo/client';

type IObjectResponse<T, K extends string = 'results'> = Record<K, T | undefined>;

function useQueryObject<T, D extends object, V extends OperationVariables>({
  key,
  resultsKey = 'results',
  query: queryResult,
  lazyQuery: queryTuple,
  flatResults
}: {
  key: keyof D;
  resultsKey?: string;
  query?: QueryResult<D, V>;
  lazyQuery?: LazyQueryResultTuple<D, V>;
  flatResults?: boolean;
  data: T;
}) {
  const [queryAsync, result] = queryTuple || [undefined, queryResult];
  if (!result) throw new Error('useQueryObject: either `query` or `lazyQuery` is required');
  const { loading, error, data, previousData, variables } = result;
  const cachedData = (data as D)?.[key] || (previousData as D)?.[key];
  const results = flatResults
    ? cachedData as (T | undefined)
    : (cachedData as unknown as IObjectResponse<T, typeof resultsKey>)?.[resultsKey];
  const [called, setCalled] = useState(!queryAsync);

  // catch any exceptions thrown by the query
  const query = useMemo(() => queryAsync
    ? (options?: Partial<LazyQueryHookOptions<D, V>>) => {
        setCalled(true);
        (async () => {
          try {
            await queryAsync(options);
          } catch (err) {
            // do nothing
          }
        })();
      }
    : queryAsync,
    [queryAsync]
  );

  return useMemo(() => ({
    // fetch function for lazy queries
    query,
    // standard Apollo Client result
    loading,
    error,
    data,
    previousData,
    variables,
    // our custom result
    failed: Boolean(error) || (called && !loading && !cachedData),
    pending: loading || (!error && !cachedData),
    results
  }), [cachedData, called, data, error, loading, previousData, query, results, variables]);
}

export default useQueryObject;
