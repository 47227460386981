import map from 'lodash/map';
import find from 'lodash/find';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// TM UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { TalentEmployeeObject } from '../graphql/customTypes';
import { Skill } from '../models/skill';
import { APIResponse } from '../models/apiResponse';
import { getSkillCurrentLevel } from '../helpers/models';
import {
  IAutoTable, IJsPDF, Rows, Headers,
  getTitleFilename,
  downloadAndSave, saveToCSV, saveToPDF
} from '../helpers/export';

// Saved Cohort Employees export

const COHORT_CSV_COLUMNS = [
  'supervisor.export.code',
  'supervisor.export.first_name',
  'supervisor.export.last_name',
  'supervisor.export.role_code',
  'supervisor.export.role_title',
  'supervisor.export.city',
  'supervisor.export.state',
  'supervisor.export.country',
  'supervisor.export.manager.uid',
  'supervisor.export.manager.name',
  'supervisor.export.performance_rating',
  'supervisor.export.years_on_job'
];

function saveCohort (
  title: string,
  headers: Headers,
  cohortSkills: Skill[],
  employees: TalentEmployeeObject[],
  jsPDF?: IJsPDF,
  autoTable?: IAutoTable
) {
  const titles = [
    ...headers,
    ...map(cohortSkills, 'title')
  ];
  const rows = map(employees, ({
    code, first_name, last_name, performance_rating, years_on_job, current_job, location, manager, skills
  }) => [
    code,
    first_name,
    last_name,
    current_job?.code,
    current_job?.title,
    location?.city,
    location?.state,
    location?.country,
    manager?.code,
    getFullName(manager?.first_name, manager?.last_name),
    performance_rating,
    years_on_job,
    ...map(cohortSkills, ({ id }) => getSkillCurrentLevel(find(skills, ['id', id]) as Skill))
  ] as RowInput) as Rows;
  const filename = `cohort_${getTitleFilename(title)}`;
  if (jsPDF && autoTable) {
    saveToPDF(jsPDF, autoTable, filename, titles, rows, title, '' /* , {} */);
  } else {
    saveToCSV(filename, titles, rows);
  }
}

function exportCohort(
  format: string,
  title: string,
  headers: Headers,
  skills: Skill[],
  employees: TalentEmployeeObject[]
): Promise<boolean | number> {
  return downloadAndSave<TalentEmployeeObject>(
    format,
    { status: 200, data: { results: employees } as APIResponse<TalentEmployeeObject> } as
      AxiosResponse<APIResponse<TalentEmployeeObject>>,
    (data, jsPDF, autoTable) => saveCohort(title, headers, skills, data, jsPDF, autoTable)
  );
}

const exportCohortHook = {
  COHORT_CSV_COLUMNS,
  exportCohort
};

function useExportCohort() {
  return exportCohortHook;
}

export default useExportCohort;
