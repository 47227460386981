import { forwardRef, memo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import GridBox from '../mixins/GridBox';
// SCSS imports
import { overlayDefault } from '../styles/modules/Overlay.module.scss';
import { shadyBg } from '../styles/modules/ItemsGrid.module.scss';
import { container, compactContainer, titleRow, footerRow } from './CardDeck.module.scss';

type CardDeckProps = {
  // React built-in
  children?: ReactNode | ReactNode[];
  // attributes
  title?: ReactNode | ReactNode[] | null;
  footer?: ReactNode | ReactNode[] | null;
  dark?: boolean | null;
  shady?: boolean | null;
  centered?: boolean | null;
  compact?: boolean;
  pending?: boolean | null;
}

const CardDeckPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  // attributes
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  dark: PropTypes.bool,
  shady: PropTypes.bool,
  centered: PropTypes.bool,
  compact: PropTypes.bool,
  pending: PropTypes.bool
};

const CardDeck = forwardRef<HTMLDivElement, CardDeckProps>(({
  children,
  title,
  footer,
  dark = false,
  shady = false,
  centered = false,
  compact = false,
  pending = false
}, ref) => {
  const content = dark || shady ? (
    <GridBox
        ref={pending ? undefined : ref}
        container
        className={clsx(compact ? compactContainer : container, {
          [shadyBg]: shady
        })}
        bgcolor={dark ? 'background.card' : undefined}
        justifyContent={centered ? 'center' : undefined}
        alignItems="stretch"
    >
      {title ? (
        <Grid item container xs={12} className={titleRow}>
          {title}
        </Grid>
      ) : undefined}
      {children}
      {footer ? (
        <Grid item container xs={12} className={footerRow}>
          {footer}
        </Grid>
      ) : undefined}
    </GridBox>
  ) : (
    <Grid
        ref={pending ? undefined : ref}
        container
        className={compact ? compactContainer : container}
        justifyContent={centered ? 'center' : undefined}
        alignItems="stretch"
    >
      {title ? (
        <Grid item container xs={12} className={titleRow}>
          {title}
        </Grid>
      ) : undefined}
      {children}
      {footer ? (
        <Grid item container xs={12} className={footerRow}>
          {footer}
        </Grid>
      ) : undefined}
      {}
    </Grid>
  );
  return pending ? (
    <Box
        ref={ref}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        position="relative"
    >
      {content}
      <Box className={overlayDefault}>
        <LinearProgress/>
      </Box>
    </Box>
  ) : content;
});

CardDeck.displayName = 'CardDeck';

CardDeck.propTypes = CardDeckPropTypes;

export default memo(CardDeck);
