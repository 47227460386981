import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import { type TooltipProps } from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
// Material Icon imports
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// TM UI Components
import SimpleTooltip from '@empathco/ui-components/src/elements/SimpleTooltip';
// SCSS imports
import { avatar, hidden, disabled as disabledColor } from './SuggestedSkillIcon.module.scss';

type SuggestedSkillIconProps = {
  active?: boolean | null;
  placement?: TooltipProps['placement'];
  className?: string;
  withoutTooltip?: boolean;
  disabled?: boolean | null;
  isOpen?: boolean;
}

const SuggestedSkillIconPropTypes = {
  active: PropTypes.bool,
  placement: PropTypes.string as Validator<TooltipProps['placement']>,
  className: PropTypes.string,
  withoutTooltip: PropTypes.bool,
  disabled: PropTypes.bool,
  // for Storybook only
  isOpen: PropTypes.bool
};

const SuggestedSkillIcon = forwardRef<HTMLDivElement, SuggestedSkillIconProps>(({
  active = false,
  placement = 'top',
  className,
  withoutTooltip = false,
  disabled = false,
  isOpen = false
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  const tooltip = useMemo(() => formatMessage({ id: 'common.suggested_skill' }), [formatMessage]);

  useEffect(() => {
    if (isOpen) setOpen(isOpen);
  }, [isOpen]);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const classNameFull = clsx(className, {
    [avatar]: active && !disabled,
    [disabledColor]: active && disabled,
    [hidden]: !active
  });

  const icon = active ? (
    <Avatar
        ref={ref}
        variant="circular"
        aria-hidden={active ? undefined : 'true'}
        className={classNameFull}
    >
      <CheckCircleIcon fontSize="inherit"/>
    </Avatar>
  ) : <Box ref={ref} aria-hidden className={classNameFull}/>;

  return (
    <>
      {active && !withoutTooltip ? (
        <SimpleTooltip
            aria-hidden
            open={open}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
            disableInteractive
            disableFocusListener
            placement={placement}
            title={tooltip}
        >
          {icon}
        </SimpleTooltip>
      ) : icon}
      {active ? (
        <Box sx={visuallyHidden}>
          {tooltip}
        </Box>
      ) : undefined}
    </>
  );
});

SuggestedSkillIcon.displayName = 'SuggestedSkillIcon';

SuggestedSkillIcon.propTypes = SuggestedSkillIconPropTypes;

export default memo(SuggestedSkillIcon);
