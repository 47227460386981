import { memo, useState, useEffect, useCallback, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { withStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';
// TM UI Components
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
// local imports
import { TOUR_TABS } from '../constants/tour';
import { TOUR_SCREENS, TOUR_SCREENSHOTS } from '../config/quicktour';
import { getScreenMessageId } from '../helpers/quicktour';
import BuilderFooter from '../elements/BuilderFooter';
import QuickTourTab from '../elements/QuickTourTab';
import QuickTourTooltip from '../elements/QuickTourTooltip';
// SCSS imports
import { title, header, content, image, loadingImage } from './QuickTourDialog.module.scss';

const TourDialog = withStyles(Dialog, (theme) => ({
  paperWidthLg: {
    maxWidth: theme.shape.tourScreenshotWidth
  }
}));

type QuickTourDialogProps = {
  isOpen?: boolean | null;
  onClose: () => void;
  // for Storybook only
  openScreen?: number;
}

const QuickTourDialogPropTypes = {
  // attributes
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  // for Storybook only
  openScreen: PropTypes.number
};

// eslint-disable-next-line complexity
const QuickTourDialog: FunctionComponent<QuickTourDialogProps> = ({
  isOpen = false,
  onClose,
  openScreen = 0
}) => {

  const [open, setOpen] = useState(false);
  const [screen, setScreen] = useState<number>(openScreen);
  const [prevScreen, setPrevScreen] = useState(-1);
  const [loadingFirst, setLoadingFirst] = useState(true);

  const displayedScreen = prevScreen >= 0 ? prevScreen : screen;
  const currentSlide = TOUR_SCREENS[screen] || {};
  const prevSlide = (prevScreen >= 0 && TOUR_SCREENS[prevScreen]) || undefined;
  const slide = prevSlide || currentSlide;
  const prevSrc = (prevSlide && prevSlide.screenshot && TOUR_SCREENSHOTS[prevSlide.screenshot]) || undefined;
  const currentSrc = (currentSlide.screenshot && TOUR_SCREENSHOTS[currentSlide.screenshot]) || undefined;
  const loadingScreenshot = Boolean(prevSrc) && prevSrc !== currentSrc;
  const loading = loadingFirst || loadingScreenshot;

  useEffect(() => {
    if (isOpen) setOpen(true);
  }, [isOpen]);

  const handleClose = useCallback(() => setOpen(false), []);

  const handlePrev = useCallback(() => {
    if (screen >= 1) {
      if (TOUR_SCREENS[screen - 1].screenshot !== TOUR_SCREENS[screen].screenshot) setPrevScreen(screen);
      setScreen(screen - 1);
    } else setOpen(false);
  }, [screen]);
  const handleNext = useCallback(() => {
    if (screen < TOUR_SCREENS.length - 1) {
      if (TOUR_SCREENS[screen + 1].screenshot !== TOUR_SCREENS[screen].screenshot) setPrevScreen(screen);
      setScreen(screen + 1);
    } else setOpen(false);
  }, [screen]);

  const handleTab = useCallback((newTab: string) => {
    const newScreen = findIndex(TOUR_SCREENS, ['tab', newTab]);
    if (newScreen >= 0) {
      if (TOUR_SCREENS[newScreen].screenshot !== TOUR_SCREENS[screen].screenshot) setPrevScreen(screen);
      setScreen(newScreen);
    }
  }, [screen]);

  const onLoad = useCallback(() => {
    setLoadingFirst(false);
    setPrevScreen(-1);
  }, []);

  const transitionProps = useMemo(() => ({ onExited: onClose }), [onClose]);

  const src = loadingScreenshot ? prevSrc : currentSrc;
  const isFirst = displayedScreen === 0;

  return isOpen ? (
    <TourDialog
        fullWidth
        maxWidth="lg"
        scroll="paper"
        open={open}
        onClose={handleClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton transparent onClick={handleClose}/>
      <DialogTitle component="div" className={title}>
        <Box className={header}>
          <FormattedMessage id="tour.title"/>
        </Box>
        <Grid container>
          {map(TOUR_TABS, (item, index) => (
            <QuickTourTab
                key={item}
                tab={item}
                selected={item === currentSlide.tab || (prevSlide && item === prevSlide.tab)}
                isFirst={index === 0}
                disabled={loading}
                onClick={handleTab}
            />
          ))}
        </Grid>
      </DialogTitle>
      <DialogContent className={content}>
        <QuickTourTooltip
            open={Boolean(src) && !loadingFirst}
            id={getScreenMessageId(displayedScreen)}
            placement={slide.placement}
            x0={slide.x0}
            y0={slide.y0}
            width={slide.width}
        />
        <img
            key={slide.screenshot}
            src={src}
            className={loadingFirst ? loadingImage : image}
            alt=""
            loading="eager"
            onLoad={loadingFirst ? onLoad : undefined}
            // TODO: onError={onError}
        />
        {!loadingFirst && loadingScreenshot && currentSrc ? (
          <img
              key={currentSlide.screenshot}
              src={currentSrc}
              className={loadingImage}
              alt=""
              loading="eager"
              onLoad={onLoad}
              // TODO: onError={onError}
          />
        ) : undefined}
        {loadingFirst ? <Skeleton variant="rectangular" className={image}/> : undefined}
      </DialogContent>
      <BuilderFooter
          tour
          onPrev={handlePrev}
          onNext={handleNext}
          isFirst={isFirst}
          isLast={displayedScreen === TOUR_SCREENS.length - 1}
          disabledPrev={loading && !isFirst ? true : undefined}
          disabledNext={loading}
          pendingNext={loading}
      />
    </TourDialog>
  ) : null;
};

QuickTourDialog.propTypes = QuickTourDialogPropTypes;

export default memo(QuickTourDialog);
