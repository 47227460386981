import { memo, useCallback, useContext, useEffect, useState, useMemo, useRef, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import endsWith from 'lodash/endsWith';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
// TM UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { CONST_MONTHS, toValidConst } from '../constants/constValues';
import { EMPLOYEE_DEV_PLANS_QUERY } from '../graphql/EmployeeDevPlans';
import { EMPLOYEE_DEV_PLAN_QUERY } from '../graphql/EmployeeDevPlan';
import { EMPLOYEE_DEV_PLAN_ACTIVITIES_QUERY } from '../graphql/EmployeeDevPlanActivities';
import { EMPLOYEE_PROGRESS_QUERY } from '../graphql/EmployeeProgress';
import {
  SkillActivity,
  EmployeeDevPlansDocument,
  EmployeeDevPlanDocument, EmployeeDevPlanQuery,
  EmployeeDevPlanActivitiesDocument,
  EmployeeProgressDocument, EmployeeProgressQuery
} from '../graphql/types';
import { EmployeeDevPlan } from '../graphql/customTypes';
import { PATH_MY_DEV_PLAN_NEW } from '../config/paths';
import { getSettingsIntValue } from '../helpers/context';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import ConstSelector from '../elements/ConstSelector';
import LargeButton from '../elements/LargeButton';
import TbTeamCard from '../v3/TbTeamCard';
import CardsGrid from '../v3/CardsGrid';
import EmployeeProgressPopup from '../v3/EmployeeProgressPopup';

type EmployeeDevPlansPanelProps = {
  scrollToView?: boolean;
  supervisor?: boolean | null;
  uid?: string | null;
  employeeName?: string;
  // for Storybook only
  testPending?: boolean;
}

const EmployeeDevPlansPanelPropTypes = {
  scrollToView: PropTypes.bool,
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string,
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity
const EmployeeDevPlansPanel: FunctionComponent<EmployeeDevPlansPanelProps> = ({
  scrollToView = false,
  supervisor = false,
  uid: selected_employee_id,
  employeeName,
  testPending
}) => {
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    editableSks: { data: mySkills, pending: pendingMySkills },
    recommendedSkills: { data: recommendedSkills, pending: pendingRecommendedSkills },
    dataStatus: { pending: pendingStatus },
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = supervisor ? 'employee_profile' : 'skills_index';
  const settingsDuration = getSettingsIntValue(settings, `${settingsId}__devplan_duration`);

  const loadingSkills = !settingsLoaded || pendingMySkills || !mySkills ||
    pendingStatus || pendingRecommendedSkills || !recommendedSkills;

  const ref = useRef<HTMLElement>();
  const [doScroll, setDoScroll] = useState(Boolean(scrollToView));

  // lazy load dev plans
  const { query: getDevPlans, pending: pendingDevPlans, failed, results: devplans } = useQueryCounted({
    data: undefined as unknown as EmployeeDevPlan,
    key: 'employeeDevplans',
    lazyQuery: useLazyQuery(EMPLOYEE_DEV_PLANS_QUERY as typeof EmployeeDevPlansDocument)
  });
  const pending = pendingDevPlans || testPending;

  // lazy load dev plan
  const {
    query: getDevPlan, pending: pendingDevplan, failed: failedDevplan, results: employeeDevplan, variables: devplanVars
  } = useQueryObject({
    data: undefined as unknown as EmployeeDevPlanQuery['employeeDevplan'],
    key: 'employeeDevplan',
    flatResults: true,
    lazyQuery: useLazyQuery(EMPLOYEE_DEV_PLAN_QUERY as typeof EmployeeDevPlanDocument)
  });

  // lazy load dev plan activities
  const {
    query: getActivities, pending: pendingActivities, failed: failedActivities, results: activities
  } = useQueryCounted({
    data: undefined as unknown as SkillActivity,
    key: 'employeeDevplanActivities',
    lazyQuery: useLazyQuery(EMPLOYEE_DEV_PLAN_ACTIVITIES_QUERY as typeof EmployeeDevPlanActivitiesDocument)
  });

  // lazy load progress
  const {
    query: getProgress, pending: pendingProgress, failed: failedProgress, results: employeeProgress, variables: progressVars
  } = useQueryObject({
    data: undefined as unknown as EmployeeProgressQuery['employeeProgress'],
    key: 'employeeProgress',
    flatResults: true,
    lazyQuery: useLazyQuery(EMPLOYEE_PROGRESS_QUERY as typeof EmployeeProgressDocument)
  });

  const [duration, setDuration] = useState(toValidConst(settingsDuration, CONST_MONTHS[1], CONST_MONTHS));

  const [selectedDevplanId, setSelectedDevplanId] = useState<number>(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = useCallback(() => setPopupOpen(false), []);

  const handleDurationChange = useCallback((event: SelectChangeEvent<number>) => {
    const value = toValidConst(event?.target?.value, CONST_MONTHS[1], CONST_MONTHS);
    setDuration(value);
    if (settingsDuration !== value) updateSettings?.({
      [`${settingsId}__devplan_duration`]: value
    });
  }, [settingsDuration, settingsId, updateSettings]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      setDuration(toValidConst(
        getSettingsIntValue(settings, `${settingsId}__devplan_duration`),
        CONST_MONTHS[1], CONST_MONTHS
      ));
    }
  }, [settingsLoaded, pendingSettingsUpdate, settings, settingsId]);

  useEffect(() => {
    getDevPlans?.({ variables: {
      duration,
      ...selected_employee_id ? { selected_employee_id } : {}
    } });
  }, [duration, selected_employee_id, getDevPlans]);

  useEffect(() => {
    if (!ref.current || !doScroll) return;
    ref.current.scrollIntoView({ behavior: 'smooth' });
    if (!loadingSkills && !pending) setDoScroll(false);
  }, [ref, doScroll, loadingSkills, pending]);

  const componentProps: Partial<GetComponentProps<typeof TbTeamCard>> = useMemo(() => ({
    variant: 'devplan',
    userId: user?.id,
    onClick: (dp?: EmployeeDevPlan) => {
      const devplan_id = dp?.id;
      if (devplan_id && getDevPlan && getActivities && getProgress) {
        const query = { variables: {
          devplan_id,
          ...selected_employee_id ? { selected_employee_id } : {}
        } };
        getDevPlan(query);
        getActivities(query);
        getProgress(query);
        setSelectedDevplanId(devplan_id);
        setPopupOpen(true);
      }
    }
  }), [selected_employee_id, user, getDevPlan, getActivities, getProgress]);

  return (
    <>
      <ContentCard>
        {scrollToView ? (
          <Box position="relative">
            <Box
                ref={ref}
                position="absolute"
                top="-8rem"
            />
          </Box>
        ) : undefined}
        <CardTitle
            title={supervisor ? 'dev_plans.employees_title' : 'dev_plans.title'}
            values={supervisor ? {
              name: employeeName,
              endsWithS: endsWith(employeeName, 's')
            } : undefined}
            action={(
              <Box px={1} pt={1}>
                <ConstSelector
                    variant="months"
                    value={duration}
                    onChange={handleDurationChange}
                    disabled={pending}
                />
              </Box>
            )}
            withDivider
        />
        <CardsGrid
            items={devplans}
            variant="shady"
            withReloading
            pending={pending}
            failed={failed}
            // disabled={...}
            blendNotFound
            blendPagination
            component={TbTeamCard}
            ComponentProps={componentProps}
            notFoundMessage="hr.dev_plans.empty"
            pagination={supervisor || (pending && !devplans) ? undefined : (
              <Box
                  flex="1 0 0"
                  display="flex"
                  justifyContent="center"
                  pt={failed ? 2.5 : undefined}
              >
                <LargeButton
                    title="dev_plans.button.new"
                    link={PATH_MY_DEV_PLAN_NEW}
                    disabled={pending}
                />
              </Box>
            )}
        />
      </ContentCard>
      {selectedDevplanId > 0 && (
        <EmployeeProgressPopup
            individual
            isMyPlan={!supervisor}
            supervisor={supervisor}
            employeeName={employeeName}
            devplan={employeeDevplan}
            activities={activities}
            employee={employeeProgress}
            pending={pendingDevplan || pendingActivities || pendingProgress ||
              devplanVars?.devplan_id !== selectedDevplanId ||
              progressVars?.devplan_id !== selectedDevplanId}
            failed={failedDevplan || failedActivities || failedProgress}
            isOpen={popupOpen}
            onClose={handlePopupClose}
        />
      )}
    </>
  );
};

EmployeeDevPlansPanel.propTypes = EmployeeDevPlansPanelPropTypes;

export default memo(EmployeeDevPlansPanel);
