import { memo, useEffect, useMemo, type FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// TM UI Components
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
// local imports
import { DEV_PLAN_QUERY } from '../graphql/DevPlan';
import { DevPlanDocument } from '../graphql/types';
import { DevPlanDetails } from '../graphql/customTypes';
import { getPlanId } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import DevPlanReview from '../panels/DevPlanReview';

const EmployeeDevPlanReviewScreen: FunctionComponent = () => {
  const plan_id = getPlanId(useParams());

  const { content } = useVerifyUser({ employee: true });

  // lazy load dev plan
  const { query: getDevPlan, pending, failed, results: devplan } = useQueryObject({
    data: undefined as unknown as DevPlanDetails,
    key: 'devplan',
    flatResults: true,
    lazyQuery: useLazyQuery(DEV_PLAN_QUERY as typeof DevPlanDocument)
  });

  useEffect(() => {
    if (!content && plan_id) getDevPlan?.({ variables: { devplan_id: plan_id } });
  }, [content, plan_id, getDevPlan]);

  const loadedPlan = Boolean(plan_id) && plan_id === devplan?.id;

  const breadcrumbs = useMemo(() => loadedPlan ? [{ title: devplan.title }] : undefined, [loadedPlan, devplan]);

  return content || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      <ContentCard>
        <DevPlanReview
            isMyPlan
            devplan={loadedPlan ? devplan : null}
            pending={pending || plan_id !== devplan?.id}
            failed={failed}
        />
      </ContentCard>
    </Screen>
  );
};

export default memo(EmployeeDevPlanReviewScreen);
