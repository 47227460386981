import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import round from 'lodash/round';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
// TM UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
// local imports
import { Course } from '../models/course';
import useCustomerSettings from '../config/customer';
import { MAX_COURSE_TITLE_LENGTH } from '../config/params';
import { PATH_SKILL } from '../config/paths';
import CourseProviderLogo from '../elements/CourseProviderLogo';
import CourseFeatures from '../elements/CourseFeatures';
// SCSS imports
import { card, item, divider, link, circle, circular, features, skillName, videoBtn } from './CourseCard.module.scss';

type CourseCardProps = {
  course: Course;
  disabled?: boolean;
  selected?: boolean | null;
  onSelect?: (course: Course) => void;
  // for Storybook only
  logo?: string;
}

const CourseCardPropTypes = {
  // attributes
  course: PropTypes.object.isRequired as Validator<Course>,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  logo: PropTypes.string
};

const CourseCard = forwardRef<HTMLDivElement, CourseCardProps>(({
  course,
  disabled = false,
  selected = false,
  onSelect,
  logo
}, ref) => {
  const { getCourseUrl } = useCustomerSettings();
  const {
    code, title, provider, external_url, course_taken_total_count, course_taken_same_job_count, skills, video_url, description
  } = course || {};

  const handleChange = useCallback(() => onSelect?.(course), [course, onSelect]);

  const pleLink = external_url || getCourseUrl(code);

  const same_job_ratio = round(
    100 * toSafeInteger(course_taken_same_job_count) /
    (toSafeInteger(course_taken_total_count) || 1)
  );

  return (
    <Grid
        ref={ref}
        item
        xs
        container
        className={item}
    >
      <Card elevation={0} className={card}>
        <Box
            display="flex"
            alignItems="center"
        >
          <Box px={1.75} py={0.75} flex="1 1 0" display="flex" alignItems="center">
            <CourseProviderLogo provider={provider} logo={logo}/>
          </Box>
          <CheckboxButton
              label={title}
              checked={Boolean(selected)}
              onChange={handleChange}
              disabled={disabled || !onSelect}
          />
        </Box>
        <Divider className={divider}/>
        <TruncatedTextLink
            text={title}
            href={pleLink}
            variant="subtitle1"
            dark
            length={MAX_COURSE_TITLE_LENGTH}
            className={link}
        />
        <Divider className={divider}/>

        <Box
            p={1}
            className={features}
        >
          <CourseFeatures course={course}/>
        </Box>

        <Box px={2} pb={1}>
          <TruncatedTextLink text={description} plain regular color="text.secondary" variant="body2"/>
        </Box>

        <Divider className={divider}/>

        <Box p={1} display="flex" alignItems="center">
          <Box position="relative" display="inline-flex">
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
              <CircularProgress
                  color="inherit"
                  variant="determinate"
                  size="2.6rem"
                  thickness={6}
                  value={100}
                  className={circle}
              />
            </Box>
            <CircularProgress
                color="inherit"
                variant="determinate"
                size="2.6rem"
                thickness={6}
                value={same_job_ratio}
                className={circular}
            />
            <BoxTypography
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="text.label"
                variant="h6"
            >
              <FormattedNumber value={toSafeInteger(course_taken_same_job_count)} notation="compact"/>
            </BoxTypography>
          </Box>
          <BoxTypography pl={1.5} flexGrow={1} flexBasis={0} display="flex" alignItems="center" variant="body2">
            <FormattedMessage id="skill.courses.same_job"/>
          </BoxTypography>
        </Box>

        {video_url ? (
          <>
            <Divider className={divider}/>
            <Box p={1} display="flex" alignItems="center" justifyContent="center">
              <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={disabled}
                  href={video_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={videoBtn}
              >
                <PlayArrowRounded fontSize="inherit"/>
              </Button>
            </Box>
          </>
        ) : undefined}

        <Box flex="1 1 0">
          {' '}
        </Box>

        {size(skills) >= 1 && (
          <>
            <Divider className={divider}/>
            <Box className={features}>
              <Box
                  minHeight="100%"
                  display="flex"
                  flexDirection="column"
              >
                <BoxTypography px={1.25} pt={1.25} variant="body2">
                  <FormattedMessage id="skill.courses.skills"/>
                </BoxTypography>
                <Box pb={1.125} pl={1.25} pr={0.25} display="flex" alignItems="center" flexWrap="wrap">
                  {map(skills, (skill) => (
                    <Box
                        key={skill.id}
                        my={0.375}
                        mr={0.75}
                        px={1}
                        py={0.25}
                        bgcolor="background.card"
                        borderRadius={shape.tinyBorderRadius}
                    >
                      <SkillName
                          skill={skill}
                          skillPath={PATH_SKILL}
                          variant="body1"
                          maxLines={1}
                          disabled={disabled}
                          className={skillName}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        )}

        {/* TODO: close match jobs button & dialog */}

      </Card>
    </Grid>
  );
});

CourseCard.displayName = 'CourseCard';

CourseCard.propTypes = CourseCardPropTypes;

export default memo(CourseCard);
