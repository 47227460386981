import { forwardRef, memo, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import { useLazyQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// TM UI Components
import { DEFAULT_MENU_RIGHT } from '@empathco/ui-components/src/helpers/menus';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { DEV_PLAN_ADVISOR_QUERY } from '../graphql/DevPlanAdvisor';
import { DEV_PLAN_COURSE_QUERY } from '../graphql/DevPlanCourse';
import { DEV_PLAN_OPPORTUNITY_QUERY } from '../graphql/DevPlanOpportunity';
import {
  CourseAdvisorItemType,
  DevPlanCourseQueryDocument, DevPlanAdvisorQueryDocument,
  DevPlanAdvisorQueryQuery, DevPlanCourseQueryQuery,
  DevPlanOpportunityQuery, DevPlanOpportunityDocument
} from '../graphql/types';
import { CourseAdvisorSearchItem, DevPlanCourse, DevPlanAdvisor, DevPlanOpportunity } from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import CourseAndAdvisorSearch from './CourseAndAdvisorSearch';
// SCSS imports
import { content, searchInput, confirmButton } from './AddCourseOrAdvisorPopover.module.scss';

type AddCourseOrAdvisorPopoverProps = {
  devplanId: number;
  title?: string | null;
  skillIds: number[];
  excludeCourses?: number[] | null;
  excludeAdvisors?: number[] | null;
  excludeOpportunities?: number[] | null;
  anchorEl?: Element | null;
  onCourse: (course: DevPlanCourse, addedManually: boolean) => void;
  onAdvisor: (advisor: DevPlanAdvisor, addedManually: boolean) => void;
  onOpportunity: (opportunity: DevPlanOpportunity, addedManually: boolean) => void;
  onCancel: () => void;
  disabled?: boolean | null;
  // for Storybook only
  testLoading?: boolean | null;
};

const AddCourseOrAdvisorPopoverPropTypes = {
  // attributes
  devplanId: PropTypes.number.isRequired,
  title: PropTypes.string,
  skillIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  excludeCourses: PropTypes.arrayOf(PropTypes.number.isRequired),
  excludeAdvisors: PropTypes.arrayOf(PropTypes.number.isRequired),
  excludeOpportunities: PropTypes.arrayOf(PropTypes.number.isRequired),
  anchorEl: PropTypes.instanceOf(Element),
  onCourse: PropTypes.func.isRequired,
  onAdvisor: PropTypes.func.isRequired,
  onOpportunity: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // for Storybook only
  testLoading: PropTypes.bool
};

const AddCourseOrAdvisorPopover = forwardRef<HTMLDivElement, AddCourseOrAdvisorPopoverProps>(({
  devplanId,
  title,
  skillIds,
  excludeCourses,
  excludeAdvisors,
  excludeOpportunities,
  anchorEl,
  onCourse,
  onAdvisor,
  onOpportunity,
  onCancel,
  disabled = false,
  testLoading
}, ref) => {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  const courseOptions = useMemo(() => ({ onCompleted: (data: DevPlanCourseQueryQuery) => {
    if (data.devplanCourse) onCourse(data.devplanCourse as DevPlanCourse, true);
  } }), [onCourse]);
  const advisorOptions = useMemo(() => ({ onCompleted: (data: DevPlanAdvisorQueryQuery) => {
    if (data.devplanAdvisor) onAdvisor(data.devplanAdvisor as DevPlanAdvisor, true);
  } }), [onAdvisor]);
  const opportunityOptions = useMemo(() => ({ onCompleted: (data: DevPlanOpportunityQuery) => {
    if (data.devplanOpportunity) onOpportunity(data.devplanOpportunity as DevPlanOpportunity, true);
  } }), [onOpportunity]);

  // lazy load dev plan course
  const { query: getCourse, loading: pendingCourse, failed: failedCourse } = useQueryObject({
    data: undefined as unknown as DevPlanCourse,
    key: 'devplanCourse',
    lazyQuery: useLazyQuery(DEV_PLAN_COURSE_QUERY as typeof DevPlanCourseQueryDocument, courseOptions)
  });

  // lazy load dev plan advisor
  const { query: getAdvisor, loading: pendingAdvisor, failed: failedAdvisor } = useQueryObject({
    data: undefined as unknown as DevPlanAdvisor,
    key: 'devplanAdvisor',
    lazyQuery: useLazyQuery(DEV_PLAN_ADVISOR_QUERY as typeof DevPlanAdvisorQueryDocument, advisorOptions)
  });

  // lazy load dev plan opportunity
  const { query: getOpportunity, loading: pendingOpportunity, failed: failedOpportunity } = useQueryObject({
    data: undefined as unknown as DevPlanOpportunity,
    key: 'devplanOpportunity',
    lazyQuery: useLazyQuery(DEV_PLAN_OPPORTUNITY_QUERY as typeof DevPlanOpportunityDocument, opportunityOptions)
  });

  const pending = pendingCourse || pendingAdvisor || pendingOpportunity;

  const [item, setItem] = useState<CourseAdvisorSearchItem | null>(null);

  const isOpen = Boolean(anchorEl);

  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setItem(null);
    }
  }, [isOpen]);

  const handleSearch = useCallback((itm: CourseAdvisorSearchItem | null) => setItem(itm), []);

  const handleConfirm = useCallback(() => {
    if (item) {
      if (item.item_type === CourseAdvisorItemType.course) {
        getCourse?.({ variables: { devplan_id: devplanId, course_id: item.item_id } });
      } else if (item.item_type === CourseAdvisorItemType.advisor) {
        getAdvisor?.({ variables: { devplan_id: devplanId, advisor_id: item.item_id } });
      } else if (item.item_type === CourseAdvisorItemType.opportunity) {
        getOpportunity?.({ variables: { devplan_id: devplanId, opportunity_id: item.item_id } });
      }
    }
  }, [item, devplanId, getCourse, getAdvisor, getOpportunity]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setVisible(false);
  } }), []);

  useEffect(() => {
    if (testLoading === true) getCourse?.({ variables: { devplan_id: 123, course_id: 17630 } });
    else if (testLoading === false) getAdvisor?.({ variables: { devplan_id: 123, advisor_id: 1 } });
    else if (isNull(testLoading)) getOpportunity?.({ variables: { devplan_id: 123, opportunity_id: 1 } });
  }, [testLoading, getCourse, getAdvisor, getOpportunity]);

  return (
    <>
      <Popover
          ref={ref}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...DEFAULT_MENU_RIGHT}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCancel}
          TransitionProps={transitionProps}
      >
        <CloseIconButton small onClick={onCancel}/>
        <CardTitle
            title={title || `hr.dev_plan.add_title${
              HAS_MENTORING ? '' : '.mentoring_off'}${
              HAS_COURSES ? '' : '.courses_off'}${
              HAS_DEV_PLAN_OPPORTUNITIES ? '' : '.opp_off'}`}
            withDivider
        />
        <CardSection className={content}>
          <Box flexGrow={1} py={1} display="flex" alignItems="center" className={searchInput}>
            {visible ? (
              <CourseAndAdvisorSearch
                  fullWidth
                  skillIds={skillIds}
                  excludeAdvisors={excludeAdvisors || undefined}
                  excludeCourses={excludeCourses || undefined}
                  excludeOpportunities={excludeOpportunities || undefined}
                  onChange={handleSearch}
                  disabled={pending || disabled}
              />
            ) : undefined}
          </Box>
        </CardSection>
        <CardFooter withDivider>
          <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disableElevation
              disabled={pending || disabled || !item}
              startIcon={pending ? <CircularProgress size={18} color="inherit"/> : undefined}
              className={confirmButton}
          >
            <FormattedMessage id="common.button.add"/>
          </Button>
        </CardFooter>
      </Popover>
      <ActionFailedAlert
          message="hr.dev_plan.add_course_error"
          open={failedCourse}
      />
      <ActionFailedAlert
          message="hr.dev_plan.add_advisor_error"
          open={failedAdvisor}
      />
      <ActionFailedAlert
          message="hr.dev_plan.add_opportunity_error"
          open={failedOpportunity}
      />
    </>
  );
});

AddCourseOrAdvisorPopover.displayName = 'AddCourseOrAdvisorPopover';

AddCourseOrAdvisorPopover.propTypes = AddCourseOrAdvisorPopoverPropTypes;

export default memo(AddCourseOrAdvisorPopover);
