import { memo, forwardRef, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
// TM UI Components
import { DEFAULT_MENU_RIGHT } from '@empathco/ui-components/src/helpers/menus';
// local imports
import { ConstValue, ConstValueProp, ConstVariant, ConstVariantProp, CONST_VALUES } from '../constants/constValues';

type ConstSelectorProps = {
  variant: ConstVariant;
  fullWidth?: boolean;
  helperText?: ReactNode;
  value: ConstValue;
  error?: boolean;
  onChange: (event: SelectChangeEvent<number>, child?: ReactNode) => void;
  disabled?: boolean;
  className?: string;
};

const ConstSelectorPropTypes = {
  variant: ConstVariantProp.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node as Validator<ReactNode>,
  value: ConstValueProp.isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

const ConstSelector = forwardRef<HTMLDivElement, ConstSelectorProps>(({
  variant,
  fullWidth,
  helperText,
  value,
  error,
  onChange,
  disabled = false,
  className
}, ref) => (
  <FormControl
      ref={ref}
      variant="outlined"
      size="small"
      error={error}
      fullWidth={fullWidth}
      className={className}
  >
    <Select
        value={value}
        onChange={onChange}
        disabled={disabled}
        MenuProps={DEFAULT_MENU_RIGHT}
    >
      {map(CONST_VALUES[variant], (val) => (
        <MenuItem key={val} value={val}>
          <FormattedMessage id={`filter.const.${variant}`} values={{ value: val }}/>
        </MenuItem>
      ))}
    </Select>
    {helperText ? (
      <FormHelperText>
        {helperText}
      </FormHelperText>
    ) : undefined}
  </FormControl>
));

ConstSelector.displayName = 'ConstSelector';

ConstSelector.propTypes = ConstSelectorPropTypes;

export default memo(ConstSelector);
