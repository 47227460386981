import { forwardRef, memo, type ReactNode, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
// Material UI imports
import CardActions, { type CardActionsProps } from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
// SCSS imports
import {
  footer, flexible, flexibleCompact, centered, centeredCompact, rightAligned, rightAlignedCompact, divider
} from './CardFooter.module.scss';

type CardFooterProps = {
  withDivider?: boolean | null;
  flex?: boolean | null;
  compact?: boolean | null;
  rightAlign?: boolean | null;
  className?: string;
  // React built-in
  children?: ReactNode | ReactNode[];
}

const CardFooterPropTypes = {
  withDivider: PropTypes.bool,
  flex: PropTypes.bool,
  compact: PropTypes.bool,
  rightAlign: PropTypes.bool,
  className: PropTypes.string,
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>
};

const CardFooter = forwardRef<Component<CardActionsProps>, CardFooterProps>(({
  children,
  withDivider = false,
  rightAlign = false,
  compact = false,
  flex = false,
  className
}, ref) => (
  <>
    {withDivider ? <Divider className={divider}/> : undefined}
    <CardActions
        ref={ref}
        className={clsx(footer, {
          [flexible]: flex && !compact,
          [flexibleCompact]: flex && compact,
          [rightAligned]: !flex && rightAlign && !compact,
          [rightAlignedCompact]: !flex && rightAlign && compact,
          [centered]: !flex && !rightAlign && !compact,
          [centeredCompact]: !flex && !rightAlign && compact
        }, className)}
    >
      {children}
    </CardActions>
  </>
));

CardFooter.displayName = 'CardFooter';

CardFooter.propTypes = CardFooterPropTypes;

export default memo(CardFooter);
