import {
  memo, useCallback, useContext, useLayoutEffect, useMemo, useState, type FunctionComponent
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import size from 'lodash/size';
import trim from 'lodash/trim';
import find from 'lodash/find';
import replace from 'lodash/replace';
import isNil from 'lodash/isNil';
// import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import toLower from 'lodash/toLower';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
// Material UI imports
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
// TM UI Components
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import { ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { UPDATE_REDEPLOYMENT_PLAN } from '../graphql/UpdateRedeploymentPlan';
import { DELETE_REDEPLOYMENT_PLAN } from '../graphql/DeleteRedeploymentPlan';
import { REDEPLOYMENT_EMPLOYEES_QUERY } from '../graphql/RedeploymentEmployees';
import {
  RedeploymentEmployeesDocument, UpdateRedeploymentPlanDocument, DeleteRedeploymentPlanDocument,
  RedeploymentEmployeesInput, RedeploymentSort, SortDirection
} from '../graphql/types';
import { RedeploymentDetails, RedeploymentEmployee } from '../graphql/customTypes';
import { DEFAULT_REDEPLOYMENT_SORT_DIRECTION } from '../constants/redeployment';
import { VALID_SORT_DIRECTION } from '../constants/talentFinder';
import { getDefaultLeaderId, getRootLeaderId, getSelectedLeaderId } from '../models/user';
import { API_HR_REDEPLOYMENT_EXPORT } from '../config/api';
import { PATH_HR_REDEPLOYMENTS } from '../config/paths';
import { locationParams } from '../helpers/context';
import { FilterValues } from '../hooks/useFilters';
import { redeploymentSortVar } from '../context/variables';
import { GlobalContext } from '../context/global';
import ReviewBar from '../elements/ReviewBar';
import PaginationControls from '../v3/PaginationControls';
import DashboardFilters from '../v3/DashboardFilters';
import RedeploymentEmployeesTable from '../v3/RedeploymentEmployeesTable';
import EmployeeRedeploymentPopup from '../v3/EmployeeRedeploymentPopup';

const redeploymentParams = ({
  manager_id, country_id, state_id, selected_leader_id // , job_levels
}: RedeploymentEmployeesInput) => ({
  ...locationParams(country_id, state_id),
  // ...job_levels && isArray(job_levels) && size(job_levels) >= 1 ? { job_levels } : {},
  ...isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? {} : { manager_id },
  ...selected_leader_id ? { selected_leader_id } : {}
} as RedeploymentEmployeesInput);

type RedeploymentPlanPanelProps = {
  plan?: RedeploymentDetails | null;
  pending?: boolean | null;
  failed?: boolean | null;
}

const RedeploymentPlanPanelPropTypes = {
  plan: PropTypes.object as Validator<RedeploymentDetails>,
  pending: PropTypes.bool,
  failed: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const RedeploymentPlanPanel: FunctionComponent<RedeploymentPlanPanelProps> = ({
  plan,
  pending = false,
  failed = false
}) => {
  const navigate = useNavigate();
  const { id: plan_id, title: parentTitle, search_criteria } = plan || {} as RedeploymentDetails;

  const { token, paths: { supvEmplPath }, user: { data: user } } = useContext(GlobalContext);

  const sortSettings = useReactiveVar(redeploymentSortVar);

  const [pendingEmployeeId, setPendingEmployeeId] = useState(0);
  const [pendingJobCode, setPendingJobCode] = useState<string | undefined>();
  const handleJobChange = useCallback((employeeId: number, jobCode: string) => {
    setPendingEmployeeId(employeeId);
    setPendingJobCode(jobCode);
  }, []);

  const [rootUid, uid, leaderUid] = useMemo(() => [
    getRootLeaderId(user),
    getDefaultLeaderId(user),
    getSelectedLeaderId(user)
  ], [user]);

  // lazy load plan employees
  const {
    query: getEmployees, pending: pendingEmployees, failed: failedEmployees, count, results: employees, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as RedeploymentEmployee,
    key: 'redeploymentEmployees',
    lazyQuery: useLazyQuery(REDEPLOYMENT_EMPLOYEES_QUERY as typeof RedeploymentEmployeesDocument)
  });

  const { mutate: updatePlanTitle, loading: titlePending, failed: titleFailed } = useMutationMethod({
    key: 'updateRedeploymentPlan',
    mutation: useMutation(UPDATE_REDEPLOYMENT_PLAN as typeof UpdateRedeploymentPlanDocument)
  });
  const { mutate: updatePlan /* , loading: updatePending, failed: updateFailed */ } = useMutationMethod({
    key: 'updateRedeploymentPlan',
    mutation: useMutation(UPDATE_REDEPLOYMENT_PLAN as typeof UpdateRedeploymentPlanDocument)
  });

  const { mutate: deletePlan, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_REDEPLOYMENT_PLAN as typeof DeleteRedeploymentPlanDocument)
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();
  const [filters, setFilters] = useState<FilterValues>();

  const [sortOrder, setSortOrder] = useState<RedeploymentSort>(
    DEFAULT_REDEPLOYMENT_SORT_DIRECTION[sortSettings.sortOrder]
      ? sortSettings.sortOrder : RedeploymentSort.employee
  );
  const [direction, setDirection] = useState<SortDirection>(
    VALID_SORT_DIRECTION[sortSettings.direction]
      ? sortSettings.direction
      : DEFAULT_REDEPLOYMENT_SORT_DIRECTION[sortSettings.sortOrder] ||
        DEFAULT_REDEPLOYMENT_SORT_DIRECTION[RedeploymentSort.employee]
  );
  useLayoutEffect(() => {
    setSortOrder(DEFAULT_REDEPLOYMENT_SORT_DIRECTION[sortSettings.sortOrder]
      ? sortSettings.sortOrder : RedeploymentSort.employee);
    setDirection(VALID_SORT_DIRECTION[sortSettings.direction] ? sortSettings.direction
      : DEFAULT_REDEPLOYMENT_SORT_DIRECTION[sortSettings.sortOrder] ||
        DEFAULT_REDEPLOYMENT_SORT_DIRECTION[RedeploymentSort.employee]
    );
  }, [sortSettings.direction, sortSettings.sortOrder]);

  useLayoutEffect(() => {
    if (employees && pendingEmployeeId && pendingJobCode) {
      const empl = find(employees, ['id', pendingEmployeeId]);
      if (empl?.job?.code === pendingJobCode) {
        setPendingEmployeeId(0);
        setPendingJobCode(undefined);
      }
    }
  }, [employees, pendingEmployeeId, pendingJobCode]);

  const exportParams: ExportParams | null = useMemo(() => !filters || !token || !isString(token) ? null : {
    ...redeploymentParams({
      manager_id: toLower(filters.manager_id || ''),
      country_id: filters.country_id,
      state_id: filters.state_id,
      // job_levels: filters.job_levels,
      selected_leader_id: leaderUid
    }),
    token
  } as ExportParams, [filters, leaderUid, token]);

  const exportEndpoint = useMemo(
    () => plan_id ? replace(API_HR_REDEPLOYMENT_EXPORT, '{args.plan_id}', toString(plan_id)) : undefined,
    [plan_id]);

  useLayoutEffect(() => {
    if (plan_id && filters && pageSize && getEmployees) {
      const input: RedeploymentEmployeesInput = {
        ...redeploymentParams({
          manager_id: toLower(filters.manager_id || ''),
          country_id: filters.country_id,
          state_id: filters.state_id,
          // job_levels: filters.job_levels,
          selected_leader_id: leaderUid
        }),
        sort_by: sortOrder,
        direction,
        limit: pageSize
      };
      let curPage = currentPage;
      if (!prevVars?.plan_id || prevVars.plan_id !== plan_id || paramsDiffer(prevVars?.input, input)) {
        curPage = 1;
        setCurrentPage(1);
      }
      input.offset = pageSize * (curPage - 1);
      getEmployees({
        variables: {
          plan_id,
          input,
          pathBuilder: pathBuilder as unknown as string
        }
      });
      updatePlan?.({
        variables: { plan_id, input: {
          search_criteria: {
            ...filters.manager_id ? { manager_id: toLower(filters.manager_id) } : {},
            ...filters.country_id ? { country_id: filters.country_id } : {},
            ...filters.state_id ? { state_id: filters.state_id } : {}
            // ...filters.job_levels ? { job_levels: filters.job_levels } : {}
          },
          selected_leader_id: leaderUid
        } },
        update: (cache) => cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlan' })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `prevVars` changes:
    plan_id, filters, currentPage, pageSize, leaderUid, sortOrder, direction, getEmployees, updatePlan
  ]);

  useLayoutEffect(() => {
    if (!isNil(count)) updatePlan?.({
      variables: { plan_id, input: {
        employees_count: count,
        selected_leader_id: leaderUid
      } },
      update: (cache) => cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlans' })
    });
  }, [plan_id, count, leaderUid, updatePlan]);

  const updateTitle = useCallback(
    (newTitle?: string) => updatePlanTitle({
      variables: { plan_id, input: { title: newTitle ? newTitle : '', selected_leader_id: leaderUid } },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlans' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlan' });
      }
    }), [plan_id, leaderUid, updatePlanTitle]
  );

  const deleteThisDevPlan = useCallback(
    () => deletePlan?.({
      variables: { plan_id, selected_leader_id: leaderUid },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlans' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentPlan' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentEmployees' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'redeploymentEmployeeJobs' });
      },
      onCompleted: () => navigate(PATH_HR_REDEPLOYMENTS)
    }),
    [plan_id, leaderUid, deletePlan, navigate]
  );

  const [employeeId, setEmployeeId] = useState<number>();
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = useCallback(() => setPopupOpen(false), []);
  const handleEmployeeClick = useCallback((empl: RedeploymentEmployee) => {
    setEmployeeId(empl.id);
    setPopupOpen(true);
  }, []);
  const employee = useMemo(() => find(employees, ['id', employeeId]), [employeeId, employees]);

  const disabled = pending || pendingEmployees || titlePending || deletePending ? true : undefined;

  return (
    <>
      {failed || pending ? (
        <CardTitle title={failed ? 'hr.redeployment.title.empty' : <Skeleton variant="text" width="12rem"/>}/>
      ) : (
        <ReviewBar
            titleLabel="hr.redeployment.title.label"
            title={parentTitle}
            // pending // handled above
            disabled={disabled}
            editLabel="hr.dev_plan.edit"
            onRename={updateTitle}
            renameLabel="hr.dev_plan.rename"
            renamePending={titlePending}
            renameFailed={titleFailed}
            renameError="hr.redeployment.rename_error"
            onDelete={deleteThisDevPlan}
            deleteLabel="hr.dev_plan.delete"
            deleteQuestion="hr.redeployment.delete_question"
            deletePending={deletePending}
            deleteFailed={deleteFailed}
            deleteError="hr.redeployment.delete_error"
            exportParams={exportParams}
            exportEndpoint={exportEndpoint}
            exportDisabled={!plan || !parentTitle || size(employees) < 1}
        />
      )}
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (
        <>
          <Divider light/>
          <RedeploymentEmployeesTable
              // route={supvEmplPath}
              employees={employees}
              pending={pendingEmployees}
              failed={failedEmployees}
              sortOrder={sortOrder}
              direction={direction}
              changeSort={redeploymentSortVar}
              onClick={handleEmployeeClick}
              disabled={disabled}
              pendingEmployeeId={pendingEmployeeId}
              pendingJobCode={pendingJobCode}
              filters={(
                <DashboardFilters
                    init={search_criteria}
                    withReset
                    // withLevels // makes no sense to use job_levels here - we're filtering by reployment plan job
                    onChange={setFilters}
                    uid={uid}
                    rootUid={rootUid}
                    disabled={disabled}
                />
              )}
              pagination={(
                <PaginationControls
                    settingsId="redeployment"
                    loaded={Boolean(employees)}
                    pending={pendingEmployees}
                    loading={pendingEmployees}
                    total={count}
                    currentPage={currentPage}
                    onPageSelected={setCurrentPage}
                    onPageSize={setPageSize}
                    disabled={disabled || failedEmployees}
                    totalMessage="hr.redeployment.employees.pagination"
                />
              )}
          />
        </>
      )}
      {plan && employee ? (
        <EmployeeRedeploymentPopup
            leaderUid={leaderUid}
            employeePath={supvEmplPath}
            plan={plan}
            employee={employee}
            isOpen={popupOpen}
            onJobChange={handleJobChange}
            onClose={handlePopupClose}
        />
      ) : undefined}
    </>
  );
};

RedeploymentPlanPanel.propTypes = RedeploymentPlanPanelPropTypes;

export default memo(RedeploymentPlanPanel);
