import { forwardRef, memo, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { SuccessStory } from '../graphql/types';
// SCSS imports
import { card, cardInactive, avatar, storyText, storyRole } from './SuccessStoryCard.module.scss';

type SuccessStoryCardProps = {
  story: SuccessStory;
  isCurrent?: boolean;
};

const SuccessStoryCardPropTypes = {
  story: PropTypes.object as Validator<SuccessStory>,
  isCurrent: PropTypes.bool
};

const SuccessStoryCard = forwardRef<HTMLDivElement, SuccessStoryCardProps>(({
  story: { photo, name, title, story, link },
  isCurrent = false,
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const values = useMemo(() => ({
    text: formatMessage({ id: story, defaultMessage: story }),
    link: link ? (
      <StandardLink
          href={isCurrent ? formatMessage({ id: link, defaultMessage: link }) : undefined}
          text="board.stories.more"
          variant="inherit"
      />
    ) : ''
  }), [story, link, isCurrent, formatMessage]);

  return (
    <Box
        px={1.75}
        pt={11}
        pb={7}
    >
      <Card
          ref={ref}
          elevation={isCurrent ? 9 : 0}
          className={isCurrent ? card : cardInactive}
      >
        {photo ? <Avatar alt={name} src={photo} className={avatar}/> : undefined}
        <Box className={storyText}>
          <FormattedMessage id="board.stories.text" values={values}/>
        </Box>
        <Box textAlign="center">
          <BoxTypography variant="h3" pb={0.25}>
            <FormattedMessage id={name} defaultMessage={name}/>
          </BoxTypography>
          <Box color="text.label" className={storyRole}>
            <FormattedMessage id={title} defaultMessage={title}/>
          </Box>
        </Box>
      </Card>
    </Box>
  );
});

SuccessStoryCard.displayName = 'SuccessStoryCard';

SuccessStoryCard.propTypes = SuccessStoryCardPropTypes;

export default memo(SuccessStoryCard);
