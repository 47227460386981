import isSafeInteger from 'lodash/isSafeInteger';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import toString from 'lodash/toString';
// TM UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import {
  COURSE_UPDATED, COURSE_UPDATING, DEV_PLAN_FETCH, EMPLOYEE_SKILL_FETCH,
  SKILL_EMPLOYEES_FETCH, TARGET_SKILL_UPDATED, TARGET_SKILL_UPDATING,
  SKILL_ADDING, SKILL_ADDED, SKILL_UPDATING, SKILL_UPDATED
} from './dataContext';
import {
  axiosInstance as axios,
  getApiEmployeeSkill, getApiSkillEmployees, getApiSkillSetTarget, getApiSkillSelectCourses,
  getApiSkillDevPlan, API_MY_SKILLS_SET, getApiSkill
} from '../config/api';
import { SKILL_LEVEL_MIN, SKILL_LEVEL_MAX, SKILL_LEVEL_TO_MENTOR } from '../models/skill';
import { invalidateApolloCacheSkills } from '../graphql/helpers';
import { getRequestHeaders, locationParams, optimizeParams } from '../helpers/context';
import { fetchFactory } from '../helpers/actions';
import { transformSkill } from './dataTranformations';

export const requireEmployeeSkill = (token, online, unauthenticate, dispatch, skill) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ({ skill_id, hrbp }) => ({
    skill_id,
    hrbp: hrbp === true ? true : null
  }),
  type: EMPLOYEE_SKILL_FETCH,
  entity: skill,
  validator: ({ skill_id }) => !isEmptyString(skill_id),
  api: ({ skill_id, hrbp }) => hrbp ? getApiSkill(skill_id) : getApiEmployeeSkill(skill_id),
  dropParams: ['skill_id', 'hrbp'],
  results: '',
  transformation: transformSkill
});

export const requireDevPlan = (token, online, unauthenticate, dispatch, devPlan) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ({ skill_id, provider_id }) => ({
    skill_id,
    provider_id: provider_id && provider_id >= 1 && isSafeInteger(provider_id) ? provider_id : null
  }),
  type: DEV_PLAN_FETCH,
  entity: devPlan,
  validator: ({ skill_id }) => !isEmptyString(skill_id),
  api: ({ skill_id }) => getApiSkillDevPlan(skill_id),
  dropParams: ['skill_id'],
  results: ''
});

export const requireSkillEmployees = (token, online, unauthenticate, dispatch, skillEmployees) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ({ skill_id, state_id, country_id, manager_id }) => ({
    skill_id,
    ...isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? {} : { manager_id },
    ...locationParams(country_id, state_id)
  }),
  type: SKILL_EMPLOYEES_FETCH,
  entity: skillEmployees,
  validator: ({ skill_id }) => !isEmptyString(skill_id),
  api: ({ skill_id }) => getApiSkillEmployees(skill_id),
  dropParams: ['skill_id']
});

// eslint-disable-next-line complexity
export const updateSkill = (token, online, dispatch, is_add_skill, updatePending, HAS_MENTORING) => async ({
  skill_id, level, is_opt_in_mentor, is_target, skill, reason, source, source_id, updateSkillsGap, onSuccess, apolloCache
} = {}) => {
  if (!token || updatePending ||
    !isSafeInteger(skill_id) || skill_id < 1 ||
    !isSafeInteger(level) || level < SKILL_LEVEL_MIN || level > SKILL_LEVEL_MAX
  ) return;
  const params = optimizeParams({
    skill_id,
    level,
    ...HAS_MENTORING ? { is_opt_in_mentor: Boolean(is_opt_in_mentor) && level >= SKILL_LEVEL_TO_MENTOR } : {},
    is_target: isBoolean(is_target) ? is_target : null,
    is_add_skill: is_add_skill ? true : null,
    reason,
    source,
    ...source_id && source_id >= 1 && isSafeInteger(source_id) ? { source_id } : {},
    ...updateSkillsGap === true ? { updateSkillsGap } : {}
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: is_add_skill ? SKILL_ADDING : SKILL_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: API_MY_SKILLS_SET,
      data: {
        ...pick(params, ['source', 'source_id']),
        levels: [pick(params, ['skill_id', 'level', 'is_opt_in_mentor', 'is_target', 'is_add_skill'])]
      },
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    if (onSuccess) onSuccess();
    if (apolloCache) invalidateApolloCacheSkills(apolloCache);
    dispatch({
      type: is_add_skill ? SKILL_ADDED : SKILL_UPDATED,
      payload: skill || true,
      params
    });
  } catch (error) {
    dispatch({
      type: is_add_skill ? SKILL_ADDED : SKILL_UPDATED,
      payload: false,
      params
    });
  }
};

export const updateTargetSkill = (token, online, dispatch, targetUpdatePending) => async ({
  skill_id, is_target, targetSkill
} = {}) => {
  if (!token || targetUpdatePending || !isSafeInteger(skill_id) || skill_id < 1) return;
  const params = optimizeParams({
    skill_id,
    is_target: Boolean(is_target)
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: TARGET_SKILL_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: getApiSkillSetTarget(skill_id),
      data: omit(params, 'skill_id'),
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    dispatch({
      type: TARGET_SKILL_UPDATED,
      payload: targetSkill || true,
      params
    });
  } catch (error) {
    dispatch({
      type: TARGET_SKILL_UPDATED,
      payload: false,
      params
    });
  }
};

export const updateCourse = (token, online, dispatch, courseUpdatePending) => async ({
  skill_id, course_ids
} = {}) => {
  if (!token || courseUpdatePending || !isSafeInteger(skill_id) || skill_id < 1 ||
    // TODO: improve `course_ids` validation
    !isArray(course_ids)
  ) return;
  const params = optimizeParams({ skill_id, course_ids }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: COURSE_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: getApiSkillSelectCourses(skill_id),
      data: omit(params, 'skill_id'),
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    dispatch({
      type: COURSE_UPDATED,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: COURSE_UPDATED,
      payload: false,
      params
    });
  }
};
