import { memo, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
// TM UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import SkillsGapLegend from '@empathco/ui-components/src/elements/SkillsGapLegend';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { EmployeeRole } from '../graphql/types';
import { Skill } from '../models/skill';
import { PATH_JOB } from '../config/paths';
import SkillsGrid from '../v3/SkillsGrid';
// SCSS imports
import { moreButton, legend } from './CurrentRoleSkills.module.scss';

type CurrentRoleSkillsProps = {
  roleId: string;
  role?: EmployeeRole;
  pending?: boolean;
  failed?: boolean;
  fullName?: string;
  supervisor?: boolean;
};

const CurrentRoleSkillsPropTypes = {
  roleId: PropTypes.string.isRequired,
  role: PropTypes.object as Validator<EmployeeRole>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  fullName: PropTypes.string,
  supervisor: PropTypes.bool
};

const CurrentRoleSkills: FunctionComponent<CurrentRoleSkillsProps> = ({
  roleId,
  role,
  pending = false,
  failed = false,
  fullName,
  supervisor = false
}) => {
  const { title, match_rate, skills_with_gap, skills } = role || {};

  const allSkills = useMemo(() => [...skills_with_gap || [], ...skills || []] as Skill[], [skills_with_gap, skills]);

  const link = useMemo(() => injectParams(PATH_JOB, { role_id: roleId }), [roleId]);

  const roleTitleValues = useMemo(() => ({
    name: fullName,
    role: failed || !title ? <FormattedMessage id="supervisor.default_job_title"/> : title
  }), [title, failed, fullName]);

  const loading = pending || !role || role.code !== roleId;

  return (
    <>
      {failed || loading ? undefined : (
        <>
          <CardSection flex top>
            <MatchIndicator value={toSafeInteger(match_rate)}/>
            <BoxTypography pl={2} flexGrow={1} variant="h3">
              {supervisor ? (
                <FormattedMessage
                    id="supervisor.job_related_skills"
                    values={roleTitleValues}
                />
              ) : roleTitleValues.role}
            </BoxTypography>
            {link ? (
              <Button
                  color="primary"
                  variant="outlined"
                  component={RouterLink}
                  to={link}
                  disabled={pending ? true : undefined}
                  className={moreButton}
              >
                <FormattedMessage id="supervisor.more_about_this_job"/>
              </Button>
            ) : undefined}
          </CardSection>
          <SkillsGapLegend className={legend}/>
        </>
      )}
      <SkillsGrid
          source="job_skills" // TODO: add "current_job_skills" source
          readOnly={supervisor}
          supervisor={supervisor}
          isEmployee={!supervisor}
          withExpectedLevel
          skills={allSkills}
          pending={loading}
          failed={failed}
      />
    </>
  );
};

CurrentRoleSkills.propTypes = CurrentRoleSkillsPropTypes;

export default memo(CurrentRoleSkills);
