import {
  memo, useState, useEffect, useCallback, useContext, useMemo,
  type FunctionComponent, type MouseEventHandler
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
// Material UI imports
import Dialog from '@mui/material/Dialog';
// TM UI Components
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
// local imports
import { SKILL_LEVEL_MIN, Skill, SkillLevel } from '../models/skill';
import useCustomerSettings from '../config/customer';
import { getSkillCurrentLevel, getSkillCurrentLevelOnly, hasCurrentLevel } from '../helpers/models';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import EditSkillLevel from '../v3/EditSkillLevel';

type SkillLevelDialogProps = {
  skill: Skill;
  defaultLevel?: SkillLevel;
  isOpen?: boolean;
  onUpdate: (level: SkillLevel, mentoring: boolean, targeting: boolean) => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onExited: (node: HTMLElement) => void;
  disabled?: boolean | null;
  pending?: boolean | null;
  plain?: boolean;
  readOnly?: boolean;
  manualOnly?: boolean;
  depersonalized?: boolean;
  // for Storybook only
  testLevel?: SkillLevel;
}

const SkillLevelDialogPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  defaultLevel: PropTypes.number as Validator<SkillLevel>,
  isOpen: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  plain: PropTypes.bool,
  readOnly: PropTypes.bool,
  manualOnly: PropTypes.bool,
  depersonalized: PropTypes.bool,
  // for Storybook only
  testLevel: PropTypes.number as Validator<SkillLevel>
};

// eslint-disable-next-line complexity
const SkillLevelDialog: FunctionComponent<SkillLevelDialogProps> = ({
  skill,
  defaultLevel,
  isOpen = false,
  onUpdate,
  onCancel,
  onExited,
  disabled = false,
  pending = false,
  plain = false,
  readOnly = false,
  manualOnly = false,
  depersonalized = false,
  testLevel
}) => {
  const { HAS_MENTORING } = useCustomerSettings();
  const { user: { data: user } } = useContext(GlobalContext);
  const { requirePreferences } = useContext(DataContext);

  const { is_mentoring, is_target } = skill || {};
  const currentLevel = getSkillCurrentLevel(skill);
  const currentOnlyLevel = getSkillCurrentLevelOnly(skill);
  
  const isAdminOnly = Boolean(user?.is_admin_only);
  const needsPreferences = !isAdminOnly && !depersonalized;
  const canMentor = HAS_MENTORING && !isAdminOnly && !manualOnly && !depersonalized;
  const canTarget = !isAdminOnly && !manualOnly && !depersonalized;
  const isMentoring = canMentor && Boolean(is_mentoring);
  const isTargeting = canTarget && Boolean(is_target);

  useEffect(() => {
    if (needsPreferences) requirePreferences?.();
  }, [needsPreferences, requirePreferences]);

  const [level, setLevel] = useState<SkillLevel>(defaultLevel || SKILL_LEVEL_MIN);
  const [mentoring, setMentoring] = useState(false);
  const [isTarget, setIsTarget] = useState(false);

  const handleUpdate = useCallback((_skl: Skill, lvl: SkillLevel, mentor: boolean, target?: boolean) => {
    setLevel(lvl);
    setMentoring(mentor);
    if (!isNil(target)) setIsTarget(target);
  }, []);

  const handleSave = useCallback(() => onUpdate(level, mentoring, isTarget), [level, mentoring, isTarget, onUpdate]);

  const transitionProps = useMemo(() => ({ onExited }), [onExited]);

  return (
    <Dialog
        disableEnforceFocus
        maxWidth="lg"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={pending ? undefined : onCancel}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onCancel} disabled={pending ? true : undefined}/>
      {skill ? (
        <EditSkillLevel
            readOnly={readOnly}
            plain={plain || manualOnly}
            noTargeting={!canTarget}
            noMentoring={!canMentor}
            depersonalized={depersonalized}
            skill={skill}
            defaultLevel={defaultLevel}
            onUpdate={handleUpdate}
            onBack={depersonalized ? onCancel : undefined}
            onConfirm={handleSave}
            disabled={disabled || pending}
            confirmPending={pending}
            confirmDisabled={currentLevel === level && currentOnlyLevel === level &&
              (isNil(defaultLevel) || defaultLevel === level) &&
              (!canMentor || isMentoring === mentoring) &&
              (!canTarget || isTargeting === isTarget) &&
              hasCurrentLevel(skill) ? true : undefined}
            testLevel={testLevel}
        />
      ) : undefined}
    </Dialog>
  );
};

SkillLevelDialog.propTypes = SkillLevelDialogPropTypes;

export default memo(SkillLevelDialog);
