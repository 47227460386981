import { memo, type FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
// TM UI Components
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
// local imports
import { hasHRBP } from '../models/user';
import useCustomerSettings from '../config/customer';
import { PATH_HR } from '../config/paths';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import ManagerDashboard from '../components/ManagerDashboard';
import DashboardAnalytics from '../components/DashboardAnalytics';

const SupervisorScreen: FunctionComponent = () => {
  const { DA_TABS } = useCustomerSettings();
  const { content, user } = useVerifyUser({ manager: true });
  return content || (hasHRBP(user) ? <Navigate replace to={PATH_HR}/> : (
    <Screen withSettings>
      <ContentCard>
        {DA_TABS ? <DashboardAnalytics supervisor/> : <ManagerDashboard/>}
      </ContentCard>
    </Screen>
  ));
};

export default memo(SupervisorScreen);
