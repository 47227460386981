import { memo, forwardRef, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { withStyles } from 'tss-react/mui';
import { type Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import EditIcon from '@mui/icons-material/Edit';
import DragIndicator from '@mui/icons-material/DragIndicator';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
// TM UI Components
import Votes from '@empathco/ui-components/src/icons/Votes';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { DevPlanTargetSkill } from '../graphql/types';
import { Skill, SkillLevel } from '../models/skill';
// SCSS imports
import { chip, dragging, selected, voteIcon, voteIconActive, primary, info } from './SkillChip.module.scss';

const editIconBtnSx = { ml: -0.75, mr: 1.25 };

const styles = (theme: Theme) => ({
  root: {
    minHeight: '2rem'
  },
  outlined: {
    backgroundColor: theme.palette.background.card,
    boxShadow: 'none'
  },
  outlinedSecondary: {
    backgroundColor: theme.palette.background.card,
    boxShadow: 'none'
  }
});

const FlatChip = withStyles(Chip, styles);

type SkillChipProps = {
  skill: Skill | DevPlanTargetSkill;
  level?: SkillLevel | null;
  small?: boolean | null;
  active?: boolean | null;
  plain?: boolean;
  flat?: boolean;
  onDelete?: (id: number, level?: SkillLevel) => void;
  onActivate?: (id: number, abbr: string, level?: SkillLevel | null) => void;
  onEdit?: (id: number, abbr: string, level?: SkillLevel | null) => void;
  disabled?: boolean;
  draggable?: boolean;
  isDragging?: boolean;
  satisfied?: boolean | null;
};

const SkillChipPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  level: PropTypes.number as Validator<SkillLevel>,
  small: PropTypes.bool,
  active: PropTypes.bool,
  plain: PropTypes.bool,
  flat: PropTypes.bool,
  onDelete: PropTypes.func,
  onActivate: PropTypes.func,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  draggable: PropTypes.bool,
  isDragging: PropTypes.bool,
  satisfied: PropTypes.bool
};

// eslint-disable-next-line complexity
const SkillChip = forwardRef<HTMLDivElement, SkillChipProps>(({
  skill,
  level,
  onDelete,
  onActivate,
  onEdit,
  small = false,
  active = false,
  plain = false,
  flat = false,
  disabled = false,
  draggable = false,
  isDragging = false,
  satisfied
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const { id, abbr, title } = skill || {};
  const { total_votes } = (skill as Skill) || {};

  const handleClick = useCallback(() => {
    onActivate?.(id, abbr, level);
  }, [id, abbr, level, onActivate]);

  const handleDelete = useCallback(() => {
    onDelete?.(id, isNil(level) ? undefined : level);
  }, [id, level, onDelete]);

  const handleEdit = useCallback(() => {
    onEdit?.(id, abbr, isNil(level) ? undefined : level);
  }, [id, abbr, level, onEdit]);

  const OurChip = flat ? FlatChip : Chip;

  const chipElement = (
    <OurChip
        ref={ref}
        size={small ? 'small' : undefined}
        icon={draggable ? <DragIndicator fontSize="inherit"/> : undefined}
        deleteIcon={<CloseIcon fontSize="inherit"/>}
        label={(total_votes && total_votes >= 1 && isSafeInteger(total_votes) && (
          <Box display="flex" alignItems="center">
            {title}
            <Votes className={active ? voteIconActive : voteIcon}/>
            <BoxTypography
                variant="body2"
                color={isDragging ? undefined : (active && 'primary.contrastText') || 'text.label'}
            >
              <FormattedMessage id="common.votes" values={{ votes: total_votes }}/>
            </BoxTypography>
          </Box>
        )) || (!isUndefined(level) && (
          <Box display="flex" alignItems="center">
            {satisfied === true && (
              <Box className={primary}>
                <CheckCircleRounded color="inherit" fontSize="medium"/>
              </Box>
            )}
            {satisfied === false && (
              <Box className={info}>
                <ErrorRounded color="inherit" fontSize="medium"/>
              </Box>
            )}
            {title}
            {' - '}
            {toSafeInteger(level)}
          </Box>
        )) || title} // TODO: if !draggable, maybe add a link to the skill details?
        clickable={draggable || Boolean(onActivate)}
        onClick={onActivate ? handleClick : undefined}
        onDelete={onDelete ? handleDelete : undefined}
        disabled={disabled}
        variant={active ? 'filled' : 'outlined'}
        color={draggable || plain || flat ? 'secondary' : 'primary'}
        className={clsx(chip, {
          [dragging]: draggable && isDragging,
          [selected]: !draggable
        })}
    />
  );

  return onEdit ? (
    <Box whiteSpace="nowrap" component="span">
      {chipElement}
      <IconButton
          aria-label={formatMessage({ id: 'opportunities.button.add_growth' })}
          color="primary"
          size="small"
          disabled={disabled}
          onClick={handleEdit}
          sx={editIconBtnSx}
      >
        <EditIcon color="inherit" fontSize="medium"/>
      </IconButton>
    </Box>
  ) : chipElement;
});

SkillChip.displayName = 'SkillChip';

SkillChip.propTypes = SkillChipPropTypes;

export default memo(SkillChip);
