import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
import { useLazyQuery } from '@apollo/client';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// TM UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { DELEGATES_SEARCH_QUERY } from '../graphql/DelegatesSearch';
import { LEADERS_SEARCH_QUERY } from '../graphql/LeadersSearch';
import {
  Delegate, DelegatesSearchDocument, DelegatesSearchQueryVariables,
  Leader, LeadersSearchDocument, LeadersSearchQueryVariables
} from '../graphql/types';
import { MAX_LOOKUP_OPTIONS } from '../config/params';
// SCSS imports
import { root } from './DelegateSearch.module.scss';

type DelegateSearchProps = {
  value?: Leader | null;
  leaderId?: number | null;
  onChange: (item: Leader | null) => void;
  exclude?: number[] | null;
  disabled?: boolean | null;
  fullWidth?: boolean;
  className?: string;
}

const DelegateSearchPropTypes = {
  // attributes
  value: PropTypes.object as Validator<Leader>,
  leaderId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string
};

const DelegateSearch = forwardRef<HTMLDivElement, DelegateSearchProps>(({
  value,
  leaderId,
  onChange,
  exclude,
  disabled = false,
  fullWidth = false,
  className
}, ref) => {
  // lazy load delegates or leaders
  const delegatesQuery = useQueryCounted({
    data: undefined as unknown as Delegate,
    key: 'delegatesSearch',
    lazyQuery: useLazyQuery(DELEGATES_SEARCH_QUERY as typeof DelegatesSearchDocument)
  });
  const leadersQuery = useQueryCounted({
    data: undefined as unknown as Leader,
    key: 'leadersSearch',
    lazyQuery: useLazyQuery(LEADERS_SEARCH_QUERY as typeof LeadersSearchDocument)
  });
  const { query, pending, failed, variables, results } = leaderId ? delegatesQuery : leadersQuery;

  const fetchParams = useMemo(() => ({
    ...leaderId ? { leader_id: leaderId } : {},
    limit: MAX_LOOKUP_OPTIONS
  }), [leaderId]);

  const fetchItems = useCallback((vars: DelegatesSearchQueryVariables & LeadersSearchQueryVariables) =>
    query?.({ variables: vars }),
    [query]);

  return query ? (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type={leaderId ? 'delegates' : 'leaders'}
        className={clsx(root, className)}
        fetched={results as Leader[]}
        pending={pending}
        failed={failed}
        params={variables as DelegatesSearchQueryVariables & LeadersSearchQueryVariables}
        fetch={fetchItems}
        fetchParams={fetchParams}
        exclude={exclude}
        value={value}
        onChange={onChange}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  ) : null;
});

DelegateSearch.displayName = 'DelegateSearch';

DelegateSearch.propTypes = DelegateSearchPropTypes;

export default memo(DelegateSearch);
