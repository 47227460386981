import { forwardRef, memo, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// TM UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { EmployeeBrief } from '../models/employee';
// SCSS imports
import { leaderName } from './LeaderHeader.module.scss';

type LeaderHeaderProps = {
  leader: EmployeeBrief;
};

const LeaderHeaderPropTypes = {
  leader: PropTypes.object.isRequired as Validator<EmployeeBrief>
};

const LeaderHeader = forwardRef<Component<BoxProps>, LeaderHeaderProps>(({
  leader: { first_name, last_name, code }
}, ref) => (
  <Box
      ref={ref}
      display="flex"
      alignItems="center"
      color="text.secondary"
      className={leaderName}
  >
    <Typography variant="subtitle1">
      {getFullName(first_name, last_name, code)}
    </Typography>
  </Box>
));

LeaderHeader.displayName = 'LeaderHeader';

LeaderHeader.propTypes = LeaderHeaderPropTypes;

export default memo(LeaderHeader);
