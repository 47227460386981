import { memo, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// Material Icon imports
import CheckIcon from '@mui/icons-material/Check';
// TM UI Components
import { fontWeightBold } from '@empathco/ui-components/src/styles/themeOptions';
import { uniformDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { ADMIN_USER_ENGAGEMENT_QUERY } from '../graphql/ARUserEngagement';
import { ADMIN_JOB_TRENDS_QUERY } from '../graphql/ARJobTrends';
import { ADMIN_MENTORED_SKILLS_BY_EMPLOYEE_QUERY } from '../graphql/ARMentoredSkillsByEmployee';
import { ADMIN_TARGET_JOBS_QUERY } from '../graphql/ARTargetJobs';
import { ADMIN_TARGET_SKILLS_QUERY } from '../graphql/ARTargetSkills';
import { ADMIN_TARGET_JOBS_BY_EMPLOYEE_QUERY } from '../graphql/ARTargetJobsByEmployee';
import { ADMIN_TARGET_SKILLS_BY_EMPLOYEE_QUERY } from '../graphql/ARTargetSkillsByEmployee';
import {
  AdminReportType,
  ARJobTrends, ARJobTrendsDocument, ARJobTrendsQueryVariables,
  ARUserEngagement, ARUserEngagementDocument, ARUserEngagementQueryVariables,
  ARMentoredSkillsByEmployee, ARMentoredSkillsByEmployeeDocument, ARMentoredSkillsByEmployeeQueryVariables,
  ARTargetJobs, ARTargetJobsDocument, ARTargetJobsQueryVariables,
  ARTargetSkills, ARTargetSkillsDocument, ARTargetSkillsQueryVariables,
  ARTargetJobsByEmployee, ARTargetJobsByEmployeeDocument, ARTargetJobsByEmployeeQueryVariables,
  ARTargetSkillsByEmployee, ARTargetSkillsByEmployeeDocument, ARTargetSkillsByEmployeeQueryVariables
} from '../graphql/types';
import { ADMIN_TABLE_PAGE_SIZES } from '../config/params';
import PaginationControls, { hasPagination } from '../v3/PaginationControls';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';

type AREmployee = ARMentoredSkillsByEmployee | ARTargetJobsByEmployee | ARTargetSkillsByEmployee;

type AdminReportProps = {
  settingsId?: string;
  reportId: number;
  reportType: AdminReportType;
  // for Storybook only
  testPending?: boolean;
}

const AdminReportPropTypes = {
  settingsId: PropTypes.string,
  reportId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired as Validator<AdminReportType>,
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity, max-lines-per-function
const AdminReport: FunctionComponent<AdminReportProps> = ({
  settingsId,
  reportId,
  reportType,
  testPending
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  // lazy load user engagement report
  const {
    query: getUserEngagement, pending: pendingUserEngagement, failed: failedUserEngagement,
    count: countUserEngagement, data: dataUserEngagement, results: userEngagement, variables: variablesUserEngagement
  } = useQueryCounted({
    data: undefined as unknown as ARUserEngagement,
    key: 'arUserEngagement',
    lazyQuery: useLazyQuery(ADMIN_USER_ENGAGEMENT_QUERY as typeof ARUserEngagementDocument)
  });
  // lazy load user engagement report
  const {
    query: getJobTrends, pending: pendingJobTrends, failed: failedJobTrends,
    count: countJobTrends, results: jobTrends, variables: variablesJobTrends
  } = useQueryCounted({
    data: undefined as unknown as ARJobTrends,
    key: 'arJobTrends',
    lazyQuery: useLazyQuery(ADMIN_JOB_TRENDS_QUERY as typeof ARJobTrendsDocument)
  });
  // lazy load mentored skills by employee
  const {
    query: getMentoredSkillsByEmployee, pending: pendingMentoredSkillsByEmployee, failed: failedMentoredSkillsByEmployee,
    count: countMentoredSkillsByEmployee, results: mentoredSkillsByEmployee, variables: variablesMentoredSkillsByEmployee
  } = useQueryCounted({
    data: undefined as unknown as ARMentoredSkillsByEmployee,
    key: 'arMentoredSkillsByEmployee',
    lazyQuery: useLazyQuery(ADMIN_MENTORED_SKILLS_BY_EMPLOYEE_QUERY as typeof ARMentoredSkillsByEmployeeDocument)
  });
  // lazy load target jobs
  const {
    query: getTargetJobs, pending: pendingTargetJobs, failed: failedTargetJobs,
    count: countTargetJobs, results: targetJobs, variables: variablesTargetJobs
  } = useQueryCounted({
    data: undefined as unknown as ARTargetJobs,
    key: 'arTargetJobs',
    lazyQuery: useLazyQuery(ADMIN_TARGET_JOBS_QUERY as typeof ARTargetJobsDocument)
  });
  // lazy load target skills
  const {
    query: getTargetSkills, pending: pendingTargetSkills, failed: failedTargetSkills,
    count: countTargetSkills, results: targetSkills, variables: variablesTargetSkills
  } = useQueryCounted({
    data: undefined as unknown as ARTargetSkills,
    key: 'arTargetSkills',
    lazyQuery: useLazyQuery(ADMIN_TARGET_SKILLS_QUERY as typeof ARTargetSkillsDocument)
  });
  // lazy load target jobs by employee
  const {
    query: getTargetJobsByEmployee, pending: pendingTargetJobsByEmployee, failed: failedTargetJobsByEmployee,
    count: countTargetJobsByEmployee, results: targetJobsByEmployee, variables: variablesTargetJobsByEmployee
  } = useQueryCounted({
    data: undefined as unknown as ARTargetJobsByEmployee,
    key: 'arTargetJobsByEmployee',
    lazyQuery: useLazyQuery(ADMIN_TARGET_JOBS_BY_EMPLOYEE_QUERY as typeof ARTargetJobsByEmployeeDocument)
  });
  // lazy load target skills by employee
  const {
    query: getTargetSkillsByEmployee, pending: pendingTargetSkillsByEmployee, failed: failedTargetSkillsByEmployee,
    count: countTargetSkillsByEmployee, results: targetSkillsByEmployee, variables: variablesTargetSkillsByEmployee
  } = useQueryCounted({
    data: undefined as unknown as ARTargetSkillsByEmployee,
    key: 'arTargetSkillsByEmployee',
    lazyQuery: useLazyQuery(ADMIN_TARGET_SKILLS_BY_EMPLOYEE_QUERY as typeof ARTargetSkillsByEmployeeDocument)
  });

  const data = (reportType === AdminReportType.user_engagement && userEngagement) ||
    (reportType === AdminReportType.job_trends && jobTrends) ||
    (reportType === AdminReportType.mentored_skills_by_employee && mentoredSkillsByEmployee) ||
    (reportType === AdminReportType.target_jobs && targetJobs) ||
    (reportType === AdminReportType.target_skills && targetSkills) ||
    (reportType === AdminReportType.target_jobs_by_employee && targetJobsByEmployee) ||
    (reportType === AdminReportType.target_skills_by_employee && targetSkillsByEmployee) ||
    undefined;
  const count = (reportType === AdminReportType.user_engagement && countUserEngagement) ||
    (reportType === AdminReportType.job_trends && countJobTrends) ||
    (reportType === AdminReportType.mentored_skills_by_employee && countMentoredSkillsByEmployee) ||
    (reportType === AdminReportType.target_jobs && countTargetJobs) ||
    (reportType === AdminReportType.target_skills && countTargetSkills) ||
    (reportType === AdminReportType.target_jobs_by_employee && countTargetJobsByEmployee) ||
    (reportType === AdminReportType.target_skills_by_employee && countTargetSkillsByEmployee) ||
    0;
  const prevVars = (reportType === AdminReportType.user_engagement && variablesUserEngagement) ||
    (reportType === AdminReportType.job_trends && variablesJobTrends) ||
    (reportType === AdminReportType.mentored_skills_by_employee && variablesMentoredSkillsByEmployee) ||
    (reportType === AdminReportType.target_jobs && variablesTargetJobs) ||
    (reportType === AdminReportType.target_skills && variablesTargetSkills) ||
    (reportType === AdminReportType.target_jobs_by_employee && variablesTargetJobsByEmployee) ||
    (reportType === AdminReportType.target_skills_by_employee && variablesTargetSkillsByEmployee) ||
    undefined;
  const pending = (reportType === AdminReportType.user_engagement && pendingUserEngagement) ||
    (reportType === AdminReportType.job_trends && pendingJobTrends) ||
    (reportType === AdminReportType.mentored_skills_by_employee && pendingMentoredSkillsByEmployee) ||
    (reportType === AdminReportType.target_jobs && pendingTargetJobs) ||
    (reportType === AdminReportType.target_skills && pendingTargetSkills) ||
    (reportType === AdminReportType.target_jobs_by_employee && pendingTargetJobsByEmployee) ||
    (reportType === AdminReportType.target_skills_by_employee && pendingTargetSkillsByEmployee) ||
    testPending;
  const failed = (reportType === AdminReportType.user_engagement && failedUserEngagement) ||
    (reportType === AdminReportType.job_trends && failedJobTrends) ||
    (reportType === AdminReportType.mentored_skills_by_employee && failedMentoredSkillsByEmployee) ||
    (reportType === AdminReportType.target_jobs && failedTargetJobs) ||
    (reportType === AdminReportType.target_skills && failedTargetSkills) ||
    (reportType === AdminReportType.target_jobs_by_employee && failedTargetJobsByEmployee) ||
    (reportType === AdminReportType.target_skills_by_employee && failedTargetSkillsByEmployee) ||
    undefined;

  const loading = pending && !data;
  const reloading = Boolean(pending && data);

  const titles = useMemo(() =>
    // User Engagement Report
    (reportType === AdminReportType.user_engagement && [
      'admin.report.user_engagement.day',
      'admin.report.user_engagement.created',
      'admin.report.user_engagement.logged_in'
    ]) ||
    // Job Trends Report
    (reportType === AdminReportType.job_trends && [
      'admin.report.job.code',
      'admin.report.job.title',
      'admin.report.job_trends.employees',
      'admin.report.job_trends.hires',
      'admin.report.job_trends.close_match_employees'
    ]) ||
    ((
      reportType === AdminReportType.mentored_skills_by_employee ||
      reportType === AdminReportType.target_jobs_by_employee ||
      reportType === AdminReportType.target_skills_by_employee
    ) && [
      'admin.report.employee.code',
      'admin.report.employee.email',
      'admin.report.employee.first_name',
      'admin.report.employee.last_name',
      'admin.report.employee.is_contractor',
      'admin.report.count'
    ]) ||
    (reportType === AdminReportType.target_jobs && [
      'admin.report.job.code',
      'admin.report.job.title',
      'admin.report.count'
    ]) ||
    (reportType === AdminReportType.target_skills && [
      'admin.report.skill.title',
      'admin.report.count'
    ]) ||
    // Unknown Report
    ['json'], [reportType]);

  const rows = useMemo(() => [
    // eslint-disable-next-line complexity
    ...map(data, (record) => ({
      selected: false,
      values:

      // User Engagement Report
      (reportType === AdminReportType.user_engagement && [
        /* eslint-disable react/jsx-key */
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FormattedDate value={getJsDateFromISO((record as ARUserEngagement).day as string)} {...uniformDateOptions}/>,
        <FormattedNumber value={toSafeInteger((record as ARUserEngagement).created)}/>,
        <FormattedNumber value={toSafeInteger((record as ARUserEngagement).logged_in)}/>
        /* eslint-enable react/jsx-key */
      ]) ||

      // Job Trends Report
      (reportType === AdminReportType.job_trends && [
        (record as ARJobTrends).job_code || '—',
        (record as ARJobTrends).job_title || '—',
        /* eslint-disable react/jsx-key */
        <FormattedNumber value={toSafeInteger((record as ARJobTrends).employees)}/>,
        <FormattedNumber value={toSafeInteger((record as ARJobTrends).hires)}/>,
        <FormattedNumber value={toSafeInteger((record as ARJobTrends).close_match_employees)}/>
        /* eslint-enable react/jsx-key */
      ]) ||

      // Mentored Skills by Employee Report
      // Target Jobs by Employee Report
      // Target Skills by Employee Report
      ((
        reportType === AdminReportType.mentored_skills_by_employee ||
        reportType === AdminReportType.target_jobs_by_employee ||
        reportType === AdminReportType.target_skills_by_employee
      ) && [
        (record as AREmployee).code || '—',
        (record as AREmployee).email || '—',
        (record as AREmployee).first_name || '—',
        (record as AREmployee).last_name || '—',
        (record as AREmployee).is_contractor ? <CheckIcon/> : '—',
        // eslint-disable-next-line react/jsx-key
        <FormattedNumber value={toSafeInteger((record as AREmployee).count)}/>
      ]) ||
      // Target Jobs Report
      (reportType === AdminReportType.target_jobs && [
        (record as ARTargetJobs).code || '—',
        (record as ARTargetJobs).title || '—',
        // eslint-disable-next-line react/jsx-key
        <FormattedNumber value={toSafeInteger((record as ARTargetJobs).count)}/>
      ]) ||
      // Target Skills Report
      (reportType === AdminReportType.target_skills && [
        (record as ARTargetSkills).title || '—',
        // eslint-disable-next-line react/jsx-key
        <FormattedNumber value={toSafeInteger((record as ARTargetSkills).count)}/>
      ]) ||

      // Unknown Report
      [JSON.stringify(record, null)]
    })),
    ...reportType === AdminReportType.user_engagement && size(data) >= 1 ? [{
      selected: true,
      values: [
        /* eslint-disable react/jsx-key */
        <Box fontWeight={fontWeightBold} textTransform="uppercase">
          <FormattedMessage id="admin.report.user_engagement.total"/>
        </Box>,
        <Box fontWeight={fontWeightBold}>
          <FormattedNumber value={toSafeInteger(dataUserEngagement?.arUserEngagement?.total_created)}/>
        </Box>,
        <Box fontWeight={fontWeightBold}>
          <FormattedNumber value={toSafeInteger(dataUserEngagement?.arUserEngagement?.total_logged_in)}/>
        </Box>
        /* eslint-enable react/jsx-key */
      ]
    }] : []
  ], [data, dataUserEngagement, reportType]);

  const pagination = (
    <PaginationControls
        settingsId={settingsId}
        loaded={Boolean(data)}
        pending={pending}
        loading={loading}
        total={count}
        currentPage={currentPage}
        pageSizes={ADMIN_TABLE_PAGE_SIZES}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={pending || failed}
        totalMessage="admin.report.pagination"
    />
  );
  const withPagination = hasPagination(pagination);

  useEffect(() => {
    if (reportId && pageSize) {
      const variables:
        ARUserEngagementQueryVariables |
        ARJobTrendsQueryVariables |
        ARMentoredSkillsByEmployeeQueryVariables |
        ARTargetJobsQueryVariables |
        ARTargetSkillsQueryVariables |
        ARTargetJobsByEmployeeQueryVariables |
        ARTargetSkillsByEmployeeQueryVariables
      = {
        report_id: reportId,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      (
        (reportType === AdminReportType.user_engagement && getUserEngagement) ||
        (reportType === AdminReportType.job_trends && getJobTrends) ||
        (reportType === AdminReportType.mentored_skills_by_employee && getMentoredSkillsByEmployee) ||
        (reportType === AdminReportType.target_jobs && getTargetJobs) ||
        (reportType === AdminReportType.target_skills && getTargetSkills) ||
        (reportType === AdminReportType.target_jobs_by_employee && getTargetJobsByEmployee) ||
        (reportType === AdminReportType.target_skills_by_employee && getTargetSkillsByEmployee) ||
        undefined
      )?.({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `prevVars` changes:
    currentPage, pageSize, reportId, reportType,
    getUserEngagement, getJobTrends, getMentoredSkillsByEmployee, getTargetJobs,
    getTargetSkills, getTargetJobsByEmployee, getTargetSkillsByEmployee
  ]);

  const content = (
    <>
      <CardSection>
        <DataTable
            tableSize="medium"
            titles={titles}
            empty="admin.report.empty"
            data={rows}
            pending={loading}
            failed={failed}
            lastLeftAlignedTitle={
              ((
                reportType === AdminReportType.job_trends ||
                reportType === AdminReportType.target_jobs
              ) && 1) ||
              ((
                reportType === AdminReportType.mentored_skills_by_employee ||
                reportType === AdminReportType.target_jobs_by_employee ||
                reportType === AdminReportType.target_skills_by_employee
              ) && 3) || 0
            }
        />
      </CardSection>
      {withPagination ? (
        <CardSection flex bottom>
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );

  return reloading ? (
    <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        position="relative"
    >
      {content}
      <Box className={overlayDefault}>
        <LinearProgress/>
      </Box>
    </Box>
  ) : content;
};

AdminReport.propTypes = AdminReportPropTypes;

export default memo(AdminReport);
