import { gql } from '@apollo/client';
// local imports
import { API_ORG_HIERARCHY } from '../config/api';

export const HIERARCHY_INTERNAL_QUERY = gql`
  query HierarchyInternal {
    hierarchyInternal
    @rest(
      type: "[HierarchyManager!]",
      path: "${API_ORG_HIERARCHY}"
    ) {
      code
      full_name
      level
      org_id
      org_title
    }
  }
`;
