/* eslint-disable max-lines */
/* eslint-disable max-len */
import { grey } from '@mui/material/colors';
import { duration, easing } from '@mui/material/styles';
import { alpha, lighten, emphasize } from '@mui/system/colorManipulator';

const palette = {
  common: {
    black: '#000',
    white: '#fff'
  },

  // MATERIAL-UI COLORS:
  // Standard variants are: `main`, `light`, `dark`, `contrastText`;
  // everything else is our custom color variant.

  // Main Color
  primary: {
    // H=205º
    lightest: '#95d2ff', // ✅ Employee Dashboard Skill Level #1, Manager Dashboard Skill Level #1
    lighter: '#59b9ff', // ✅ current Job circle; Employees with Skill Level #1
    light4: '#00aaf3', // ✅ Explore Other Resources gradient bg start (top)
    light2: '#2ea1f8', // ✅ Profile Builder & Quick Tour header bg gradient end (top)
    light3: '#259af2', // ✅ Quick Tour header bg gradient start (bottom)
    light1: '#1990ea', // ✅ Profile Builder bg gradient start (bottom), Quick Tour tab border
    light: '#2aa5ff', // ✅ Manager Dashboard Skill Level #2, Employees with Skill Level #2; Skill Trend icon bg; Circle Progress flat color; Target & (i) icon circle; Job dots & flat arrows
    main: '#0294ff', // ✅ Job Dot & Rect gradient (start), Skill Target icon; Employee Dashboard Skill Level #2
    dark: '#0282e0', // ✅ Employees with Skill Level #3
    dark1: '#0093ff', // ✅ Welcome Screen bg gradient start (top left)
    shifted: '#4a66f8', // ✅ Job Dot gradient (end)
    shifted2: '#5e5af7', // ✅ Job Rect gradient (end)
    // text
    contrastText: '#fff'
  },

  secondary: {
    // H~210º
    light: '#0a98ff', // 🗹 table header sort buttons;
    main: '#1880e7', // ✅ Manager Dashboard Skill Level #3; Links, Active Skill Card bg
    icon: '#0081f6', // ✅ Skill Remove button; Top Ticker gradient (start)
    text: '#0073e4', // ✅ Employee Dashboard Skill Level #3, Skill Card text color, Explore Other Resources gradient bg end (bottom)
    dark0: '#006be1', // ✅ Match Rate indicator bg (gradient bottom/start); Proficiency meets requirements gradient (end)
    hover: '#0d65bd', // ✅ derived color = 96% brightness of main
    darker: '#104b94', // ✅ Employee Dashboard Skill Level #4
    dark: '#05438f', // ✅ Employees with Skill Level #4
    dark2: '#0059c4', // ✅ Manager Dashboard Skill Level #4, Active + Selected Skill Chip bg
    dark1: '#002175', // ✅ Welcome Screen bg gradient end (bottom right)
    shifted: '#383a7e', // ✅ Top Ticker gradient (end)
    // text
    contrastText: '#fff'
  },

  // H=296.5º
  info: {
    lightest: '#d15ad1', // ✅ Vote icon
    lighter: '#b58db7', // ✅ Course Card Borders
    light: '#b15ab9', // ✅ Your Current Role, Course Circles
    main: '#b500b5', // ✅ Match Rate indicator bg ("empty" section), New! skill text
    text: '#816782', // ✅ Caption text on Manager|HR|Leader|Admin Dashboard Filters
    caption: '#8478a5', // ✅ Talent Finder - caption text
    dark: '#7c437f',
    contrastText: '#fff'
  },

  // Jobs
  success: {
    // H~190º
    light1: '#00cff9', // ✅ Match Rate indicator bg (gradient top/end); Proficiency meets requirements gradient (start)
    light: '#13d9ff', // ✅ job arrow gradient (start), Total Employees gradient (top/start)
    main: '#12d6ff',
    alt: '#009ded', // ✅ Match Rate indicator bg (default/gradient middle)
    // H=207º
    dark: '#048dff', // ✅ job arrow gradient (end), Total Employees gradient (bottom/end)
    // text
    contrastText: '#fff'
  },

  // –
  warning: {
    // H=339º
    light: '#a89dc6', // ✅ Selected Skill Chip Remove button
    // H=~265º
    main: '#8b5ad8', // –
    dark: '#62198b', // –
    // text
    contrastText: '#fff'
  },

  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f'
  },

  // Manager Dashboard
  chart: {
    grid: '#f0f0f0', // ✅ Top In-Demand Skills chart grid color
    category: 'rgba(0, 0, 0, 0.8)', // ~#333333 ✅ Manager Dashboard category labels
    series0: '#00c480', // ✅ Dashboard Analytics - Close Match Jobs / Jobs Series #1
    series1: '#8dde00', // ✅ Manager Dashboard Top Skill/Job #1
    series2: '#3defdc', // ✅ Manager Dashboard Top Skill/Job #2
    series3: '#0073e4', // ✅ Manager Dashboard Top Skill/Job #3 === secondary.text
    series4: '#2aa5ff', // ✅ Manager Dashboard Top Skill/Job #4 === primary.light
    series5: '#b11cbd', // ✅ Manager Dashboard Top Skill/Job #5
    series6: '#ff54b7', // ✅ Manager Dashboard Top Skill/Job #6
    series1s: '#a677ff', // ✅ Talent Finder - 0 desired skills sector bg gradient start
    series1e: '#dd77ff', // ✅ Talent Finder - 0 desired skills sector bg gradient end
    series2s: '#00b5f3', // ✅ Talent Finder - 1 desired skill sector bg gradient start
    series2e: '#0082e7', // ✅ Talent Finder - 1 desired skill sector bg gradient end
    series3s: '#16ecb1', // ✅ Talent Finder - 2+ desired skills sector bg gradient start
    series3e: '#06c787', // ✅ Talent Finder - 2+ desired skills sector bg gradient end
    series1level1: '#97feff', // ✅ Dashboard Analytics - Series 1, Level 1
    series1level2: '#2ae3e6', // ✅ Dashboard Analytics - Series 1, Level 2
    series1level3: '#00c1c3', // ✅ Dashboard Analytics - Series 1, Level 3
    series1level4: '#009ea0', // ✅ Dashboard Analytics - Series 1, Level 4
    series2level1: '#9ce8a2', // ✅ Dashboard Analytics - Series 2, Level 1
    series2level2: '#3dd57f', // ✅ Dashboard Analytics - Series 2, Level 2
    series2level3: '#07b568', // ✅ Dashboard Analytics - Series 2, Level 3
    series2level4: '#00a55c', // ✅ Dashboard Analytics - Series 2, Level 4
    series3level1: '#fff0b2', // ✅ Dashboard Analytics - Series 3, Level 1
    series3level2: '#f9db61', // ✅ Dashboard Analytics - Series 3, Level 2
    series3level3: '#ffbb22', // ✅ Dashboard Analytics - Series 3, Level 3
    series3level4: '#efa500', // ✅ Dashboard Analytics - Series 3, Level 4
    series4level1: '#ffbe9d', // ✅ Dashboard Analytics - Series 4, Level 1
    series4level2: '#f98850', // ✅ Dashboard Analytics - Series 4, Level 2
    series4level3: '#e35712', // ✅ Dashboard Analytics - Series 4, Level 3
    series4level4: '#ca4605', // ✅ Dashboard Analytics - Series 4, Level 4
    series5level1: '#e9c9eb', // ✅ Dashboard Analytics - Series 5, Level 1
    series5level2: '#b27cb5', // ✅ Dashboard Analytics - Series 5, Level 2
    series5level3: '#985e9b', // ✅ Dashboard Analytics - Series 5, Level 3
    series5level4: '#7c437f', // ✅ Dashboard Analytics - Series 5, Level 4
    series6level1: '#95d2ff', // ✅ Dashboard Analytics - Series 6, Level 1
    series6level2: '#2aa5ff', // ✅ Dashboard Analytics - Series 6, Level 2
    series6level3: '#1880e7', // ✅ Dashboard Analytics - Series 6, Level 3
    series6level4: '#0059c4', // ✅ Dashboard Analytics - Series 6, Level 4
    radarSeries1: '#e8aae8', // ✅ Dashboard Analytics - Radar Series 1 (~Series 5 ~info)
    radarSeries2: '#6bc0ff', // ✅ Dashboard Analytics - Radar Series 2 (~Series 6 ~primary)
    radarSeries3: '#72d4ec', // ✅ Dashboard Analytics - Radar Series 3 (— ~success)
    radarSeries4: '#92dd9d', // ✅ Dashboard Analytics - Radar Series 4 (~ Series 2)
    barGradientTop: '#007be5', // ✅ Dashboard Analytics - Confirmed Job Related Skills / Targeting Job
    barGradientBottom: '#00cff9', // ✅ Dashboard Analytics - Confirmed Job Related Skills / Targeting Job
    infoBarBorder: '#bcc6e3', // ✅ Dashboard Analytics - Open Reqs that require the Skill
    infoBarHighlight: '#ffbd00', // ✅ Dashboard Analytics - Open Reqs that require the Skill
    gridLight: '#f0f4ff', // ✅ Dashboard Analytics
    gridNormal: '#e0e8ff', // ✅ Dashboard Analytics
    gridColored: '#d3cfdf', // ✅ Dashboard Analytics
    gridGrey: '#dadada', // ✅ Dashboard Analytics
    gridLightGrey: '#e9e9e9', // ✅ Dashboard Analytics
    gridRadar: '#bfc9e4', // ✅ Dashboard Analytics
    gridBars: '#dee2f0', // ✅ Dashboard Analytics
    // Skill Growth & HR Dashboard Timeline Chart
    primaryDot: '#0f87f0', // ✅
    primaryLucid: 'rgba(2, 148, 255, 0.38)', // ✅ derived from '#0294ff' (primary color) at Disabled opacity
    infoDot: '#b964db', // ✅
    infoLucid: 'rgba(185, 100, 219, 0.38)', // ✅ derived from '#bc68f2' color (~info) at Disabled opacity
    // Skills Taxonomy Manager
    precision: '#2196f3',
    f1: '#673ab7',
    recall: '#9c27b0',
    taxonomyLevel1: '#8a80d2',
    taxonomyLevel4: '#15c2c4'
  },

  misc: {
    searchIcon: '#b0b1dd', // ✅ Search icon in Search/Dropdown (Top Menu, Search and add skill)
    dropdownBorder: '#b5b5e5', // ✅ Select/Dropdown choices popup border
    checkBoxBorder: '#cbd6f8', // ✅ selectable Skill Card checkbox border
    selectBorder: '#cdd5ed', // ✅ Select choices dropdown border
    selectedBorder: '#bbc5e2', // ✅ Selected Skill Chip border
    draggedBorder: '#c3cff0', // ✅ Dragged Skill Chip border
    starRating: '#faba0c', // ✅ Star Rating color
    starInactive: '#d8d8d8', // ✅ Star Rating inactive color
    tooltipBorder: '#dbe1f4', // ✅ Tooltip (hover popup) border
    stepFinished: '#13cb3a', // ✅ Profile Builder finished step icon color
    tourFooterBorder: '#a1abc7', // ✅ Quick Tour footer top border color
    filterDot: '#a0accf', // ✅ Dot between Org and CoC filters on Manager Dashboard
    sliderPrev: '#13c296', // ✅ Slider Prev Button icon color
    skillConfirmed: '#00b01b', // ✅ confirmed Course Skill color
    skillRejected: '#fa2256', // ✅ rejected Course Skill color
    skillSuggested: '#ffbf00', // ✅ suggested Course Skill color
    coursesLegend: '#61667f', // ✅ Courses Legend text color
    searchGroup: '#985e9b', // ✅ Search Jobs and Skills Dropdown Group Label color
    tagCourse: '#a291de', // ✅ Course Tag bg
    // tagSkill: '#59b9ff', // ✅ Skill Tag bg === primary.lighter
    // tagInactive: '#bbc5e2' // ✅ Active Tag bg === misc.selectedBorder
    tagActive: '#00c480', // ✅ Active Tag bg
    matchRateStart: '#1cfac1', // ✅ DevPlan Match Rate indicator gradient (bottom-right)
    matchRateEnd: '#03bf7e' // ✅ DevPlan Match Rate indicator gradient (top-left)
  },

  greys: {
    iconBg: '#f2f2f2', // ✅ User Icon bg
    barBackground: '#eeeeee', // ✅ Scroll Bar bg, Proficiency bar bg (empty)
    switchItemBorder: '#e8e8e8', // ✅ View Style Switch inactive item border
    matchRate: '#e4e4e4', // ✅ inactive part of Match Rate slider
    courseCircleBg: '#e1e1e1', // ✅ empty sector of the circle of "People in your job took this course"
    delimiter: '#dcdcdc', // ✅ Skill Level Dialog horizontal delimiter
    border: '#d3d3d3', // ✅ inactive Target icon
    switch: '#a7a7a7', // ✅ inactive On/Off & View Style Switch
    verticalDelimiter: '#9b9b9b', // ✅ vertical delimiter on Card Info panel
    barBorder: '#979797', // ✅ Proficiency empty bar border (only if not meets requirements!)
    popupBorder: '#929292', // ✅ Talent Finder - total, Tooltip (e.g. Proficiency Level Popup, Moment Rate Tooltip) border
    gapLegendArrow: '#8f8f8f' // ✅ Skill Gap ledend arrow color
  },

  // the default theme values, added here only to make them available in `overrides` section below
  action: {
    active: '#0294ff', // ✅ === primary.main
    hover: 'rgba(32, 76, 190, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(32, 76, 190, 0.08)', // ✅ results in #eef1fa on white bg
    selectedOpacity: 0.08,
    disabled: 'rgba(32, 76, 190, 0.26)',
    disabledBackground: 'rgba(32, 76, 190, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(32, 76, 190, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
    tooltipOpacity: 0.75
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)', // ~#212121 ✅
    label: 'rgba(0, 0, 0, 0.69)', // ~#505050 ✅ Circle Progress text, On/Off Switch text, Match Rate text & labels, Search/Dropdown text
    secondary: 'rgba(0, 0, 0, 0.56)', // ✅ including Select/Dropdown active text in Top Menu
    disabled: 'rgba(0, 0, 0, 0.35)' // ~#a7a7a7 ✅ dates of Notifications, Table Header text
  },
  background: {
    paper: '#fff', // ✅ Card bg
    button: '#fafbfc', // ✅  inactive Button (in group) bg: Yes/No Switch, Scope selector(?)
    dragIcon: 'rgba(0, 68, 255, 0.04)', // ~#f4f7ff ✅ Drag Icon bg
    tableHeader: '#f7f9ff', // ✅  Table Header bg
    default: '#f6f7fb', // ✅ including Select/Dropdown highlighted item bg
    dashboard: '#e8ebf6', // ✅ Dashboard radial gradient bg end (sides)
    dot: '#d0d4e1', // ✅ Slider dot color
    backdrop: 'rgba(0, 0, 32, 0.37)', // ✅ Dialog popup backdrop (shadow)
    switch: '#edeff9', // ✅ active [? On/Off &] Style Switch bg
    switchHover: '#e3e4f1', // ✅ derived from `switch` background with brightness -4%; Small Level Gauge gradient (start)
    gaugeRight: '#b1a7cc', // ✅ Small Level Gauge gradient (end)
    card: '#eef1fa', // ✅ Item Card bg, Select/Dropdown bg, Card Info bg, darker Card section bg, Table Highlighted Row bg; "shady" bg (gradient end)
    card80: 'rgba(238, 241, 250, 0.8)', // ✅ derived from Item Card bg at 80% opacity
    shady: '#f7f9fd', // ✅ "shady" bg (gradient start)
    chipLeft: '#fbfeff', // ✅ Selected Skill Chip bg (gradient start)
    chipRight: '#f1f3fb', // ✅ Selected Skill Chip bg (gradient end)
    primaryHover: 'rgba(2, 148, 255, 0.04)', // ✅ derived from Pimary Main color at Hover opacity
    closeIcon: '#9ea4c0', // ✅ Proficiency Level & Add Skill Dialog close button bg
    dropIconBg: '#b5c5ee', // ✅ Skills View drop area Plus icon bg
    darker: '#d7dff4', // ✅ Skill Drop area bg
    stepActive: '#e0e5f5', // ✅ Profile Builder active step header bg
    stepHeader: '#f5f7fc', // ✅ Profile Builder step header bg
    tourHeader: '#273142', // ✅ Quick Tour header bg
    tourBody: '#222c3c', // ✅ Quick Tour body bg
    tooltip: 'rgba(255, 255, 255, 0.96)', // ✅ Dashboard Analytics - paper color minus hover opacity
    tooltipMedium: 'rgba(255, 255, 255, 0.92)', // ✅ Dashboard Analytics - paper color minus selected opacity
    tooltipLight: 'rgba(255, 255, 255, 0.75)' // ✅ Dashboard Analytics - paper color with tooltip opacity
  },

  // delimiter color
  delimiterColor: 'rgba(0, 0, 0, 0.08)', // ~#eaeaea ✅ Card Header, Style Switch, List Items & Table Rows delimiter (e.g. Notifications ~#e6eaee)
  // default border color
  borderColor: 'rgba(0, 0, 0, 0.17)', // ~#d3d3d3 ✅ inactive Tab Underline, inactive Button (in group) border
  // shadow color
  shadowColor: 'rgba(0, 32, 160, 0.06)', // ✅ Shadows color
  singleShadowColor: 'rgba(0, 32, 160, 0.2)' // ✅ for ECharts where we can only appy one shadow
};

export const spacing = 8;

export const shape = {
  borderWidth: 1.5,
  thinBorderWidth: 1,
  mediumBorderWidth: 2,
  thickBorderWidth: 3,
  borderRadius: 5,
  mediumBorderRadius: 4,
  smallBorderRadius: 3,
  tinyBorderRadius: 1.5,
  cardPadding: '1.5rem', // must be (1.5 x base padding)
  cardSpacing: '1rem', // must be (1 x base padding)
  cardVSpacing: '0.8rem', // must be close to (0.8 x base padding)
  sliderThumbSize: '1.05rem',
  sliderThumbMargin: '-0.333rem',
  tourScreenshotWidth: 1212,
  tourScreenshotHeight: 522
};

// shadow colors
const shadow = [palette.shadowColor, palette.shadowColor, palette.shadowColor];

const shadows = [
  'none',
  // 1: `0px 2px 1px -1px ${shadow[0]},0px 1px 1px 0px ${shadow[1]},0px 1px 3px 0px ${shadow[2]}`,
                                      `1px 1px 1px 0px ${shadow[1]},0px 0px 4px 0px ${shadow[2]}`,
  // 2: `0px 3px 1px -2px ${shadow[0]},0px 2px 2px 0px ${shadow[1]},0px 1px 5px 0px ${shadow[2]}`,
        `1px 1px 1px -2px ${shadow[0]},2px 2px 2px 0px ${shadow[1]},0px 0px 5px 0px ${shadow[2]}`,
  // 3: `0px 3px 3px -2px ${shadow[0]},0px 3px 4px 0px ${shadow[1]},0px 1px 8px 0px ${shadow[2]}`,
        `2px 2px 3px -2px ${shadow[0]},3px 3px 4px 0px ${shadow[1]},0px 0px 8px 0px ${shadow[2]}`,
  `2px 2px 4px -1px ${shadow[0]},4px 4px 5px 0px ${shadow[1]},0px 0px 10px 0px ${shadow[2]}`,
  `3px 3px 5px -1px ${shadow[0]},5px 5px 8px 0px ${shadow[1]},0px 0px 14px 0px ${shadow[2]}`,
  `3px 3px 5px -1px ${shadow[0]},6px 6px 10px 0px ${shadow[1]},0px 0px 18px 0px ${shadow[2]}`,
  `4px 4px 5px -2px ${shadow[0]},7px 7px 10px 1px ${shadow[1]},0px 0px 16px 1px ${shadow[2]}`,
  `5px 5px 5px -3px ${shadow[0]},8px 8px 10px 1px ${shadow[1]},0px 0px 14px 2px ${shadow[2]}`,
  `5px 5px 6px -3px ${shadow[0]},9px 9px 12px 1px ${shadow[1]},0px 0px 16px 2px ${shadow[2]}`,
  `6px 6px 6px -3px ${shadow[0]},10px 10px 14px 1px ${shadow[1]},0px 0px 18px 3px ${shadow[2]}`,
  `6px 6px 7px -4px ${shadow[0]},11px 11px 15px 1px ${shadow[1]},0px 0px 20px 3px ${shadow[2]}`,
  `7px 7px 8px -4px ${shadow[0]},12px 12px 17px 2px ${shadow[1]},0px 0px 22px 4px ${shadow[2]}`,
  `7px 7px 8px -4px ${shadow[0]},13px 13px 19px 2px ${shadow[1]},0px 0px 24px 4px ${shadow[2]}`,
  `7px 7px 9px -4px ${shadow[0]},14px 14px 21px 2px ${shadow[1]},0px 0px 26px 4px ${shadow[2]}`,
  `8px 8px 9px -5px ${shadow[0]},15px 15px 22px 2px ${shadow[1]},0px 0px 28px 5px ${shadow[2]}`,
  `8px 8px 10px -5px ${shadow[0]},16px 16px 24px 2px ${shadow[1]},0px 0px 30px 5px ${shadow[2]}`,
  `8px 8px 11px -5px ${shadow[0]},17px 17px 26px 2px ${shadow[1]},0px 0px 32px 5px ${shadow[2]}`,
  `9px 9px 11px -5px ${shadow[0]},18px 18px 28px 2px ${shadow[1]},0px 0px 34px 6px ${shadow[2]}`,
  `9px 9px 12px -6px ${shadow[0]},19px 19px 29px 2px ${shadow[1]},0px 0px 36px 6px ${shadow[2]}`,
  `10px 10px 13px -6px ${shadow[0]},20px 20px 31px 3px ${shadow[1]},0px 0px 38px 7px ${shadow[2]}`,
  `10px 10px 13px -6px ${shadow[0]},21px 21px 33px 3px ${shadow[1]},0px 0px 40px 7px ${shadow[2]}`,
  `10px 10px 14px -6px ${shadow[0]},22px 22px 35px 3px ${shadow[1]},0px 0px 42px 7px ${shadow[2]}`,
  `11px 11px 14px -7px ${shadow[0]},23px 23px 36px 3px ${shadow[1]},0px 0px 44px 8px ${shadow[2]}`,
  `11px 11px 15px -7px ${shadow[0]},24px 24px 38px 3px ${shadow[1]},0px 0px 46px 8px ${shadow[2]}`
];

export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 700;

const typography = {
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,

  // Breadcrumbs (main item)
  h1: {
    fontWeight: fontWeightMedium,
    fontSize: '1.5rem',
    lineHeight: 1.33,
    letterSpacing: '0.0075em'
  },
  // Section Headers
  h2: {
    fontWeight: fontWeightMedium,
    fontSize: '1.75rem',
    lineHeight: 1.25,
    letterSpacing: '0.0075em'
  },
  // Card Headers
  h3: {
    fontWeight: fontWeightMedium,
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0.0075em'
  },
  // Titles
  h4: {
    fontWeight: fontWeightMedium,
    fontSize: '1.15rem',
    lineHeight: 1.5,
    letterSpacing: '0.0095em'
  },
  // Block Headers (Bold `body1`)
  h5: {
    fontWeight: fontWeightBold,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  // Block Subheaders (Bold `body2`)
  h6: {
    fontWeight: fontWeightBold,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.00714em'
  },
  // Card Subheaders
  subtitle1: {
    fontWeight: fontWeightMedium,
    fontSize: '1.25rem',
    lineHeight: 1.25,
    letterSpacing: '0.0075em'
  },
  // Section Subheaders
  subtitle2: {
    fontWeight: fontWeightRegular,
    fontSize: '1.05rem',
    lineHeight: 1.15,
    letterSpacing: '0.01em'
  },
  body1: {
    fontWeight: fontWeightRegular,
    fontSize: '1rem',
    lineHeight: 1.43,
    letterSpacing: '0.00938em'
  },
  body2: {
    fontWeight: fontWeightRegular,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  },
  // <Button size="medium" variant="*"> (default button size)
  button: {
    fontSize: '1.1rem',
    fontWeight: fontWeightMedium,
    lineHeight: 1.33,
    letterSpacing: '0.015em',
    textTransform: 'none'
  },
  caption: {
    fontSize: '0.825rem',
    letterSpacing: 0
  },
  // keeping default font size:
  overline: {
    fontSize: '0.75rem'
  }
};

export const themeOptions = {
  palette,
  spacing,
  shape,
  mixins: {
    toolbar: {
      minHeight: '4.5rem',
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: '4.33rem'
      },
      '@media (min-width:600px)': {
        minHeight: '5rem'
      }
    }
  },
  shadows,
  typography,
  breakpoints: {
    values: {
      // TODO: use MUI v5 default breakpoints
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  components: {
    MuiButton: {
      // TODO: custom Button varants & colors: `variants: [],`
      styleOverrides: {
        root: {
          forcedColorAdjust: 'none',
          textAlign: 'center'
        },
        textPrimary: {
          color: palette.secondary.text,
          '&:hover': {
            backgroundColor: alpha(palette.secondary.text, palette.action.hoverOpacity)
          }
        },
        outlined: {
          background: palette.background.button
        },
        outlinedInfo: {
          color: palette.secondary.text,
          background: palette.background.button,
          border: `${shape.thinBorderWidth}px solid ${palette.borderColor}`,
          '&:hover': {
            border: `${shape.thinBorderWidth}px solid ${palette.primary.main}`,
            backgroundColor: palette.background.primaryHover
          },
          '&.Mui-disabled': {
            border: `${shape.thinBorderWidth}px solid ${palette.action.disabledBackground}`
          }
        },
        outlinedPrimary: {
          color: palette.secondary.text,
          backgroundColor: 'transparent',
          border: `${shape.mediumBorderWidth}px solid ${palette.primary.main}`,
          '&:hover': {
            border: `${shape.mediumBorderWidth}px solid ${palette.primary.main}`,
            backgroundColor: palette.background.primaryHover
          },
          '&.Mui-disabled': {
            border: `${shape.mediumBorderWidth}px solid ${palette.action.disabledBackground}`
          }
        },
        outlinedSecondary: {
          border: `${shape.thinBorderWidth}px solid ${palette.borderColor}`,
          '&.Mui-disabled': {
            border: `${shape.thinBorderWidth}px solid ${palette.action.disabledBackground}`
          }
        },
        // <Button size="small" variant="contained">
        containedSizeSmall: {
          // padding: '4px 10px',
          minWidth: '8rem',
          fontSize: typography.body1.fontSize
        },
        // <Button size="medium" variant="contained">
        // root: {
        //   padding: '6px 16px'
        // },
        // <Button size="large" variant="contained">
        containedSizeLarge: {
          // padding: '4px 22px',
          fontSize: '1.2rem'
        },
        // <Button size="small" variant="outlined">
        outlinedSizeSmall: {
          // padding: '3px 9px',
          fontSize: typography.body1.fontSize
        },
        // <Button size="medium" variant="outlined">
        // outlined: {
        //   padding: '5px 15px'
        // },
        // <Button size="large" variant="outlined">
        outlinedSizeLarge: {
          padding: '8px 18px', // default: '7px 21px'
          fontSize: typography.subtitle1.fontSize
        },
        // <Button size="large" variant="outlined">
        textSizeSmall: {
          // padding: '4px 5px',
          fontSize: typography.body1.fontSize
        },
        // <Button size="medium" variant="text">
        // text: {
        //   padding: '6px 8px'
        // },
        textSizeLarge: {
          // padding: '8px 11px',
          fontSize: typography.subtitle1.fontSize
        },
        colorInherit: {
          borderColor: palette.greys.switchItemBorder,
          backgroundColor: 'inherit'
        },
        containedInfo: {
          color: palette.primary.main,
          backgroundColor: palette.background.switch,
          boxShadow: shadows[3],
          '&:hover': {
            backgroundColor: palette.background.switchHover,
            boxShadow: shadows[3],
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              boxShadow: shadows[3]
            }
          },
          '&.Mui-focusVisible': {
            boxShadow: shadows[3]
          },
          '&:active': {
            boxShadow: shadows[3]
          },
          '&.Mui-disabled': {
            opacity: 1 - palette.action.disabledOpacity,
            backgroundColor: palette.background.switchHover,
            boxShadow: shadows[0]
          }
        },
        containedSecondary: {
          color: palette.text.label,
          backgroundColor: palette.background.stepActive,
          border: `${shape.thinBorderWidth}px solid ${palette.misc.selectedBorder}`,
          '&:hover': {
            backgroundColor: palette.background.stepActive,
            border: `${shape.thinBorderWidth}px solid ${palette.primary.main}`
          },
          '&.Mui-disabled': {
            backgroundColor: lighten(palette.background.stepActive, palette.action.disabledOpacity),
            borderColor: lighten(palette.misc.selectedBorder, palette.action.disabledOpacity)
          }
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          forcedColorAdjust: 'none'
        },
        grouped: {
          minWidth: '2.25rem'
        },
        groupedOutlinedHorizontal: {
          '&:hover + button': {
            borderLeftColor: palette.primary.main
          }
        },
        groupedOutlinedSecondary: {
          '&:hover': {
            borderColor: palette.primary.main
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: `1.6rem ${shape.cardPadding} 1.3rem ${shape.cardPadding}`
        },
        content: {
          display: 'flex',
          alignItems: 'center'
        },
        action: {
          flex: '0 1 auto',
          alignSelf: 'center',
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          paddingLeft: spacing
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: shape.cardPadding,
          '&:last-child': {
            paddingBottom: shape.cardPadding
          }
        }
      }
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          backgroundColor: palette.primary.main
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: -shape.thickBorderWidth,
          flex: '1 1 0'
        },
        indicator: {
          height: shape.thickBorderWidth,
          background: `linear-gradient(to right, ${palette.text.label}, ${palette.text.label})`,
          backgroundSize: 'calc(100% - 4rem) 100%',
          backgroundPosition: 'left bottom',
          backgroundRepeat: 'no-repeat',
          '@media (forced-colors: active)': {
            forcedColorAdjust: 'none',
            background: 'linear-gradient(to right, SelectedItemText, SelectedItemText)'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: `1.6rem 5.5rem 1rem ${shape.cardPadding}`, // right = shape.cardPadding + 4rem
          fontSize: typography.h3.fontSize,
          fontWeight: typography.h3.fontWeight,
          letterSpacing: typography.h3.letterSpacing,
          lineHeight: typography.h3.lineHeight,
          maxWidth: 'none',
          position: 'relative',
          '&:after': {
            position: 'absolute',
            top: '0.5rem',
            right: '4rem',
            height: '100%',
            left: 0,
            bottom: 0,
            content: '""',
            backgroundColor: palette.background.primaryHover,
            borderTopLeftRadius: shape.borderRadius,
            borderTopRightRadius: shape.borderRadius,
            transition: `opacity ${duration.short}ms ${easing.easeInOut}`,
            opacity: 0
          },
          '&:hover&:after': {
            opacity: 1
          },
          '&.Mui-selected&:hover&:after': {
            opacity: 0
          }
        },
        textColorSecondary: {
          color: palette.secondary.text,
          transition: `color ${duration.short}ms ${easing.easeInOut}`,
          '&:hover': {
            color: palette.secondary.main
          },
          '&.Mui-selected': {
            color: palette.text.primary,
            '&:hover': {
              color: palette.text.primary
            }
          },
          '&.Mui-disabled': {
            color: palette.text.disabled
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
            '&:before': {
              opacity: 1
            }
          },
          '&.Mui-expanded + &': {
            '&:before': {
              display: 'inherit'
            }
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 48 // default value from MuiAccordionSummary.root
          }
        },
        content: {
          fontSize: typography.body1.fontSize,
          '&.Mui-expanded': {
            margin: 'inherit'
          }
        },
        expandIcon: {
          transform: 'rotateX(0deg)',
          '&.Mui-expanded': {
            transform: 'rotateX(180deg)'
          }
        },
        expandIconWrapper: {
          padding: 1.5 * spacing,
          marginRight: -1.5 * spacing
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        textPrimary: {
          color: palette.secondary.main,
          fontSize: typography.h5.fontSize,
          fontWeight: typography.fontWeightMedium,
          letterSpacing: typography.h5.letterSpacing,
          lineHeight: typography.h5.lineHeight,
          '&.Mui-selected': {
            fontWeight: typography.h5.fontWeight
          },
          '&.Mui-disabled': {
            color: palette.text.disabled
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.light
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: palette.text.primary,
          fontSize: typography.body1.fontSize,
          fontWeight: typography.fontWeightMedium,
          '&.Mui-active': {
            color: palette.text.primary,
            fontWeight: typography.fontWeightMedium
          },
          '&.Mui-completed': {
            color: palette.text.primary,
            fontWeight: typography.fontWeightMedium
          },
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: 3 * spacing
          }
        },
        labelContainer: {
          // optional subtitle
          '*:nth-of-type(2)': {
            color: palette.info.caption,
            fontStyle: 'italic',
            // fontFamily: typography.body2.fontFamily,
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.body2.fontSize,
            lineHeight: typography.body2.lineHeight,
            letterSpacing: typography.body2.letterSpacing
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '2rem',
          height: '2rem',
          overflow: 'visible',
          '& circle': {
            fill: palette.background.paper,
            stroke: palette.misc.selectedBorder,
            strokeWidth: 1.5 * shape.borderWidth
          },
          '&.Mui-active': {
            stroke: palette.primary.main
          },
          '&.Mui-active circle': {
            fill: palette.background.card,
            stroke: palette.primary.main
          }
        },
        text: {
          fill: 'transparent',
          stroke: 'transparent'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: `calc(1rem - ${0.75 * shape.borderWidth}px) !important`,
          '&.Mui-active': {
            '& .MuiStepConnector-line': {
              borderColor: palette.primary.main
            }
          },
          '&.Mui-completed': {
            '& .MuiStepConnector-line': {
              borderColor: palette.primary.main
            }
          }
        },
        alternativeLabel: {
          top: (1.5 * spacing) - shape.borderWidth,
          left: 'calc(-50% + 1rem)',
          right: 'calc(50% + 1rem)'
        },
        lineHorizontal: {
          borderColor: palette.misc.selectedBorder,
          borderTopWidth: shape.thickBorderWidth
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: palette.info.main
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.dropIconBg,
          fontSize: typography.subtitle2.fontSize,
          fontWeight: typography.fontWeightMedium,
          letterSpacing: typography.subtitle2.letterSpacing,
          minHeight: '2.5rem',
          height: 'auto',
          borderRadius: shape.mediumBorderRadius
        },
        sizeSmall: {
          minHeight: '2.5rem',
          height: 'auto'
        },
        iconSmall: {
          width: null, // TODO: change `null` to appropriate default value
          height: null // TODO: change `null` to appropriate default value
        },
        labelSmall: {
          paddingLeft: '2.2rem',
          paddingRight: 1.5 * spacing
        },
        deletable: {
          '.MuiChip-outlined:focus': {
            backgroundColor: null // TODO: change `null` to appropriate default value
          }
        },
        deleteIconSmall: {
          width: 2.5 * spacing,
          height: 2.5 * spacing
        },
        colorPrimary: {
          backgroundColor: palette.secondary.dark2,
          color: palette.secondary.contrastText,
          paddingRight: spacing,
          border: `${shape.thinBorderWidth}px solid transparent`,
          boxShadow: shadows[5],
          '& .MuiChip-label': {
            paddingLeft: 2.5 * spacing,
            paddingRight: 2 * spacing
          }
        },
        clickableColorPrimary: {
          '&:hover, &:focus': {
            backgroundColor: emphasize(palette.secondary.dark2, palette.action.selectedOpacity)
          }
        },
        iconColorSecondary: {
          color: 'inherit'
        },
        outlined: {
          color: palette.secondary.main,
          backgroundColor: palette.background.paper,
          border: 'none',
          boxShadow: shadows[8],
          paddingLeft: 1.5 * spacing,
          paddingRight: spacing,
          '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
            textDecoration: 'underline'
          },
          '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus, .MuiChip-deletable&:focus': {
            backgroundColor: 'inherit'
          },
          '& .MuiChip-label': {
            paddingLeft: 1.75 * spacing
          },
          '& .MuiChip-icon, & .MuiChip-iconSmall': {
            marginLeft: 0
          },
          '& .MuiChip-deleteIcon, & .MuiChip-deleteIconSmall': {
            marginLeft: 0
          }
        },
        outlinedPrimary: {
          color: palette.secondary.main,
          background: `linear-gradient(90deg, ${
            palette.background.chipLeft} 0%, ${
            palette.background.chipRight} 100%) ${
            palette.background.chipRight}`,
          border: `${shape.thinBorderWidth}px solid ${palette.misc.selectedBorder}`,
          boxShadow: shadows[5],
          paddingLeft: 0,
          '& .MuiChip-label': {
            paddingLeft: 2.5 * spacing,
            paddingRight: 2 * spacing
          },
          '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus, .MuiChip-deletable&:focus': {
            textDecoration: 'none',
            backgroundColor: 'inherit'
          },
          // identical for `outlinedPrimary` and `outlinedSecondary`:
          '& .MuiChip-icon, & .MuiChip-iconSmall': {
            alignSelf: 'stretch',
            height: 'auto',
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: palette.background.dragIcon
          }
        },
        outlinedSecondary: {
          backgroundColor: palette.background.paper,
          border: 'none',
          borderRadius: shape.borderRadius,
          boxShadow: shadows[5],
          paddingLeft: 0,
          '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus, .MuiChip-deletable&:focus': {
            textDecoration: 'none',
            backgroundColor: palette.background.tableHeader
          },
          // identical for `outlinedPrimary` and `outlinedSecondary`:
          '& .MuiChip-icon, & .MuiChip-iconSmall': {
            alignSelf: 'stretch',
            height: 'auto',
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: palette.background.dragIcon
          }
        },
        icon: {
          color: palette.background.card,
          fontSize: typography.h2.fontSize
        },
        deleteIcon: {
          color: palette.warning.light,
          marginLeft: 0,
          '&:hover, &:active': {
            color: palette.warning.main
          }
        },
        deleteIconOutlinedColorPrimary: {
          color: palette.warning.light,
          marginLeft: 0,
          '&:hover, &:active': {
            color: palette.warning.main
          }
        },
        deleteIconOutlinedColorSecondary: {
          color: palette.warning.light,
          marginLeft: 0,
          '&:hover, &:active': {
            color: palette.warning.main
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            color: palette.secondary.text,
            background: `linear-gradient(0deg, ${
              palette.background.card} 0%, ${
              palette.background.paper} 100%) ${
              palette.background.paper}`
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.card
        },
        adornedEnd: {
          paddingRight: 0.5 * spacing
        },
        notchedOutline: {
          borderColor: palette.primary.main,
          borderWidth: 0,
          '&:hover': {
            borderColor: palette.primary.main,
            borderWidth: shape.borderWidth
          },
          '&.Mui-focused': {
            borderColor: palette.primary.main,
            borderWidth: shape.mediumBorderWidth
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.card
        },
        select: {
          '&:focus': {
            backgroundColor: palette.background.card
          }
        },
        iconOutlined: {
          transition: `transform ${duration.standard}ms ${easing.easeInOut}`
        },
        iconOpen: {
          transform: 'rotateX(180deg)'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.card,
          borderRadius: shape.borderRadius
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        colorSecondary: {
          color: palette.secondary.contrastText,
          '&.MuiInput-underline:before, &.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: palette.primary.main
          },
          '&.MuiInput-underline:after': {
            borderBottomColor: palette.secondary.contrastText
          },
          '.Mui-disabled': {
            color: alpha(palette.secondary.contrastText, 1 - palette.action.disabledOpacity),
            WebkitTextFillColor: `${alpha(palette.secondary.contrastText, 1 - palette.action.disabledOpacity)} !important`
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        colorSecondary: {
          color: palette.secondary.contrastText,
          '&.Mui-focused': {
            color: palette.secondary.contrastText
          },
          '&.Mui-disabled': {
            color: alpha(palette.secondary.contrastText, 1 - palette.action.disabledOpacity)
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: typography.body1.fontSize
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: '4.25rem'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          '@media (forced-colors: active)': {
            border: `${shape.borderWidth}px solid ButtonBorder`
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '@media (forced-colors: active)': {
            border: `${shape.borderWidth}px solid ButtonBorder`
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '.MuiDialogTitle-root + &': {
            paddingTop: spacing
          }
        },
        dividers: {
          borderTop: `${shape.mediumBorderWidth}px solid ${palette.greys.border}`,
          borderBottom: `${shape.mediumBorderWidth}px solid ${palette.greys.border}`
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          '& > :not(:first-of-type)': {
            marginLeft: '4rem'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          '@media (forced-colors: active)': {
            borderRight: `${shape.borderWidth}px solid ButtonBorder`
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media (forced-colors: active)': {
            borderBottom: `${shape.borderWidth}px solid ButtonBorder`
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: palette.action.selected
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `${shape.mediumBorderWidth}px solid ${palette.delimiterColor}`
        },
        body: {
          color: palette.text.label,
          fontWeight: typography.body1.fontWeight,
          fontSize: typography.body1.fontSize,
          lineHeight: typography.body1.lineHeight,
          letterSpacing: typography.body1.letterSpacing
        },
        head: {
          color: palette.info.caption,
          backgroundColor: palette.background.tableHeader,
          fontSize: typography.body2.fontSize,
          lineHeight: typography.body2.lineHeight,
          letterSpacing: typography.body2.letterSpacing,
          fontWeight: typography.fontWeightMedium,
          textTransform: 'uppercase'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          opacity: 1,
          color: palette.greys.switch
        },
        root: {
          '&:hover': {
            color: palette.secondary.main,
            '& .MuiTableSortLabel-icon': {
              opacity: 1,
              color: palette.secondary.light
            }
          },
          '&.Mui-active': {
            color: palette.info.caption,
            fill: palette.greys.switch,
            '&:hover': {
              color: palette.secondary.main
            },
            '&& .MuiTableSortLabel-icon': {
              color: palette.secondary.light
            }
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          height: '0.4rem',
          borderRadius: '0.1rem',
          border: 'none'
        },
        rail: {
          height: '0.4rem',
          borderRadius: '0.1rem',
          opacity: 1
        },
        thumb: {
          height: shape.sliderThumbSize,
          width: shape.sliderThumbSize,
          border: `${1.5 * shape.borderWidth}px solid currentColor`,
          backgroundColor: palette.background.paper,
          '&~.MuiSlider-thumb': {
            backgroundColor: 'currentColor'
          },
          '&.Mui-disabled': {
            height: shape.sliderThumbSize,
            width: shape.sliderThumbSize,
            marginLeft: null, // TODO: change `null` to appropriate default value
            marginTop: null // TODO: change `null` to appropriate default value
          }
        },
        mark: {
          marginLeft: shape.sliderThumbMargin,
          width: shape.sliderThumbSize,
          height: shape.sliderThumbSize,
          borderRadius: '50%',
          border: `${1.5 * shape.borderWidth}px solid ${grey[300]}`,
          backgroundColor: palette.background.paper,
          boxSizing: 'border-box',
          opacity: 1,
          '&~.MuiSlider-mark': {
            backgroundColor: 'currentColor',
            border: `${1.5 * shape.borderWidth}px solid currentColor`
          }
        },
        markActive: {
          opacity: 1
        },
        markLabel: {
          top: 26
        },
        markLabelActive: {
          color: palette.text.label
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: palette.greys.popupBorder,
          '&::before': {
            backgroundColor: palette.greys.popupBorder
          }
        },
        tooltip: {
          maxWidth: '32rem',
          backgroundColor: palette.background.paper,
          color: palette.text.label,
          border: `${shape.thinBorderWidth}px solid ${palette.greys.popupBorder}`,
          fontWeight: typography.body1.fontWeight,
          fontSize: typography.body1.fontSize,
          lineHeight: typography.body1.lineHeight,
          letterSpacing: typography.body1.letterSpacing,
          textTransform: 'none'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '@media (forced-colors: active)': {
            border: `${shape.borderWidth}px solid ButtonBorder`
          }
        },
        standardInfo: {
          padding: shape.cardPadding,
          color: palette.text.secondary,
          backgroundColor: 'transparent',
          fontWeight: typography.fontWeightRegular,
          fontSize: typography.subtitle1.fontSize,
          lineHeight: typography.subtitle1.lineHeight,
          letterSpacing: typography.subtitle1.letterSpacing,
          '& .MuiAlert-message': {
            textAlign: 'center',
            flexGrow: 1
          },
          '& .MuiAlert-icon': {
            display: 'none',
            margin: 0,
            padding: 0
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          forcedColorAdjust: 'none',
          width: 42,
          height: 24,
          padding: 0,
          display: 'flex'
        },
        switchBase: {
          padding: 2,
          color: palette.warning.light,
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: palette.background.paper,
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: palette.primary.main,
              borderColor: palette.primary.main
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: palette.action.disabledOpacity
            }
          },
          '&.Mui-disabled': {
            color: palette.action.disabledBackground,
            '& + .MuiSwitch-track': {
              borderColor: palette.common.black
            },
            '&.Mui-checked': {
              color: palette.background.paper,
              '& + .MuiSwitch-track': {
                borderColor: palette.primary.main
              }
            }
          }
        },
        thumb: {
          width: 18,
          height: 18,
          boxShadow: 'none',
          transform: 'translate(1px, 1px)'
        },
        track: {
          border: `${shape.thinBorderWidth}px solid ${palette.misc.selectedBorder}`,
          borderRadius: 24 / 2,
          backgroundColor: palette.background.paper,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: shape.smallBorderRadius
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: shadows[10],
          '&.Mui-focusVisible': {
            boxShadow: shadows[10]
          }
        },
        secondary: {
          color: palette.misc.sliderPrev,
          backgroundColor: palette.background.paper,
          '&:hover': {
            backgroundColor: palette.background.button,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: palette.background.paper
            }
          }
        },
        extended: {
          borderRadius: shape.borderRadius,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: palette.misc.starRating
        },
        iconEmpty: {
          color: palette.misc.starInactive
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: palette.delimiterColor
        },
        vertical: {
          marginTop: 0,
          marginBottom: 0
        },
        middle: {
          marginLeft: 2 * spacing,
          marginRight: 2 * spacing
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: lighten(palette.error.main, 0.62)
        },
        barColorSecondary: {
          backgroundColor: lighten(palette.error.main, 0.62)
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: palette.action.disabledBackground
        },
        '@keyframes pulse': {
          '50%': {
            opacity: 0.6
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.backdrop
        },
        invisible: {
          opacity: '0 !important'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-root+.MuiDivider-root': {
            marginTop: 0,
            marginBottom: 0
          }
        }
      },
      defaultProps: {
        disableRipple: true
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  }
};
