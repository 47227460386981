import { memo, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Alert from '@mui/material/Alert';
// TM UI Components
import { getStandardLink } from '@empathco/ui-components/src/helpers/values';
import { getCurrentSeconds } from '@empathco/ui-components/src/helpers/datetime';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { NotificationsParams } from '../context/dataContext';
import { DataContext } from '../context';
import PaginationControls, { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';
import Notification from '../v3/Notification';

const Notifications: FunctionComponent = () => {
  const {
    notifications: { data: notifications, count, pending, failed, params },
    requireNotifications
  } = useContext(DataContext);

  const timestamp = useMemo(() => getCurrentSeconds(), []);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const values = useMemo(() => ({ link: getStandardLink({ href: '/EmPath_Release_Notes.pdf' }) }), []);

  useEffect(() => {
    if (!isNil(pageSize)) {
      const newParams: NotificationsParams = {
        timestamp,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(params, newParams)) {
        curPage = 1;
        setCurrentPage(1);
      }
      newParams.offset = curPage > 1 ? pageSize * (curPage - 1) : null;
      requireNotifications?.(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, timestamp, requireNotifications]); // ignoring `params` changes

  const pagination = (
    <PaginationControls
        settingsId="notifications"
        loaded={Boolean(notifications)}
        pending={pending}
        loading={pending}
        total={count}
        currentPage={currentPage}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={pending}
    />
  );

  return (
    <ContentCard>
      <CardTitle
          title="notifications.title"
          withDivider
      />
      {(failed && <FetchFailedAlert/>) ||
      (pending && <LoadingPlaceholder/>) || ((count === 0 || size(notifications) === 0) && (
        <Alert severity="info" variant="standard">
          <FormattedMessage id="notifications.empty"/>
        </Alert>
      )) || (
        <CardSection>
          {map(notifications, (notification) => (
            <Notification
                key={notification.id}
                notification={notification}
            />
          ))}
        </CardSection>
      )}
      {hasPagination(pagination as PaginationControlsComponent) ? (
        <CardSection
            flex
            bottom={!pending}
        >
          {pagination}
        </CardSection>
      ) : pagination}
      <CardSection bottom={!failed}>
        <FormattedMessage id="notifications.release_notes" values={values}/>
      </CardSection>
    </ContentCard>
  );
};

export default memo(Notifications);
