import { memo, type ReactNode, useCallback, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import map from 'lodash/map';
import filter from 'lodash/filter';
import transform from 'lodash/transform';
// import isUndefined from 'lodash/isUndefined';
import { useMutation, type ApolloCache } from '@apollo/client';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// TM UI Components
import { getStandardLink } from '@empathco/ui-components/src/helpers/values';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { SELECT_ACTIVITY } from '../graphql/SelectActivity';
import { UPDATE_ACTIVITY } from '../graphql/UpdateActivity';
import {
  SkillActivity, EmployeeSkillResources,
  SelectActivityDocument, UpdateActivityDocument, EmployeeActivityStatus
} from '../graphql/types';
import { SKILL_ACTIVITY_TYPES } from '../graphql/customTypes';
import { SKILL_LEVEL_FIRST, Skill, SkillLevel } from '../models/skill';
import useCustomerSettings, { SkillDevelopmentRes, ISkillDevelopmentResource } from '../config/customer';
import { CUSTOMER_IMAGES } from '../customers/getImages';
import { getActualActivities } from '../helpers/graphql';
import { getSkillCurrentLevel } from '../helpers/models';
import SkillDevelopmentResource from '../elements/SkillDevelopmentResource';
import ActivityCard from '../v3/ActivityCard';
import EmployeesPopup from './EmployeesPopup';

// TODO: optimistic update
const updateEmployeeSkill = (cache: ApolloCache<unknown>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkill' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myActivities' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
};

interface SkillDevResource extends ISkillDevelopmentResource {
  href?: string | null;
  message?: ReactNode | ReactNode[] | null;
}

type SkillDevelopmentResourcesProps = {
  skill?: EmployeeSkillResources | null;
  pending?: boolean;
  failed?: boolean;
  disabled?: boolean | null;
  hrbp?: boolean;
  isInternational?: boolean | null;
  // for Storybook only
  customerImages?: string[];
  testResources?: SkillDevelopmentRes[][];
}

const SkillDevelopmentResourcesPropTypes = {
  skill: PropTypes.object as Validator<EmployeeSkillResources>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  disabled: PropTypes.bool,
  hrbp: PropTypes.bool,
  isInternational: PropTypes.bool,
  customerImages: PropTypes.array,
  testResources: PropTypes.array as Validator<SkillDevelopmentRes[][]>
};

const SkillDevelopmentResources: FunctionComponent<SkillDevelopmentResourcesProps> = ({
  skill,
  pending = false,
  failed = false,
  disabled: parentDisabled = false,
  hrbp = false,
  isInternational = false,
  customerImages = CUSTOMER_IMAGES,
  testResources
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { HAS_MENTORING, SKILL_DEVELOPMENT_RESOURCES, SKILL_DEVELOPMENT_RESOURCES_DISABLED } = useCustomerSettings();

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const { mutate: selectActivity, loading: selectPending, failed: selectFailed } = useMutationMethod({
    mutation: useMutation(SELECT_ACTIVITY as typeof SelectActivityDocument)
  });
  const { mutate: unselectActivity, loading: unselectPending, failed: unselectFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_ACTIVITY as typeof UpdateActivityDocument)
  });
  const { mutate: updateActivity, loading: updatePending, failed: updateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_ACTIVITY as typeof UpdateActivityDocument)
  });

  const { id: skillId, title, mentors_count } = skill || {};
  const level = skill ? getSkillCurrentLevel(skill as Skill) : undefined;

  const disabled = parentDisabled || pending || !skill || !skillId || !title;

  const activities = useMemo(() => skill && size(skill.activities) >= 1
    ? getActualActivities(
        [skill as Skill],
        isNil(level) ? null : (level + SKILL_LEVEL_FIRST) as SkillLevel
      )
    : undefined, [skill, level]);

  const values = useMemo(() => ({
    count: (HAS_MENTORING && mentors_count) || 0,
    link: getStandardLink({
      onClick: disabled ? undefined : handleOpen,
      variant: 'h4'
    }),
    br: <br/>
  }), [mentors_count, disabled, handleOpen, HAS_MENTORING]);

  const resources = useMemo(() => isNil(level) ? [] : transform([
    ...testResources || SKILL_DEVELOPMENT_RESOURCES || [],
    ...!SKILL_DEVELOPMENT_RESOURCES_DISABLED && HAS_MENTORING && mentors_count && mentors_count >= 1 ? [
      [
        null,
        {
          text: 'skill.development.advisors.text',
          hover: 'skill.development.advisors.hover.1'
        },
        {
          text: 'skill.development.advisors.text',
          hover: 'skill.development.advisors.hover.1'
        },
        {
          text: 'skill.development.advisors.text',
          hover: 'skill.development.advisors.hover.3'
        },
        {
          text: 'skill.development.advisors.text',
          hover: 'skill.development.advisors.hover.3'
        }
      ] as SkillDevelopmentRes[]
    ] : []
  ], (result, resource) => {
    const res = resource[level] ? { ...resource[level] } as SkillDevResource : undefined;
    if (res) {
      let resourceValues = values;
      if (res.link) {
        const link = formatMessage({ id: res.link }, { title: title ? encodeURIComponent(title) : '' });
        res.href = link;
        resourceValues = {
          ...values,
          link: getStandardLink({
            href: disabled ? undefined : link,
            variant: 'h4'
          })
        };
      }
      if (res.text) res.message = formatMessage({ id: res.text }, resourceValues as Parameters<typeof formatMessage>[1]);
      if (res.hover) res.hover = formatMessage({ id: res.hover });
      result.push(res);
    }
  }, [] as SkillDevResource[]), [
    mentors_count, formatMessage, level, testResources, title, disabled, values,
    HAS_MENTORING, SKILL_DEVELOPMENT_RESOURCES, SKILL_DEVELOPMENT_RESOURCES_DISABLED
  ]);

  const handleSelectActivity = useCallback((activity: SkillActivity) => {
    const { id: activity_id, employee_activity_id, is_selected, is_complete } = activity;
    if (is_complete || !activity_id) return;
    if (employee_activity_id) unselectActivity({
      variables: { activity_id: employee_activity_id, input: {
        status: is_selected ? EmployeeActivityStatus.inactive : EmployeeActivityStatus.active
      } },
      // TODO: optimistic response
      update: updateEmployeeSkill
    });
    else selectActivity({
      variables: { input: { activity_id } },
      // TODO: optimistic response
      update: updateEmployeeSkill
    });
  }, [selectActivity, unselectActivity]);

  const handleCompleteActivity = useCallback((activity: SkillActivity) => {
    const { employee_activity_id, is_selected, is_complete } = activity;
    if (is_complete || !is_selected || !employee_activity_id) return;
    updateActivity({
      variables: { activity_id: employee_activity_id, input: { status: EmployeeActivityStatus.completed } },
      // TODO: optimistic response
      update: updateEmployeeSkill
    });
  }, [updateActivity]);

  return failed || (!pending && size(resources) < 1 && size(activities) < 1) ? null : (
    <>
      <ContentCard pending={pending}>
        <CardTitle
            title="skill.development.title"
            withDivider
        />
        {pending ? undefined : (
          <CardSection>
            <Grid container justifyContent="center">
              {size(activities) >= 1 ? SKILL_ACTIVITY_TYPES.map((activityType) => {
                const activitiesOfType = filter(activities, ['activity_type', activityType]);
                return size(activitiesOfType) >= 1 ? (
                  <Grid
                      key={activityType}
                      item
                      container
                      xs={12}
                      md={6}
                      lg={4}
                      xl={3}
                      flexDirection="column"
                      alignItems="center"
                  >
                    {map(activitiesOfType, (skillActivity, index) => skillActivity?.code ? (
                      <Box key={skillActivity?.code || index} pb={3} alignSelf="stretch">
                        <ActivityCard
                            small
                            item={skillActivity}
                            isSelected={Boolean(skillActivity?.is_selected)}
                            onSelect={skillActivity?.is_complete ? undefined : handleSelectActivity}
                            onComplete={skillActivity?.is_selected ? handleCompleteActivity : undefined}
                            onSelectPendingId={selectPending || unselectPending ? skillActivity?.id : undefined}
                            onCompletePendingId={updatePending ? skillActivity?.id : undefined}
                            withCompletedState
                            disabled={disabled}
                        />
                      </Box>
                    ) : undefined)}
                  </Grid>
                ) : undefined;
              }) : undefined}
              {map(resources, ({ message, hover, href }, index) => (
                <SkillDevelopmentResource
                    key={index}
                    src={customerImages[index]}
                    text={message}
                    href={href}
                    hover={hover}
                    onClick={handleOpen}
                    disabled={disabled}
                />
              ))}
            </Grid>
          </CardSection>
        )}
      </ContentCard>
      {!disabled && (
        <EmployeesPopup
            advisors
            skill={skill as Skill}
            isOpen={open}
            onClose={handleClose}
            hrbp={hrbp}
            isInternational={isInternational}
        />
      )}
      <ActionFailedAlert
          message="skill.development.activity.error"
          open={selectFailed || unselectFailed || updateFailed}
      />
    </>
  );
};

SkillDevelopmentResources.propTypes = SkillDevelopmentResourcesPropTypes;

export default memo(SkillDevelopmentResources);
