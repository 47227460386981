import { Fragment, memo, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
// TM UI Components
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import useCustomerSettings from '../config/customer';
import PreferencesRow from '../v3/PreferencesRow';

type AdminSettingsProps = {
  // for Storybook only
  testPending?: boolean;
}

const AdminSettingsPropTypes = {
  testPending: PropTypes.bool
};

const AdminSettings: FunctionComponent<AdminSettingsProps> = ({
  testPending
}) => {
  const {
    // HAS_MULTIPLE_SSO, setHasMultipleSSO,
    // HAS_LOGIN, setHasLogin,
    HAS_LOGOUT, setHasLogout,
    SHOW_WELCOME_DATA_PRIVACY, setShowWelcomeDataPrivacy,
    HAS_INTERNAL_COURSE_LOGO, setHasInternalCourseLogo,

    HAS_JOBS_CHART, setHasJobsChart,
    HAS_JOBS_SCOPE, setHasJobsScope,
    HAS_INDEMAND_SKILLS, setHasInDemandSkills,
    HAS_PROFILE_BUILDER, setHasProfileBuilder,
    HAS_RESUME_UPLOADER, setHasResumeUploader,
    HAS_QUICK_TOUR, setHasQuickTour,
    HAS_REDUCED_UI, setHasReducedUI,
    HAS_COMPENSATION, setHasCompensation,
    HAS_MENTORING, setHasMentoring,
    HAS_COURSES, setHasCourses,
    HAS_COURSES_READONLY, setHasCoursesReadOnly,
    HAS_DEV_PLAN, setHasDevPlan,
    HAS_OPPORTUNITIES, setHasOpportunities,
    HAS_DEV_PLAN_OPPORTUNITIES, setHasDevPlanOpportunities,
    HAS_REDEPLOYMENT, setHasRedeployment,
    // HAS_HRP_JOBS, setHasHrpJobs,
    HAS_TALENT_FINDER, setHasTalentFinder,
    HAS_TEAM_BUILDER, setHasTeamBuilder,
    HAS_CANDIDATES, setHasCandidates,
    HAS_JOBS_SKILLS_GAP_CHART, setHasJobsSkillsGapChart,
    HAS_INDEMAND_SKILLS_EDITOR, setHasInDemandSkillsEditor,

    DA_TABS
  } = useCustomerSettings();

  const updatePending = testPending ? true : undefined;

  const rows = useMemo(() => [
    // { id: 'multiple_sso', value: HAS_MULTIPLE_SSO, handler: setHasMultipleSSO },
    // { id: 'login', value: HAS_LOGIN, handler: setHasLogin },
    { id: 'logout', value: HAS_LOGOUT, handler: setHasLogout },
    { id: 'internal_course_logo', value: HAS_INTERNAL_COURSE_LOGO, handler: setHasInternalCourseLogo },
    { id: 'show_welcome_data_privacy', value: SHOW_WELCOME_DATA_PRIVACY, handler: setShowWelcomeDataPrivacy,
      sectionEnd: true },

    { id: 'quick_tour', value: HAS_QUICK_TOUR, handler: setHasQuickTour },
    { id: 'profile_builder', value: HAS_PROFILE_BUILDER, handler: setHasProfileBuilder },
    { id: 'resume_uploader', value: HAS_RESUME_UPLOADER, handler: setHasResumeUploader, disabled: !HAS_PROFILE_BUILDER },
    { id: 'indemand_skills', value: HAS_INDEMAND_SKILLS, handler: setHasInDemandSkills },
    { id: 'mentoring', value: HAS_MENTORING, handler: setHasMentoring },
    { id: 'courses', value: HAS_COURSES, handler: setHasCourses },
    { id: 'courses_readonly', value: HAS_COURSES_READONLY, handler: setHasCoursesReadOnly, disabled: HAS_COURSES },
    { id: 'opportunities', value: HAS_OPPORTUNITIES, handler: setHasOpportunities },
    { id: 'dev_plan', value: HAS_DEV_PLAN, handler: setHasDevPlan },
    { id: 'devplan_opportunities', value: HAS_DEV_PLAN_OPPORTUNITIES, handler: setHasDevPlanOpportunities,
      disabled: !HAS_DEV_PLAN || !HAS_OPPORTUNITIES },
    { id: 'jobs_scope', value: HAS_JOBS_SCOPE, handler: setHasJobsScope },
    { id: 'jobs_chart', value: HAS_JOBS_CHART, handler: setHasJobsChart },
    { id: 'reduced_ui', value: HAS_REDUCED_UI, handler: setHasReducedUI, sectionEnd: true },

    { id: 'compensation', value: HAS_COMPENSATION, handler: setHasCompensation },
    { id: 'candidates', value: HAS_CANDIDATES, handler: setHasCandidates, sectionEnd: Boolean(DA_TABS) },
    ...DA_TABS ? [] : [{
      id: 'jobs_skills_gap_chart', value: HAS_JOBS_SKILLS_GAP_CHART, handler: setHasJobsSkillsGapChart, sectionEnd: true
    }],

    { id: 'talent_finder', value: HAS_TALENT_FINDER, handler: setHasTalentFinder },
    { id: 'team_builder', value: HAS_TEAM_BUILDER, handler: setHasTeamBuilder },
    { id: 'redeployment', value: HAS_REDEPLOYMENT, handler: setHasRedeployment },
    { id: 'indemand_skills_editor', value: HAS_INDEMAND_SKILLS_EDITOR, handler: setHasInDemandSkillsEditor }
  ], [
    HAS_CANDIDATES, HAS_COMPENSATION, HAS_COURSES, HAS_COURSES_READONLY, HAS_DEV_PLAN, HAS_DEV_PLAN_OPPORTUNITIES,
    HAS_INDEMAND_SKILLS, HAS_INDEMAND_SKILLS_EDITOR, HAS_INTERNAL_COURSE_LOGO, HAS_JOBS_CHART, HAS_JOBS_SCOPE,
    HAS_JOBS_SKILLS_GAP_CHART, HAS_LOGOUT, HAS_MENTORING, HAS_OPPORTUNITIES,
    HAS_PROFILE_BUILDER, HAS_QUICK_TOUR, HAS_REDEPLOYMENT, HAS_REDUCED_UI, HAS_RESUME_UPLOADER, HAS_TALENT_FINDER,
    HAS_TEAM_BUILDER, SHOW_WELCOME_DATA_PRIVACY, DA_TABS,
    setHasCandidates, setHasCompensation, setHasCourses, setHasCoursesReadOnly, setHasDevPlan, setHasDevPlanOpportunities,
    setHasInDemandSkills, setHasInDemandSkillsEditor, setHasInternalCourseLogo, setHasJobsChart,
    setHasJobsScope, setHasJobsSkillsGapChart, setHasLogout, setHasMentoring, setHasOpportunities,
    setHasProfileBuilder, setHasQuickTour, setHasRedeployment, setHasReducedUI, setHasResumeUploader, setHasTalentFinder,
    setHasTeamBuilder, setShowWelcomeDataPrivacy
  ]);

  return (
    <CardSection>
      {rows.map(({ id, value, handler, disabled, sectionEnd }) => (
        <Fragment key={id}>
          <PreferencesRow
              prompt={`admin.settings.${id}`}
              value={value}
              onChange={handler}
              disabled={disabled || updatePending}
          />
          {sectionEnd ? (
            <Box py={2.5}>
              <Divider/>
            </Box>
          ) : undefined}
        </Fragment>
      ))}
    </CardSection>
  );
};

AdminSettings.propTypes = AdminSettingsPropTypes;

export default memo(AdminSettings);
