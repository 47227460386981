import { memo, useMemo, type MouseEventHandler, type ReactNode, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isString from 'lodash/isString';
// Material UI imports
import Typography from '@mui/material/Typography';
import Button, { type ButtonProps } from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// local imports
import BoxTypography from '../mixins/BoxTypography';
import { Values, ValuesPropType } from '../helpers/intl';
import CloseIconButton from '../elements/CloseIconButton';
// SCSS imports
import { actions, actionsPadding, button, cancelButton, extraPadding } from './ConfirmDialog.module.scss';

const paperProps = { className: extraPadding };
const contentSx = { pb: 1 };

type ConfirmDialogProps = {
  open: boolean;
  maxWidth?: DialogProps['maxWidth'];
  title?: ReactNode | ReactNode[] | string | null;
  text?: ReactNode | ReactNode[] | string | null;
  values?: Values;
  withCancelButton?: boolean;
  withExtraPadding?: boolean;
  withoutCloseButton?: boolean;
  cancelColor?: ButtonProps['color'];
  confirmColor?: ButtonProps['color'];
  cancelLabel?: string | null;
  confirmLabel?: string | null;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onExited: (node: HTMLElement) => void;
};

const ConfirmDialogPropTypes = {
  // attributes
  open: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string as Validator<DialogProps['maxWidth']>,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]) as Validator<ReactNode | ReactNode[] | string>,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]) as Validator<ReactNode | ReactNode[] | string>,
  values: ValuesPropType,
  withCancelButton: PropTypes.bool,
  withExtraPadding: PropTypes.bool,
  withoutCloseButton: PropTypes.bool,
  cancelColor: PropTypes.string as Validator<ButtonProps['color']>,
  confirmColor: PropTypes.string as Validator<ButtonProps['color']>,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onExited: PropTypes.func.isRequired
};

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  open = false,
  maxWidth,
  title,
  text,
  values,
  withCancelButton = false,
  withExtraPadding = false,
  withoutCloseButton = false,
  cancelColor,
  confirmColor,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
  onExited
}) => {
  const titleSx = useMemo(() => ({ pt: 3.5, pb: text ? 2 : 3.5, px: 7, textAlign: 'center' }), [text]);
  const transitionProps = useMemo(() => ({ onExited }), [onExited]);
  return (
    <Dialog
        disableEnforceFocus
        maxWidth={maxWidth}
        open={open}
        onClose={onCancel}
        scroll="body"
        TransitionProps={transitionProps}
        PaperProps={withExtraPadding ? paperProps : undefined}
    >
      {!withoutCloseButton && <CloseIconButton small onClick={onCancel}/>}
      {title ? (
        <DialogTitle sx={titleSx}>
          <Typography variant="subtitle1" component="span">
            {isString(title)
              ? <FormattedMessage id={title} defaultMessage={title} values={values}/>
              : title }
          </Typography>
        </DialogTitle>
      ) : undefined}
      {text ? (
        <DialogContent sx={contentSx}>
          <BoxTypography pt={title ? undefined : 1} pb={2.5} px={4} variant="body1" textAlign="center">
            {isString(text)
              ? <FormattedMessage id={text} defaultMessage={text} values={values}/>
              : text }
          </BoxTypography>
        </DialogContent>
      ) : undefined}
      {onConfirm || withCancelButton ? (
        <DialogActions className={withExtraPadding ? actionsPadding : actions}>
          {withCancelButton ? (
            <Button
                onClick={onCancel}
                color={cancelColor || 'primary'}
                size="small"
                variant="outlined"
                className={cancelButton}
            >
              <FormattedMessage id={cancelLabel || 'common.button.cancel'}/>
            </Button>
          ) : undefined}
          {onConfirm ? (
            <Button
                onClick={onConfirm}
                color={confirmColor || 'primary'}
                size="small"
                variant="contained"
                disableElevation
                autoFocus
                className={button}
            >
              <FormattedMessage id={confirmLabel || 'common.button.confirm'}/>
            </Button>
          ) : undefined}
        </DialogActions>
      ) : undefined}
    </Dialog>
  );
};

ConfirmDialog.propTypes = ConfirmDialogPropTypes;

export default memo(ConfirmDialog);
