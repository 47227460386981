import { forwardRef, memo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
// TM UI Components
import { fontWeightBold } from '@empathco/ui-components/src/styles/themeOptions';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';

type JobDetailsItemProps = {
  prompt: ReactNode | ReactNode[];
  value: boolean | ReactNode | ReactNode[];
  divider?: boolean;
}

const JobDetailsItemPropTypes = {
  prompt: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]).isRequired as Validator<ReactNode | ReactNode[]>,
  value: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]).isRequired as Validator<boolean | ReactNode | ReactNode[]>,
  divider: PropTypes.bool
};

const JobDetailsItem = forwardRef<HTMLElement, JobDetailsItemProps>(({
  prompt,
  value,
  divider = false
}, ref) => (
  <>
    <BoxTypography
        ref={ref}
        flexGrow={1}
        flexShrink={0}
        py={2}
        pl={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        variant="subtitle2"
    >
      <Box pr={2} fontWeight={fontWeightBold}>
        {prompt}
      </Box>
      <Box pr={2}>
        {isBoolean(value) ? <FormattedMessage id={value ? 'common.yes' : 'common.no'}/> : value}
      </Box>
    </BoxTypography>
    {divider ? (
      <Box alignSelf="stretch" py={1.5}>
        <Divider
            orientation="vertical"
            variant="middle"
        />
      </Box>
    ) : undefined}
  </>
));

JobDetailsItem.displayName = 'JobDetailsItem';

JobDetailsItem.propTypes = JobDetailsItemPropTypes;

export default memo(JobDetailsItem);
