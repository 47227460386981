/* eslint-disable max-lines */
import { Fragment, memo, forwardRef, useMemo, useState, useCallback, type MouseEvent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import toSafeInteger from 'lodash/toSafeInteger';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import StarRounded from '@mui/icons-material/StarRounded';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
// TM UI Components
import { shape, fontWeightRegular } from '@empathco/ui-components/src/styles/themeOptions';
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import PhoneInTalk from '@empathco/ui-components/src/icons/PhoneInTalk';
import { longDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getFullName, getName } from '@empathco/ui-components/src/helpers/strings';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
import SkillLevelGauge from '@empathco/ui-components/src/elements/SkillLevelGauge';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
// local imports
import { SkillLevel } from '../models/skill';
import { Employee } from '../models/employee';
import { EmployeeAdvisorStatus, SkillAdvisor } from '../graphql/types';
import {
  TalentEmployeeObject, DevPlanEmployee, DevPlanAdvisor, OpportunityEmployee, EmployeeAdvisee
} from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import { PATH_SKILL } from '../config/paths';
import { getAdvisoryState } from '../helpers/graphql';
import useModels, { getSkillCurrentLevelOnly } from '../helpers/models';
import EmployeeSkillsPopup from './EmployeeSkillsPopup';
import EmployeeDetailsPopup from './EmployeeDetailsPopup';
// SCSS imports
import {
  titleContainer, titleRow, delegatorRow, infoContainer, infoContainer2, jobRow, jobRow2, zoomInBtn, avatar
} from './EmployeeCard.module.scss';

export type AvatarVariant = 'default' | 'phone' | 'grey' | 'opportunity' | 'advisor' | 'advisee' | 'devplanadvisor';
export type MatchRateVariant = 'default' | 'planned';

type EmployeeCardProps = {
  item: Employee | TalentEmployeeObject | DevPlanEmployee | DevPlanAdvisor | OpportunityEmployee | SkillAdvisor;
  withSkills?: boolean | null;
  matchRateVariant?: MatchRateVariant;
  growthRateVariant?: MatchRateVariant;
  avatarVariant?: AvatarVariant;
  route?: string | null;
  disabled?: boolean | null;
  pending?: boolean | null;
  draggable?: boolean;
  isDragging?: boolean;
  isHighlighted?: boolean;
  withZoomInSkills?: boolean;
  withDetailsPopup?: boolean;
  withCompletedState?: boolean;
  selected?: boolean | null;
  onSelectSwitch?: (id: number) => void;
  isSelected?: boolean;
  onSelect?: (advisor: DevPlanAdvisor & OpportunityEmployee) => void;
  onRemove?: (advisor: DevPlanAdvisor & OpportunityEmployee & SkillAdvisor & EmployeeAdvisee) => void;
  onComplete?: (advisor: SkillAdvisor) => void;
  onAction?: (id: number) => void;
  actionLabel?: string;
  actionFinishedLabel?: string;
  actionPendingId?: number | null;
  onCompletePendingId?: number | null;
}

const EmployeeCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<
    Employee | TalentEmployeeObject | DevPlanEmployee | DevPlanAdvisor | OpportunityEmployee
  >,
  withSkills: PropTypes.bool,
  matchRateVariant: PropTypes.string as Validator<MatchRateVariant>,
  growthRateVariant: PropTypes.string as Validator<MatchRateVariant>,
  avatarVariant: PropTypes.string as Validator<AvatarVariant>,
  route: PropTypes.string,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  draggable: PropTypes.bool,
  isDragging: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  onAction: PropTypes.func,
  withZoomInSkills: PropTypes.bool,
  withDetailsPopup: PropTypes.bool,
  withCompletedState: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectSwitch: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onComplete: PropTypes.func,
  actionLabel: PropTypes.string,
  actionFinishedLabel: PropTypes.string,
  actionPendingId: PropTypes.number,
  onCompletePendingId: PropTypes.number
};

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
const EmployeeCard = forwardRef<HTMLDivElement, EmployeeCardProps>(({
  item,
  withSkills = false,
  matchRateVariant,
  growthRateVariant,
  avatarVariant = 'default',
  route,
  disabled: parentDisabled = false,
  pending: parentPending = false,
  draggable = false,
  isDragging = false,
  isHighlighted = false,
  withZoomInSkills = false,
  withDetailsPopup = false,
  withCompletedState = false,
  selected = false,
  onSelectSwitch,
  isSelected,
  onSelect,
  onRemove,
  onComplete,
  onAction,
  actionLabel,
  actionFinishedLabel,
  actionPendingId,
  onCompletePendingId
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { getEmployeeContactUrl, HAS_MENTORING } = useCustomerSettings();
  const { getLocationStr } = useModels();

  const isOppMatch = avatarVariant === 'opportunity';
  const isAdvisor = avatarVariant === 'advisor';
  const isDevPlanAdvisor = avatarVariant === 'devplanadvisor';
  const isAdvisee = avatarVariant === 'advisee';

  const { id, code, email, first_name, last_name, current_job, location } = item || {};
  const { match_rate, delegator, skills, is_profile_accessible } = item as Employee || {};
  const { current_match_rate } = item as DevPlanEmployee || {};
  const { is_selected, advisory_skills } = item as DevPlanAdvisor || {};
  const { growth_rate, is_applicant, is_shortlisted } = (isOppMatch && item as OpportunityEmployee) || {};
  const { is_new } = (isOppMatch && item as OpportunityEmployee) || {};
  const { is_complete, confirmed_at, requested_at } = (isDevPlanAdvisor && item as SkillAdvisor) || {};
  const { status, status_updated_at, is_new: isNew } = isAdvisor || isAdvisee
    ? getAdvisoryState((item as SkillAdvisor & EmployeeAdvisee)?.skills)
    : { status: undefined, status_updated_at: undefined, is_new: false };
  const { title } = current_job || {};
  const { code: delegator_code, first_name: delegator_first_name, last_name: delegator_last_name } = delegator || {};

  const ariaLabelPreferred = useMemo(() => formatMessage({ id: 'hr.dev_plan.preferred' }), [formatMessage]);
  const locationStr = useMemo(() => getLocationStr(location), [location, getLocationStr]);
  const dialogValues = useMemo(
    () => ({ first_name, last_name, code: isOppMatch ? null : code }),
    [code, first_name, last_name, isOppMatch]
  );

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMounted, setConfirmMounted] = useState(false);

  const handleConfirm = useCallback(() => {
    setConfirmOpen(false);
    if (isOppMatch) {
      onRemove?.(item as (DevPlanAdvisor & OpportunityEmployee & SkillAdvisor & EmployeeAdvisee));
    } else if (id) onAction?.(id);
  }, [id, item, isOppMatch, onAction, onRemove]);

  const handleCancel = useCallback(() => setConfirmOpen(false), []);
  const handleExited = useCallback(() => setConfirmMounted(false), []);

  const handleAction = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (isOppMatch || isAdvisor || isDevPlanAdvisor) {
      if (id) onAction?.(id);
    } else if (delegator) {
      setConfirmOpen(true);
      setConfirmMounted(true);
    }
  }, [id, delegator, isOppMatch, isAdvisor, isDevPlanAdvisor, onAction]);

  const handleSelectSwitch = useCallback(() => onSelectSwitch?.(id), [id, onSelectSwitch]);
  const handleChange = useCallback(() => onSelect?.(item as (DevPlanAdvisor & OpportunityEmployee)), [item, onSelect]);

  const handleRemove = useCallback(() => {
    if (isOppMatch) {
      setConfirmOpen(true);
      setConfirmMounted(true);
    } else onRemove?.(item as (DevPlanAdvisor & OpportunityEmployee & SkillAdvisor & EmployeeAdvisee));
  }, [item, isOppMatch, onRemove]);

  const handleComplete = useCallback(() => onComplete?.(item as SkillAdvisor), [item, onComplete]);

  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setPopupOpen(false);
  }, []);
  const handlePopupOpen = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setPopupOpen(true);
  }, []);

  const [link, href] = useMemo(() => route && is_profile_accessible
    ? [injectParams(route, { employee_id: code }), undefined]
    : [undefined, getEmployeeContactUrl(code, email)],
    [code, email, route, is_profile_accessible, getEmployeeContactUrl]
  );

  const pendingAction = parentPending || actionPendingId === id;
  const disabled = parentDisabled || pendingAction || onCompletePendingId === id ? true : undefined;

  const hasPopup = withZoomInSkills || withDetailsPopup;
  const hasSkills = size(skills) > 0;
  const hasSelectSwitch = Boolean(onSelectSwitch);
  const withAdvisorySkills = HAS_MENTORING && withSkills && size(advisory_skills) >= 1;

  const actionFinished = Boolean(is_shortlisted || requested_at || (
    status && (status === EmployeeAdvisorStatus.active || status === EmployeeAdvisorStatus.completed)
  ));
  const canRemove = !is_selected && !is_shortlisted && (!status || status !== EmployeeAdvisorStatus.completed);
  const actionText = (actionFinished && actionFinishedLabel) || actionLabel;
  const action = onAction && actionLabel && (!isDevPlanAdvisor || size(advisory_skills) >= 1) ? (
    <Button
        color="primary"
        variant={isOppMatch ? 'contained' : 'outlined'}
        disableElevation={isOppMatch ? true : undefined}
        aria-label={formatMessage({ id: actionText, defaultMessage: actionText })}
        onClick={handleAction}
        disabled={disabled || !id || is_complete || actionFinished}
        startIcon={pendingAction ? <CircularProgress size={18} color="inherit"/> : undefined}
    >
      <FormattedMessage id={actionText} defaultMessage={actionText}/>
    </Button>
  ) : undefined;

  const matchIndicator = matchRateVariant ? (
    <MatchIndicator
        value={toSafeInteger(match_rate || current_match_rate)}
        variant={matchRateVariant === 'planned' ? 'planned' : 'normal'}
    />
  ) : undefined;
  const growthIndicator = growthRateVariant ? (
    <MatchIndicator
        value={toSafeInteger(growth_rate)}
        variant={growthRateVariant === 'planned' ? 'planned' : 'normal'}
        label="opportunities.growth_rate_value"
    />
  ) : undefined;

  const cardIsSelected = isSelected || (onSelect ? Boolean(is_selected) : undefined);
  const topLevelLink = !draggable && !withAdvisorySkills && !isOppMatch && !hasPopup && !action && !disabled;

  return (
    <ItemCard
        ref={ref}
        selected={cardIsSelected}
        header={onSelect || isSelected
          ? (isHighlighted && !cardIsSelected && (
            <Box flexGrow={1} display="flex" alignItems="flex-start" justifyContent="space-between" color="misc.starRating">
              <TagLabel variant="advisor"/>
              <StarRounded color="inherit" fontSize="medium" aria-label={ariaLabelPreferred}/>
            </Box>
          )) || <TagLabel variant="advisor"/>
        : undefined}
        footer={(isOppMatch || isAdvisor || isDevPlanAdvisor) && action ? (
          <Box pb={0.75} display="flex" alignItems="center" justifyContent="center">
            {action}
          </Box>
        ) : undefined}
        onSelect={onSelect ? handleChange : undefined}
        onRemoveClick={onRemove && canRemove ? handleRemove : undefined}
        sm={draggable ? null : undefined}
        md={draggable ? 6 : undefined}
        lg={draggable ? 4 : undefined}
        draggable={draggable}
        disabled={disabled}
        isDragging={isDragging}
        bottomPadding={(!HAS_MENTORING || !withSkills || (size(skills) < 1 && size(advisory_skills) < 1)) &&
          !withDetailsPopup ? true : undefined}
        light
        link={link && topLevelLink ? link : undefined}
        href={href && topLevelLink ? href : undefined}
        status={(HAS_MENTORING && (withCompletedState && (
          is_complete || status === EmployeeAdvisorStatus.completed
        ) && (
          confirmed_at || status_updated_at
        ) ? (
          <BoxTypography pt={1} pl={0.25} variant="body2" fontStyle="italic">
            <FormattedMessage
                id="skill.advisors.completed"
                values={{
                  date: (
                    <FormattedDate
                        value={getJsDateFromISO((confirmed_at || status_updated_at) as string)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...longDateOptions}
                    />
                  )
                }}
            />
          </BoxTypography>
        ) : (onComplete && (status === EmployeeAdvisorStatus.active || (requested_at && !is_complete)) && (
          <Box pt={1} display="flex" alignItems="flex-end" justifyContent="center">
            <Button
                color="secondary"
                variant="text"
                onClick={handleComplete}
                disabled={disabled}
                startIcon={onCompletePendingId === id ? <CircularProgress size={16} color="inherit"/> : undefined}
            >
              <FormattedMessage id="hr.dev_plan.button.mark_as_completed"/>
            </Button>
          </Box>
        )) || undefined)) || undefined}
    >
      {isOppMatch || isAdvisee ? <SkillNewTag active={is_new || isNew}/> : undefined}
      <Box display="flex">
        {isOppMatch ? undefined : (
          <Box
              pr={1}
              pt={0.25}
              color={avatarVariant === 'grey' ? 'misc.selectedBorder' : undefined}
              className={draggable ? avatar : undefined}
          >
            {avatarVariant === 'phone' || avatarVariant === 'advisor' || avatarVariant === 'advisee'
              ? <PhoneInTalk fontSize="large" color="primary"/>
              : <AccountCircleAlt fontSize="large" color={avatarVariant === 'grey' ? 'inherit' : 'primary'}/>}
          </Box>
        )}
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" className={titleContainer}>
            <Box color="secondary.main" className={titleRow}>
              {draggable || withAdvisorySkills || isOppMatch || ((hasPopup || action) && (href || link)) ? (
                <StandardLink
                    to={disabled ? undefined : link}
                    href={disabled ? undefined : href}
                >
                  {getFullName(first_name, last_name, code)}
                </StandardLink>
              ) : getFullName(first_name, last_name, code)}
              {delegator ? (
                <Box color="text.label" fontWeight={fontWeightRegular} component="span">
                  {` (${code})`}
                </Box>
              ) : undefined}
            </Box>
          </Box>
          <Box
              color={withSkills ? 'text.primary' : 'text.label'}
              display="flex"
              flexDirection="column"
              className={withSkills ? infoContainer2 : infoContainer}
          >
            <Typography
                variant={withSkills || isOppMatch ? 'body2' : 'body1'}
                className={withSkills || isOppMatch ? jobRow2 : jobRow}
            >
              {title}
            </Typography>
            <BoxTypography
                pt={withSkills || isOppMatch ? undefined : 1}
                variant={withSkills || isOppMatch ? 'body2' : 'body1'}
            >
              {locationStr || <FormattedMessage id="employees.not_available"/>}
            </BoxTypography>
            {isOppMatch && is_applicant ? (
              <Box pt={1.25}>
                <TagLabel small variant="outlined" title="opportunities.matches.applicant"/>
              </Box>
            ) : undefined}
          </Box>
          {delegator ? (
            <Box
                color="text.label"
                display="flex"
                flexDirection="column"
                pt={2}
            >
              <BoxTypography variant="body2" fontStyle="italic" color="text.secondary">
                <FormattedMessage id="delegates.delegated_by"/>
              </BoxTypography>
              <Box display="flex" alignItems="flex-start" className={titleContainer}>
                <BoxTypography className={delegatorRow} color="text.label" variant="body1">
                  {getFullName(delegator_first_name, delegator_last_name)}
                  {` (${delegator_code})`}
                </BoxTypography>
              </Box>
              <Box pt={1}>
                {action}
              </Box>
            </Box>
          ) : undefined}
        </Box>
        {matchIndicator || growthIndicator || hasPopup || hasSelectSwitch ? (
          <Box
              pl={0.75}
              minWidth={withDetailsPopup ? '4.5rem' : undefined}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent={withDetailsPopup ? (matchIndicator && 'space-between') || 'flex-end' : undefined}
          >
            {matchIndicator ? (hasPopup && hasSkills && (
              <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={handlePopupOpen}
              >
                {matchIndicator}
              </IconButton>
            )) || matchIndicator : undefined}
            {growthIndicator ? (
              <Box pt={1.25} display="flex" flexDirection="column">
                {hasPopup && hasSkills ? (
                  <IconButton
                      size="small"
                      disabled={disabled}
                      onClick={handlePopupOpen}
                  >
                    {growthIndicator}
                  </IconButton>
                ) : growthIndicator}
              </Box>
            ) : undefined}
            {hasSelectSwitch ? (
              <Box py={0.75}>
                <OnOffSwitch
                    value={Boolean(selected)}
                    onChange={handleSelectSwitch}
                    disabled={disabled}
                />
              </Box>
            ) : undefined}
            {hasPopup && hasSkills ? (
              <IconButton
                  size="large"
                  color="primary"
                  disabled={disabled}
                  onClick={handlePopupOpen}
                  className={zoomInBtn}
              >
                <ZoomInIcon color="inherit" fontSize="large"/>
              </IconButton>
            ) : undefined}
          </Box>
        ) : undefined}
      </Box>
      {withSkills ? map(skills, (skill, index) => (
        <Fragment key={skill.id}>
          <Box pt={index > 0 ? 2.25 : 0} pb={1} px={0.25}>
            <TruncatedTextLink
                text={skill.title}
                plain
                variant="inherit"
                maxLines={1}
                disabled={disabled}
            />
          </Box>
          <SkillLevelGauge
              level={getSkillCurrentLevelOnly(skill)}
              inferredLevel={skill.inferred_level}
              isInferenceNewer={skill.is_inference_newer}
          />
        </Fragment>
      )) : undefined}
      {withAdvisorySkills ? (
        <Box pt={1.125} display="flex" alignItems="center" flexWrap="wrap">
          {map(advisory_skills, (skill) => (
            <Box
                key={skill.id}
                my={0.375}
                mr={0.75}
                px={1}
                py={0.25}
                bgcolor="background.card"
                borderRadius={shape.tinyBorderRadius}
            >
              <SkillName
                  skill={skill}
                  level={skill.current_level as SkillLevel}
                  skillPath={PATH_SKILL}
                  variant="body1"
                  maxLines={1}
                  disabled={disabled}
              />
            </Box>
          ))}
        </Box>
      ) : undefined}
      {(delegator || isOppMatch) && confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            text="employees.remove.question"
            values={dialogValues}
            confirmLabel="common.button.remove"
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      {hasPopup && hasSkills && withZoomInSkills ? (
        <EmployeeSkillsPopup
            isOpen={popupOpen}
            title={getName(first_name)}
            skills={skills}
            onClose={handlePopupClose}
        />
      ) : undefined}
      {hasPopup && hasSkills && withDetailsPopup ? (
        <EmployeeDetailsPopup
            employee={item as TalentEmployeeObject}
            isOpen={popupOpen}
            onClose={handlePopupClose}
            route={route}
        />
      ) : undefined}
    </ItemCard>
  );
});

EmployeeCard.displayName = 'EmployeeCard';

EmployeeCard.propTypes = EmployeeCardPropTypes;

export default memo(EmployeeCard);
