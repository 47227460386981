import {
  memo, useCallback, useContext, useEffect, useMemo, useRef, useState, type FunctionComponent, type ChangeEvent
} from 'react';
import PropTypes from 'prop-types';
import toString from 'lodash/toString';
import { useMutation } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
// TM UI Components
import { isValidEmail } from '@empathco/ui-components/src/helpers/strings';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { VERIFY_EMAIL_QUERY } from '../graphql/VerifyEmail';
import { VerifyEmailDocument } from '../graphql/types';
import { PATH_LOGIN_LINK_SSO } from '../config/paths';
import useCustomerSettings from '../config/customer';
import useRedirects from '../helpers/redirect';
import { getEmail, saveEmail } from '../helpers/storage';
import { GlobalContext } from '../context/global';
import SplashText from '../elements/SplashText';
import Screen from '../v3/Screen';

type LoginScreenProps = {
  // for Storybook only
  testAction?: number;
}

const LoginScreenPropTypes = {
  testAction: PropTypes.number
};

// eslint-disable-next-line complexity
const LoginScreen: FunctionComponent<LoginScreenProps> = ({
  testAction
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { HAS_MULTIPLE_SSO, HAS_LOGIN } = useCustomerSettings();
  const { getRedirectionPath } = useRedirects();
  const hasMultipleSSO = HAS_MULTIPLE_SSO || !HAS_LOGIN;

  const inputRef = useRef<HTMLInputElement>();
  const { current } = inputRef;

  const {
    token,
    user: { data: user, pending, failed },
    authEmail: { pending: pendingAuthEmail, failed: failedAuthEmail, params }, sendAuthEmail
  } = useContext(GlobalContext);

  // verifying email
  const { mutate: verifyEmail, loading: pendingVerify, failed: failedVerify } = useMutationMethod({
    // key: 'verifyEmail', // `success: false` means the email is invalid
    mutation: useMutation(VERIFY_EMAIL_QUERY as typeof VerifyEmailDocument)
  });

  const [email, setEmail] = useState<string>((hasMultipleSSO ? getEmail() : params?.email) || '');
  const [invalidEmail, setInvalidEmail] = useState(testAction === 3);
  const [pendingRedirect, setPendingRedirect] = useState(testAction === 1);
  const [waiting, setWaiting] = useState(pendingAuthEmail === false && failedAuthEmail === false);

  const pendingAction = pendingVerify || pendingRedirect;

  const emailLabel = useMemo(() => formatMessage({ id: 'login.email' }), [formatMessage]);

  useEffect(() => {
    current?.focus?.();
  }, [current]);

  const handleEmailChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event?.preventDefault();
    setEmail(toString(event?.target?.value));
    setInvalidEmail(false);
  }, []);

  const handleClick = useCallback(() => {
    if (hasMultipleSSO) {
      setInvalidEmail(false);
      setPendingRedirect(true);
      saveEmail(email);
      verifyEmail?.({
        variables: { email },
        onCompleted: (data) => {
          if (data?.verifyEmail?.success) {
            window.location.assign(`${PATH_LOGIN_LINK_SSO}?email=${encodeURIComponent(email)}`);
          } else {
            setInvalidEmail(true);
            setPendingRedirect(false);
          }
        },
        onError: () => {
          setInvalidEmail(false);
          setPendingRedirect(false);
        }
      });
    } else sendAuthEmail?.({
      email,
      onSuccess: () => setWaiting(true)
    });
  }, [email, verifyEmail, sendAuthEmail, hasMultipleSSO]);

  useEffect(() => {
    if (testAction === 2) verifyEmail?.({ variables: { email: 'test@example.com' } });
  }, [testAction, verifyEmail]);

  if (token && user && !pending && !failed) return <Navigate replace to={getRedirectionPath(user)}/>;

  return pending ? <LinearProgress/> : (
    <>
      <Screen appbar="empty" splash>
        <SplashText
            variant={(hasMultipleSSO && 'multiple_sso') || (waiting ? 'logging_in' : 'login')}
            name={email}
            onClick={waiting ? undefined : handleClick}
            pending={pendingAuthEmail || pendingAction}
            disabled={!isValidEmail(email)}
        >
          {!waiting && (
            <Box width="28rem" mx="auto" pt={4} px={6}>
              <TextField
                  inputRef={inputRef}
                  variant="standard"
                  color="secondary"
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  label={emailLabel}
                  onChange={handleEmailChange}
                  value={email}
                  disabled={pendingAuthEmail || pendingAction ? true : undefined}
              />
            </Box>
          )}
        </SplashText>
      </Screen>
      <ActionFailedAlert
          message="splash.multiple_sso.error"
          open={invalidEmail}
      />
      <ActionFailedAlert
          message="splash.multiple_sso.verify.error"
          open={failedVerify}
      />
      <ActionFailedAlert
          long
          message="splash.login.error"
          open={failedAuthEmail}
      />
    </>
  );
};

LoginScreen.propTypes = LoginScreenPropTypes;

export default memo(LoginScreen);
