import { forwardRef, memo, useCallback, useContext, useEffect, type Component, type ReactNode, type ReactElement } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import head from 'lodash/head';
import last from 'lodash/last';
// Material UI imports
import { type PaginationProps } from '@mui/material/Pagination';
// TM UI Components
import { MAX_PAGE_SIZE, MIN_PAGE_SIZE } from '@empathco/ui-components/src/config/params';
import { ReactComponent } from '@empathco/ui-components/src/helpers/react';
import Pagination, { hasPaginationElements } from '@empathco/ui-components/src/elements/Pagination';
// local imports
import { getSettingsIntValue } from '../helpers/context';
import { FilterValues } from '../hooks/useFilters';
import { DataContext } from '../context';

const PAGINATION_CONTROLS_DISPLAY_NAME = 'PaginationControls' as const;

export type PaginationControlsComponent = ReactComponent<PaginationControlsProps>;

export const hasPagination = (pagination?: PaginationControlsComponent | null) => {
  if (!pagination) return false;
  if (pagination.type?.displayName !== PAGINATION_CONTROLS_DISPLAY_NAME) return true;
  const { loaded, total, pending, totalOverride } = pagination.props || {};
  return hasPaginationElements(loaded, total, pending) || Boolean(totalOverride);
};

type PaginationControlsProps = {
  settingsId?: string;
  init?: FilterValues | null;
  loaded: boolean;
  pending?: boolean | null;
  loading?: boolean | null;
  total?: number | null;
  totalMessage?: string;
  totalOverride?: ReactNode | ReactNode[];
  currentPage: number;
  pageSizes?: readonly number[];
  onPageSelected: (page: number) => void;
  onPageSize: (pageSize: number) => void;
  disabled?: boolean | null;
}

const PaginationControlsPropTypes = {
  settingsId: PropTypes.string,
  init: PropTypes.object,
  loaded: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  loading: PropTypes.bool,
  total: PropTypes.number,
  totalMessage: PropTypes.string,
  totalOverride: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  currentPage: PropTypes.number.isRequired,
  pageSizes: PropTypes.array,
  onPageSelected: PropTypes.func.isRequired,
  onPageSize: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

// eslint-disable-next-line complexity
const PaginationControls = forwardRef<Component<PaginationProps>, PaginationControlsProps>(({
  settingsId,
  init,
  loaded,
  pending = false,
  loading = false,
  total,
  totalMessage,
  totalOverride,
  currentPage,
  pageSizes,
  onPageSelected,
  onPageSize,
  disabled: parentDisabled = false
}, ref) => {
  const minPageSize = head(pageSizes) || MIN_PAGE_SIZE;
  const maxPageSize = last(pageSizes) || MAX_PAGE_SIZE;

  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const settingsLoaded = !settingsId || (pendingSettings === false && failedSettings === false && Boolean(settingsData));
  const settings = settingsLoaded ? settingsData : null;

  const settingsPageSize = (
    init ? init.page_size : settingsId && getSettingsIntValue(settings, `${settingsId}__pagesize`)
  ) || undefined;

  const handlePageSizeChange = useCallback((pSize: number): void => {
    if (settingsId && settingsPageSize !== pSize) updateSettings?.({
      [`${settingsId}__pagesize`]: pSize
    });
    if (onPageSize) onPageSize(pSize);
  }, [onPageSize, settingsId, settingsPageSize, updateSettings]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      const pSize = init ? init.page_size
        : (settingsId && getSettingsIntValue(settings, `${settingsId}__pagesize`)) || undefined;
      if (onPageSize) onPageSize(pSize && pSize > minPageSize && pSize <= maxPageSize ? pSize : minPageSize);
    }
  }, [settingsLoaded, pendingSettingsUpdate, minPageSize, maxPageSize, settings, settingsId, init, onPageSize]);

  // pagination must be invisible in this case:
  if (!hasPaginationElements(loaded, total, pending)) return (totalOverride as ReactElement) || null;

  return (
    <Pagination
        ref={ref}
        loaded={loaded}
        pending={pending}
        loading={loading}
        total={total}
        totalMessage={totalMessage}
        totalOverride={totalOverride}
        currentPage={currentPage}
        pageSize={settingsLoaded && !pendingSettingsUpdate ? settingsPageSize : undefined}
        pageSizes={pageSizes}
        onPageSelected={onPageSelected}
        onPageSize={handlePageSizeChange}
        disabled={parentDisabled || !settingsLoaded || pendingSettingsUpdate}
    />
  );
});

PaginationControls.displayName = PAGINATION_CONTROLS_DISPLAY_NAME;

PaginationControls.propTypes = PaginationControlsPropTypes;

const PaginationControlsMemo = memo(PaginationControls);

PaginationControlsMemo.displayName = PAGINATION_CONTROLS_DISPLAY_NAME;

export default PaginationControlsMemo;
