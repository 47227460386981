/* eslint-disable max-lines */
import {
  memo, useCallback, useEffect, useMemo, useState, useLayoutEffect, useContext, useReducer,
  type FunctionComponent, type MouseEvent, type ReactNode
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import max from 'lodash/max';
import min from 'lodash/min';
import size from 'lodash/size';
import find from 'lodash/find';
import head from 'lodash/head';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import without from 'lodash/without';
import indexOf from 'lodash/indexOf';
import toString from 'lodash/toString';
import toSafeInteger from 'lodash/toSafeInteger';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useLazyQuery, useMutation, useReactiveVar, type ApolloCache } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
// TM UI Components
import { getFullName, getStringifiedIds } from '@empathco/ui-components/src/helpers/strings';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import ViewSwitch, { TABLE_VIEW, TILES_VIEW } from '@empathco/ui-components/src/elements/ViewSwitch';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import SimpleTooltip from '@empathco/ui-components/src/elements/SimpleTooltip';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { NEW_DEV_PLAN } from '../graphql/NewDevPlan';
import { UPDATE_DEV_PLAN } from '../graphql/UpdateDevPlan';
import { DEV_PLAN_EMPLOYEES_QUERY } from '../graphql/DevPlanEmployees';
import { DEV_PLAN_COURSES_QUERY } from '../graphql/DevPlanCourses';
import { DEV_PLAN_ADVISORS_QUERY } from '../graphql/DevPlanAdvisors';
import { DEV_PLAN_OPPORTUNITIES_QUERY } from '../graphql/DevPlanOpportunities';
import { DEV_PLAN_ACTIVITIES_QUERY } from '../graphql/DevPlanActivities';
import { DEV_PLAN_SUGGESTED_ACTIVITIES_QUERY } from '../graphql/DevPlanSuggestedActivities';
import { DEV_PLAN_QUERY } from '../graphql/DevPlan';
import { JOB_DETAILS_QUERY } from '../graphql/JobDetails';
import {
  DevPlanAdvisorType, DevPlanCourseType, DevPlanOpportunityType, SkillActivity,
  Opportunity, OpportunitySkillStatus, SkillWithLevel, JobWithSkills,
  NewDevPlanDocument, UpdateDevPlanDocument, DevPlanEmployeesDocument,
  DevPlanQuery, DevPlanCoursesDocument, DevPlanAdvisorsDocument, DevPlanOpportunitiesDocument,
  DevPlanActivitiesDocument, DevPlanSuggestedActivitiesDocument,
  JobDetailsDocument
} from '../graphql/types';
import {
  CohortDetails, DevPlanAdvisor, DevPlanCourse, DevPlanDetails, DevPlanEmployee,
  TalentEmployeeObject, TalentEmployeeDetails, DevPlanOpportunity, JobLookupItem
} from '../graphql/customTypes';
import { SKILL_LEVEL_MIN, SkillLevel } from '../models/skill';
import {
  PATH_HR_DEV_PLAN, PATH_HR_DEV_PLAN_EDITOR, PATH_JOB, PATH_MY_DEV_PLAN, PATH_MY_DEV_PLAN_EDITOR, PATH_MY_OPPORTUNITY
} from '../config/paths';
import useCustomerSettings from '../config/customer';
import { getActualActivities, getDefaultTargetSkillIds, updateCachedResult } from '../helpers/graphql';
import { toggleReducer } from '../helpers/reducers';
import { devPlanViewVar } from '../context/variables';
import { GlobalContext } from '../context/global';
import DevPlanAvatar from '../elements/DevPlanAvatar';
import CoursesAndAdvisorsGrid from '../v3/CoursesAndAdvisorsGrid';
import EmployeeTalentBand from '../v3/EmployeeTalentBand';
import EmployeeDetailsPopup from '../v3/EmployeeDetailsPopup';
import AddJobDialog from '../widgets/AddJobDialog';
import AddCourseOrAdvisorPopover from '../widgets/AddCourseOrAdvisorPopover';
import SkillsSelector from '../widgets/SkillsSelector';
import CustomActivitiesDialog from '../widgets/CustomActivitiesDialog';
import DevPlanCohort from '../components/DevPlanCohort';
import DevPlanAccordion from '../components/DevPlanAccordion';
// SCSS imports
import { btn, discardBtn, ctrlPanel, activitiesBtn } from './DevPlanEditor.module.scss';

type DevPlanEditorPanelProps = {
  devplan?: DevPlanDetails | null;
  cohort?: CohortDetails | null;
  employee?: TalentEmployeeDetails | null;
  pending?: boolean | null;
  failed?: boolean | null;
  isMyPlan?: boolean;
  // for Storybook only
  testNew?: boolean | null;
  testUpdate?: boolean | null;
  testPending?: boolean | null;
}

const DevPlanEditorPanelPropTypes = {
  devplan: PropTypes.object as Validator<DevPlanDetails>,
  cohort: PropTypes.object as Validator<CohortDetails>,
  employee: PropTypes.object as Validator<TalentEmployeeDetails>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  testNew: PropTypes.bool,
  testUpdate: PropTypes.bool,
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const DevPlanEditor: FunctionComponent<DevPlanEditorPanelProps> = ({
  devplan,
  cohort,
  employee,
  pending: parentPending = false,
  failed = false,
  isMyPlan = false,
  testNew,
  testUpdate,
  testPending
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  const { user: { data: user }, paths: { supvEmplPath } } = useContext(GlobalContext);

  const view = useReactiveVar(devPlanViewVar);

  const { mutate: newDevPlan, loading: newPending, failed: newFailed } = useMutationMethod({
    mutation: useMutation(NEW_DEV_PLAN as typeof NewDevPlanDocument)
  });
  const { mutate: updateDevPlanJob, loading: updateJobPending, failed: updateJobFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_DEV_PLAN as typeof UpdateDevPlanDocument)
  });
  const { mutate: updateDevPlan, loading: updatePending, failed: updateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_DEV_PLAN as typeof UpdateDevPlanDocument)
  });

  const {
    id: devplan_id, job: devplanJob, opportunity: devplanOpp, employee: devplanEmployee, target_skills
  } = devplan || {};
  // const isIndividual = Boolean(devplanEmployee || isMyPlan);
  const currentJobCode = isMyPlan ? user?.current_job?.code : (devplanEmployee || employee)?.current_job?.code;
  const hasTargetSkillActivities = useMemo(() => findIndex(target_skills, ({ activities }) => size(activities) > 0) >= 0,
    [target_skills]);

  const savedTargetSkillIds = useMemo(() => target_skills ? transform(target_skills, (result, { id, is_selected }) => {
    if (is_selected) result[id] = true;
  }, {} as Record<string, boolean>) : undefined, [target_skills]);
  const defaultTargetSkillIds = useMemo(() =>
    transform(isNull(testNew) ? [] : getDefaultTargetSkillIds(target_skills), (result, id) => {
      result[id] = true;
    }, {} as Record<string, boolean>), [target_skills, testNew]);

  const [selectedTargetSkillIds, setSelectedTargetSkillIds] = useState(defaultTargetSkillIds);
  useLayoutEffect(() => {
    setSelectedTargetSkillIds(defaultTargetSkillIds);
  }, [defaultTargetSkillIds]);
  const hasTargetSkills = size(selectedTargetSkillIds) >= 1;

  const toggleTargetSkill = useCallback((id: number) => setSelectedTargetSkillIds((prevIds) =>
    prevIds[id] ? omit(prevIds, toString(id)) : { ...prevIds, [id]: true }
  ), []);

  const targetSkillsChanged = useMemo(() => Boolean(savedTargetSkillIds) &&
    !isEqual(selectedTargetSkillIds, savedTargetSkillIds),
    [selectedTargetSkillIds, savedTargetSkillIds]);

  const selectedSkillIds = useMemo(() => map(keys(selectedTargetSkillIds), toSafeInteger), [selectedTargetSkillIds]);
  const targetSkillIds = useMemo(() => targetSkillsChanged ? selectedSkillIds : undefined,
    [selectedSkillIds, targetSkillsChanged]);

  const targetSkills = useMemo(() =>
    (target_skills && filter(target_skills, ({ id }) => selectedTargetSkillIds[id])) || undefined,
    [selectedTargetSkillIds, target_skills]);

  const targetCode = devplanJob?.code || (isMyPlan ? (devplanOpp as Opportunity)?.opportunity_match_id : devplanOpp?.id);
  const targetPath = (devplanJob && PATH_JOB) ||
    (devplanOpp && isMyPlan && PATH_MY_OPPORTUNITY) || // TODO: HRBP path for opportunity?
    undefined;

  const [addedCourses, setAddedCourses] = useState<DevPlanCourse[]>([]);
  const [addedAdvisors, setAddedAdvisors] = useState<DevPlanAdvisor[]>([]);
  const [addedOpps, setAddedOpps] = useState<DevPlanOpportunity[]>([]);
  const [removedCourses, setRemovedCourses] = useState<number[]>([]);
  const [removedAdvisors, setRemovedAdvisors] = useState<number[]>([]);
  const [removedOpps, setRemovedOpps] = useState<number[]>([]);
  const [removedActivityIds, setRemovedActivityIds] = useState<number[]>([]);
  useLayoutEffect(() => {
    setRemovedCourses([]);
    setRemovedAdvisors([]);
    setRemovedOpps([]);
    setRemovedActivityIds([]);
  }, [devplan_id]);

  // lazy load current job details (for individual dev plan)
  const { query: getCurrentJob, pending: pendingCurJob, failed: failedCurJob, results: curJobDetails } = useQueryObject({
    data: undefined as unknown as JobWithSkills,
    key: 'jobDetails',
    flatResults: true,
    lazyQuery: useLazyQuery(JOB_DETAILS_QUERY as typeof JobDetailsDocument)
  });
  const currentJobDetails = pendingCurJob || failedCurJob || size(curJobDetails?.skills) < 1 ? undefined : curJobDetails;

  // lazy load dev plan employees (with real skill levels)
  const { query: getOrigEmpls, pending: pendingOrigEmpls, failed: failedOrigEmpls, results: origEmpls } = useQueryCounted({
    data: undefined as unknown as DevPlanEmployee,
    key: 'devplanEmployees',
    lazyQuery: useLazyQuery(DEV_PLAN_EMPLOYEES_QUERY as typeof DevPlanEmployeesDocument)
  });
  const origEmployees = !hasTargetSkillActivities || failedOrigEmpls ? null : origEmpls;

  // lazy load dev plan employees
  const { query: getEmployees, pending: pendingEmployees, failed: failedEmployees, results: employeesData } = useQueryCounted({
    data: undefined as unknown as DevPlanEmployee,
    key: 'devplanEmployees',
    lazyQuery: useLazyQuery(DEV_PLAN_EMPLOYEES_QUERY as typeof DevPlanEmployeesDocument)
  });
  const employees = useMemo(() => employeesData ? map(employeesData, (empl) => ({
    ...empl,
    ...hasTargetSkills ? {
      skills: map(empl.skills, (skill) => ({
        ...skill,
        current_level: max([skill.current_level || SKILL_LEVEL_MIN, skill.inferred_level || SKILL_LEVEL_MIN]),
        is_inference_newer: false,
        inferred_level: null
      }))
    } : {
      current_match_rate: null,
      initial_match_rate: null,
      skills: null
    }
  })) : employeesData, [employeesData, hasTargetSkills]);

  // lazy load dev plan courses (selected, suggested)
  const {
    query: getSelectedCourses, pending: pendingSelectedCourses, failed: failedSelectedCourses, results: savedCourses
  } = useQueryCounted({
    data: undefined as unknown as DevPlanCourse,
    key: 'devplanCourses',
    lazyQuery: useLazyQuery(DEV_PLAN_COURSES_QUERY as typeof DevPlanCoursesDocument)
  });
  const {
    query: getSuggestedCourses, pending: pendingSuggestedCourses, failed: failedSuggestedCourses, results: suggestedCourses
  } = useQueryCounted({
    data: undefined as unknown as DevPlanCourse,
    key: 'devplanCourses',
    lazyQuery: useLazyQuery(DEV_PLAN_COURSES_QUERY as typeof DevPlanCoursesDocument)
  });
  const pendingCourses = HAS_COURSES ? pendingSelectedCourses || (hasTargetSkills && pendingSuggestedCourses) : false;
  const failedCourses = HAS_COURSES ? failedSelectedCourses || (hasTargetSkills && failedSuggestedCourses) : false;

  const [selCourses, setSelCourses] = useState(savedCourses);
  useLayoutEffect(() => {
    setSelCourses(savedCourses);
  }, [savedCourses]);
  const selectedCourses = useMemo(() => selCourses
    ? filter(selCourses, ({ covered_skills }) => findIndex(covered_skills, ({ id }) => selectedTargetSkillIds[id]) >= 0)
    : selCourses,
    [selCourses, selectedTargetSkillIds]);
  const coursesChanged = useMemo(() => !isEqual(sortBy(map(savedCourses, 'id')), sortBy(map(selectedCourses, 'id'))),
    [savedCourses, selectedCourses]);

  const excludeCourseIds = useMemo(() => HAS_COURSES ? map(selectedCourses, 'id') : [], [selectedCourses, HAS_COURSES]);
  const removedCourseIds = useMemo(
    () => HAS_COURSES && selectedCourses
      ? union(map(selectedCourses, 'id'), removedCourses)
      : (!HAS_COURSES && []) || undefined,
    [selectedCourses, removedCourses, HAS_COURSES]
  );
  const [courses, courseHighlighRating, coursesLoaded] = useMemo(() => {
    if (!HAS_COURSES || !selectedCourses || (hasTargetSkills && !suggestedCourses)) {
      return [HAS_COURSES ? undefined : [] as DevPlanCourse[], undefined, !HAS_COURSES];
    }
    const unselectedCourses = uniqBy([
      ...hasTargetSkills ? filter(suggestedCourses, ({ id }) =>
        indexOf(removedCourses, id) < 0 && findIndex(selectedCourses, { id }) < 0
      ) : [],
      ...filter(addedCourses, ({ id }) => findIndex(selectedCourses, { id }) < 0)
    ], 'id');
    const ratings = map(unselectedCourses, ({ preferred_rating }) => toSafeInteger(preferred_rating));
    const maxRating = max(ratings);
    const minRating = min(ratings);
    const allCourses = [
      ...map(selectedCourses, (course) => isNil(course.is_selected) ? { ...course, is_selected: true } : course),
      ...unselectedCourses
    ];
    return [allCourses, maxRating === minRating ? undefined : maxRating, Boolean(allCourses)];
  }, [selectedCourses, suggestedCourses, addedCourses, removedCourses, hasTargetSkills, HAS_COURSES]);

  // lazy load dev plan advisors (selected, suggested)
  const {
    query: getSelectedAdvisors, pending: pendingSelectedAdvisors, failed: failedSelectedAdvisors, results: savedAdvisors
  } = useQueryCounted({
    data: undefined as unknown as DevPlanAdvisor,
    key: 'devplanAdvisors',
    lazyQuery: useLazyQuery(DEV_PLAN_ADVISORS_QUERY as typeof DevPlanAdvisorsDocument)
  });
  const {
    query: getSuggestedAdvisors, pending: pendingSuggestedAdvisors, failed: failedSuggestedAdvisors, results: suggestedAdvisors
  } = useQueryCounted({
    data: undefined as unknown as DevPlanAdvisor,
    key: 'devplanAdvisors',
    lazyQuery: useLazyQuery(DEV_PLAN_ADVISORS_QUERY as typeof DevPlanAdvisorsDocument)
  });
  const pendingAdvisors = HAS_MENTORING ? pendingSelectedAdvisors || (hasTargetSkills && pendingSuggestedAdvisors) : false;
  const failedAdvisors = HAS_MENTORING ? failedSelectedAdvisors || (hasTargetSkills && failedSuggestedAdvisors) : false;

  const [selAdvisors, setSelAdvisors] = useState(savedAdvisors);
  useLayoutEffect(() => {
    setSelAdvisors(savedAdvisors);
  }, [savedAdvisors]);
  const selectedAdvisors = useMemo(() => selAdvisors
    ? filter(selAdvisors, ({ advisory_skills }) => findIndex(advisory_skills, ({ id }) => selectedTargetSkillIds[id]) >= 0)
    : selAdvisors, [selAdvisors, selectedTargetSkillIds]);
  const advisorsChanged = useMemo(() => !isEqual(sortBy(map(savedAdvisors, 'id')), sortBy(map(selectedAdvisors, 'id'))),
    [savedAdvisors, selectedAdvisors]);

  const excludeAdvisorIds = useMemo(() => HAS_MENTORING ? map(selectedAdvisors, 'id') : [], [selectedAdvisors, HAS_MENTORING]);
  const removedAdvisorIds = useMemo(
    () => HAS_MENTORING && selectedAdvisors
      ? union(map(selectedAdvisors, 'id'), removedAdvisors)
      : (!HAS_MENTORING && []) || undefined,
    [selectedAdvisors, removedAdvisors, HAS_MENTORING]
  );
  const [advisors, advisorHighlightCount, advisorsLoaded] = useMemo(() => {
    if (!HAS_MENTORING || !selectedAdvisors || (hasTargetSkills && !suggestedAdvisors)) {
      return [HAS_MENTORING ? undefined : [] as DevPlanAdvisor[], undefined, !HAS_MENTORING];
    }
    const unselectedAdvisors = uniqBy([
      ...hasTargetSkills ? filter(suggestedAdvisors, ({ id }) =>
        indexOf(removedAdvisors, id) < 0 && findIndex(selectedAdvisors, { id }) < 0
      ) : [],
      ...filter(addedAdvisors, ({ id }) => findIndex(selectedAdvisors, { id }) < 0)
    ], 'id');
    const activeMentorCounts = map(unselectedAdvisors, ({ active_mentor_count }) => toSafeInteger(active_mentor_count));
    const maxCount = max(activeMentorCounts);
    const minCount = min(activeMentorCounts);
    const allAdvisors = uniqBy([
        ...map(selectedAdvisors, (advisor) => isNil(advisor.is_selected) ? { ...advisor, is_selected: true } : advisor),
        ...unselectedAdvisors
      ], 'id');
    return [allAdvisors, maxCount === minCount ? undefined : minCount, Boolean(allAdvisors)];
  }, [selectedAdvisors, suggestedAdvisors, addedAdvisors, removedAdvisors, hasTargetSkills, HAS_MENTORING]);

  // lazy load dev plan opportunities (selected, suggested)
  const {
    query: getSelectedOpps, pending: pendingSelectedOpps, failed: failedSelectedOpps, results: savedOpps
  } = useQueryCounted({
    data: undefined as unknown as DevPlanOpportunity,
    key: 'devplanOpportunities',
    lazyQuery: useLazyQuery(DEV_PLAN_OPPORTUNITIES_QUERY as typeof DevPlanOpportunitiesDocument)
  });
  const {
    query: getSuggestedOpps, pending: pendingSuggestedOpps, failed: failedSuggestedOpps, results: suggestedOpps
  } = useQueryCounted({
    data: undefined as unknown as DevPlanOpportunity,
    key: 'devplanOpportunities',
    lazyQuery: useLazyQuery(DEV_PLAN_OPPORTUNITIES_QUERY as typeof DevPlanOpportunitiesDocument)
  });
  const pendingOpps = HAS_DEV_PLAN_OPPORTUNITIES ? pendingSelectedOpps || (hasTargetSkills && pendingSuggestedOpps) : false;
  const failedOpps = HAS_DEV_PLAN_OPPORTUNITIES ? failedSelectedOpps || (hasTargetSkills && failedSuggestedOpps) : false;

  const [selOpps, setSelOpps] = useState(savedOpps);
  useLayoutEffect(() => {
    setSelOpps(savedOpps);
  }, [savedOpps]);
  const selectedOpps = useMemo(() => selOpps
    ? filter(selOpps, ({ skills }) =>
        findIndex(skills, ({ id, status }) => status === OpportunitySkillStatus.required && selectedTargetSkillIds[id]) >= 0
      )
    : selOpps, [selOpps, selectedTargetSkillIds]);
  const oppsChanged = useMemo(() => !isEqual(sortBy(map(savedOpps, 'id')), sortBy(map(selectedOpps, 'id'))),
    [savedOpps, selectedOpps]);

  const excludeOppIds = useMemo(() => HAS_DEV_PLAN_OPPORTUNITIES ? map(selectedOpps, 'id') : [],
    [selectedOpps, HAS_DEV_PLAN_OPPORTUNITIES]);
  const removedOppIds = useMemo(
    () => HAS_DEV_PLAN_OPPORTUNITIES && selectedOpps
      ? union(map(selectedOpps, 'id'), removedOpps)
      : (!HAS_DEV_PLAN_OPPORTUNITIES && []) || undefined,
    [selectedOpps, removedOpps, HAS_DEV_PLAN_OPPORTUNITIES]
  );
  const [opportunities, oppsLoaded] = useMemo(() => {
    const allOpps = HAS_DEV_PLAN_OPPORTUNITIES && selectedOpps && (!hasTargetSkills || suggestedOpps)
      ? uniqBy([
          ...map(selectedOpps, (opp) => isNil(opp.is_selected) ? { ...opp, is_selected: true } : opp),
          ...hasTargetSkills ? filter(suggestedOpps, ({ id }) => indexOf(removedOpps, id) < 0) : [],
          ...addedOpps
        ], 'id')
      : (!HAS_DEV_PLAN_OPPORTUNITIES && []) || undefined;
    return [allOpps, Boolean(allOpps)];
  }, [selectedOpps, suggestedOpps, addedOpps, removedOpps, hasTargetSkills, HAS_DEV_PLAN_OPPORTUNITIES]);

  // lazy load dev plan activities (selected)
  const {
    query: getSavedActivities, pending: pendingSavedActivities, failed: failedSavedActivities, results: savedActivities
  } = useQueryCounted({
    data: undefined as unknown as SkillActivity,
    key: 'devplanActivities',
    lazyQuery: useLazyQuery(DEV_PLAN_ACTIVITIES_QUERY as typeof DevPlanActivitiesDocument)
  });
  const {
    query: getSuggestedActivities, pending: pendingSuggestedActivities, failed: failedSuggestedActivities,
    results: suggestedActivities
  } = useQueryCounted({
    data: undefined as unknown as SkillActivity,
    key: 'devplanSuggestedActivities',
    lazyQuery: useLazyQuery(DEV_PLAN_SUGGESTED_ACTIVITIES_QUERY as typeof DevPlanSuggestedActivitiesDocument)
  });
  const pendingActivities = pendingSavedActivities || (!isMyPlan && hasTargetSkills && pendingSuggestedActivities);
  const failedActivities = failedSavedActivities || (!isMyPlan && hasTargetSkills && failedSuggestedActivities);

  const pending = parentPending || (hasTargetSkillActivities && pendingOrigEmpls);

  const savedActivityIds = useMemo(() => savedActivities ? sortBy(map(savedActivities, 'id')) : undefined, [savedActivities]);

  const allCustomActivities = useMemo(() => !isMyPlan && savedActivities
    ? filter(uniqBy([...savedActivities, ...(hasTargetSkills && suggestedActivities) || []], 'id'), 'owner') : undefined,
    [savedActivities, suggestedActivities, hasTargetSkills, isMyPlan]);

  const [customActivities, setCustomActivities] = useState(allCustomActivities);
  useLayoutEffect(() => {
    setCustomActivities(allCustomActivities);
  }, [allCustomActivities]);

  const [selectedActivityIds, setSelectedActivityIds] = useState(savedActivityIds);
  useLayoutEffect(() => {
    setSelectedActivityIds(savedActivityIds);
  }, [savedActivityIds]);

  // target skill Activities relevant for the original Dev Plan Employee skill levels:
  const actualTargetSkillActivities = useMemo(() => hasTargetSkillActivities
    ? getActualActivities(targetSkills, null, origEmployees, null, true) || [] : [],
    [hasTargetSkillActivities, targetSkills, origEmployees]);

  // target skill Activities excluding removed ones:
  const targetSkillActivities = useMemo(() =>
    filter(actualTargetSkillActivities, ({ id }) => indexOf(removedActivityIds, id) < 0),
    [actualTargetSkillActivities, removedActivityIds]);

  // all actual Skill Activities and only effective (actually displayed) selected activity ids:
  const [activities, effectiveSelectedActivityIds] = useMemo(() => isMyPlan || customActivities ? transform(
    customActivities ? [...targetSkillActivities, ...customActivities] : targetSkillActivities,
    (result, activity) => {
      const is_selected = indexOf(selectedActivityIds, activity.id) >= 0;
      result[0].push({ ...activity, is_selected } as SkillActivity);
      if (is_selected) result[1].push(activity.id);
    },
    [[] as SkillActivity[], [] as number[]]
  ) : [undefined, undefined], [targetSkillActivities, customActivities, selectedActivityIds, isMyPlan]);

  const activitiesChanged = useMemo(() => !isEqual(savedActivityIds, sortBy(effectiveSelectedActivityIds)),
    [savedActivityIds, effectiveSelectedActivityIds]);

  const devplanChanged = targetSkillsChanged || coursesChanged || advisorsChanged || oppsChanged || activitiesChanged;

  useLayoutEffect(() => {
    if (devplan_id) getSavedActivities?.({ variables: { devplan_id } });
  }, [devplan_id, getSavedActivities]);

  useLayoutEffect(() => {
    if (devplan_id && hasTargetSkillActivities) getOrigEmpls?.({ variables: {
      devplan_id, input: {}, pathBuilder: pathBuilder as unknown as string }
    });
  }, [devplan_id, hasTargetSkillActivities, getOrigEmpls]);

  useLayoutEffect(() => {
    if (devplan_id && advisorsLoaded && coursesLoaded && oppsLoaded && effectiveSelectedActivityIds) {
      getEmployees?.({ variables: {
        devplan_id,
        input: {
          course_ids: sortBy(map(selectedCourses, 'id')),
          advisor_ids: sortBy(map(selectedAdvisors, 'id')),
          opportunity_ids: sortBy(map(selectedOpps, 'id')),
          activity_ids: sortBy(effectiveSelectedActivityIds),
          ...hasTargetSkills && targetSkillIds ? { target_skill_ids: sortBy(targetSkillIds) } : {}
        },
        pathBuilder: pathBuilder as unknown as string
      } });
    }
  }, [
    advisorsLoaded, coursesLoaded, oppsLoaded,
    selectedCourses, selectedAdvisors, selectedOpps, effectiveSelectedActivityIds, targetSkillIds,
    hasTargetSkills, devplan_id, getEmployees
  ]);

  useLayoutEffect(() => {
    if (devplan_id) {
      if (HAS_COURSES) getSelectedCourses?.({ variables: {
        devplan_id, type: DevPlanCourseType.courses, exclude_ids: '', target_skill_ids: ''
      } });
      if (HAS_MENTORING) getSelectedAdvisors?.({ variables: {
        devplan_id, type: DevPlanAdvisorType.advisors, exclude_ids: '', target_skill_ids: ''
      } });
      if (HAS_DEV_PLAN_OPPORTUNITIES) getSelectedOpps?.({ variables: {
        devplan_id, type: DevPlanOpportunityType.opportunities, exclude_ids: '', target_skill_ids: ''
      } });
    }
  }, [
    devplan_id, getSelectedCourses, getSelectedAdvisors, getSelectedOpps,
    HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES
  ]);

  useLayoutEffect(() => {
    if (HAS_COURSES && devplan_id && removedCourseIds && hasTargetSkills) getSuggestedCourses?.({ variables: {
      devplan_id,
      type: DevPlanCourseType.suggested_courses,
      exclude_ids: getStringifiedIds(removedCourseIds) || '',
      target_skill_ids: (targetSkillIds && getStringifiedIds(targetSkillIds)) || ''
    } });
  }, [removedCourseIds, targetSkillIds, hasTargetSkills, devplan_id, getSuggestedCourses, HAS_COURSES]);

  useLayoutEffect(() => {
    if (HAS_MENTORING && devplan_id && removedAdvisorIds && hasTargetSkills) getSuggestedAdvisors?.({ variables: {
      devplan_id,
      type: DevPlanAdvisorType.suggested_advisors,
      exclude_ids: getStringifiedIds(removedAdvisorIds) || '',
      target_skill_ids: (targetSkillIds && getStringifiedIds(targetSkillIds)) || ''
    } });
  }, [removedAdvisorIds, targetSkillIds, hasTargetSkills, devplan_id, getSuggestedAdvisors, HAS_MENTORING]);

  useLayoutEffect(() => {
    if (HAS_DEV_PLAN_OPPORTUNITIES && devplan_id && removedOppIds && hasTargetSkills) getSuggestedOpps?.({ variables: {
      devplan_id,
      type: DevPlanOpportunityType.suggested_opportunities,
      exclude_ids: getStringifiedIds(removedOppIds) || '',
      target_skill_ids: (targetSkillIds && getStringifiedIds(targetSkillIds)) || ''
    } });
  }, [removedOppIds, targetSkillIds, hasTargetSkills, devplan_id, getSuggestedOpps, HAS_DEV_PLAN_OPPORTUNITIES]);

  useLayoutEffect(() => {
    if (!isMyPlan && devplan_id && hasTargetSkills) getSuggestedActivities?.({ variables: {
      devplan_id,
      exclude_ids: getStringifiedIds(removedActivityIds) || '',
      target_skill_ids: (targetSkillIds && getStringifiedIds(targetSkillIds)) || ''
    } });
  }, [removedActivityIds, targetSkillIds, hasTargetSkills, devplan_id, isMyPlan, getSuggestedActivities]);

  useEffect(() => {
    if (currentJobCode) getCurrentJob?.({ variables: { job_code: currentJobCode } });
  }, [currentJobCode, getCurrentJob]);

  const disabled = failedCourses || failedAdvisors || failedOpps || failedActivities || pendingActivities ||
    !removedCourseIds || !removedAdvisorIds || !removedOppIds || !effectiveSelectedActivityIds ||
    updateJobPending || updatePending;

  // Job dialog
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  // Add course, skill advisor or opportunity
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  // Target Skills popup
  const [popupOpen, togglePopupOpen] = useReducer(toggleReducer, false);

  // Custom Activities dialog
  const [openActivities, setOpenActivities] = useState(false);
  const handleActivitiesOpen = useCallback(() => setOpenActivities(true), []);
  const handleActivitiesClose = useCallback(() => setOpenActivities(false), []);

  const { coveredCount, coveredSkills, satisfiedSkills } = useMemo(() => target_skills && targetSkills
    ? transform(target_skills, (result, skl) => {
        const { id } = skl;
        const lvl = skl.skill_proficiency_level || 0;
        let maxLevel = SKILL_LEVEL_MIN as SkillLevel;
        let satisfied = !lvl;
        if (HAS_COURSES && lvl) forEach(selectedCourses, ({ covered_skills }) => {
          const lev = (find(covered_skills, ['id', id])?.current_level || 0) as SkillLevel;
          if (maxLevel < lev) maxLevel = lev;
        });
        if (HAS_MENTORING && lvl) forEach(selectedAdvisors, ({ advisory_skills }) => {
          const lev = (find(advisory_skills, ['id', id])?.current_level || 0) as SkillLevel;
          if (maxLevel < lev) maxLevel = lev;
        });
        if (HAS_DEV_PLAN_OPPORTUNITIES && lvl) forEach(selectedOpps, ({ skills: oppSkills }) => {
          const lev = (
            find(oppSkills, { id, status: OpportunitySkillStatus.growth })?.skill_proficiency_level || 0
          ) as SkillLevel;
          if (maxLevel < lev) maxLevel = lev;
        });
        if (size(activities) >= 1 && lvl) forEach(activities, ({ is_selected, skills: activitySkills }) => {
          if (!is_selected) return;
          const lev = (find(activitySkills, ['id', id])?.skill_proficiency_level || 0) as SkillLevel;
          if (maxLevel < lev) maxLevel = lev;
        });
        if (maxLevel >= lvl) satisfied = true;

        result.satisfiedSkills[id] = satisfied;

        if (findIndex(targetSkills, ['id', id]) >= 0) {
          if (satisfied) result.coveredCount += 1;
          result.coveredSkills.push({
            id: skl.id,
            abbr: skl.abbr,
            title: skl.title,
            current_level: maxLevel,
            expected_level: lvl,
            is_satisfied: satisfied
          } as SkillWithLevel);
        }
      }, { coveredCount: 0, coveredSkills: [] as SkillWithLevel[], satisfiedSkills: {} as Record<number, boolean> })
    : { coveredCount: null, coveredSkills: null, satisfiedSkills: null }, [
      selectedAdvisors, selectedCourses, selectedOpps, activities, targetSkills, target_skills,
      HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES
    ]);

  const canSelect = size(courses) >= 1 || size(advisors) >= 1 || size(opportunities) >= 1;

  const devplanSubtitleValues = useMemo(() => {
    const total = size(targetSkills);
    return {
      covered: coveredCount,
      total,
      select: canSelect,
      // eslint-disable-next-line react/no-unstable-nested-components
      text: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
        <Box color="misc.coursesLegend" fontStyle="italic" component="span">
          {mapChunks(chunks)}
        </Box>
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
        <StandardLink variant="inherit" onClick={disabled || total < 1 ? undefined : togglePopupOpen}>
          {mapChunks(chunks)}
        </StandardLink>
      )
    };
  }, [coveredCount, targetSkills, canSelect, disabled]);

  const handleTargetClick = useCallback(() => {
    if (targetPath && targetCode) navigate(injectParams(targetPath, { role_id: targetCode, opp_id: targetCode }));
  }, [navigate, targetPath, targetCode]);

  const updateCache = useCallback((cache: ApolloCache<unknown>, isDevplan: boolean) => {
    // if (isMyPlan) {
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplans' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplan' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplanActivities' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
    // }
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanEmployees' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanProgresses' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanActivities' });
    if (HAS_COURSES) cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanCourses' });
    if (HAS_MENTORING) cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanAdvisors' });
    if (HAS_DEV_PLAN_OPPORTUNITIES) cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanOpportunities' });
    if (isDevplan) cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplan' });
  }, [HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING]);

  const handleTargetSelection = useCallback((job?: JobLookupItem | null, opp?: Opportunity | null) => {
    setIsOpen(false);
    if ((job?.id || opp?.id) && (cohort?.id || employee?.id)) {
      const { first_name, last_name } = employee || {};
      const title = isMyPlan ? job?.title || opp?.title
        : cohort?.title || (first_name || last_name ? getFullName(first_name, last_name) : undefined) || undefined;
      newDevPlan({
        variables: { input: {
          cohort_id: cohort?.id,
          employee_id: employee?.id,
          title: title
            ? formatMessage({ id: 'hr.dev_plan.devplan_title' }, { title })
            : formatMessage({ id: 'hr.dev_plan.default_devplan_title' }),
          ...job?.id ? { job_id: job.id } : {},
          ...opp?.id ? { opportunity_id: opp.id } : {}
        } },
        update: (cache) => {
          // if (isMyPlan) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplans' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplan' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
          // }
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplans' });
          if (cohort) cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohort' });
          // If we include existing dev_plan data into employeeDetails:
          // if (employee) cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDetails' });
          // If we add dev_plan info to the cohort cards:
          // cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohorts' });
        },
        onCompleted: (data) => {
          const plan_id = data?.newDevPlan?.id;
          if (plan_id) navigate(injectParams(isMyPlan ? PATH_MY_DEV_PLAN_EDITOR : PATH_HR_DEV_PLAN_EDITOR, { plan_id }));
        }
      });
    } else if ((job?.id || opp?.id) && devplan_id) updateDevPlanJob({
      variables: { devplan_id, input: job?.id ? { job_id: job.id } : { opportunity_id: opp?.id } },
      update: (cache, result) => {
        if (result.data?.updateDevPlan?.id === 0) {
          cache.updateQuery({
            query: DEV_PLAN_QUERY, variables: { devplan_id }
          }, (data: DevPlanQuery | null) => updateCachedResult(data, 'devplan', (plan) => ({
            ...(plan || {}) as DevPlanDetails,
            job: job ? { ...(plan as DevPlanDetails)?.job || {}, id: job.id, title: job.title } : null,
            opportunity: opp || null
          } as DevPlanDetails)));
        } else updateCache(cache, true);
      },
      optimisticResponse: { updateDevPlan: { id: 0, __typename: 'DevPlan' } }
    });
  }, [cohort, employee, devplan_id, isMyPlan, newDevPlan, formatMessage, updateDevPlanJob, updateCache, navigate]);

  const handleOpportunitySelection = useCallback((opp?: Opportunity | null) => {
    handleTargetSelection(null, opp);
  }, [handleTargetSelection]);

  // Course: Add / Unselect / Delete
  const handleCourse = useCallback((course: DevPlanCourse, addedManually = false) => {
    setAnchorAddBtn(null);
    if (devplan_id) {
      if (addedManually) setAddedCourses((prevAddedCourses) => uniqBy([course, ...prevAddedCourses], 'id'));
      if (addedManually || !course.is_selected) {
        // Add (select) Course
        setSelCourses((prevSelCourses) => uniqBy([...prevSelCourses || [], course], 'id'));
      } else {
        // Delete (unselect) Course
        setSelCourses((prevSelCourses) => reject(prevSelCourses, ['id', course.id]));
      }
    }
  }, [devplan_id]);

  const handleRemoveCourse = useCallback((course: DevPlanCourse) => {
    setAddedCourses((crss) => reject(crss, ['id', course.id]));
    setRemovedCourses((prevRemovedCourses) => uniq([...prevRemovedCourses, course.id]));
  }, []);

  // Skill Advisor: Add / Unselect / Delete
  const handleAdvisor = useCallback((advisor: DevPlanAdvisor, addedManually = false) => {
    setAnchorAddBtn(null);
    if (devplan_id) {
      if (addedManually) setAddedAdvisors((prevAddedAdvisors) => uniqBy([advisor, ...prevAddedAdvisors], 'id'));
      if (addedManually || !advisor.is_selected) {
        // Add (select) Advisor
        setSelAdvisors((prevSelAdvisors) => uniqBy([...prevSelAdvisors || [], advisor], 'id'));
      } else {
        // Delete (unselect) Advisor
        setSelAdvisors((prevSelAdvisors) => reject(prevSelAdvisors, ['id', advisor.id]));
      }
    }
  }, [devplan_id]);

  const handleRemoveAdvisor = useCallback((advisor: DevPlanAdvisor) => {
    setAddedAdvisors((advsrs) => reject(advsrs, ['id', advisor.id]));
    setRemovedAdvisors((prevRemovedAdvisors) => uniq([...prevRemovedAdvisors, advisor.id]));
  }, []);

  // Opportunity: Add / Unselect / Delete
  const handleOpportunity = useCallback((opp: DevPlanOpportunity, addedManually = false) => {
    setAnchorAddBtn(null);
    if (devplan_id) {
      if (addedManually) setAddedOpps((prevAddedOpps) => uniqBy([opp, ...prevAddedOpps], 'id'));
      if (addedManually || !opp.is_selected) {
        // Add (select) Opportunity
        setSelOpps((prevSelOpps) => uniqBy([...prevSelOpps || [], opp], 'id'));
      } else {
        // Delete (unselect) Opportunity
        setSelOpps((prevSelOpps) => reject(prevSelOpps, ['id', opp.id]));
      }
    }
  }, [devplan_id]);

  const handleRemoveOpportunity = useCallback((opp: DevPlanOpportunity) => {
    setAddedOpps((opps) => reject(opps, ['id', opp.id]));
    setRemovedOpps((prevRemovedOpps) => uniq([...prevRemovedOpps, opp.id]));
  }, []);

  const handleActivity = useCallback((activity: SkillActivity) => {
    if (activity.is_selected) {
      // Delete (unselect) Opportunity
      setSelectedActivityIds((prevSelectedActivityIds) => without(prevSelectedActivityIds, activity.id));
    } else {
      // Add (select) Activity
      setSelectedActivityIds((prevSelectedActivityIds) => uniq([...prevSelectedActivityIds || [], activity.id]));
    }
  }, []);

  const handleCustomActivity = useCallback((activity?: SkillActivity) => {
    if (!isMyPlan && activity) {
      const notSelected = indexOf(selectedActivityIds, activity.id) < 0;
      const suggested = findIndex(suggestedActivities, ['id', activity.id]) >= 0;
      setSelectedActivityIds((prevSelectedActivityIds) => notSelected
        // if not selected, then select
        ? uniq([...prevSelectedActivityIds || [], activity.id])
        // otherwise unselect
        : without(prevSelectedActivityIds, activity.id)
      );
      setCustomActivities((prevCustomActivities) => notSelected || suggested
        // if not selected, then add to custom activities
        ? uniqBy([...prevCustomActivities || [], { ...activity, is_selected: notSelected }], 'id')
        // otherwise remove from custom activities
        : reject(prevCustomActivities, ['id', activity.id])
      );
      setRemovedActivityIds((prevRemovedActivityIds) => indexOf(prevRemovedActivityIds, activity.id) >= 0
        ? without(prevRemovedActivityIds, activity.id) : prevRemovedActivityIds);
    }
  }, [selectedActivityIds, suggestedActivities, isMyPlan]);

  const handleUpdateActivity = useCallback((activity: Partial<SkillActivity>) => {
    if (activity?.id) setCustomActivities((prevCustomActivities) =>
      findIndex(prevCustomActivities, ['id', activity.id]) < 0
        ? prevCustomActivities
        : map(prevCustomActivities, (act) => act.id === activity.id ? { ...act, ...activity } : act)
    );
  }, []);

  const handleRemoveActivity = useCallback((activity: SkillActivity) => {
    setRemovedActivityIds((prevRemovedActivityIds) => uniq([...prevRemovedActivityIds, activity.id]));
    setSelectedActivityIds((prevSelectedActivityIds) => without(prevSelectedActivityIds, activity.id));
    if (!isMyPlan) {
      setCustomActivities((prevCustomActivities) => reject(prevCustomActivities, ['id', activity.id]));
    }
  }, [isMyPlan]);

  const handleSaveDevPlan = useCallback((_event?: MouseEvent<HTMLButtonElement>, onCompleted?: () => void) => {
    if (devplan_id) updateDevPlan?.({
      variables: { devplan_id, input: {
        course_ids: map(selectedCourses, 'id'),
        advisor_ids: map(selectedAdvisors, 'id'),
        opportunity_ids: map(selectedOpps, 'id'),
        activity_ids: effectiveSelectedActivityIds,
        target_skill_ids: map(targetSkills, 'id'),
        levels: map(targetSkills, 'skill_proficiency_level')
      } },
      update: (cache) => updateCache(cache, true /* was: isIndividual // but need to update devplan.activity_ids for HRBP */),
      onCompleted: onCompleted ||
        (() => navigate(injectParams(isMyPlan ? PATH_MY_DEV_PLAN : PATH_HR_DEV_PLAN, { plan_id: devplan_id })))
    });
  }, [
    selectedCourses, selectedAdvisors, selectedOpps, effectiveSelectedActivityIds, targetSkills,
    devplan_id, isMyPlan, updateDevPlan, updateCache, navigate
  ]);

  const handleSaveAndChangeJob = useCallback(() => {
    handleSaveDevPlan(undefined, handleOpen);
  }, [handleSaveDevPlan, handleOpen]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleJobChangeConfirmation,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(handleSaveAndChangeJob);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testNew && (cohort || employee)) newDevPlan({ variables: { input: {
      title: formatMessage({ id: 'hr.dev_plan.default_devplan_title' }), job_id: 1,
      ...cohort ? { cohort_id: cohort?.id } : {},
      ...employee ? { employee_id: employee?.id } : {}
    } } });
  }, [testNew, cohort, employee, newDevPlan, formatMessage]);
  useEffect(() => {
    if (
      !devplan_id || !savedCourses || !suggestedCourses || !savedAdvisors || !suggestedAdvisors || !savedOpps || !suggestedOpps
    ) return;
    if (testUpdate === true) updateDevPlan?.({ variables: { devplan_id, input: { course_ids: [17630], advisor_ids: [1] } } });
    else if (testUpdate === false) updateDevPlanJob?.({ variables: { devplan_id, input: { job_id: 321 } } });
    else if (isNull(testUpdate)) {
      setSelCourses([...savedCourses, suggestedCourses[0]]);
      setSelAdvisors([...savedAdvisors, suggestedAdvisors[0]]);
      setSelOpps([...savedOpps, suggestedOpps[0]]);
    }
  }, [
    testUpdate, devplan_id, savedCourses, suggestedCourses, savedAdvisors, suggestedAdvisors, savedOpps, suggestedOpps,
    updateDevPlan, updateDevPlanJob
  ]);

  const nothingSelected = !hasTargetSkills || (
    size(selectedCourses) < 1 && size(selectedAdvisors) < 1 && size(selectedOpps) < 1 && size(effectiveSelectedActivityIds) < 1
  );
  const showSaveTooltip = !disabled && nothingSelected && (
    size(suggestedCourses) >= 1 || size(suggestedAdvisors) >= 1 || size(suggestedOpps) >= 1
  );
  const noSaveButton = Boolean(failed || pending || cohort || employee || !devplan);

  const saveButton = useMemo(() => noSaveButton ? undefined : (
    <Button
        color="primary"
        variant="contained"
        disableElevation
        disabled={disabled || nothingSelected || !devplanChanged}
        startIcon={updatePending ? <CircularProgress size={18} color="inherit"/> : undefined}
        onClick={handleSaveDevPlan}
    >
      <FormattedMessage id="hr.dev_plan.button.save"/>
    </Button>
  ), [noSaveButton, disabled, nothingSelected, updatePending, devplanChanged, handleSaveDevPlan]);

  return (failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (
    <>
      {((cohort || employee) && (
        <>
          {cohort ? (
            <DevPlanCohort
                title={cohort.title}
                employees={cohort.employees as TalentEmployeeObject[]}
                disabled={newPending}
            />
          ) : undefined}
          {employee && !isMyPlan ? (
            <EmployeeTalentBand
                employee={employee}
                variant="standalone"
                route={supvEmplPath}
                // TODO: disabled={...}
            />
          ) : undefined}
          <CardTitle
              title={isMyPlan ? 'dev_plans.new' : (
                <Typography variant="subtitle1" component="span">
                  <FormattedMessage id="hr.dev_plan.title"/>
                </Typography>
              )}
              avatar={isMyPlan ? undefined : <DevPlanAvatar/>}
              withDivider
          />
          <CardSection>
            <Box py={2} display="flex" alignItems="center" justifyContent="center">
              <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disableElevation
                  disabled={newPending}
                  startIcon={newPending ? <CircularProgress size={18} color="inherit"/> : undefined}
                  className={btn}
                  onClick={handleOpen}
              >
                <FormattedMessage id={`hr.dev_plan.select_target${HAS_DEV_PLAN_OPPORTUNITIES ? '' : '.opp_off'}`}/>
              </Button>
            </Box>
          </CardSection>
        </>
      )) || (devplan && (
        <>
          {devplan.cohort ? (
            <DevPlanCohort
                withMatchRate={hasTargetSkills}
                title={devplan.cohort?.title}
                employees={employees}
                disabled={disabled}
                pending={testPending || pendingEmployees}
                failed={failedEmployees || failedCourses || failedAdvisors || failedOpps || failedSavedActivities}
            />
          ) : undefined}
          {devplanEmployee ? (
            <EmployeeTalentBand
                isMyPlan={isMyPlan}
                employee={head(employees) as DevPlanEmployee}
                variant="standalone"
                matchIndicatorVariant="planned"
                withReloading
                route={supvEmplPath}
                pending={testPending || pendingEmployees}
                failed={failedEmployees || failedCourses || failedAdvisors || failedOpps || failedSavedActivities}
                // TODO: disabled={...}
            />
          ) : undefined}
          <CardTitle
              title={(
                <>
                  <Typography variant="subtitle1" component="span">
                    {devplan.title || <FormattedMessage id="hr.dev_plan.default_devplan_title"/>}
                  </Typography>
                  {coursesLoaded && advisorsLoaded && oppsLoaded ? (
                    <BoxTypography pt={0.25} variant="body2">
                      <FormattedMessage
                          id={nothingSelected || isNil(coveredCount)
                            ? 'hr.dev_plan.subtitle' : 'hr.dev_plan.skills_covered'}
                          values={devplanSubtitleValues}
                      />
                    </BoxTypography>
                  ) : undefined}
                </>
              )}
              avatar={<DevPlanAvatar/>}
              withDivider
          />
          <SkillsSelector
              caption={`hr.dev_plan.button.select_target${HAS_DEV_PLAN_OPPORTUNITIES ? '' : '.opp_off'}`}
              target={(devplanJob && 'job') || (devplanOpp && 'opportunity') || null}
              targetTitle={(devplanJob?.title || devplanOpp?.title) as string}
              targetGroupId={devplanJob?.id || -(devplanOpp?.id || 0)}
              targetedSkills={target_skills}
              satisfiedSkills={satisfiedSkills}
              selectedSkills={selectedTargetSkillIds}
              disabled={disabled || updateJobPending}
              pending={updateJobPending}
              onTargetClick={!devplanChanged && targetPath && targetCode ? handleTargetClick : undefined}
              onTargetChange={devplanChanged ? handleJobChangeConfirmation : handleOpen}
              onSelect={toggleTargetSkill}
          />
          <CardSection
              flex
              top={Boolean((!pendingCourses || !pendingAdvisors || !pendingOpps) && !failedCourses &&
                (!hasTargetSkills || view !== TABLE_VIEW))}
              className={ctrlPanel}
          >
            <Box flexGrow={1} py={1} pl={2} display="flex" alignItems="center" justifyContent="flex-end">
              <PlusButton
                  label={`hr.dev_plan.button.add${
                    HAS_MENTORING ? '' : '.mentoring_off'}${
                    HAS_COURSES ? '' : '.courses_off'}${
                    HAS_DEV_PLAN_OPPORTUNITIES ? '' : '.opp_off'}`}
                  disabled={disabled || !hasTargetSkills}
                  onClick={handleAddOpen}
              />
              {isMyPlan ? undefined : (
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    disabled={disabled || !hasTargetSkills}
                    onClick={handleActivitiesOpen}
                    className={activitiesBtn}
                >
                  <FormattedMessage id="hr.dev_plan.button.custom_activities"/>
                </Button>
              )}
            </Box>
            <ViewSwitch
                tiles
                value={view}
                onChange={devPlanViewVar}
                disabled={disabled || !hasTargetSkills}
            />
          </CardSection>
          {hasTargetSkills ? undefined : (
            <Alert severity="info" variant="standard">
              <FormattedMessage id="hr.dev_plan.no_target_skills"/>
            </Alert>
          )}
          {hasTargetSkills && view === TABLE_VIEW ? (
            <DevPlanAccordion
                isMyPlan={isMyPlan}
                skills={targetSkills}
                courses={courses}
                advisors={advisors}
                opportunities={opportunities}
                activities={activities}
                pending={pendingCourses || pendingAdvisors || pendingOpps || pendingActivities}
                failed={failedCourses || failedAdvisors || failedOpps || failedActivities}
                disabled={disabled}
                onCourse={handleCourse}
                onAdvisor={handleAdvisor}
                onOpportunity={handleOpportunity}
                onActivity={handleActivity}
                courseHighlighRating={courseHighlighRating}
                advisorHighlightCount={advisorHighlightCount}
            />
          ) : undefined}
          {hasTargetSkills && view === TILES_VIEW ? (
            <CoursesAndAdvisorsGrid
                isMyPlan={isMyPlan}
                courses={courses}
                advisors={advisors}
                opportunities={opportunities}
                activities={activities}
                pendingCourses={pendingCourses}
                pendingAdvisors={pendingAdvisors}
                pendingOpportunities={pendingOpps}
                pendingActivities={pendingActivities}
                failedCourses={failedCourses}
                failedAdvisors={failedAdvisors}
                failedOpportunities={failedOpps}
                failedActivities={failedActivities}
                disabled={disabled}
                onSelectCourse={handleCourse}
                onSelectAdvisor={handleAdvisor}
                onSelectOpportunity={handleOpportunity}
                onSelectActivity={handleActivity}
                onRemoveCourse={handleRemoveCourse}
                onRemoveAdvisor={handleRemoveAdvisor}
                onRemoveOpportunity={handleRemoveOpportunity}
                onRemoveActivity={handleRemoveActivity}
                withoutLoadingPlaceholders
                courseHighlighRating={courseHighlighRating}
                advisorHighlightCount={advisorHighlightCount}
            />
          ) : undefined}
          <CardFooter>
            {noSaveButton ? undefined : (
              <>
                <Button
                    color="primary"
                    variant="outlined"
                    disabled={disabled}
                    component={RouterLink}
                    to={injectParams(isMyPlan ? PATH_MY_DEV_PLAN : PATH_HR_DEV_PLAN, { plan_id: devplan_id })}
                    className={discardBtn}
                >
                  <FormattedMessage id={devplanChanged ? 'hr.dev_plan.button.discard' : 'hr.dev_plan.button.view'}/>
                </Button>
                <Box width="5%"/>
                {saveButton && showSaveTooltip ? (
                  <SimpleTooltip
                      open
                      placement="top"
                      title={<FormattedMessage id="hr.dev_plan.save.tooltip"/>}
                      disableInteractive
                  >
                    {saveButton}
                  </SimpleTooltip>
                ) : saveButton}
              </>
            )}
          </CardFooter>
        </>
      )) || undefined}
      <AddJobDialog
          devplan
          isMyPlan={isMyPlan}
          currentJob={currentJobDetails}
          isOpen={isOpen}
          onAdd={handleTargetSelection}
          onAddOpportunity={HAS_DEV_PLAN_OPPORTUNITIES ? handleOpportunitySelection : undefined}
          onCancel={handleClose}
          disabled={newPending}
          exclude={employee?.current_job?.id ? [employee.current_job.id] : undefined}
      />
      {devplan_id && confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title="hr.dev_plan.confirm.target_change.title"
            text="hr.dev_plan.confirm.target_change.text"
            withCancelButton
            values={{ canTargetOpportunity: HAS_DEV_PLAN_OPPORTUNITIES, br: <br/> }}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      {devplan_id ? (
        <AddCourseOrAdvisorPopover
            devplanId={devplan_id}
            anchorEl={anchorAddBtn}
            skillIds={selectedSkillIds}
            excludeAdvisors={excludeAdvisorIds}
            excludeCourses={excludeCourseIds}
            excludeOpportunities={excludeOppIds}
            onCourse={handleCourse}
            onAdvisor={handleAdvisor}
            onOpportunity={handleOpportunity}
            onCancel={handleAddClose}
            disabled={disabled}
        />
      ) : undefined}
      {devplan_id && !isMyPlan ? (
        <CustomActivitiesDialog
            open={openActivities}
            onClose={handleActivitiesClose}
            selectedIds={effectiveSelectedActivityIds}
            targetSkills={targetSkills}
            onSelect={handleCustomActivity}
            onUpdate={handleUpdateActivity}
            onRemove={handleRemoveActivity}
        />
      ) : undefined}
      {coveredSkills ? (
        <EmployeeDetailsPopup
            title={devplan?.title}
            isMyPlan={isMyPlan}
            targetSkills={coveredSkills}
            isOpen={popupOpen}
            onClose={togglePopupOpen}
        />
      ) : undefined}
      <ActionFailedAlert
          message="hr.dev_plan.new_devplan_error"
          open={newFailed}
      />
      <ActionFailedAlert
          message="hr.dev_plan.update_devplan_error"
          open={updateJobFailed}
      />
      <ActionFailedAlert
          message="hr.dev_plan.save_devplan_error"
          open={updateFailed}
      />
    </>
  );
};

DevPlanEditor.propTypes = DevPlanEditorPanelPropTypes;

export default memo(DevPlanEditor);
