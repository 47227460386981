import { memo, useContext, useEffect, useState, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { useLazyQuery } from '@apollo/client';
// TM UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
// local imports
import { ADMIN_JOBS_QUERY } from '../graphql/AdminJobs';
import {
  AdminJob, AdminJobsDocument, AdminJobsQueryVariables, AdminJobsSort, JobCategory, SortDirection
} from '../graphql/types';
import { ADMIN_JOBS_SORT, DEFAULT_ADMIN_JOBS_DIRECTION, JobLookupItem } from '../graphql/customTypes';
import { Job } from '../models/job';
import { ROLE_SCOPE_TECH } from '../constants/scopes';
import { JobSortExt } from '../constants/jobSort';
import useCustomerSettings from '../config/customer';
import useFilters, { FilterValues } from '../hooks/useFilters';
import useTableSort from '../hooks/useTableSort';
import { DataContext } from '../context';
import RolesGrid from '../v3/RolesGrid';
import JobsIndexFilters from '../v3/JobsIndexFilters';
import PaginationControls from '../v3/PaginationControls';
import AdminJobDialog from '../widgets/AdminJobDialog';

type AdminJobsProps = {
  settingsId?: string;
  // for Storybook only
  testPending?: boolean;
}

const AdminJobsPropTypes = {
  settingsId: PropTypes.string,
  testPending: PropTypes.bool
};

const AdminJobs: FunctionComponent<AdminJobsProps> = ({
  settingsId,
  testPending
}) => {
  const { defaultFilters, updateSearchParams } = useFilters(ADMIN_JOBS_SORT);
  const defaultPage = defaultFilters?.current_page;

  const { HAS_INDEMAND_SKILLS, HAS_JOBS_SCOPE, MANAGEMENT_LEVEL_FIRST } = useCustomerSettings();

  const { sort, dir, handleSortLegacy } = useTableSort({
    settingsId,
    sortValues: ADMIN_JOBS_SORT,
    defaultDirections: DEFAULT_ADMIN_JOBS_DIRECTION,
    defaultSortBy: defaultFilters?.sort_by as AdminJobsSort,
    defaultDirection: defaultFilters?.direction
  });

  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);
  const settingsLoaded = !settingsId || (pendingSettings === false && failedSettings === false && Boolean(settingsData));

  // lazy load jobs
  const { query: getJobs, pending: pendingJobs, failed, count, results: jobs, variables: prevVars } = useQueryCounted({
    data: undefined as unknown as AdminJob,
    key: 'adminJobs',
    lazyQuery: useLazyQuery(ADMIN_JOBS_QUERY as typeof AdminJobsDocument)
  });
  const pending = pendingJobs || testPending;

  const [filters, setFilters] = useState(defaultFilters);
  const [currentPage, setCurrentPage] = useState(defaultPage || 1);
  const [pageSize, setPageSize] = useState(defaultFilters?.page_size || undefined);

  const [job, setJob] = useState<AdminJob>();

  const handleClick = useCallback((_code: string, role?: Job | JobLookupItem | AdminJob) => {
    setJob(role as AdminJob);
  }, []);

  const handleClose = useCallback(() => {
    setJob(undefined);
  }, []);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsLoaded && filters && getJobs && !isNil(pageSize)) {
      const filterValues: FilterValues = {
        org_id: filters.org_id || null,
        country_id: filters.country_id || null,
        state_id: filters.state_id || null,
        location_id: filters.location_id || null,
        management_level: isNil(filters.management_level) || filters.management_level < MANAGEMENT_LEVEL_FIRST
          ? null : filters.management_level,
        management_level_min: isNil(filters.management_level_min) || filters.management_level_min < MANAGEMENT_LEVEL_FIRST
          ? null : filters.management_level_min,
        management_level_max: isNil(filters.management_level_max) || filters.management_level_max < MANAGEMENT_LEVEL_FIRST
          ? null : filters.management_level_max,
        supervisory_jobs_only: filters.supervisory_jobs_only || null,
        open_reqs_only: filters.open_reqs_only || null,
        direction: dir
      };
      const variables: AdminJobsQueryVariables = {
        ...omitBy(filterValues, isNull),
        ...filters.scope ? {
          job_category: filters.scope === ROLE_SCOPE_TECH
            ? JobCategory.technology : (filters.scope as JobCategory) // JobCategory
        } : {},
        sort_by: sort, // typed as AdminJobsSort
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables) && (size(prevVars) >= 1 || !defaultPage)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getJobs({ variables });
      updateSearchParams({
        ...filterValues,
        scope: filters.scope, // RoleScope
        sort_by: sort, // typed as string
        current_page: curPage,
        page_size: pageSize
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only filter values are monitored here (i.e. ignoring `prevVars` changes):
    filters, sort, dir, pageSize, currentPage, defaultPage, getJobs, settingsLoaded,
    updateSearchParams, MANAGEMENT_LEVEL_FIRST
  ]);

  const pendingAll = !settingsLoaded || !filters || pending;
  const loading = pendingAll || !jobs;
  const failedAny = failed || (settingsId ? failedSettings : false);
  const disabled = loading || failedAny || (settingsId ? pendingSettingsUpdate : undefined);

  return (
    <>
      <RolesGrid
          admin
          table
          withOpenReqsPopup
          withReloading
          roles={jobs}
          pending={loading}
          failed={failedAny}
          onClick={handleClick}
          sortBy={sort}
          direction={dir === SortDirection.ascending}
          changeSort={handleSortLegacy as (sort: JobSortExt, direction: boolean) => void}
          filters={(
            <JobsIndexFilters
                init={defaultFilters}
                settingsId={settingsId}
                visible={settingsLoaded}
                dropdownsFirst
                onChange={setFilters}
                disabled={pendingAll}
                withOrg={HAS_INDEMAND_SKILLS}
                withScope={HAS_JOBS_SCOPE}
                withSupervisory
                withCategory={!HAS_JOBS_SCOPE}
                withLadder
                withCountry
                withState
                withOpenReqs
                withLevel
            />
          )}
          pagination={(
            <PaginationControls
                init={defaultFilters}
                settingsId={settingsId}
                loaded={Boolean(jobs)}
                pending={pending}
                loading={pendingAll}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={disabled}
            />
          )}
      />
      <AdminJobDialog
          job={job}
          onClick={handleClick}
          onClose={handleClose}
      />
    </>
  );
};

AdminJobs.propTypes = AdminJobsPropTypes;

export default memo(AdminJobs);
