import { memo, useContext, useCallback, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import map from 'lodash/map';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// TM UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
// local imports
import { Skill } from '../models/skill';
import { Employee } from '../models/employee';
import { PATH_HR_TEAM_BUILDER, PATH_HR_TEAM_BUILDER_TEAM } from '../config/paths';
import useExportRoster from '../hooks/useExportRoster';
import { SupervisorContext } from '../context/supervisor';
import ReviewBar from '../elements/ReviewBar';

type TbReviewBarProps = {
  teamId: number;
  title?: string;
  roster?: Employee[];
  skills?: Skill[];
  pending?: boolean;
  disabled?: boolean;
}

const TbReviewBarPropTypes = {
  // attributes
  teamId: PropTypes.number.isRequired,
  title: PropTypes.string,
  roster: PropTypes.array as Validator<Employee[]>,
  skills: PropTypes.array as Validator<Skill[]>,
  pending: PropTypes.bool,
  disabled: PropTypes.bool
};

// eslint-disable-next-line max-statements
const TbReviewBar: FunctionComponent<TbReviewBarProps> = ({
  teamId,
  title,
  roster,
  skills,
  pending = false,
  disabled: disabledParent = false
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { exportRoster, ROSTER_CSV_COLUMNS } = useExportRoster();

  const {
    tbTeamUpdate: { pending: renamePending, failed: renameFailed, params: renameParams }, tbUpdateTeam,
    tbTeamDelete: { pending: deletePending, failed: deleteFailed }, tbDeleteTeam
  } = useContext(SupervisorContext);

  const updateTitle = useCallback(
    (newTitle?: string) => tbUpdateTeam?.({ team_id: teamId, title: newTitle ? newTitle : '' }),
    [teamId, tbUpdateTeam]
  );

  const deleteTeam = useCallback(() => tbDeleteTeam?.({
    team_id: teamId,
    onSuccess: () => navigate(PATH_HR_TEAM_BUILDER)
  }), [tbDeleteTeam, teamId, navigate]);

  const link = useMemo(() => injectParams(PATH_HR_TEAM_BUILDER_TEAM, { team_id: teamId }), [teamId]);

  const getExport = useCallback((format: ExportFormat, teamTitle: string) => {
    if (!roster || !skills || !teamTitle) return null;
    return exportRoster(
      format,
      teamTitle,
      map(ROSTER_CSV_COLUMNS, (id) => formatMessage({ id })),
      skills,
      roster
    );
  }, [roster, skills, formatMessage, ROSTER_CSV_COLUMNS, exportRoster]);

  return (
    <ReviewBar
        titleLabel="hr.teambuilder.review.title_label"
        title={title}
        link={link}
        pending={pending}
        disabled={disabledParent}
        editLabel="hr.teambuilder.review.edit"
        onRename={updateTitle}
        renameLabel="hr.teambuilder.review.rename"
        renamePending={renamePending}
        renameFailed={renameFailed}
        renameError={renameParams?.badRequest ? 'hr.teambuilder.review.bad_request' : 'hr.teambuilder.review.rename_error'}
        onDelete={deleteTeam}
        deleteLabel="hr.teambuilder.review.delete"
        deleteTitle="hr.teambuilder.review.delete_title"
        deleteQuestion="hr.teambuilder.review.delete_question"
        deletePending={deletePending}
        deleteFailed={deleteFailed}
        deleteError="hr.teambuilder.review.delete_error"
        onExport={getExport}
        exportDisabled={!skills || !roster || size(roster) < 1}
    />
  );
};

TbReviewBar.propTypes = TbReviewBarPropTypes;

export default memo(TbReviewBar);
