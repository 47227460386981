import { memo, useContext, useEffect, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// TM UI Components
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { SupervisorContext } from '../context/supervisor';
import TbReviewBar from '../v3/TbReviewBar';
import TbRosterList from '../v3/TbRosterList';
import TbSkillsList from '../v3/TbSkillsList';

type TeamBuilderReviewProps = {
  teamId: number;
}

const TeamBuilderReviewPropTypes = {
  teamId: PropTypes.number.isRequired
};

const TeamBuilderReview: FunctionComponent<TeamBuilderReviewProps> = ({
  teamId
}) => {
  const {
    tbTeam: { data: team, pending, failed }, requireTbTeam
  } = useContext(SupervisorContext);
  const { title, skills, employees } = team || {};

  useEffect(() => {
    if (teamId) requireTbTeam?.({ team_id: teamId });
  }, [teamId, requireTbTeam]);

  return (
    <>
      <TbReviewBar
          teamId={teamId}
          title={failed ? ' ' : title}
          roster={employees || []}
          skills={skills || []}
          pending={(pending || !team) && !failed ? true : undefined}
          disabled={pending || failed ? true : undefined}
      />
      {(failed && <FetchFailedAlert flat/>) ||
       (pending && <LoadingPlaceholder flat/>) || (
         <>
           <TbRosterList roster={employees || []}/>
           <TbSkillsList skills={skills || []}/>
         </>)}
    </>
  );
};

TeamBuilderReview.propTypes = TeamBuilderReviewPropTypes;

export default memo(TeamBuilderReview);
