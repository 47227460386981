import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import floor from 'lodash/floor';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import { type TableProps } from '@mui/material/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
// TM UI Components
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { TalentFinderSort, SortDirection, AdminEmployee, AdminEmployeesSort, AdminJob } from '../graphql/types';
import {
  EmployeeSortExt, TalentEmployeeObject, DEFAULT_ADMIN_EMPLOYEES_DIRECTION
} from '../graphql/customTypes';
import { Job } from '../models/job';
import { DEFAULT_TF_SORT_DIRECTION } from '../constants/talentFinder';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
import ImpersonateButton from '../elements/ImpersonateButton';
import RoleName from './RoleName';

export type EmployeeSelection = Record<number, boolean>;

type EmployeesTableProps = {
  admin?: boolean;
  supervisor?: boolean;
  route?: string | null;
  data?: (TalentEmployeeObject | AdminEmployee)[] | null;
  selectedIds?: number[] | null;
  pending?: boolean | null;
  failed?: boolean | null;
  sortBy?: TalentFinderSort | AdminEmployeesSort | null;
  direction?: SortDirection | null;
  changeSort: ((sort: TalentFinderSort, direction: SortDirection) => void) |
    ((sort: AdminEmployeesSort, direction: SortDirection) => void);
  onClick?: (employee: TalentEmployeeObject | AdminEmployee) => void;
  onJobClick?: (code: string, job?: Job | AdminJob, employee?: TalentEmployeeObject | AdminEmployee) => void;
  onSelect?: (id: number, selected: boolean) => void;
  selection?: EmployeeSelection;
  disabled?: boolean | null;
  disableMatchRateSort?: boolean;
  withReloading?: boolean;
  tableSize?: TableProps['size'];
}

const EmployeesTablePropTypes = {
  // attributes
  admin: PropTypes.bool,
  supervisor: PropTypes.bool,
  route: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object.isRequired) as Validator<(TalentEmployeeObject | AdminEmployee)[]>,
  selectedIds: PropTypes.array,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  sortBy: PropTypes.string as Validator<TalentFinderSort | AdminEmployeesSort>,
  direction: PropTypes.string as Validator<SortDirection>,
  changeSort: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onJobClick: PropTypes.func,
  onSelect: PropTypes.func,
  selection: PropTypes.object as Validator<EmployeeSelection>,
  disabled: PropTypes.bool,
  disableMatchRateSort: PropTypes.bool,
  withReloading: PropTypes.bool,
  tableSize: PropTypes.string as Validator<TableProps['size']>
};

const EmployeesTable = forwardRef<HTMLDivElement, EmployeesTableProps>(({
  admin = false,
  supervisor = false,
  route,
  data,
  selectedIds,
  pending = false,
  failed = false,
  sortBy,
  direction,
  changeSort,
  onClick,
  onJobClick,
  onSelect,
  selection,
  disabled: parentDisabled = false,
  disableMatchRateSort = false,
  withReloading = false,
  tableSize
}, ref) => {
  const { getLocationStr } = useModels();
  const [impersonationPending, setImpersonationPending] = useState(false);
  const disabled = parentDisabled || impersonationPending;

  const handleSelection = useCallback((checked: boolean, id?: number) => {
    if (id) onSelect?.(id, checked);
  }, [onSelect]);

  const sortHandlers = useMemo(() => [
    admin ? EmployeeSortExt.full_name : EmployeeSortExt.employee,
    EmployeeSortExt.current_job,
    EmployeeSortExt.location,
    ...supervisor ? [] : [
      EmployeeSortExt.manager,
      EmployeeSortExt.performance_rating,
      EmployeeSortExt.years_on_job
    ],
    ...supervisor || admin ? [] : [EmployeeSortExt.job_match_rate],
    ...admin ? [
      EmployeeSortExt.code,
      EmployeeSortExt.skill_count,
      EmployeeSortExt.management_level
    ] : []
  ].map((tfSort) => () => (changeSort as ((sort: TalentFinderSort | AdminEmployeesSort, direction: SortDirection) => void))(
      tfSort,
      sortBy === tfSort && !isNil(direction)
        ? (direction === SortDirection.ascending && SortDirection.descending) || SortDirection.ascending
        : (admin && DEFAULT_ADMIN_EMPLOYEES_DIRECTION[tfSort as AdminEmployeesSort]) ||
          DEFAULT_TF_SORT_DIRECTION[tfSort as TalentFinderSort]
    )
  ), [direction, changeSort, sortBy, admin, supervisor]);

  const sortDisabled = disabled || pending || failed || !data || size(data) < 1;

  const titles = useMemo(() => map([
    ...supervisor ? [] : [{ label: 'hr.talentfinder.column.selection' }],
    ...admin ? [{ label: 'admin.employees.code', id: EmployeeSortExt.code, handler: sortHandlers[6] }] : [],
    {
      label: 'hr.talentfinder.column.employee',
      id: admin ? EmployeeSortExt.full_name : EmployeeSortExt.employee,
      handler: sortHandlers[0]
    },
    { label: 'hr.talentfinder.column.current_job', id: EmployeeSortExt.current_job, handler: sortHandlers[1] },
    { label: 'hr.talentfinder.column.location', id: EmployeeSortExt.location, handler: sortHandlers[2] },
    ...supervisor ? [] : [
      { label: 'hr.talentfinder.column.manager', id: EmployeeSortExt.manager, handler: sortHandlers[3] },
      { label: 'hr.talentfinder.column.rating', id: EmployeeSortExt.performance_rating, handler: sortHandlers[4] },
      { label: 'hr.talentfinder.column.years', id: EmployeeSortExt.years_on_job, handler: sortHandlers[5] },
      ...admin ? [
        { label: 'admin.employees.skill_count', id: EmployeeSortExt.skill_count, handler: sortHandlers[7] },
        { label: 'admin.employees.management_level', id: EmployeeSortExt.management_level, handler: sortHandlers[8] }
      ] : [
        {
          label: 'hr.talentfinder.column.match_rate',
          id: disableMatchRateSort ? undefined : EmployeeSortExt.job_match_rate,
          handler: disableMatchRateSort ? undefined : sortHandlers[6]
        }
      ]
    ]
  ], ({ id, label, handler }) => id
    ? (
      <TableSortLabel key={id}
          direction={(sortBy === id && direction ? direction
            : (admin && DEFAULT_ADMIN_EMPLOYEES_DIRECTION[id as AdminEmployeesSort]) ||
              DEFAULT_TF_SORT_DIRECTION[id as TalentFinderSort]
          ) === SortDirection.ascending ? 'asc' : 'desc'}
          active={sortBy === id}
          onClick={handler}
          disabled={sortDisabled}
          IconComponent={SortArrow}
      >
        <FormattedMessage id={label}/>
      </TableSortLabel>
    ) : <FormattedMessage key={id} id={label}/>
  ), [sortBy, direction, sortDisabled, disableMatchRateSort, admin, supervisor, sortHandlers]);

  // eslint-disable-next-line complexity
  const rows = useMemo(() => map(data, (employee) => {
    const { id, code, current_job, location, manager, performance_rating, years_on_job } = employee;
    const { job_match_rate } = employee as TalentEmployeeObject;
    const { management_level, skill_count } = employee as AdminEmployee;
    const { code: jobCode, title } = current_job || {};
    return {
      selected: !admin && Boolean(selectedIds?.includes(id)),
      values: [
        /* eslint-disable react/jsx-key */
        ...supervisor ? [] : [
          admin ? (
            <ImpersonateButton
                code={code}
                disabled={disabled ? true : undefined}
                onPending={setImpersonationPending}
            />
          ) : (
            <CheckboxButton
                id={id}
                small
                // TODO: label={ ... }
                checked={selection?.[id]}
                onChange={handleSelection}
                disabled={disabled || pending ? true : undefined}
            />
          )
        ],
        ...admin ? [code || '—'] : [],
        <EmployeeName
            variant={admin ? 'subtitle2' : undefined}
            employee={employee}
            admin={admin}
            supervisor={supervisor}
            route={route}
            onClick={onClick}
            disabled={disabled || pending ? true : undefined}
        />,
        onJobClick && jobCode && title ? (
          <RoleName
              code={disabled ? undefined : jobCode}
              title={title}
              role={current_job as AdminJob}
              context={employee}
              onClick={onJobClick}
          />
        ) : title || '—',
        getLocationStr(location) || <FormattedMessage id="employees.not_available"/>,
        ...supervisor ? [] : [
          manager ? (
            <EmployeeName
                variant={admin ? 'subtitle2' : undefined}
                employee={manager}
                admin={admin}
                manager={!admin}
                onClick={admin ? onClick : undefined}
                disabled={disabled || pending ? true : undefined}
            />
          ) : '—',
          isNil(performance_rating) ? '—' : <FormattedNumber value={round(performance_rating)}/>,
          isNil(years_on_job) ? '—' : <FormattedNumber value={floor(years_on_job)}/>,
          ...admin ? [
            <EmployeeName
                variant="subtitle2"
                employee={employee}
                fullName={<FormattedNumber value={skill_count || 0}/>}
                admin
                supervisor={supervisor}
                route={route}
                onClick={onClick}
                disabled={disabled || pending ? true : undefined}
            />,
            isNil(management_level) ? '—' : <FormattedNumber value={toSafeInteger(management_level)}/>
          ] : [
            isNil(job_match_rate) ? '—' : (
              // eslint-disable-next-line react/style-prop-object
              <FormattedNumber value={toSafeInteger(job_match_rate) / 100} style="percent" minimumFractionDigits={0}/>
            )
          ]
        ]
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [
    data, selectedIds, selection, disabled, pending, route, admin, supervisor,
    onClick, onJobClick, handleSelection, getLocationStr
  ]);

  return (
    <DataTable
        ref={ref}
        tableSize={tableSize}
        titles={titles}
        empty="hr.talentfinder.empty"
        lastLeftAlignedTitle={supervisor ? size(titles) : (admin && 5) || 4}
        data={rows}
        pending={withReloading ? pending && !data : pending || !data}
        failed={failed}
    />
  );
});

EmployeesTable.displayName = 'EmployeesTable';

EmployeesTable.propTypes = EmployeesTablePropTypes;

export default memo(EmployeesTable);
