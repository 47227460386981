import { useMemo } from 'react';
import { type ApolloCache, type DefaultContext, type MutationFunctionOptions, type MutationTuple } from '@apollo/client';

type MutationResponse<K extends string = 'success'> = Record<K, boolean | null> | undefined;

function useMutationMethod<D extends object, V>({
  key,
  successKey = 'success',
  mutation
}: {
  key?: keyof D;
  successKey?: string;
  mutation: MutationTuple<D, V>;
}) {
  const [mutateFunc, { loading, error, data }] = mutation;

  // catch any exceptions thrown by the mutation
  const mutate = useMemo(() => (options?: MutationFunctionOptions<D, V, DefaultContext, ApolloCache<unknown>>) => {
    (async () => {
      try {
        await mutateFunc(options);
      } catch (err) {
        // do nothing
      }
    })();
  }, [mutateFunc]);

  return useMemo(() => ({
    // mutate function
    mutate,
    // standard Apollo Client result
    loading,
    error,
    data,
    // our custom result
    failed: Boolean(error || (data && key && !((data as D)[key] as MutationResponse<typeof successKey>)?.[successKey])),
    succeeded: Boolean(!error && data && (!key || ((data as D)[key] as MutationResponse<typeof successKey>)?.[successKey]))
  }), [data, error, key, loading, successKey, mutate]);
}

export default useMutationMethod;
