import { forwardRef, memo, useCallback, useState, type Ref } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import clsx from 'clsx';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Box from '@mui/material/Box';
// TM UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { AdminJob } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import { Job } from '../models/job';
import OpenReqsDialog from '../components/OpenReqsDialog';
// SCSS imports
import { normal, disabled, withNew, centered } from './OpenReqsLink.module.scss';

type OpenReqsLinkProps = {
  role?: Job | JobLookupItem | AdminJob | null;
  admin?: boolean;
  variant?: Variant | 'inherit';
  plain?: boolean;
  table?: boolean;
  withoutLink?: boolean;
  withOpenReqsPopup?: boolean;
  withoutMoreButton?: boolean;
};

const OpenReqsLinkPropTypes = {
  role: PropTypes.object as Validator<Job | AdminJob>,
  admin: PropTypes.bool,
  variant: PropTypes.string as Validator<Variant | 'inherit'>,
  plain: PropTypes.bool,
  table: PropTypes.bool,
  withoutLink: PropTypes.bool,
  withOpenReqsPopup: PropTypes.bool,
  withoutMoreButton: PropTypes.bool
};

// eslint-disable-next-line complexity
const OpenReqsLink = forwardRef<HTMLAnchorElement | HTMLDivElement, OpenReqsLinkProps>(({
  role,
  admin = false,
  variant,
  plain = false,
  table = false,
  withoutLink = false,
  withOpenReqsPopup = false,
  withoutMoreButton = false
}, ref) => {
  const { code } = role || {};
  const { open_reqs_count, new_open_reqs_count, open_reqs_url } = (role || {}) as Job;
  const { open_req_count, new_req_count } = (role || {}) as AdminJob;
  const href = withoutLink ? undefined : open_reqs_url;

  const [open, setOpen] = useState(false);
  const [roleId, setRoleId] = useState('');

  const handleClick = useCallback(() => {
    if (code) {
      setOpen(true);
      setRoleId(code);
    }
  }, [code]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    setRoleId('');
  }, []);

  const open_reqs = toSafeInteger(open_reqs_count || open_req_count);
  const new_reqs = toSafeInteger(new_open_reqs_count || new_req_count);
  const openReqsCount = plain
    ? <FormattedNumber value={open_reqs}/>
    : <FormattedMessage id="common.open_reqs" values={{ open_reqs }}/>;
  const newReqsCount = new_reqs >= 1 ? (
    <BoxTypography variant="body2" color="info.main" fontStyle="italic">
      <FormattedMessage id="open_reqs.new_count" values={{ new_reqs }}/>
    </BoxTypography>
  ) : undefined;

  return (code && (withOpenReqsPopup || href) && (!plain || (plain && open_reqs >= 1)) && (
    <>
      <StandardLink
          ref={ref as Ref<HTMLAnchorElement>}
          variant={variant}
          href={!withOpenReqsPopup && open_reqs >= 1 ? href : undefined}
          onClick={withOpenReqsPopup && open_reqs >= 1 ? handleClick : undefined}
          className={clsx({
            [withNew]: Boolean(newReqsCount),
            [centered]: table
          })}
      >
        {openReqsCount}
        {newReqsCount}
      </StandardLink>
      {roleId ? (
        <OpenReqsDialog
            admin={admin}
            role={role}
            isOpen={open}
            onClose={handleClose}
            onExited={handleExited}
            withoutMoreButton={withoutMoreButton}
        />
      ) : undefined}
    </>
  )) || (plain ? openReqsCount : (
    <Box
        ref={ref as Ref<HTMLDivElement>}
        className={clsx(open_reqs >= 1 ? normal : disabled, {
          [withNew]: Boolean(newReqsCount),
          [centered]: table
        })}
    >
      {openReqsCount}
      {newReqsCount}
    </Box>
  ));
});

OpenReqsLink.displayName = 'OpenReqsLink';

OpenReqsLink.propTypes = OpenReqsLinkPropTypes;

export default memo(OpenReqsLink);
