import { forwardRef, memo, useContext, useState, useCallback, useMemo, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// TM UI Components
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { Delegate, Leader } from '../graphql/types';
import { SupervisorContext } from '../context/supervisor';
import DelegateSearch from '../widgets/DelegateSearch';
// SCSS imports
import { header, selector, button } from './SelectDelegate.module.scss';

type SelectDelegateProps = {
  hrbp?: boolean;
  withDivider?: boolean | null;
  onChange: (value: Leader | null) => void;
  disabled?: boolean | null;
  delegationDisabled?: boolean | null;
  exclude?: number[] | null;
  leader?: Leader | null;
  testDelegate?: Delegate | null;
}

const SelectDelegatePropTypes = {
  // attributes
  hrbp: PropTypes.bool,
  withDivider: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  delegationDisabled: PropTypes.bool,
  exclude: PropTypes.array,
  leader: PropTypes.object as Validator<Leader>,
  testDelegate: PropTypes.object as Validator<Delegate>
};

const SelectDelegate = forwardRef<Component<BoxProps>, SelectDelegateProps>(({
  hrbp = false,
  withDivider = false,
  onChange,
  disabled: parentDisabled = false,
  delegationDisabled = false,
  exclude,
  leader,
  testDelegate
}, ref) => {
  const { delegateAdd: { pending, failed }, addDelegate } = useContext(SupervisorContext);

  const [delegate, setDelegate] = useState<Delegate | null>(testDelegate || null);
  const dialogValues = useMemo(() => ({ pure_name: delegate?.pure_name }), [delegate]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMounted, setConfirmMounted] = useState(false);

  const onSuccess = useCallback(() => setDelegate(null), []);

  const handleDelegate = useCallback(() => {
    setConfirmOpen(false);
    if (leader?.id && delegate?.id) addDelegate?.({ from_id: leader.id, to_id: delegate.id, onSuccess });
  }, [delegate, leader, addDelegate, onSuccess]);

  const handleCancel = useCallback(() => setConfirmOpen(false), []);
  const handleExited = useCallback(() => setConfirmMounted(false), []);
  const handleConfirm = useCallback(() => {
    if (delegate?.is_delegated) {
      setConfirmMounted(true);
      setConfirmOpen(true);
    } else {
      handleDelegate();
    }
  }, [delegate, handleDelegate]);

  const disabled = parentDisabled || pending;
  const disabledDelegated = disabled || delegationDisabled;

  return (
    <CardSection ref={ref} top>
      <GridBox
          container
          spacing={3}
          flexWrap="wrap"
          alignItems="flex-end"
          pb={3.5}
      >
        {hrbp ? (
          <Grid
              container
              item
              xs={12}
              md={5}
              xl={4}
              direction="column"
          >
            <Typography className={header}>
              <FormattedMessage id="delegation.select_leader"/>
            </Typography>
            <DelegateSearch
                value={leader}
                onChange={onChange}
                disabled={disabled}
                fullWidth
                className={selector}
            />
          </Grid>
        ) : undefined}
        {leader?.id ? (
          <Grid
              container
              item
              xs={12}
              md={7}
              xl={8}
              direction="column"
          >
            <Typography className={header}>
              <FormattedMessage id="delegation.search_delegate"/>
            </Typography>
            <Box display="flex" flexWrap="wrap">
              <DelegateSearch
                  leaderId={leader.id}
                  value={delegate as Leader}
                  onChange={setDelegate as (item: Leader | null) => void}
                  disabled={disabledDelegated}
                  exclude={exclude}
                  fullWidth
                  className={selector}
              />
              <Button
                  onClick={handleConfirm}
                  color="primary"
                  variant="contained"
                  disabled={disabledDelegated || !delegate?.id || !leader?.id}
                  startIcon={pending ? <CircularProgress size={23} color="inherit"/> : undefined}
                  className={button}
              >
                <FormattedMessage id="delegation.button.delegate"/>
              </Button>
            </Box>
          </Grid>
        ) : undefined}
      </GridBox>
      {withDivider ? <Divider/> : undefined}
      {confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            text="employees.replace_delegate"
            values={dialogValues}
            confirmLabel="common.button.delegate"
            onCancel={handleCancel}
            onConfirm={handleDelegate}
            onExited={handleExited}
            withCancelButton
        />
      ) : undefined}
      <ActionFailedAlert
          message="delegation.delegate_error"
          open={failed}
      />
    </CardSection>
  );
});

SelectDelegate.displayName = 'SelectDelegate';

SelectDelegate.propTypes = SelectDelegatePropTypes;

export default memo(SelectDelegate);
