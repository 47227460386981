import { forwardRef, memo, useCallback, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import filter from 'lodash/filter';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import Assignment from '@mui/icons-material/Assignment';
// TM UI Components
import { getStringifiedIds } from '@empathco/ui-components/src/helpers/strings';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { COURSE_ADVISOR_SEARCH_QUERY } from '../graphql/CourseAdvisorSearch';
import { CourseAdvisorItemType, CourseAdvisorSearchDocument, CourseAdvisorSearchQueryVariables } from '../graphql/types';
import { CourseAdvisorSearchItem } from '../graphql/customTypes';
import { MAX_ITEMS } from '../config/params';
import useCustomerSettings from '../config/customer';
// SCSS imports
import { root } from './CourseAndAdvisorSearch.module.scss';

type CourseAndAdvisorSearchProps = {
  skillIds: number[];
  excludeCourses?: number[];
  excludeAdvisors?: number[];
  excludeOpportunities?: number[];
  value?: CourseAdvisorSearchItem | null;
  onChange: (item: CourseAdvisorSearchItem | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
}

const CourseAndAdvisorSearchPropTypes = {
  // attributes
  skillIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  excludeCourses: PropTypes.arrayOf(PropTypes.number.isRequired),
  excludeAdvisors: PropTypes.arrayOf(PropTypes.number.isRequired),
  excludeOpportunities: PropTypes.arrayOf(PropTypes.number.isRequired),
  value: PropTypes.object as Validator<CourseAdvisorSearchItem>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool
};

const CourseAndAdvisorSearch = forwardRef<HTMLDivElement, CourseAndAdvisorSearchProps>(({
  skillIds,
  excludeCourses,
  excludeAdvisors,
  excludeOpportunities,
  value,
  onChange,
  disabled = false,
  fullWidth = false
}, ref) => {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  const { query: getSkills, loading, failed, results, variables } = useQueryCounted({
    data: undefined as unknown as CourseAdvisorSearchItem,
    key: 'courseAdvisorSearch',
    lazyQuery: useLazyQuery(COURSE_ADVISOR_SEARCH_QUERY as typeof CourseAdvisorSearchDocument)
  });

  const fetchParams: Partial<CourseAdvisorSearchQueryVariables> = useMemo(() => ({
    skill_ids: getStringifiedIds(skillIds),
    ...excludeAdvisors && size(excludeAdvisors) >= 1 ? { exclude_advisor_ids: getStringifiedIds(excludeAdvisors) } : {},
    ...excludeCourses && size(excludeCourses) >= 1 ? { exclude_course_ids: getStringifiedIds(excludeCourses) } : {},
    ...excludeOpportunities && size(excludeOpportunities) >= 1
      ? { exclude_opp_ids: getStringifiedIds(excludeOpportunities) } : {},
    limit: MAX_ITEMS
  }), [skillIds, excludeAdvisors, excludeCourses, excludeOpportunities]);

  const fetchSkills = useCallback((params: CourseAdvisorSearchQueryVariables) =>
    getSkills?.({ variables: params }), [getSkills]);

  const renderOption = useCallback(({ item_type, title }: CourseAdvisorSearchItem): ReactNode => (
    <Box display="flex" alignItems="center">
      {(item_type === CourseAdvisorItemType.course && (
        <SchoolOutlined color="inherit" fontSize="medium"/>
      )) || (item_type === CourseAdvisorItemType.advisor && (
        <PersonIcon color="inherit" fontSize="medium"/>
      )) || (item_type === CourseAdvisorItemType.opportunity && (
        <Assignment color="inherit" fontSize="medium"/>
      )) || undefined}
      <Box pl={2}>
        {title}
      </Box>
    </Box>
  ), []);

  const filterOptions = useCallback((opts: CourseAdvisorSearchItem[]) =>
    HAS_MENTORING && HAS_COURSES && HAS_DEV_PLAN_OPPORTUNITIES ? opts
    : filter(opts, ({ item_type }) =>
        (HAS_MENTORING && item_type === CourseAdvisorItemType.advisor) ||
        (HAS_COURSES && item_type === CourseAdvisorItemType.course) ||
        (HAS_DEV_PLAN_OPPORTUNITIES && item_type === CourseAdvisorItemType.opportunity)
      ), [HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING]);

  return (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type={(HAS_MENTORING && HAS_COURSES && HAS_DEV_PLAN_OPPORTUNITIES && 'course_advisor_opp') ||
          (HAS_MENTORING && HAS_COURSES && 'course_advisor') ||
          (HAS_MENTORING && HAS_DEV_PLAN_OPPORTUNITIES && 'advisor_opp') ||
          (HAS_COURSES && HAS_DEV_PLAN_OPPORTUNITIES && 'course_opp') ||
          (HAS_DEV_PLAN_OPPORTUNITIES && 'opp') ||
          (HAS_MENTORING ? 'advisor' : 'course')}
        className={root}
        fetched={results}
        pending={loading}
        failed={failed}
        params={variables}
        fetch={fetchSkills}
        fetchParams={fetchParams}
        value={value}
        onChange={onChange}
        renderOption={renderOption}
        filterOptions={filterOptions}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  );
});

CourseAndAdvisorSearch.displayName = 'CourseAndAdvisorSearch';

CourseAndAdvisorSearch.propTypes = CourseAndAdvisorSearchPropTypes;

export default memo(CourseAndAdvisorSearch);
