import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// TM UI Components
import { bold } from '@empathco/ui-components/src/helpers/intl';
// local imports
import { PayRegion } from '../models/payRegion';

type GeoZonePayrangeProps = {
  ranges?: PayRegion[] | null;
}

const GeoZonePayrangePropTypes = {
  // attributes
  ranges: PropTypes.array
};

const GeoZonePayrange: FunctionComponent<GeoZonePayrangeProps> = ({
  ranges
}) => ranges ? (
  <>
    {map(ranges,
      ({ region, salary_minimum, salary_midpoint, salary_maximum }, index) => {
        const minimum = toSafeInteger(salary_minimum);
        const midpoint = toSafeInteger(salary_midpoint);
        const maximum = toSafeInteger(salary_maximum);
        return (
          <Box key={index} display="flex" alignItems="center" pb={1.5}>
            <FormattedMessage
                id={
                  (minimum > 0 && midpoint > 0 && maximum > 0 && 'role_details.range') ||
                  (minimum <= 0 && midpoint <= 0 && maximum <= 0 && 'role_details.range_unknown') ||
                  (minimum > 0 && maximum > 0 ? 'role_details.range_minmax' : 'role_details.range_midpoint')
                }
                values={{ region, minimum, midpoint, maximum, bold }}
            />
          </Box>
        );
      }
    )}
  </>
) : (
  <Box display="flex" alignItems="center" py={1}>
    <FormattedMessage id="role_details.range_cooming_soon"/>
  </Box>
);

GeoZonePayrange.propTypes = GeoZonePayrangePropTypes;

export default memo(GeoZonePayrange);
