import { memo, useCallback, useEffect, useMemo, useState, useContext, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import take from 'lodash/take';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
// TM UI Components
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
// local imports
import { DA_CLOSE_MATCH_JOBS_QUERY } from '../graphql/DACloseMatchJobs';
import { DA_AVERAGE_SKILLS_QUERY } from '../graphql/DAAverageSkills';
import { DA_EMPLOYEE_VELOCITY_QUERY } from '../graphql/DAEmployeeVelocity';
import { DA_CURRENT_ROLE_MATCH_QUERY } from '../graphql/DACurrentRoleMatch';
import { DA_JOB_MOVEMENT_SKILLS_QUERY } from '../graphql/DAJobMovementSkills';
import { DA_USAGE_BY_ORG_QUERY } from '../graphql/DAOrgUsage';
import {
  SkillType,
  DACloseMatchJobsDocument, DACloseMatchJobsQuery,
  DAAverageSkillsDocument, DAAverageSkillsQuery,
  DAEmployeeVelocityDocument, DAEmployeeVelocityQuery,
  DACurrentRoleMatchDocument, DACurrentRoleMatchQuery,
  DAJobMovementSkillsDocument, DAJobMovementSkillsQuery,
  DAOrgUsageDocument, DAOrgUsageQuery
} from '../graphql/types';
import { CONST_MATCH_RATE, toValidConst } from '../constants/constValues';
import { JOB_MOVEMENT_SCOPE_UP, JOB_MOVEMENT_SKILL_SCOPES } from '../constants/scopes';
import {
  DA_AVERAGE_SKILLS, DA_CLOSE_MATCH_JOBS, DA_CURRENT_ROLE_MATCH, DA_EMPLOYEE_VELOCITY, DA_JOB_MOVEMENT_SKILLS,
  DA_USAGE_BY_ORG
} from '../constants/dashboardAnalytics';
import { MAX_DURATION, MAX_MANAGER_DASHBOARD_ITEMS } from '../config/params';
import useCustomerSettings from '../config/customer';
import {
  API_DA_AVERAGE_SKILLS_EXPORT, API_DA_CLOSE_MATCH_JOBS_EXPORT, API_DA_CURRENT_ROLE_MATCH_EXPORT,
  API_DA_EMPLOYEE_VELOCITY_EXPORT, API_DA_JOB_MOVEMENT_SKILLS_EXPORT, API_DA_USAGE_BY_ORG_EXPORT
} from '../config/api';
import { PATH_SKILL } from '../config/paths';
import { FilterValues } from '../hooks/useFilters';
import { GlobalContext } from '../context/global';
import ConstSelector from '../elements/ConstSelector';
import TopChart from '../widgets/TopChart';
// SCSS imports
import { matchRateSelector } from './DashboardAnalyticsEmployees.module.scss';

type DashboardAnalyticsEmployeesProps = {
  chartId: number;
  skillType: SkillType;
  filters?: FilterValues;
  years: number;
  pending?: boolean | null;
  disabled?: boolean | null;
  onEmployeeCountChange?: (value: number | null | undefined) => void;
  pinned?: number[];
  onPin: (value: number) => void;
  pinning?: boolean;
}

const DashboardAnalyticsEmployeesPropTypes = {
  chartId: PropTypes.number.isRequired,
  skillType: PropTypes.string.isRequired as Validator<SkillType>,
  filters: PropTypes.object as Validator<FilterValues>,
  years: PropTypes.number.isRequired,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  onEmployeeCountChange: PropTypes.func,
  pinned: PropTypes.array,
  onPin: PropTypes.func.isRequired,
  pinning: PropTypes.bool
};

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
const DashboardAnalyticsEmployees: FunctionComponent<DashboardAnalyticsEmployeesProps> = ({
  chartId,
  skillType,
  filters,
  years,
  pending: parentPending = false,
  disabled = false,
  onEmployeeCountChange,
  pinned,
  onPin,
  pinning = false
}) => {
  const { DA_TABS, HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { fontsLoaded, token } = useContext(GlobalContext);
  const pending = parentPending || !fontsLoaded;

  // lazy load Job Movement Skills data
  const {
    query: getMovementSkills, pending: pendingMovementSkills, failed: failedMovementSkills, results: movementSkills
  } = useQueryObject({
    data: undefined as unknown as DAJobMovementSkillsQuery['daJobMovementSkills'],
    key: 'daJobMovementSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_JOB_MOVEMENT_SKILLS_QUERY as typeof DAJobMovementSkillsDocument)
  });

  // lazy load Close Match Jobs data
  const {
    query: getCloseMatchJobs, pending: pendingCloseMatchJobs, failed: failedCloseMatchJobs, results: closeMatchJobs
  } = useQueryObject({
    data: undefined as unknown as DACloseMatchJobsQuery['daCloseMatchJobs'],
    key: 'daCloseMatchJobs',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_CLOSE_MATCH_JOBS_QUERY as typeof DACloseMatchJobsDocument)
  });

  // lazy load Close Match Jobs data
  const {
    query: getAvgSkills, pending: pendingAvgSkills, failed: failedAvgSkills, results: avgSkills
  } = useQueryObject({
    data: undefined as unknown as DAAverageSkillsQuery['daAverageSkills'],
    key: 'daAverageSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_AVERAGE_SKILLS_QUERY as typeof DAAverageSkillsDocument)
  });

  // lazy load Employee Velocity data
  const {
    query: getEmplVelocity, pending: pendingEmplVelocity, failed: failedEmplVelocity, results: emplVelocity
  } = useQueryObject({
    data: undefined as unknown as DAEmployeeVelocityQuery['daEmployeeVelocity'],
    key: 'daEmployeeVelocity',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_EMPLOYEE_VELOCITY_QUERY as typeof DAEmployeeVelocityDocument)
  });

  // lazy load Current Role Match data
  const {
    query: getCurMatch, pending: pendingCurMatch, failed: failedCurMatch, results: curMatch
  } = useQueryObject({
    data: undefined as unknown as DACurrentRoleMatchQuery['daCurrentRoleMatch'],
    key: 'daCurrentRoleMatch',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_CURRENT_ROLE_MATCH_QUERY as typeof DACurrentRoleMatchDocument)
  });

  // lazy load Current Role Match data
  const {
    query: getOrgUsage, pending: pendingOrgUsage, failed: failedOrgUsage, results: orgUsage
  } = useQueryObject({
    data: undefined as unknown as DAOrgUsageQuery['daOrgUsage'],
    key: 'daOrgUsage',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_USAGE_BY_ORG_QUERY as typeof DAOrgUsageDocument)
  });

  const [matchRate, setMatchRate] = useState<number>(CONST_MATCH_RATE[3]);
  const [inDemandOnly, setInDemandOnly] = useState(false);
  const [jobMovementScope, setJobMovementScope] = useState(JOB_MOVEMENT_SKILL_SCOPES[0]);

  const handleMatchRateChange = useCallback((event: SelectChangeEvent<number>) =>
    setMatchRate(toValidConst(event?.target?.value, CONST_MATCH_RATE[3], CONST_MATCH_RATE)), []);

  useEffect(() => {
    onEmployeeCountChange?.({
      [DA_JOB_MOVEMENT_SKILLS]: !failedMovementSkills && isNil(movementSkills?.total_employees)
        ? null : movementSkills?.total_employees,
      [DA_CLOSE_MATCH_JOBS]: !failedCloseMatchJobs && isNil(closeMatchJobs?.total_employees)
        ? null : closeMatchJobs?.total_employees,
      [DA_AVERAGE_SKILLS]: !failedAvgSkills && isNil(avgSkills?.total_employees) ? null : avgSkills?.total_employees,
      [DA_EMPLOYEE_VELOCITY]: !failedEmplVelocity && isNil(emplVelocity?.total_employees)
        ? null : emplVelocity?.total_employees,
      [DA_CURRENT_ROLE_MATCH]: !failedCurMatch && isNil(curMatch?.total_employees) ? null : curMatch?.total_employees,
      [DA_USAGE_BY_ORG]: !failedOrgUsage && isNil(orgUsage?.total_employees) ? null : orgUsage?.total_employees
    }[chartId]);
  }, [
    chartId,
    movementSkills?.total_employees, failedMovementSkills,
    closeMatchJobs?.total_employees, failedCloseMatchJobs,
    avgSkills?.total_employees, failedAvgSkills,
    emplVelocity?.total_employees, failedEmplVelocity,
    curMatch?.total_employees, failedCurMatch,
    orgUsage?.total_employees, failedOrgUsage,
    onEmployeeCountChange
  ]);

  const optionalParams = useMemo(() => ({ years }), [years]);
  const optionalParams2 = useMemo(() => ({
    upwardJobMovement: jobMovementScope === JOB_MOVEMENT_SCOPE_UP
  }), [jobMovementScope]);

  const [
    exportParams,
    movementSkillsExportParams,
    closeMatchJobsExportParams,
    avgSkillsExportParams
  ] = useMemo(() => {
    if (!filters || !token || !isString(token)) return [null, null, null, null];
    const params: FilterValues & { token: string; } = { ...filters, token };
    return [
      // Employee Velocity + Current Role Match
      params,
      // Job Movement Skills
      { ...params, skill_type: skillType },
      // Close Match Jobs
      { ...params, match_rate: matchRate },
      // Average # of Skills
      { ...params, duration: MAX_DURATION, ...inDemandOnly ? { indemand_only: true } : {} }
    ];
  }, [matchRate, inDemandOnly, skillType, filters, token]);

  // load Job Movement Skills
  useEffect(() => {
    if (
      chartId === DA_JOB_MOVEMENT_SKILLS && HAS_INDEMAND_SKILLS && DA_TABS?.in_demand_skills && filters
    ) getMovementSkills?.({ variables: {
      input: { ...filters, skill_type: skillType },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, skillType, filters, getMovementSkills, HAS_INDEMAND_SKILLS, DA_TABS?.in_demand_skills]);

  // load Close Match Jobs
  useEffect(() => {
    if (
      chartId === DA_CLOSE_MATCH_JOBS && DA_TABS?.close_match_jobs && filters && matchRate
    ) getCloseMatchJobs?.({ variables: {
      input: {
        ...filters,
        match_rate: matchRate
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, matchRate, getCloseMatchJobs, DA_TABS?.close_match_jobs]);

  // load Average # of Skills
  useEffect(() => {
    if (chartId === DA_AVERAGE_SKILLS && DA_TABS?.average_skills && filters) getAvgSkills?.({ variables: {
      input: {
        ...filters,
        duration: MAX_DURATION,
        ...inDemandOnly ? { indemand_only: true } : {}
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, inDemandOnly, getAvgSkills, DA_TABS?.average_skills]);

  // load Employee Velocity
  useEffect(() => {
    if (chartId === DA_EMPLOYEE_VELOCITY && DA_TABS?.employee_velocity && filters) getEmplVelocity?.({ variables: {
      input: filters,
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, getEmplVelocity, DA_TABS?.employee_velocity]);

  // load Current Role Match
  useEffect(() => {
    if (chartId === DA_CURRENT_ROLE_MATCH && DA_TABS?.match_to_current_role && filters) getCurMatch?.({ variables: {
      input: filters,
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, getCurMatch, DA_TABS?.match_to_current_role]);

  // load Current Role Match
  useEffect(() => {
    if (chartId === DA_USAGE_BY_ORG && DA_TABS?.usage_by_org && filters) getOrgUsage?.({ variables: {
      input: filters,
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [chartId, filters, getOrgUsage, DA_TABS?.usage_by_org]);

  const handlePinJobMovementSkills = useCallback(() => onPin(DA_JOB_MOVEMENT_SKILLS), [onPin]);
  const handlePinCloseMatchJobs = useCallback(() => onPin(DA_CLOSE_MATCH_JOBS), [onPin]);
  const handlePinAvgSkills = useCallback(() => onPin(DA_AVERAGE_SKILLS), [onPin]);
  const handlePinEmplVelocity = useCallback(() => onPin(DA_EMPLOYEE_VELOCITY), [onPin]);
  const handlePinCurMatch = useCallback(() => onPin(DA_CURRENT_ROLE_MATCH), [onPin]);
  const handlePinOrgUsage = useCallback(() => onPin(DA_USAGE_BY_ORG), [onPin]);

  const exportDisabled = disabled || pending || !filters;

  return (
    <>
      {chartId === DA_JOB_MOVEMENT_SKILLS && HAS_INDEMAND_SKILLS && DA_TABS?.in_demand_skills ? (
        <TopChart
            variant="job_movement_skills"
            hrbp
            layout="full"
            info="hr.dashboard.job_movement_skills.info"
            withInfoButton
            data={movementSkills?.results ? take(movementSkills?.results, MAX_MANAGER_DASHBOARD_ITEMS) : null}
            totalEmployees={movementSkills?.total_employees}
            path={PATH_SKILL}
            pending={pendingMovementSkills || pending}
            failed={failedMovementSkills}
            optionalParams={optionalParams2}
            action={(
              <ScopeSelector
                  simple
                  scope={JOB_MOVEMENT_SKILL_SCOPES}
                  value={jobMovementScope}
                  onChange={setJobMovementScope as (item: string) => void}
                  disabled={disabled || pendingMovementSkills ? true : undefined}
              />
            )}
            pinned={includes(pinned, DA_JOB_MOVEMENT_SKILLS)}
            onPin={disabled ? undefined : handlePinJobMovementSkills}
            pinning={pinning}
            exportEndpoint={API_DA_JOB_MOVEMENT_SKILLS_EXPORT}
            exportParams={movementSkillsExportParams}
            exportDisabled={exportDisabled || pendingMovementSkills || failedMovementSkills ||
              size(movementSkills?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_CLOSE_MATCH_JOBS && DA_TABS?.close_match_jobs ? (
        <TopChart
            variant="close_match_jobs"
            hrbp
            layout="full"
            info="hr.dashboard.close_match_jobs.info"
            withInfoButton
            data={closeMatchJobs?.results}
            totalEmployees={closeMatchJobs?.total_employees}
            pending={pendingCloseMatchJobs || pending}
            failed={failedCloseMatchJobs}
            optionalParams={optionalParams}
            action={(
              <ConstSelector
                  variant="match_rate"
                  value={matchRate}
                  onChange={handleMatchRateChange}
                  disabled={disabled || pendingCloseMatchJobs ? true : undefined}
                  className={matchRateSelector}
              />
            )}
            pinned={includes(pinned, DA_CLOSE_MATCH_JOBS)}
            onPin={disabled ? undefined : handlePinCloseMatchJobs}
            pinning={pinning}
            exportEndpoint={API_DA_CLOSE_MATCH_JOBS_EXPORT}
            exportParams={closeMatchJobsExportParams}
            exportDisabled={exportDisabled || pendingCloseMatchJobs || failedCloseMatchJobs ||
              size(closeMatchJobs?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_AVERAGE_SKILLS && DA_TABS?.average_skills ? (
        <TopChart
            variant="job_average_skills"
            hrbp
            info="hr.dashboard.job_average_skills.info"
            withInfoButton
            layout="full"
            data={avgSkills?.results}
            totalEmployees={avgSkills?.total_employees}
            pending={pendingAvgSkills || pending}
            failed={failedAvgSkills}
            optionalParams={optionalParams}
            action={(
              <OnOffSwitch
                  label="hr.dashboard.in_demand_only"
                  value={inDemandOnly}
                  onChange={setInDemandOnly}
                  disabled={disabled || pendingAvgSkills ? true : undefined}
              />
            )}
            pinned={includes(pinned, DA_AVERAGE_SKILLS)}
            onPin={disabled ? undefined : handlePinAvgSkills}
            pinning={pinning}
            exportEndpoint={API_DA_AVERAGE_SKILLS_EXPORT}
            exportParams={avgSkillsExportParams}
            exportDisabled={exportDisabled || pendingAvgSkills || failedAvgSkills || size(avgSkills?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_EMPLOYEE_VELOCITY && DA_TABS?.employee_velocity ? (
        <TopChart
            variant="employee_velocity"
            hrbp
            layout="full"
            info="hr.dashboard.employee_velocity.info"
            withInfoButton
            data={emplVelocity?.results}
            totalEmployees={emplVelocity?.total_employees}
            pending={pendingEmplVelocity || pending}
            failed={failedEmplVelocity}
            optionalParams={optionalParams}
            pinned={includes(pinned, DA_EMPLOYEE_VELOCITY)}
            onPin={disabled ? undefined : handlePinEmplVelocity}
            pinning={pinning}
            exportEndpoint={API_DA_EMPLOYEE_VELOCITY_EXPORT}
            exportParams={exportParams}
            exportDisabled={exportDisabled || pendingEmplVelocity || failedEmplVelocity || size(emplVelocity?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_CURRENT_ROLE_MATCH && DA_TABS?.match_to_current_role ? (
        <TopChart
            variant="current_role_match"
            hrbp
            layout="full"
            info="hr.dashboard.current_role_match.info"
            withInfoButton
            data={curMatch?.results}
            totalEmployees={curMatch?.total_employees}
            pending={pendingCurMatch || pending}
            failed={failedCurMatch}
            optionalParams={optionalParams}
            pinned={includes(pinned, DA_CURRENT_ROLE_MATCH)}
            onPin={disabled ? undefined : handlePinCurMatch}
            pinning={pinning}
            exportEndpoint={API_DA_CURRENT_ROLE_MATCH_EXPORT}
            exportParams={exportParams}
            exportDisabled={exportDisabled || pendingCurMatch || failedCurMatch || size(curMatch?.results) < 1}
        />
      ) : undefined}
      {chartId === DA_USAGE_BY_ORG && DA_TABS?.usage_by_org ? (
        <TopChart
            variant="usage_by_org"
            hrbp
            layout="full"
            // info="hr.dashboard.usage_by_org.info"
            withInfoButton
            data={orgUsage?.results}
            totalEmployees={orgUsage?.total_employees}
            pending={pendingOrgUsage || pending}
            failed={failedOrgUsage}
            optionalParams={optionalParams}
            pinned={includes(pinned, DA_USAGE_BY_ORG)}
            onPin={disabled ? undefined : handlePinOrgUsage}
            pinning={pinning}
            exportEndpoint={API_DA_USAGE_BY_ORG_EXPORT}
            exportParams={exportParams}
            exportDisabled={exportDisabled || pendingOrgUsage || failedOrgUsage || size(orgUsage?.results) < 1}
        />
      ) : undefined}
    </>
  );
};

DashboardAnalyticsEmployees.propTypes = DashboardAnalyticsEmployeesPropTypes;

export default memo(DashboardAnalyticsEmployees);
