import { memo, useContext, useEffect, useMemo, type FunctionComponent } from 'react';
import toLower from 'lodash/toLower';
import { useLazyQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
// TM UI Components
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
// local imports
import { EMPLOYEE_SKILL_QUERY } from '../graphql/EmployeeSkill';
import { EmployeeSkillDocument, EmployeeSkillResources } from '../graphql/types';
import { Skill } from '../models/skill';
import { isNotContractor, isEmployee, isManager, hasHRBP, isAdmin } from '../models/user';
import useCustomerSettings from '../config/customer';
import { PATH_MY_SKILLS } from '../config/paths';
import { getSkillId, RouterLinkState } from '../helpers/routerParams';
import { DataContext } from '../context';
import useVerifyUser from '../hooks/useVerifyUser';
import SkillDetails from '../components/SkillDetails';
import EmployeesWithSkillCard from '../components/EmployeesWithSkillCard';
import EmployeeSkillCourses from '../components/EmployeeSkillCourses';
import SkillDevelopmentResources from '../components/SkillDevelopmentResources';
import EmployeeSkillRelatedJobs from '../components/EmployeeSkillRelatedJobs';
import Screen from '../v3/Screen';
import DataStatusPoller from '../v3/DataStatusPoller';

const SkillScreen: FunctionComponent = () => {
  const { HAS_COURSES } = useCustomerSettings();

  const { state } = useLocation();
  const skill_id = getSkillId(useParams());
  const { content, user } = useVerifyUser({});
  const { skill: { data: skill, pending, failed }, requireEmployeeSkill } = useContext(DataContext);
  const { is_international } = user || {};
  const nonReducedUI = isNotContractor(user);

  const isEmpl = isEmployee(user);
  const supervisor = isManager(user);
  const hrbp = hasHRBP(user) || isAdmin(user);
  const notEmpl = !isEmpl && !supervisor;

  // lazy load skill
  const { query: getSkill, pending: pendingSkill, failed: failedSkill, results: skillDetails } = useQueryObject({
    data: undefined as unknown as EmployeeSkillResources,
    key: 'employeeSkill',
    flatResults: true,
    lazyQuery: useLazyQuery(EMPLOYEE_SKILL_QUERY as typeof EmployeeSkillDocument)
  });

  useEffect(() => {
    if (!content && skill_id) {
      requireEmployeeSkill?.({ skill_id, hrbp: notEmpl });
      if (isEmpl) getSkill?.({ variables: { skill_id } });
    }
  }, [content, skill_id, isEmpl, notEmpl, requireEmployeeSkill, getSkill]);

  const { title } = content || pending || failed || !skill || toLower(skill.abbr || '') !== skill_id ? {} as Skill : skill;

  const breadcrumbs = useMemo(() => [
    {
      title: 'breadcrumbs.skills',
      ...isEmpl ? { link: PATH_MY_SKILLS } : {}
    },
    ...title ? [title] : []
  ], [title, isEmpl]);

  const withSettings = !user?.is_admin_only;

  return content || (
    <Screen
        withSettings={withSettings}
        breadcrumbs={breadcrumbs}
    >
      <SkillDetails reducedUI={!nonReducedUI} isEmployee={isEmpl} supervisor={supervisor}/>
      {isEmpl && nonReducedUI ? (
        <SkillDevelopmentResources
            skill={skillDetails}
            pending={pendingSkill}
            failed={failed || failedSkill}
            hrbp={hrbp}
            isInternational={is_international}
        />
      ) : undefined}
      {HAS_COURSES && isEmpl && nonReducedUI ? <EmployeeSkillCourses skillId={skill_id}/> : undefined}
      <EmployeesWithSkillCard
          skillId={skill_id}
          isEmployee={isEmpl}
          supervisor={supervisor}
          hrbp={hrbp}
          isInternational={is_international}
          reducedUI={!nonReducedUI}
          resetFilters={(state as RouterLinkState)?.fromDashboard}
      />
      {isEmpl ? <EmployeeSkillRelatedJobs/> : undefined}
      {isEmpl ? <DataStatusPoller/> : undefined}
    </Screen>
  );
};

export default memo(SkillScreen);
