import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
// TM UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
// local imports
import { GlobalContext } from '../context/global';
import useStatusPoller from './useStatusPoller';

function useExport(
  getPromise: (format: ExportFormat, token: string) => Promise<boolean | number> | null,
  onFinished?: ((success: boolean) => void) | null,
  testExportFailed: boolean | null = null
) {
  const { online, token } = useContext(GlobalContext);

  const [failed, setFailed] = useState<boolean | null>(testExportFailed);

  const [jobId, setJobId] = useState<number>();
  const [savedFormat, setSavedFormat] = useState<ExportFormat>();
  const { computed, failed: statusFailed } = useStatusPoller(jobId);

  const handleExport = useCallback((format: ExportFormat) => {
    if (online && token && isString(token)) {
      setFailed(false);
      const promise = getPromise(format, token);
      if (promise) {
        promise
          .then((result) => {
            if (isBoolean(result)) {
              setFailed(result ? null : true);
              onFinished?.(result);
            } else {
              setJobId(result);
              setSavedFormat(format);
              onFinished?.(true);
            }
          })
          .catch(() => {
            setFailed(true);
            onFinished?.(false);
          });
        return;
      }
    }
    setFailed(true);
  }, [getPromise, onFinished, online, token]);

  const handleDismiss = useCallback(() => setFailed(null), []);

  useEffect(() => {
    if (computed && savedFormat) {
      const format = savedFormat;
      setJobId(undefined);
      setSavedFormat(undefined);
      handleExport(format);
    }
  }, [computed, handleExport, savedFormat]);

  useEffect(() => {
    if (statusFailed) {
      setJobId(undefined);
      setSavedFormat(undefined);
      setFailed(true);
    }
  }, [statusFailed]);

  return useMemo(() => ({
    enabled: Boolean(online && token && isString(token)) && failed !== false && !jobId,
    failed,
    handleExport,
    handleDismiss
  }), [failed, handleDismiss, handleExport, jobId, online, token]);
}

export default useExport;
