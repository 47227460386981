import { useCallback, useMemo } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// TM UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { DevPlanTargetSkill, SkillActivity } from '../graphql/types';
import {
  DevPlanAdvisor, DevPlanCourse, DevPlanOpportunity
} from '../graphql/customTypes';
import { APIResponse } from '../models/apiResponse';
import useCustomerSettings from '../config/customer';
import {
  IAutoTable, IJsPDF, Rows, Headers,
  getTitleFilename,
  downloadAndSave, saveToCSV, saveToPDF
} from '../helpers/export';

function useExportDevPlan() {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  // DevPlan Employees Progress export

  const DEV_PLAN_CSV_COLUMNS = useMemo(() => [
    ...HAS_COURSES ? ['supervisor.export.course.id'] : [],
    ...HAS_MENTORING ? ['supervisor.export.advisor.id'] : [],
    ...HAS_DEV_PLAN_OPPORTUNITIES ? ['supervisor.export.opportunity.id'] : [],
    'supervisor.export.activity.id',
    'supervisor.export.title_or_name.title'
  ], [HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING]);

  const saveDevPlan = useCallback((
    devplanTitle: string,
    headers: Headers,
    targetSkills: DevPlanTargetSkill[],
    courses: DevPlanCourse[],
    advisors: DevPlanAdvisor[],
    opportunities: DevPlanOpportunity[],
    activities?: SkillActivity[] | null,
    jsPDF?: IJsPDF,
    autoTable?: IAutoTable
    // eslint-disable-next-line max-params
  ) => {
    const titles = [
      headers[0], // course
      headers[1], // advisor
      headers[2], // opportunity
      ...activities ? [headers[3]] : [], // activity
      headers[4], // title
      ...map(targetSkills, 'title')
    ];
    const rows = [
      ...HAS_COURSES ? map(courses, ({ code, title, covered_skills }) => [
        code,
        ...HAS_MENTORING ? [null] : [],
        ...HAS_DEV_PLAN_OPPORTUNITIES ? [null] : [],
        ...activities ? [null] : [],
        title,
        ...map(targetSkills, ({ id }) => find(covered_skills, ['id', id])?.current_level || 0)
      ] as RowInput) : [],
      ...HAS_MENTORING ? map(advisors, ({ code, first_name, last_name, advisory_skills }) => [
        ...HAS_COURSES ? [null] : [],
        code,
        ...HAS_DEV_PLAN_OPPORTUNITIES ? [null] : [],
        ...activities ? [null] : [],
        getFullName(first_name, last_name),
        ...map(targetSkills, ({ id }) => find(advisory_skills, ['id', id])?.current_level || 0)
      ] as RowInput) : [],
      ...HAS_DEV_PLAN_OPPORTUNITIES ? map(opportunities, ({ id: oppId, title, skills }) => [
        ...HAS_COURSES ? [null] : [],
        ...HAS_MENTORING ? [null] : [],
        oppId,
        ...activities ? [null] : [],
        title,
        ...map(targetSkills, ({ id }) => find(skills, ['id', id])?.skill_proficiency_level || 0)
      ] as RowInput) : [],
      ...activities ? map(activities, ({ code: activityId, name, skills }) => [
        ...HAS_COURSES ? [null] : [],
        ...HAS_MENTORING ? [null] : [],
        ...HAS_DEV_PLAN_OPPORTUNITIES ? [null] : [],
        activityId,
        name,
        ...map(targetSkills, ({ id }) => find(skills, ['id', id])?.skill_proficiency_level || 0)
      ] as RowInput) : []
    ] as Rows;
    const filename = `devplan_${getTitleFilename(devplanTitle)}`;
    if (jsPDF && autoTable) {
      saveToPDF(jsPDF, autoTable, filename, titles, rows, devplanTitle, '' /* , {} */);
    } else {
      saveToCSV(filename, titles, rows);
    }
  }, [HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING]);

  const exportDevPlan = useCallback((
    format: string,
    title: string,
    headers: Headers,
    skills: DevPlanTargetSkill[],
    courses: DevPlanCourse[],
    advisors: DevPlanAdvisor[],
    opportunities: DevPlanOpportunity[],
    activities?: SkillActivity[] | null
    // eslint-disable-next-line max-params
  ): Promise<boolean | number> => downloadAndSave<DevPlanCourse>(
    format,
    {
      status: 200, data: { results: courses } as APIResponse<DevPlanCourse>
    } as AxiosResponse<APIResponse<DevPlanCourse>>,
    (_data, jsPDF, autoTable) =>
      saveDevPlan(title, headers, skills, courses, advisors, opportunities, activities, jsPDF, autoTable)
  ), [saveDevPlan]);

  return useMemo(() => ({
    DEV_PLAN_CSV_COLUMNS,
    exportDevPlan
  }), [DEV_PLAN_CSV_COLUMNS, exportDevPlan]);
}

export default useExportDevPlan;
