import { forwardRef, memo, type ReactNode, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
// SCSS imports
import { shadyBg } from '../styles/modules/ItemsGrid.module.scss';
import { section, compactPadding, cardTop, cardBottom } from './CardSection.module.scss';

type CardSectionProps = {
  dark?: boolean;
  shady?: boolean;
  light?: boolean;
  compact?: boolean;
  top?: boolean | null;
  bottom?: boolean | null;
  flex?: boolean;
  className?: string;
  children: ReactNode | ReactNode[];
};

const CardSectionPropTypes = {
  dark: PropTypes.bool,
  shady: PropTypes.bool,
  light: PropTypes.bool,
  compact: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  flex: PropTypes.bool,
  className: PropTypes.string,
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>
};

const CardSection = forwardRef<Component<BoxProps>, CardSectionProps>(({
  children,
  dark = false,
  shady = false,
  light = false,
  compact = false,
  top = false,
  bottom = false,
  flex = false,
  className
}, ref) => (
  <CardContent
      ref={ref}
      component={Box}
      color={dark || shady || light ? 'text.label' : undefined}
      bgcolor={(dark && 'background.card') || (light && 'background.shady') || undefined}
      display={flex ? 'flex' : undefined}
      alignItems={flex ? 'center' : undefined}
      flexWrap={flex ? 'wrap' : undefined}
      className={clsx(section, className, {
        [compactPadding]: compact,
        [cardTop]: top,
        [cardBottom]: bottom,
        [shadyBg]: shady
      })}
  >
    {children}
  </CardContent>
));

CardSection.displayName = 'CardSection';

CardSection.propTypes = CardSectionPropTypes;

export default memo(CardSection);
