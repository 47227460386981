import { forwardRef, memo, useCallback, useLayoutEffect, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import findIndex from 'lodash/findIndex';
import thru from 'lodash/thru';
import map from 'lodash/map';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Tabs from '@mui/material/Tabs';
// local imports
import { TabbarItem } from '../models/tabbarItem';
import TabLink from '../mixins/TabLink';
// SCSS imports
import { header, withoutAction, withAction, withWideAction } from './CardTabbar.module.scss';

type CardTabbarProps = {
  items: TabbarItem[];
  action?: ReactNode | null;
  wideAction?: boolean | null;
  withoutScroll?: boolean | null;
  onChange?: (index: number) => void;
  savedPathname?: string;
  // React built-in
  children?: ReactNode[] | null;
}

const CardTabbarPropTypes = {
  items: PropTypes.array.isRequired as Validator<TabbarItem[]>,
  action: PropTypes.node as Validator<ReactNode>,
  wideAction: PropTypes.bool,
  withoutScroll: PropTypes.bool,
  onChange: PropTypes.func,
  savedPathname: PropTypes.string,
  // React built-in
  children: PropTypes.arrayOf(PropTypes.node) as Validator<ReactNode[]>
};

const CardTabbar = forwardRef<HTMLElement, CardTabbarProps>(({
  items,
  action,
  wideAction = false,
  withoutScroll = false,
  onChange,
  savedPathname,
  children
}, ref) => {
  const { pathname } = useLocation();
  const currentPathname = savedPathname || pathname;

  const activeIndex = thru(findIndex(items, ({ active, link }) => Boolean(link) && (
    active || link === currentPathname || (link as Record<string, string>)[currentPathname] === currentPathname
  )), (idx) => idx < 0 ? 0 : idx);
  const tabAction = items?.[activeIndex]?.action;

  const renderItem = useCallback(({ title, values, link, disabled }: TabbarItem, index: number) => {
    const titleStr = isString(title)
      ? <FormattedMessage id={title} values={values} defaultMessage={title}/>
      : title;
    const path = (link && (isString(link)
      ? link
      : isPlainObject(link) && link[currentPathname]
    )) || undefined;
    const tabActionOnly = tabAction && action && activeIndex === index ? (
      <Box pl={1} display="flex" alignItems="center" zIndex={100}>
        {tabAction}
      </Box>
    ) : undefined;
    return (
      <TabLink
          key={index}
          value={index}
          label={tabActionOnly ? (
            <Box display="flex" alignItems="center">
              {titleStr}
              {tabActionOnly}
            </Box>
          ) : titleStr}
          to={path || ''}
          disabled={disabled || !path}
      />
    );
  }, [activeIndex, action, tabAction, currentPathname]);

  useLayoutEffect(() => onChange?.(activeIndex), [activeIndex, onChange]);

  return (
    <>
      <CardHeader
          ref={ref}
          disableTypography
          className={clsx(header, {
            [withoutAction]: !action && !tabAction,
            [withAction]: (action || tabAction) && !wideAction,
            [withWideAction]: (action || tabAction) && wideAction
          })}
          title={
            <Tabs
                value={activeIndex}
                indicatorColor="primary"
                textColor="secondary"
                centered={withoutScroll ? true : undefined}
                variant={withoutScroll ? 'fullWidth' : 'scrollable'}
            >
              {map(items, renderItem)}
            </Tabs>
          }
          action={action || tabAction}
      />
      {children?.[activeIndex]}
    </>
  );
});

CardTabbar.displayName = 'CardTabbar';

CardTabbar.propTypes = CardTabbarPropTypes;

export default memo(CardTabbar);
