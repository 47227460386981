import isSafeInteger from 'lodash/isSafeInteger';
import isBoolean from 'lodash/isBoolean';
import toString from 'lodash/toString';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
// TM UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import {
  EMPLOYEE_ROLE_FETCH,
  JOB_MOVES_FETCH, TARGET_ROLE_FETCH,
  TARGET_ROLE_UPDATED, TARGET_ROLE_UPDATING,
  EMPLOYEES_CONNECT_FETCH, EMPLOYEE_MATCH_RATE_FETCH,
  TECHNICAL_CAREER_PATH_FETCH, OPEN_REQS_FETCH
} from './dataContext';
import {
  axiosInstance as axios,
  API_EMPLOYEE_JOBS_TARGETED, API_EMPLOYEES_CONNECT, API_TECHNICAL_CAREER_PATH,
  getApiEmployeeRole, getApiJobMoves, getApiMatchRate, getApiOpenReqs, getApiRoleSetTarget, getApiJob
} from '../config/api';
import { getRequestHeaders, locationParams, optimizeParams } from '../helpers/context';
import { fetchFactory } from '../helpers/actions';
import { transformMatchRate } from './dataTranformations';

export const requireEmployeeRole = (token, online, unauthenticate, dispatch, role, routeParams) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  type: EMPLOYEE_ROLE_FETCH,
  entity: role,
  params: ({ role_id, hrbp, onSuccess }) => ({
    role_id,
    onSuccess,
    hrbp: hrbp === true ? true : null
  }),
  validator: ({ role_id }) => !isEmptyString(role_id),
  api: ({ role_id, hrbp }) => hrbp ? getApiJob(role_id) : getApiEmployeeRole(role_id),
  dropParams: ['role_id', 'hrbp', 'onSuccess'],
  results: ''
});

export const requireMatchRate = (token, online, unauthenticate, dispatch, matchRate) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: EMPLOYEE_MATCH_RATE_FETCH,
  entity: matchRate,
  params: ['role_id'],
  validator: ({ role_id }) => !isEmptyString(role_id),
  api: ({ role_id }) => getApiMatchRate(role_id),
  dropParams: ['role_id'],
  results: '',
  transformation: transformMatchRate
});

export const requireEmployeesToConnect = (token, online, unauthenticate, dispatch, employeesToConnect) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: EMPLOYEES_CONNECT_FETCH,
  entity: employeesToConnect,
  withCount: true,
  params: ({ job_id, state_id, country_id, limit, offset, manager_id, my_team_only }) => ({
    ...locationParams(country_id, state_id),
    job_id,
    ...isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? {} : { manager_id },
    my_team_only: my_team_only === true ? true : null,
    limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null
  }),
  validator: ({ job_id }) => job_id && isSafeInteger(job_id) && job_id >= 1,
  api: API_EMPLOYEES_CONNECT
});

export const requireCareerPath = (token, online, unauthenticate, dispatch, careerPath) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: TECHNICAL_CAREER_PATH_FETCH,
  entity: careerPath,
  params: ['discipline_id'],
  validator: ({ discipline_id }) => isSafeInteger(discipline_id) && discipline_id >= 1,
  api: API_TECHNICAL_CAREER_PATH
});

export const requireJobMoves = (token, online, unauthenticate, dispatch, jobMoves) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: JOB_MOVES_FETCH,
  entity: jobMoves,
  params: ['job_id'],
  validator: ({ job_id }) => isSafeInteger(job_id) && job_id >= 1,
  api: ({ job_id }) => getApiJobMoves(job_id),
  dropParams: ['job_id'],
  results: ''
});

export const requireTargetRole = (token, online, unauthenticate, dispatch, targetRole) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: TARGET_ROLE_FETCH,
  entity: targetRole,
  api: API_EMPLOYEE_JOBS_TARGETED
});

export const updateTargetRole = (token, online, dispatch, targetRoleUpdatePending) => async (callParams = {}) => {
  const { role_id, is_target } = callParams || {};
  if (!token || targetRoleUpdatePending || isEmptyString(role_id) || !isBoolean(is_target)) return;
  const params = optimizeParams({ role_id, is_target }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: TARGET_ROLE_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: getApiRoleSetTarget(role_id),
      data: omit(params, 'role_id'),
      headers: getRequestHeaders(token)
    }) || {};
    if (status === 400) throw new Error({ response: { status: 400 } });
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    dispatch({
      type: TARGET_ROLE_UPDATED,
      payload: true,
      params
    });
  } catch (error) {
    if (error && error.response && error.response.status === 400) {
      dispatch({
        type: TARGET_ROLE_UPDATED,
        payload: false,
        params: { ...params, limitReached: true }
      });
      return;
    }
    dispatch({
      type: TARGET_ROLE_UPDATED,
      payload: false,
      params
    });
  }
};

export const requireOpenReqs = (token, online, unauthenticate, dispatch, openReqs) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: OPEN_REQS_FETCH,
  entity: openReqs,
  withCount: true,
  params: ({ role_id, limit, offset }) => ({
    role_id,
    limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null
  }),
  validator: ({ role_id }) => !isEmptyString(role_id),
  api: ({ role_id }) => getApiOpenReqs(role_id),
  dropParams: ['role_id']
});
