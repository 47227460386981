import { memo, useCallback, useContext, useEffect, useState, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import endsWith from 'lodash/endsWith';
import { useLazyQuery } from '@apollo/client';
import { useLocation, Navigate } from 'react-router-dom';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
// TM UI Components
import { MIN_PAGE_SIZE } from '@empathco/ui-components/src/config/params';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { MY_ACTIVITIES_QUERY } from '../graphql/MyActivities';
import { EMPLOYEE_ACTIVITIES_QUERY } from '../graphql/EmployeeActivities';
import { EmployeeActivitiesDocument, MyActivitiesDocument, EmployeeActivity } from '../graphql/types';
import { CONST_MONTHS, toValidConst } from '../constants/constValues';
import useCustomerSettings from '../config/customer';
import {
  PATH_MY_SKILLS, PATH_MY_SKILLS_LEARNING, PATH_MY_SKILLS_MENTORSHIP, PATH_MY_SKILLS_ACTIVITIES,
  PATH_MY_SKILLS_IN_DEMAND, PATH_MY_SKILLS_IN_DEMAND_LEARNING,
  PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP, PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES,
  PATH_MY_SKILLS_CURRENT_ROLE, PATH_MY_SKILLS_CURRENT_ROLE_LEARNING,
  PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP, PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES,
  PATH_SUPERVISOR_EMPLOYEE, PATH_SUPERVISOR_LEARNING, PATH_SUPERVISOR_MENTORSHIP, PATH_SUPERVISOR_ACTIVITIES,
  PATH_SUPERVISOR_IN_DEMAND, PATH_SUPERVISOR_IN_DEMAND_LEARNING,
  PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES,
  PATH_SUPERVISOR_CURRENT_ROLE, PATH_SUPERVISOR_CURRENT_ROLE_LEARNING,
  PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP, PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES
} from '../config/paths';
import { getSettingsIntValue } from '../helpers/context';
import { DataContext } from '../context';
import ConstSelector from '../elements/ConstSelector';
import TargetSkills from './TargetSkills';
import Mentorship from './Mentorship';
import LearningProgress from './LearningProgress';
import EmployeeSelectedActivities from './EmployeeSelectedActivities';

type SkillsDevelopmentProps = {
  supervisor?: boolean;
  uid?: string | null;
  employeeName?: string | null;
  savedPathname?: string;
  // for Storybook only
  testPending?: boolean;
}

const SkillsDevelopmentPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string,
  savedPathname: PropTypes.string,
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const SkillsDevelopment: FunctionComponent<SkillsDevelopmentProps> = ({
  supervisor = false,
  uid,
  employeeName,
  savedPathname,
  testPending
}) => {
  const { HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING } = useCustomerSettings();
  const { pathname } = useLocation();
  const {
    learningProgress: { data: skills, pending: pendingSkills, failed: failedSkills },
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = supervisor ? 'employee_profile' : 'skills_index';
  const settingsDuration = getSettingsIntValue(settings, `${settingsId}__duration`);

  // lazy load selected activities
  const myActivities = useQueryCounted({
    data: undefined as unknown as EmployeeActivity,
    key: 'myActivities',
    lazyQuery: useLazyQuery(MY_ACTIVITIES_QUERY as typeof MyActivitiesDocument)
  });
  const employeeActivities = useQueryCounted({
    data: undefined as unknown as EmployeeActivity,
    key: 'employeeActivities',
    lazyQuery: useLazyQuery(EMPLOYEE_ACTIVITIES_QUERY as typeof EmployeeActivitiesDocument)
  });
  const { query: getActivities, pending, failed, count, results: activities } = supervisor ? employeeActivities : myActivities;

  const [duration, setDuration] = useState(toValidConst(settingsDuration, CONST_MONTHS[0], CONST_MONTHS));

  const handleDurationChange = useCallback((event: SelectChangeEvent<number>) => {
    const value = toValidConst(event?.target?.value, CONST_MONTHS[0], CONST_MONTHS);
    setDuration(value);
    if (settingsDuration !== value) updateSettings?.({
      [`${settingsId}__duration`]: value
    });
  }, [settingsDuration, settingsId, updateSettings]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      setDuration(toValidConst(
        getSettingsIntValue(settings, `${settingsId}__duration`),
        CONST_MONTHS[0], CONST_MONTHS
      ));
    }
  }, [settingsLoaded, pendingSettingsUpdate, settings, settingsId]);

  useEffect(() => {
    getActivities?.({ variables: {
      ...supervisor && uid ? { selected_employee_id: toLower(uid) } : {},
      limit: MIN_PAGE_SIZE,
      offset: 0
    } });
  }, [supervisor, uid, getActivities]);

  const [tab41, tab42, tab43] = useMemo(() => [
    supervisor
      ? injectParams(PATH_SUPERVISOR_ACTIVITIES, { employee_id: uid })
      : PATH_MY_SKILLS_ACTIVITIES,
    supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_ACTIVITIES, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_ACTIVITIES,
    supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE_ACTIVITIES, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE_ACTIVITIES
  ], [supervisor, uid]);

  const atActivitiesTab = pathname === tab41 || pathname === tab42 || pathname === tab43;
  const hasSelectedActivities = !failed && ((!pending && Boolean(count || size(activities) >= 1)) || atActivitiesTab);

  const values = useMemo(() => supervisor ? {
    name: employeeName,
    endsWithS: employeeName && endsWith(employeeName, 's')
  } : undefined, [supervisor, employeeName]);

  // eslint-disable-next-line complexity
  const { items, tab11path, tab12path, tab13path } = useMemo(() => {
    // recommended skills
    const tab11 = supervisor
      ? injectParams(PATH_SUPERVISOR_EMPLOYEE, { employee_id: uid })
      : PATH_MY_SKILLS;
    const tab21 = supervisor
      ? injectParams(PATH_SUPERVISOR_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_LEARNING;
    const tab31 = supervisor
      ? injectParams(PATH_SUPERVISOR_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_MENTORSHIP;
    // in-demand skills
    const tab12 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND;
    const tab22 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_LEARNING;
    const tab32 = supervisor
      ? injectParams(PATH_SUPERVISOR_IN_DEMAND_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_IN_DEMAND_MENTORSHIP;
    // current job skills
    const tab13 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE;
    const tab23 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE_LEARNING, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE_LEARNING;
    const tab33 = supervisor
      ? injectParams(PATH_SUPERVISOR_CURRENT_ROLE_MENTORSHIP, { employee_id: uid })
      : PATH_MY_SKILLS_CURRENT_ROLE_MENTORSHIP;
    return {
      tab11path: tab11,
      tab12path: tab12,
      tab13path: tab13,
      items: [
        {
          title: supervisor ? 'skills.tab.employees_target_skills' : 'skills.tab.my_target_skills',
          values,
          link: {
            [tab11]: tab11,
            ...HAS_COURSES_READONLY ? {
              [tab21]: tab11,
              [tab23]: tab13
            } : {},
            ...HAS_INDEMAND_SKILLS ? {
              [tab12]: tab12,
              ...HAS_COURSES_READONLY ? { [tab22]: tab12 } : {}
            } : {},
            ...HAS_MENTORING ? {
              [tab31]: tab11,
              ...HAS_INDEMAND_SKILLS ? { [tab32]: tab12 } : {},
              [tab33]: tab13
            } : {},
            ...hasSelectedActivities ? {
              [tab41]: tab11,
              ...HAS_INDEMAND_SKILLS ? { [tab42]: tab12 } : {}
            } : {},
            [tab13]: tab13,
            [tab43]: tab13
          }
        },
        ...HAS_COURSES_READONLY ? [
          {
            title: supervisor ? 'skills.tab.employees_learning_progress' : 'skills.tab.my_learning_progress',
            values,
            link: {
              [tab11]: tab21,
              [tab21]: tab21,
              ...HAS_INDEMAND_SKILLS ? {
                [tab12]: tab22,
                [tab22]: tab22
              } : {},
              ...HAS_MENTORING ? {
                [tab31]: tab21,
                ...HAS_INDEMAND_SKILLS ? { [tab32]: tab22 } : {},
                [tab33]: tab23
              } : {},
              ...hasSelectedActivities ? {
                [tab41]: tab21,
                ...HAS_INDEMAND_SKILLS ? { [tab42]: tab22 } : {}
              } : {},
              [tab13]: tab23,
              [tab23]: tab23,
              [tab43]: tab23
            },
            action: (
              <Box px={1} pt={1}>
                <ConstSelector
                    variant="months"
                    value={duration}
                    onChange={handleDurationChange}
                    disabled={pendingSkills || failedSkills || !skills}
                />
              </Box>
            )
          }
        ] : [],
        ...HAS_MENTORING ? [
          {
            title: supervisor ? 'skills.tab.employee_mentorship' : 'skills.tab.mentorship',
            values,
            link: {
              [tab11]: tab31,
              ...HAS_COURSES_READONLY ? {
                [tab21]: tab31,
                [tab23]: tab33
              } : {},
              [tab31]: tab31,
              ...HAS_INDEMAND_SKILLS ? {
                [tab12]: tab32,
                ...HAS_COURSES_READONLY ? { [tab22]: tab32 } : {},
                [tab32]: tab32
              } : {},
              ...hasSelectedActivities ? {
                [tab41]: tab31,
                [tab42]: tab32
              } : {},
              [tab13]: tab33,
              [tab33]: tab33,
              [tab43]: tab33
            }
          }
        ] : [],
        ...hasSelectedActivities ? [
          {
            title: supervisor ? 'skills.tab.employees_activities' : 'skills.tab.my_activities',
            values,
            link: {
              [tab11]: tab41,
              ...HAS_COURSES_READONLY ? {
                [tab21]: tab41,
                [tab23]: tab43
              } : {},
              ...HAS_MENTORING ? {
                [tab31]: tab41,
                ...HAS_INDEMAND_SKILLS ? { [tab32]: tab42 } : {},
                [tab33]: tab43
              } : {},
              [tab41]: tab41,
              ...HAS_INDEMAND_SKILLS ? {
                [tab12]: tab42,
                ...HAS_COURSES_READONLY ? { [tab22]: tab42 } : {},
                [tab42]: tab42
              } : {},
              [tab13]: tab43,
              [tab43]: tab43
            }
          }
        ] : []
      ]
    };
  }, [
    duration, failedSkills, hasSelectedActivities, pendingSkills, skills, supervisor, uid, tab41, tab42, tab43,
    values, handleDurationChange, HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING
  ]);

  if ((failed || activities) && !hasSelectedActivities && atActivitiesTab) return (
    <Navigate
        replace
        to={(pathname === tab41 && tab11path) || (pathname === tab42 && tab12path) || tab13path}
    />
  );

  const chilrden = [<TargetSkills key={1} supervisor={supervisor} uid={uid}/>];
  if (HAS_COURSES_READONLY) chilrden.push(
    <LearningProgress key={2} supervisor={supervisor} uid={uid} duration={duration}/>
  );
  if (HAS_MENTORING) chilrden.push(
    <Mentorship
        key={3}
        supervisor={supervisor ? true : undefined}
        uid={uid || undefined}
        employeeName={employeeName || undefined}
    />
  );
  if (hasSelectedActivities) chilrden.push(
    <EmployeeSelectedActivities key={4} supervisor={supervisor} uid={uid} testPending={testPending}/>
  );

  return (
    <ContentCard>
      {items.length > 1 ? (
        <CardTabbar
            savedPathname={savedPathname}
            wideAction
            items={items}
        >
          {chilrden}
        </CardTabbar>
      ) : (
        <>
          <CardTitle
              title={supervisor ? 'skills.tab.employees_target_skills' : 'skills.tab.my_target_skills'}
              values={values}
              withDivider
          />
          {chilrden}
        </>
      )}
    </ContentCard>
  );
};

SkillsDevelopment.propTypes = SkillsDevelopmentPropTypes;

export default memo(SkillsDevelopment);
